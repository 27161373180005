import React, { useState, useEffect } from 'react';
import { Table, Dropdown, Menu, Badge, Button, Modal, Input, Form, Avatar,Switch } from 'antd';
import { history } from '../../../Helpers';
import { placesActions } from '../../../actions';
import { connect } from 'react-redux';
import { SettingOutlined} from '@ant-design/icons';
import {config} from '../../../config'
import moment from 'moment';

const { confirm } = Modal;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};



type UserPlaces = {
  dispatch: any;
  data: any;
}



function UserPlaces(props: UserPlaces) {
  let fetchId: any = '';
  const [form] = Form.useForm();
  const { dispatch, data } = props;
  const [page,setPage]=useState(1);
  const [querySearch,setQuerySearch]=useState('""');
  const [pageLimit,setPageLimit]=useState(10);


  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {

      dispatch(placesActions.fetchAllUserPlacesAdmin(page,querySearch,pageLimit));
    }

  }, [dispatch]);



  const [selectedRowKeys, setselectedRowKeys] = useState([]);


  const onSelectChange = (selectedRowKeys: any) => {
    setselectedRowKeys(selectedRowKeys)

  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  if (!data) {
    return (<div></div>)
  }
  console.log({data})
  const format = "YYYY-MM-DD HH:mm"
  let userStatus: any = '';
  let image: any = '';

  let isFrom:any='N/A';
  let search = (value: any) => {
    if(value)
    {
    //  dispatch(userActions.fetchAllUsersAdmin(page,value,pageLimit));
     setQuerySearch(value)
    }
    else
    {
      dispatch(placesActions.fetchAllUserPlacesAdmin(page,"",pageLimit));
      setQuerySearch("")
    }

  };
  const usersArray: any = [];

  let added: any = '';
  data.docs.forEach((element: any, index: any) => {
    if (element.userplaces.image) {

      image =config.s3BucketUrl+element.userplaces.image[0]
    }
    else {
      image = "N/A"
    }
    if (element.userplaces.isActive) {
      userStatus = <Badge status="success" text="Active" />

    }
    else{
      userStatus = <Badge status="default" text="inActive" />
    }
    if (element.userplaces.isAdded) {
      added = <Badge status="success" text="Added" />

    }
    else{
      added = <Badge status="default" text="Not Yet" />
    }
    usersArray.push({
      key: element.userplaces._id,
      id: element.userplaces._id,
      placeName: element.userplaces.placeName,
      fullName: element.users.firstName + " " + element.users.lastName,
      photo:image,
      isAdded:added,
      userStatus:userStatus,
      createdDate: moment(element.users.createdDate).format(format),

    })
  });
  const onPageChange=(e:any)=>{
   dispatch(placesActions.fetchAllUserPlacesAdmin(e,querySearch,pageLimit));
    setPage(e);
  }
  const onMenuClick = (id: any) => {
    fetchId = id;
  }
  const onSelect = (e: any) => {
    if (e.key == "EU") {
      history.push('/pr-admin-cp/edit-user-place/' + fetchId)
    }
    else {
      Modal.warning({
        title: 'Opppssss',

        content: (
          <div className="warning-modal">
            <p>Are you sure you want to Delete?</p>
          </div>
        ),
        className: "cancel-modal",
        width: 560,
        okType: 'danger',
        okText: 'ok',
        okCancel: true,
        centered: true,
        cancelText: 'cancel',
        onOk: () => {
          // dispatch(userActions.DeActiveUser(fetchId));
          // dispatch(userActions.fetchAllUsers());
        },
        onCancel() {
        },
      });

    }

  }

  const onpagesizeChange=(current:any,size:any) => {
    setPageLimit(size);
    dispatch(placesActions.fetchAllUserPlacesAdmin(page,querySearch,size));

  }
  const onImageClick =(image:any) =>{
    window.open(image);
}
  const columns = [
    {
      title: 'Photo',
      dataIndex: 'photo',
      render: (records: any) =><div onClick={()=>onImageClick(records)}><Avatar className="table-img"  src={records}></Avatar></div>

    },
    {
      title: 'Place Name',
      dataIndex: 'placeName',
    },
    {
      title: 'User Name',
      dataIndex: 'fullName',
    },
    // {
    //   title: 'Session',
    //   render: (records: any) =>
    //     <a className="table-img" onClick={()=>onSessionClick(records.id)} >
    //       check session
    //     </a>
    // },

    {
      title: 'Creation Date',
      dataIndex: 'createdDate',
    },
    {
      title: 'User Status',
      dataIndex: 'userStatus',
    },
    {
      title: 'Added',
      dataIndex: 'isAdded',
    },
    {
      title: 'Settings',
      render: (records: any) => <Dropdown className="admin-acc" overlay={(
        <Menu onClick={onSelect}>
          <Menu.Item key="EU" onClick={() => onMenuClick(records.id)} className="user-menu-item">
            <span>
              Edit Place
          </span>

          </Menu.Item>
          <Menu.Item key="DU" onClick={() => onMenuClick(records.id)} className="user-menu-item">
            <span>
              Delete Place
          </span>
          </Menu.Item>
        </Menu>
      )} trigger={['click']} >
        <SettingOutlined />
      </Dropdown>,
    },
  ];

  return (
    <div>
      <div style={{ width: '50%', float: 'left' }}>
        <Input.Search
          placeholder="Search by..."
          enterButton
          size="large"
          onSearch={search}
        />
      </div>
          <Table className="show-table"  pagination={{total:data.totalDocs,pageSizeOptions:["10","20","50","100"],onShowSizeChange:onpagesizeChange,onChange:onPageChange}} rowSelection={rowSelection} columns={columns} dataSource={usersArray} />
    </div>
  );

}

function mapStateToProps(state: any) {

  return {
    data: state.GetUserAllPlaces.getUserAllPlacesObject

  };
}

export const connectedUserPlaces = connect(mapStateToProps)(UserPlaces);

