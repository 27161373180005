import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom'
import { friendsActions } from '../../actions';
import { Avatar} from "@chakra-ui/core";
import Icon from "@ant-design/icons";
import moment from 'moment';
import './cards.css';

type UserCard={
  profile:{
    id:string,
    firstName:string,
    lastName:string,
    role:any,
    intro:string,
    address:any,
    image:string,
    joinDate:string,
    gender:string,
    languages:any,
    DOB:any,
    playerId:any,
  };
  checkedUser:any;
  dispatch:any;
}
const FriendsSvg = () => (

  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      d="M3.783 2.826L12 1l8.217 1.826a1 1 0 0 1 .783.976v9.987a6 6 0 0 1-2.672 4.992L12 23l-6.328-4.219A6 6 0 0 1 3 13.79V3.802a1 1 0 0 1 .783-.976zM12 11a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm-4.473 5h8.946a4.5 4.5 0 0 0-8.946 0z"
      fill="rgba(55,180,78,1)"
    />
  </svg>
);

const FriendIcon = (props: any) => <Icon component={FriendsSvg} {...props} />;
export function UserCard(props:UserCard) {
  let user = JSON.parse(localStorage.getItem('user')!)
  const [isCurrentUser,setIsCurrentUser] = useState(false);
  const [finalDate,setFinalDate] = useState<any>();
  const {profile,checkedUser,dispatch} = props;
  let date:any ='';

  useEffect(()=>{
    if(profile.id.toString()===user.id.toString())
    {
      setIsCurrentUser(true)
    }
    if(profile.DOB)
    {
      date=moment(profile.DOB).format("yyyy-MM-DD");
      setFinalDate(moment().diff(date, 'years',false));
    }

    let lang:any='';
    if(profile.languages.length>1)
    {
     lang = profile.languages
    }
  },[])
  const onUnfriend=(id:any)=>{
    dispatch(friendsActions.UnfriendRequest(id))
     }
     const onFriendRequest=()=>{
       let data ={
         senderId:user.id,
         receiverId:profile.id,
         firstName:user.firstName,
         lastName:user.lastName,
         userName:user.user.userName,
         playerId:profile.playerId,
        }
        dispatch(friendsActions.SendFriendRequest(data));
     }

     const onRespondRequest=(requestedId:any)=>{

       let data={
         firstName:user.firstName,
         lastName:user.lastName,
         userName:user.user.userName,
         senderId:profile.id,
         receiverId:user.id,
         playerId:profile.playerId,
        }
        dispatch(friendsActions.AcceptFriendRequest(data,requestedId));

     }
     const onCancelRequest=()=>{
           let data={
         senderId:user.id,
         receiverId:profile.id
       }
       dispatch(friendsActions.CancelFriendRequest(data))
     }

  return (
    <div className="main-cont-user">
      <div className="User-main-container">
        <div className="usr-inr-container">
          <div className="user-avatar">
            <Avatar
            onClick={()=>window.open(profile.image)}
              size="2xl"
              name={profile.firstName + profile.lastName}
              src={profile.image}
            />
          </div>
          <div className="user-name-container">
            <p className="user-name-txt" >
            {profile.firstName+" "+ profile.lastName}
            </p>
          </div>
          <div className="user-name-container">
          <div className="tripmate-btn-container">
            {
              checkedUser.isfriends?
              <button className="un-friend-btn " onClick={()=>onUnfriend(checkedUser.friend)} >
              Remove Tripmate
            <FriendIcon />
            </button>
            :
            checkedUser.isReceiver?
            <button className="un-friend-btn " onClick={()=>onRespondRequest(checkedUser.friend)} >
            Accept Request
          <FriendIcon />
          </button>:
          checkedUser.isSender?
          <button className="un-friend-btn " onClick={onCancelRequest}>
          Cancel Request
        <FriendIcon />
        </button>
        :
        <button className="un-friend-btn " onClick={onFriendRequest}>
        Add Tripmate
      <FriendIcon />
      </button>
            }

            </div>
             </div>
          {/* <div className="user-role-container">
         <p className="user-role-txt">{profile.role}</p>
          </div> */}
          <div className="user-address-container">
          {
               profile.intro?

                <p className="user-address-txt">
                 {profile.intro}
                </p>
               :
               isCurrentUser?
               <div className="user-card-link-wrapper" style={{textAlign:'center'}}>
               <Link to={`/user/settings/${profile.id}`} className="user-data-link">Update your short intro</Link>
               </div>:
                 <p className="user-address-txt">
                 {profile.intro}
                </p>
             }
          </div>
          <div>
          <div className="badge-container-user">
            <div className="user-data-icon-wrapper">
            <i className="ri-admin-line user-data-icons"></i>
            </div>
            <div className="user-dynamic-data-wrapper">
            <span className="user-card-static-data">Joined on: </span>
              <span className="user-card-dynamic-data">{moment(profile.joinDate).format("MMMM DD, YYYY")} </span>
            </div>
            </div>
            <div className="badge-container-user">
            <div className="user-data-icon-wrapper">
            <i className="ri-map-pin-fill user-data-icons"></i>
            </div>
            {
               profile.address.country?
                <div className="user-dynamic-data-wrapper">
               <span className="user-card-static-data">From: </span>
                 <span className="user-card-dynamic-data">{profile.address.country} </span>
               </div>
               :
               isCurrentUser?
               <div className="user-card-link-wrapper">
               <Link to={`/user/settings/${profile.id}`} className="user-data-link" >Update your address.</Link>
            </div>:
             <div className="user-dynamic-data-wrapper">
             <span className="user-card-static-data">From: </span>
               <span className="user-card-dynamic-data">{profile.address.country} </span>
             </div>
             }
            </div>
            <div className="badge-container-user">
            <div className="user-data-icon-wrapper">
            <i className="ri-user-3-fill user-data-icons"></i>
            </div>
            <div className="user-dynamic-data-wrapper">
              {
                profile.gender?
                <span className="user-card-dynamic-data">{profile.gender+", "+finalDate }</span>
                :
                isCurrentUser?
                <div className="user-card-link-wrapper">
                 <Link to={`/user/settings/${profile.id}`} className="user-data-link">Update your age and gender.</Link>
                 </div>:
                  <span className="user-card-dynamic-data">{profile.gender+", "+finalDate }</span>
              }

            </div>
            </div>
            <div className="badge-container-user">
            <div className="user-data-icon-wrapper">
            <i className="ri-chat-smile-fill user-data-icons"></i>
            </div>
            <div className="user-dynamic-data-wrapper">
            {
              profile.languages.length>1?
                  profile.languages.map((item: any) => (
                    <span className="user-card-dynamic-data">{item+", " }</span>
                  ))
                  :
                  isCurrentUser?
                  <div className="user-card-link-wrapper">
                  <Link to={`/user/settings/${profile.id}`} className="user-data-link">Update your languages.</Link>
                  </div>
                  :
                  profile.languages.length>1?
                  profile.languages.map((item: any) => (
                    <span className="user-card-dynamic-data">{item+", " }</span>
                  ))
                  :null
               }
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
