import {facilitiesConstants} from '../constants';

export function FetchFacilities(state={getAllFacilitiesObject:false},action:any)
{

    switch (action.type) {
        case facilitiesConstants.GET_ALL_FACILITIES_REQUEST:
          return state;
          case facilitiesConstants.GET_ALL_FACILITIES_SUCCESS:
          return{
            ...state,
            getAllFacilitiesObject:action.user
          };
      default:
        return state
    }
}

export function FetchEditActivity(state={fetchEditActivityObject:""},action:any)
{
  switch (action.type) {
    case facilitiesConstants.EDIT_ACTIVITY_REQUEST:
      return state;
      case facilitiesConstants.EDIT_ACTIVITY_SUCCESS:
      return{
        ...state,
        fetchEditActivityObject:action.user
      };
  default:
    return state
}
}

