import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Upload, message, Select, Modal } from 'antd';
import { CloudUploadOutlined } from "@ant-design/icons";
import { history} from '../../Helpers';
import { connect } from 'react-redux';
import NavBar from '../Navbar/Navbar'
import { storyActions, categoryActions } from '../../actions';
import { config } from "../../config";
import ReactGA from 'react-ga';
import ReactQuill from 'react-quill';
import './stories.css';
require('medium-editor/dist/css/themes/tim.css');
require('medium-editor/dist/css/medium-editor.css');

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};
const modules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    [ 'image'],
    ['clean']
  ],
}

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
]


const { Option } = Select;

type EditStories = {
  dispatch: any;
  match: any;
  stories: any;
  places: any
}

const { Dragger } = Upload;

const prop: any = {
  name: 'userImage',
  action: '',
  headers: {
    authorization: 'authorization-text',
  },
};
let image: any = '';

function beforeUpload(file: any) {

  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}


function UserEditStories(props: EditStories) {


  const { dispatch, match, stories, places } = props;
  let reason: any = '';
  const onFinish = (values: any) => {
    const data = new FormData()
    let isUnPulish=false;
    data.append("title", values.title)
    data.append("story", values.quil)
    data.append("place",values.places )
    if (values.userImage) {
      data.append("storyImage", values.userImage.file.originFileObj)
    }
    dispatch(storyActions.UpdateStory(data, match.params.id,isUnPulish));

  };
  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    let user = JSON.parse(localStorage.getItem('user')!)
    if (!user) {
      history.push('/login')
    }
    else {
      dispatch(storyActions.FetchEditStories(match.params.id));
      dispatch(categoryActions.FetchAllPlaceCategories());
    }

  }, [dispatch,match]);
  const onQuilValueChange = (value: any) => {
    // setquilValue(value);

  }

  const onFinishFailed = (errorInfo: any) => {
  };
  // const onUnpublish = () => {
  //   Modal.warning({
  //     title: 'UnPublish',
  //     content: (
  //       <div className="warning-modal">
  //         <Form.Item className="lbl-name" label="Reason"></Form.Item>
  //         <ReactQuill style={{ height: '200px', marginBottom: '20px' }} theme="snow" onChange={onUnPublishChange} />
  //       </div>
  //     ),
  //     className: "cancel-modal",
  //     width: 560,
  //     okType: 'danger',
  //     okText: 'UnPublish',
  //     okCancel: true,
  //     centered: true,
  //     cancelText: 'cancel',
  //     onOk: () => {
  //       let isUnpulishFlag = true;
  //       dispatch(storyActions.UpdateStory(reason, match.params.id, isUnpulishFlag));

  //     },
  //     onCancel() {
  //     },
  //   });

  // }
  const onUnPublishChange = (value: any) => {
    reason = {
      value: value
    };
  }

  const onDelete = () => {
    Modal.error({
      title: 'Deltete',
      content: (
        <div className="warning-modal">
          <Form.Item className="lbl-name" label="Reason"></Form.Item>
          <ReactQuill style={{ height: '200px', marginBottom: '20px' }} theme="snow" onChange={onUnPublishChange} />
        </div>
      ),
      className: "cancel-modal",
      width: 560,
      okType: 'danger',
      okText: 'Delete',
      okCancel: true,
      centered: true,
      cancelText: 'cancel',
      onOk: () => {
        dispatch(storyActions.DeleteStory(reason.value,match.params.id));


      },
      onCancel() {
      },
    });
  }

  if (!stories) {
    return (<div></div>)
  }


  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
  };

  image = config.s3BucketUrl+ stories[0].image
  return (
    <div>
      <NavBar/>
      <div className="inner-container-banner-stories">
      <Form
        {...layout}
        className="admin-login-form"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item className="lbl-name" label="Title"></Form.Item>
        <Form.Item
          initialValue={stories[0].title}
          name="title"
          rules={[{ required: true, message: 'Please enter a title.' },]}
        >
          <Input name="title" size="large" placeholder="title" />
        </Form.Item>
        <Form.Item className="lbl-name" label="Place Name"></Form.Item>
        <Form.Item
          hasFeedback
          name="places"
          initialValue={stories[0].place._id}
          rules={[{ required: true, message: 'Please enter a place.' },]}
        >
          <Select  placeholder="select a place" size="large" >

            {
              places.length > 0 ?
                places.map((item: any) => <Option selected="selected" value={item._id} >
                  {item.placeName}
                </Option >)
                :
                null
            }
          </Select>
        </Form.Item>
        <Form.Item className="lbl-name" label="Photo"></Form.Item>
        <Form.Item
          name="userImage"
          className="file-upload"
        >
          <Dragger
            beforeUpload={beforeUpload}
            onPreview={onPreview}
            multiple={false}
            {...prop}
            listType="picture-card">

            <p className="ant-upload-drag-icon">
              <CloudUploadOutlined style={{ color: "#000000" }} />
              <span className="upld-desc" > Upload or drop photo here</span>
            </p>
          </Dragger>
        </Form.Item>
        <Form.Item style={{ width: "50%" }}>
          <img src={image} width="30%" style={{ textAlign: 'center' }} />
        </Form.Item>
        <Form.Item
          initialValue={stories[0].story}
          name="quil"
        >
          <ReactQuill style={{ height: '200px', marginBottom: '20px' }} theme="snow" modules={modules} formats={formats}  onChange={onQuilValueChange} />
        </Form.Item>
        <Form.Item >
          <Button style={{ marginTop: '50px', width: '30%', marginRight: '20px' }} className="btn-admin-login" size="large" type="primary" htmlType="submit">
            Update Story
          </Button>
        </Form.Item>
      </Form>
      </div>
    </div>
  )
}
function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    stories: state.FetchEditStories.fetchEditStoriesObject,
    type,
    message,
    places: state.FetchPlaceCategories.getAllPlaceCategoriesObject,
  };
}

export const connectedUserEditStories = connect(mapStateToProps)(UserEditStories);
