import {placesConstants} from '../constants';

export function FetchRelatedPlaces(state={getRelatedPlacesObject:false},action:any)
{

    switch (action.type) {
        case placesConstants.GET_RELATED_PLACES_REQUEST:
          return state;
          case placesConstants.GET_RELATED_PLACES_SUCCESS:
          return{
            ...state,
            getRelatedPlacesObject:action.user
          };
      default:
        return state
    }
}

export function GetUserPlaceImageLimit(state={getUserPlaceImageLimitObject:""},action:any)
{

    switch (action.type) {
        case placesConstants.GET_USER_PLACE_IMAGES_LIMIT_REQUEST:
          return state;
          case placesConstants.GET_USER_PLACE_IMAGES_LIMIT_SUCCESS:
          return{
            ...state,
            getUserPlaceImageLimitObject:action.user
          };
      default:
        return state
    }
}

export function GetUserAllPlaceImage(state={getUserAllPlaceImageObject:""},action:any)
{

    switch (action.type) {
        case placesConstants.GET_USER_PLACE_IMAGES_REQUEST:
          return state;
          case placesConstants.GET_USER_PLACE_IMAGES_SUCCESS:
          return{
            ...state,
            getUserAllPlaceImageObject:action.user
          };
      default:
        return state
    }
}

export function GetUserAllPlaceImageAdmin(state={getUserAllPlaceImageAdminObject:""},action:any)
{
    switch (action.type) {
        case placesConstants.GET_USER_IMAGES_ADMIN_REQUEST:
          return state;
          case placesConstants.GET_USER_IMAGES_ADMIN_SUCCESS:
          return{
            ...state,
            getUserAllPlaceImageAdminObject:action.user
          };
      default:
        return state
    }
}


export function GetUserAllPlaceCountAdmin(state={getUserAllPlaceCountAdminObject:""},action:any)
{
    switch (action.type) {
        case placesConstants.GET_USER_PLACE_COUNT_ADMIN_REQUEST:
          return state;
          case placesConstants.GET_USER_PLACE_COUNT_ADMIN_SUCCESS:
          return{
            ...state,
            getUserAllPlaceCountAdminObject:action.user
          };
      default:
        return state
    }
}

export function GetAllPlacesAdmin(state={getAllPlacesAdminObject:""},action:any)
{

    switch (action.type) {
        case placesConstants.GET_ALL_PLACES_ADMIN_REQUEST:
          return state;
          case placesConstants.GET_ALL_PLACES_ADMIN_SUCCESS:
          return{
            ...state,
            getAllPlacesAdminObject:action.user
          };
      default:
        return state
    }
}

export function GetUserAllPlaces(state={getUserAllPlacesObject:""},action:any)
{

    switch (action.type) {
        case placesConstants.GET_USER_ALL_PLACES_ADMIN_REQUEST:
          return state;
          case placesConstants.GET_USER_ALL_PLACES_ADMIN_SUCCESS:
          return{
            ...state,
            getUserAllPlacesObject:action.user
          };
      default:
        return state
    }
}

export function GetUserPlace(state={getUserPlaceObject:""},action:any)
{

    switch (action.type) {
        case placesConstants.GET_USER_SINGLE_PLACE_ADMIN_REQUEST:
          return state;
          case placesConstants.GET_USER_SINGLE_PLACE_ADMIN_SUCCESS:
          return{
            ...state,
            getUserPlaceObject:action.user
          };
      default:
        return state
    }
}
