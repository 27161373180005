import React, { useState, useEffect } from 'react';
import { Table, Dropdown, Menu, Select, Button, Modal, Input, Form, Avatar, Upload, message,Switch} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CloudUploadOutlined,SettingOutlined,CloseOutlined, CheckOutlined,ExclamationCircleOutlined} from "@ant-design/icons";
import { history } from '../../../Helpers';
import { categoryActions, facilitiesActions } from '../../../actions';
import { connect } from 'react-redux';
import { config } from "../../../config";
import { Instagram  } from 'react-content-loader'
import moment from 'moment';
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyCD3_32W8jvGAUj2mvcPKvbfc5Yt7zCVq0");

const format = "YYYY-MM-DD HH:mm";
const { confirm } = Modal;
const { Option } = Select;
function beforeUpload(file: any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const { TextArea } = Input;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};



type User = {
  dispatch: any;
  data: any;
  categories: any;
  facilities: any;
}

const { Dragger } = Upload;

const prop: any = {
  name: 'userImage',
  action: '',
  headers: {
    authorization: 'authorization-text',
  },
};

const onPreview = async (file: any) => {
  let src = file.url;
  if (!src) {
    src = await new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  }
  const image = new Image();
  image.src = src;
  const imgWindow: any = window.open(src);
  imgWindow.document.write(image.outerHTML);
};

function PlaceCategories(props: User) {
  let formRef:any = React.createRef<FormInstance>();
  let fetchId: any = '';
  const { dispatch, data, categories, facilities } = props;
  const [visible, setvisible] = useState(false);
  const [checkSearch, setcheckSearch] = useState(false);
  const [filterData, setfilterData] = useState();
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {
      dispatch(categoryActions.FetchAllPlaceCategories());
    }

  }, [dispatch]);


  const [selectedRowKeys, setselectedRowKeys] = useState([]);


  const onSelectChange = (selectedRowKeys: any) => {
    setselectedRowKeys(selectedRowKeys)

  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };


  if (!data) {
    return (<div>
     <Instagram />
    </div>)
  }
  let filterArray: any = [];
  let catergory: string = '';
  let search = (value: any) => {

    const filterArrayData = data.filter((o: any) =>
      Object.keys(o).some(k =>
        String(o[k])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );
    filterArrayData.forEach((element: any, index: any) => {
      if (element.image) {
        imagee = config.s3BucketUrl+ element.image[0];
      }
      else {
        imagee = "N/A"
      }
      if (element.categories) {
        catergory = element.categories.categoryName;
      }

      filterArray.push({
        key: element._id,
        id: element._id,
        Photo: imagee,
        placeName: element.placeName,
        category: catergory,
        createdDate:moment(element.createdDate).format(format),
        isFeatured:element.isFeatured
      })

    });
    setfilterData(filterArray);
    setcheckSearch(true);
  };
  const categoriesArray: any = [];
  let imagee: any = '';
  data.forEach((element: any, index: any) => {
    if (element.image) {
      imagee = config.s3BucketUrl+element.image[0];
    }
    else {
      imagee = "N/A"
    }
    if (element.categories) {
      catergory = element.categories.categoryName;
    }

    categoriesArray.push({
      key: element._id,
      id: element._id,
      Photo: imagee,
      placeName: element.placeName,
      category: catergory,
      createdDate: moment(element.createdDate).format(format),
      isFeatured:element.isFeatured
    })

  });

  const showModal = () => {
    dispatch(categoryActions.FetchAllCategories());
    dispatch(facilitiesActions.FetchAllFacilities());
    setvisible(true);

  };

  const handleOk = (e: any) => {
    setvisible(false);
    dispatch(categoryActions.FetchAllPlaceCategories());

  };

  const handleCancel = (e: any) => {
    setvisible(false);
  };

  const onFinishFailed = (errorInfo: any) => {
  };
  const onMenuClick = (id: any) => {
    fetchId = id;
  }
  const onSelect = (e: any) => {
    if (e.key == "EPC") {
      history.push('/pr-admin-cp/edit-place/' + fetchId)
    }
    else {
      Modal.warning({
        title: 'Opppssss',

        content: (
          <div className="warning-modal">
            <p>Are you sure you want to delete this?</p>
          </div>
        ),
        className: "cancel-modal",
        width: 560,
        okType: 'danger',
        okText: 'ok',
        okCancel: true,
        centered: true,
        cancelText: 'cancel',
        onOk: () => {
          dispatch(categoryActions.DeletePlace(fetchId));
          dispatch(categoryActions.FetchAllPlaceCategories());
        },
        onCancel() {
        },
      });
    }

  }
  const  getLatitude =(value:any)=>{
      //  setlatitude(value.target.value);

  }
  const  getLongitude =(value:any)=>{
    // setlongitude(value.target.value);

}
  const prefixSelector = (
    <Form.Item initialValue={"+92"} name="prefix" noStyle>
      <Select inputValue="+92" style={{ width: 70 }}>
        <Option value="+92">+92</Option>
        <Option value="+91">+91</Option>
      </Select>
    </Form.Item>
  );
  const onImageClick = (image: any) => {
    window.open(image);
  }
  // const onGetAddress=()=>{
  //   try {
  //     Geocode.fromLatLng(latitude, longitude).then((response:any) => {
  //       if(response.status==="OK")
  //       {
  //       }
  //       else
  //       {
  //       }


  //       },
  //       error => {
  //         message.error("address not found")
  //       }
  //     );

  //   } catch (error) {
  //   }
  // }
  const onFeaturedChange=(records:any)=>{
    let dat={
      isFeatured:!records.isFeatured
    }
    if(!records.isFeatured)
    {
      confirm({
        title: 'Do you Want to add this user as featured place?',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          dispatch(categoryActions.FeaturedPlace(dat,records.key));
        },
        onCancel() {
        },
      });
    }
    else
    {
      confirm({
        title: 'Do you Want to add this user as featured place?',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          dispatch(categoryActions.FeaturedPlace(dat,records.key));
        },
        onCancel() {
        },
      });
    }
  }

  const onFinish = (values: any) => {
    let valueArray:any=[];
    if(values.facilities)
    { values.facilities.forEach((element:any) => {
      valueArray.push({
        _id:element
      });
    });
    }

  let result = facilities.filter((o1:any) => valueArray.some((o2:any) => o1._id === o2._id));
   let images:any=[];
    values.placeImage.fileList.forEach((element:any) => {
      images.push(element.originFileObj)

    });

    const data = new FormData()
    if(!values.accessibleVia)
    {
      data.append("accessibleVia", 'Information will be updated soon')
    }
    else
    {
      data.append("accessibleVia", values.accessibleVia)
    }
    if(!values.availableFor)
    {
      data.append("availableFor", 'Information will be updated soon')
    }
    else
    {
      data.append("availableFor", values.availableFor)
    }
    if(!values.foodsAvailable)
    {
      data.append("foodsAvailable", 'Information will be updated soon')
    }
    else
    {
      data.append("foodsAvailable", values.foodsAvailable)
    }
    if(!values.roadCondition)
    {
      data.append("roadCondition", 'Information will be updated soon')
    }
    else
    {
      data.append("roadCondition", values.roadCondition)
    }
    if(!values.phone ||!values.prefix)
    {
      data.append("prefix", "")
      data.append("phone", 'Information will be updated soon')
    }
    else
    {
      data.append("phone", values.phone)
      data.append("prefix", values.prefix)
    }
    if(!values.description)
    {
      data.append("description", 'Information will be updated soon')
    }
    else
    {
      data.append("description", values.description)
    }
    data.append("placeName", values.placeName)
    data.append("address", values.address)
    data.append("city", values.city)
    data.append("province", values.province)
    data.append("latitude", values.latitude)

    data.append("longititude", values.longitude)
    data.append("categories", values.categoryName)
    data.append("facilities",JSON.stringify(result))
    for (let i = 0; i <  values.placeImage.fileList.length; i++) {
     data.append("placeImage",values.placeImage.fileList[i].originFileObj)
    }
    dispatch(categoryActions.AddPlaceCategories(data));
    formRef.current.resetFields();
    dispatch(categoryActions.FetchAllCategories());
  };
  const columns = [
    {
      title: 'Photo',
      dataIndex: 'Photo',
      render: (records: any) => <div onClick={() => onImageClick(records)}><Avatar className="table-img" src={records}></Avatar></div>

    },
    {
      title: 'Place Name',
      dataIndex: 'placeName',
    },
    {
      title: 'Category',
      dataIndex: 'category',
    },
    {
      title: 'Created  Date',
      dataIndex: 'createdDate',
    },
    {
      title: 'Featured',
      dataIndex: 'isFeatured',
      render:(e:any,records:any)=> <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked={e}
      onChange={()=>onFeaturedChange(records)}
    />,
    },
    {
      title: 'Settings',
      render: (records: any) => <Dropdown className="admin-acc" overlay={(
        <Menu onClick={onSelect}>
          <Menu.Item key="EPC" onClick={() => onMenuClick(records.id)} className="user-menu-item">
            <span>
              Edit Place
          </span>
          </Menu.Item>
          <Menu.Item key="DPC" onClick={() => onMenuClick(records.id)} className="user-menu-item">
            <span>
              Delete Place
          </span>
          </Menu.Item>
        </Menu>
      )} trigger={['click']} >
        <SettingOutlined />
      </Dropdown>,
    },
  ];

  return (
    <div>
      <div style={{ width: '50%', float: 'left' }}>
        <Input.Search
          placeholder="Search by..."
          enterButton
          size="large"
          onSearch={search}
        />
      </div>
      <p style={{textAlign:'center',fontWeight:'bold',fontSize:'30px'}}>Total: {data.length}</p>
      <div style={{ marginBottom: "20px", textAlign: "right" }}>
        <Button type="primary" onClick={showModal} >Add Place </Button>
      </div>
      {
        checkSearch ? <Table className="show-table" rowSelection={rowSelection} columns={columns} dataSource={filterData} /> :
          <Table className="show-table" rowSelection={rowSelection} columns={columns} dataSource={categoriesArray} />

      }

      <Modal
        title="Add a Place"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
         width="70%"
      >
        <div className=''>
          <Form
            {...layout}
            ref={formRef}
            className="admin-login-form"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item className="lbl-name" label="Place Name"></Form.Item>
            <Form.Item
              hasFeedback
              name="placeName"
              rules={[{ required: true, message: 'Please enter a Place name.' },]}
            >
              <Input name="placeName" size="large" placeholder="Place Name" />
            </Form.Item>

            <Form.Item className="lbl-name" label="Category Name"></Form.Item>
            <Form.Item
              hasFeedback
              name="categoryName"
              rules={[{ required: true, message: 'Please enter a category.' },]}
            >
              <Select placeholder="select a category" size="large" >

                {
                  categories.length > 0 ?
                    categories.map((item: any) => <Option value={item._id} >
                      {item.categoryName}
                    </Option >)
                    :
                    <Option value="1">data</Option>
                }
              </Select>
            </Form.Item>
            <Form.Item className="lbl-name" label="Facilities"></Form.Item>
            <Form.Item
              hasFeedback
              name="facilities"
              // rules={[{type: 'array' }]}
            >
              <Select mode="multiple" placeholder="select facilities" size="large" >

                {
                  facilities.length > 0 ?
                    facilities.map((item: any) => <Option value={item._id} >
                      {item.title}
                    </Option >)
                    :
                    <Option value="1">data</Option>
                }
              </Select>
            </Form.Item>
            <Form.Item className="lbl-name" label="Latitude "></Form.Item>
            <Form.Item
              hasFeedback
              name="latitude"
              rules={[
                {
                  pattern: new RegExp(/^\S*$/),
                  message:'space is not allowed'
                },
                { required: true, message: 'Please enter a latitude.' }]}
            >
              <Input name="latitude" size="large" placeholder="Latitude" />
            </Form.Item>

            <Form.Item className="lbl-name" label="Longitude"></Form.Item>
            <Form.Item
              hasFeedback
              name="longitude"
              rules={[
                {
                  pattern: new RegExp(/^\S*$/),
                  message:'space is not allowed'
                },
                { required: true, message: 'Please enter a longitude.' }]}
            >
              <Input name="longitude" size="large" placeholder="Longitude" />
            </Form.Item>
            {/* <Button className="btn-admin-login" style={{width:'130px'}} size="large" onClick={onGetAddress} type="primary">
                      Get Address
               </Button> */}
            <Form.Item className="lbl-name" label="Address"></Form.Item>
            {/* {
              address?
              <Form.Item
              hasFeedback
              name="addresss"
              initialValue={address}
              rules={[{ required: true, message: 'Please enter an address .' },]}
            >
              <Input name="addresss" size="large" placeholder="Address" />
            </Form.Item>
            : */}
            <Form.Item
            hasFeedback
            name="address"
            rules={[{ required: true, message: 'Please enter an address .' },]}
          >

            <Input name="address" size="large" placeholder="Address" />
          </Form.Item>
            {/* } */}
            <Form.Item className="lbl-name" label="Province"></Form.Item>
            <Form.Item
              hasFeedback
              name="province"
              rules={[{ required: true, message: 'Please enter a Province.' },]}
            >
              <Select placeholder="select a province" size="large" >
                    <Option value="Azad Kashmir">Azad Kashmir</Option>
                    <Option value="Balochistan">Balochistan</Option>
                    <Option value="Gilgit Baltistan">Gilgit Baltistan</Option>
                    <Option value="Islamabad Capital Territory">Islamabad Capital Territory</Option>
                    <Option value="Khyber Pakhtunkhwa">Khyber Pakhtunkhwa</Option>
                    <Option value="Punjab">Punjab</Option>
                    <Option value="Sindh">Sindh</Option>
              </Select>
            {/* <Form.Item className="lbl-name" label="Province"></Form.Item>
            {/* {provience?
             <Form.Item
             hasFeedback
             initialValue={provience}
             name="provincee"
             rules={[{ required: true, message: 'Please enter a Province.' },]}
           >
             <Input name="provincee" size="large" placeholder="Province" />
           </Form.Item>
           : */}
           {/* <Form.Item
             hasFeedback
             name="province"
             rules={[{ required: true, message: 'Please enter a Province.' },]}
           >
             <Input name="province" size="large" placeholder="Province" /> */}
           </Form.Item>
            {/* } */}

            <Form.Item className="lbl-name" label="City"></Form.Item>
            {/* {
              city?
              <Form.Item
              hasFeedback
              initialValue={city}
              name="cityy"
            >
              <Input name="cityy" size="large" placeholder="City" />
            </Form.Item>
            : */}
            <Form.Item
            hasFeedback
            name="city"
            rules={[{ required: true, message: 'Please enter a city name.' },]}
          >
            <Input name="city" size="large" placeholder="City" />
          </Form.Item>
            {/* } */}

            <Form.Item className="lbl-name" label="Phone"></Form.Item>
            <Form.Item
              hasFeedback
              initialValue={"3237257476"}
              name="phone"
              rules={[{
                min: 10,
                message: 'Phone must be minimum 10 characters.'
              }
              ]}>
              <Input addonBefore={prefixSelector} size="large" />
            </Form.Item>
            <Form.Item className="lbl-name" label="Description"></Form.Item>
            <Form.Item
              hasFeedback
              name="description"
            >
              <TextArea name="lastName" placeholder="Description" rows={4}
              />
            </Form.Item>
            <Form.Item className="lbl-name" label="Available for"></Form.Item>
            <Form.Item
              hasFeedback
              name="availableFor"
            >
              <Select mode="multiple" placeholder="Available for" size="large" >
                  <Option value="Everyone">
                    Everyone
                  </Option>
                  <Option value="Family">
                    Family
                  </Option>
                  <Option value="Infants">
                  Infants
                  </Option>
                  <Option value="Adults">
                  Adults
                  </Option>
                  <Option value="Elders">
                  Elders
                  </Option>
                  <Option value="Kids">
                  Kids
                  </Option>
                  <Option value="Disable People">
                  Disable People
                  </Option>
              </Select>
            </Form.Item>
            <Form.Item className="lbl-name" label="Accessible via"></Form.Item>
            <Form.Item
              hasFeedback
              name="accessibleVia"
            >
              <Select mode="multiple" placeholder="Accessible Via" size="large" >
                  <Option value="Walk">
                     Walk
                  </Option>
                  <Option value="Bike">
                    Bike
                  </Option>
                  <Option value="Car">
                    Car
                  </Option>
                  <Option value="Bus">
                    Bus
                  </Option>
                  <Option value=" 4x4 Jeep">
                    4x4 Jeep
                  </Option>
                  <Option value="Hike">
                    Hike
                  </Option>
                  <Option value="Climb">
                    Climb
                  </Option>
              </Select>
            </Form.Item>
            <Form.Item className="lbl-name" label="Foods available"></Form.Item>
            <Form.Item
              hasFeedback
              name="foodsAvailable"
            >
              <Input  size="large" placeholder="Foods available" />
            </Form.Item>
            <Form.Item className="lbl-name" label="Road condition"></Form.Item>
            <Form.Item
              hasFeedback
              name="roadCondition"
            >
              <Input  size="large" placeholder="Road Condition" />
            </Form.Item>
            <Form.Item className="lbl-name" label="Photo"></Form.Item>
            <Form.Item
              name="placeImage"
              rules={[{ required: true, message: 'Please upload atleast one image.' }]}
              className="file-upload">
              <Dragger  name="placeImage" beforeUpload={beforeUpload}
                onPreview={onPreview}
                multiple={true}
                {...prop}
                listType="picture-card">

                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined style={{ color: "#000000" }} />
                  <span className="upld-desc" > Upload or drop photo here</span>
                </p>
              </Dragger>
            </Form.Item>
            <Form.Item >
              <Button className="btn-admin-login" size="large" type="primary" htmlType="submit">
                Add Place
                    </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );

}

function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    data: state.FetchPlaceCategories.getAllPlaceCategoriesObject,
    categories: state.FetchCategories.getAllCategoriesObject,
    facilities: state.FetchFacilities.getAllFacilitiesObject,
  };
}

export const connectedPlaceCategories = connect(mapStateToProps)(PlaceCategories);

