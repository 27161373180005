export const experiencesConstants ={
  GET_EXPERIENCE_BY_PLACE_REQUEST: "GET_EXPERIENCE_BY_PLACE_REQUEST",
  GET_EXPERIENCE_BY_PLACE_SUCCESS: "GET_EXPERIENCE_BY_PLACE_SUCCESS",
  GET_EXPERIENCE_BY_PLACE_FAILURE: "GET_EXPERIENCE_BY_PLACE_FAILURE",

  GET_ALL_EXPERIENCE_BY_PLACE_REQUEST: "GET_ALL_EXPERIENCE_BY_PLACE_REQUEST",
  GET_ALL_EXPERIENCE_BY_PLACE_SUCCESS: "GET_ALL_EXPERIENCE_BY_PLACE_SUCCESS",
  GET_ALL_EXPERIENCE_BY_PLACE_FAILURE: "GET_ALL_EXPERIENCE_BY_PLACE_FAILURE",

  GET_ALL_EXPERIENCE_ADMIN_REQUEST: "GET_ALL_EXPERIENCE_ADMIN_REQUEST",
  GET_ALL_EXPERIENCE_ADMIN_SUCCESS: "GET_ALL_EXPERIENCE_ADMIN_SUCCESS",
  GET_ALL_EXPERIENCE_ADMIN_FAILURE: "GET_ALL_EXPERIENCE_ADMIN_FAILURE",

  GET_EXPERIENCE_ADMIN_REQUEST: "GET_EXPERIENCE_ADMIN_REQUEST",
  GET_EXPERIENCE_ADMIN_SUCCESS: "GET_EXPERIENCE_ADMIN_SUCCESS",
  GET_EXPERIENCE_ADMIN_FAILURE: "GET_EXPERIENCE_ADMIN_FAILURE",

  UPDATE_EXPERIENCE_ADMIN_REQUEST: "UPDATE_EXPERIENCE_ADMIN_REQUEST",
  UPDATE_EXPERIENCE_ADMIN_SUCCESS: "UPDATE_EXPERIENCE_ADMIN_SUCCESS",
  UPDATE_EXPERIENCE_ADMIN_FAILURE: "UPDATE_EXPERIENCE_ADMIN_FAILURE",

  DELETE_EXPERIENCE_ADMIN_REQUEST: "DELETE_EXPERIENCE_ADMIN_REQUEST",
  DELETE_EXPERIENCE_ADMIN_SUCCESS: "DELETE_EXPERIENCE_ADMIN_SUCCESS",
  DELETE_EXPERIENCE_ADMIN_FAILURE: "DELETE_EXPERIENCE_ADMIN_FAILURE",
}
