import React, {useEffect } from 'react';
import {history} from './Helpers';
import {HomePage} from "./components/HomePage";
import SignUp from "./components/SignUp/signup";
import Login from "./components/Login/login";
import {connectedAdminLoginPage} from './components/Admin/Login';
import {connectedDashBoard} from './components/Admin/Dashboard';
import {connectedPakMap} from './components/PakMap';
import {connectedForgetPassword,connectedResendEmail} from './components/ForgetPassword';
import {connectedResetPassword} from './components/ResetPassword';
import {SignupSuccess} from './components/SignupSuccess';
import {connectedEmailVerificationPage} from './components/EmailVerification';
import {connectedUserDashBoard} from './components/Users';
import {connectedPlace,connectedSingleQuestion} from './components/Places';
import {connectedPlaceAllPhotos} from './components/PlacePhotos/place-photos';
import {connectedAllPlaceExperience} from './components/PlaceExperiences'
import {connectedAllQuestions} from './components/AllQuestions'
import {connectedAllAlbums,
  connectedCreateAlbum,
  connectedMyAlbums,
  connectedEditAlbumUser,
  connectedSingleAlbum} from './components/Albums';
  import {connectedUserSettings} from './components/UserSettings';
  import {connectedMembersPage} from './components/Members';
  import {connectedHelpPage,connectedReportPage,connectedTerms,connectedPrivacy,connectedContactPage,connectedCookies,connectedDataDeletion} from './components/Support';
  import {ErrorComponent} from './components/ErrorComponent'
  import {AppleAssociation} from './components/apple-app-site-association';
  import appStore from '../src/Assets/Apple Store@2x.png';
  import ReactGA from 'react-ga';
import googleStore from '../src/Assets/Google Play@2x.png';
import {
  BrowserView,
  MobileView,
} from "react-device-detect";
  // import OneSignal from 'react-onesignal';



import {connectedAllStories
  ,connectedStory,
  connectedMyStories,
  connectedUserEditStories,
  connectedWriteStory
} from './components/Stories';


import "antd/dist/antd.css";
import "../src/App.css"
import { Router, Route,Switch} from 'react-router-dom';
 function App() {
  ;
  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

   return(
     <div>
       <BrowserView>
       <Router history={history}>
        <Switch>
        <Route path='/' exact component={HomePage}/>
        <Route path='/pr-admin-cp/login'  component={connectedAdminLoginPage}/>
        <Route path='/pr-admin-cp'  component={connectedDashBoard}/>
         <Route path="/apple-app-site-association" component={AppleAssociation} />
        <Route path='/cookies-statement' exact component={connectedCookies}/>
        <Route path='/data-deletion' exact component={connectedDataDeletion}/>
        <Route path='/contact-us' exact component={connectedContactPage}/>
        <Route path='/privacy-policy' exact component={connectedPrivacy}/>
        <Route path='/terms-of-use' exact component={connectedTerms}/>
        <Route path='/report-a-problem' exact component={connectedReportPage}/>
        <Route path='/forget-password' exact component={connectedForgetPassword}/>
        <Route path='/resent-verification-email' exact component={connectedResendEmail}/>
        <Route path='/email-varification' exact component={SignupSuccess}/>
        <Route path='/help' exact component={connectedHelpPage}/>
        <Route path='/login' exact component={Login} />
        <Route path='/signup' exact  component={SignUp} />
        <Route path='/pak-map' exact  component={connectedPakMap}/>
        <Route path='/travel-albums/create-album' exact component={connectedCreateAlbum}/>
        <Route path='/user/my-stories'  component={connectedMyStories}/>
        <Route path='/travel-albums/my-albums/' exact component={connectedMyAlbums}/>
        <Route path='/user/edit-album/:id'  component={connectedEditAlbumUser}/>
        <Route path='/user/edit-stories/:id'  component={connectedUserEditStories}/>
        <Route path='/user/write-story' exact component={connectedWriteStory}/>
        <Route path='/travel-stories' exact component={connectedAllStories}/>
        <Route path='/travel-albums' exact  component={connectedAllAlbums}/>
        <Route path='/tourists' exact component={connectedMembersPage}/>
        <Route path='/travel-places/:id' exact  component={connectedPlace}/>
         <Route path='/place-photo/:id' exact component={connectedPlaceAllPhotos}/>
         <Route path='/travel-stories/:id' exact  component={connectedStory}/>
        <Route path='/travel-albums/:id'  component={connectedSingleAlbum}/>
        <Route path='/email-varification-link/:id' exact component={connectedEmailVerificationPage}/>
        <Route path='/reset-password/:token/:id' exact  component={connectedResetPassword}/>
        <Route path='/place-questions/:placeId' exact component={connectedAllQuestions}/>
         <Route path='/place-experience/:placeId'  component={connectedAllPlaceExperience}/>
         <Route path='/question/:question/:id/:placeId' exact component={connectedSingleQuestion}/>
         <Route path='/user/settings/:id'  component={connectedUserSettings}/>
         <Route path='/tourist/:userName/'  component={connectedUserDashBoard}/>
        <Route path="*"  component={ErrorComponent} />
        </Switch>
      </Router>
</BrowserView>
<MobileView>
<div className="small-app-container">
        <div style={{width:'100%',marginTop:'150px'}}>
        <span className="hero-heading" style={{color:'#000000'}}>For Mobiles, Download<br></br><span className="pakrism-txt">Pakrism </span>App</span>
        </div>
      <div>
          <img src={appStore} alt={appStore} className='apple-store-btn' style={{cursor:"pointer",margin:'20px auto'}} onClick={()=>window.open("https://apps.apple.com/app/id1496630730",'_blank')}>
          </img>
          <img src={googleStore} alt={googleStore} className='google-store-btn' style={{cursor:"pointer",margin:'20px auto'}} onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.pakrism")}/>
      </div>
    </div>
</MobileView>
  </div>
   )
}

export default App;
