import React, { useEffect,useState } from 'react';
import { Button, Form, Input, Select,message,Upload} from 'antd';
import { CloudUploadOutlined } from "@ant-design/icons";
import { history } from '../../Helpers';
import { connect } from 'react-redux';
import NavBar from '../Navbar/Navbar'
import ReactGA from 'react-ga';
import {albumCategoryActions,albumActions} from '../../actions';
import './albums.css';
const { Dragger } = Upload;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};

const prop = {
  name: 'uploadImages',
  action: '',
};


const { Option } = Select;
const { TextArea } = Input;
function beforeUpload(file:any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

function CreateAlbum(props:any) {
  let user = JSON.parse(localStorage.getItem('user')!)
  const { dispatch,albumCategories} = props;
  const [loading,setLoading]= useState(false);
  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (!user) {
      history.push('/login')
    }
    else {

      dispatch(albumCategoryActions.FetchAllAlbumCategories());
    }

  }, [dispatch]);
  const onFinishFailed = (errorInfo:any) => {
  };

  if (!albumCategories) {
    return (<div></div>)
  }

  const onFinish = (values:any) => {
    const data = new FormData();
   if(values.uploadImages)
   {
    values.uploadImages.fileList.forEach((element:any) => {
      data.append("albumImages", element.originFileObj)
     });
   }
   data.append("title",values.title)
   data.append("categories",values.albumcats)
   data.append("description",values.description)
   data.append("user",user.id)
   data.append("firstName",user.firstName)
   data.append("lastName",user.lastName)
   setLoading(true)
   dispatch(albumActions.AddAlbum(data));

  };

    const onChange = () => {

    };

    const onPreview = async (file:any) => {
      let src = file.url;
      if (!src) {
        src = await new Promise(resolve => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow:any = window.open(src);
      imgWindow.document.write(image.outerHTML);
    };
  return (
    <div>
      <NavBar/>
      <div className="add-album-container">
        <div className="travel-story-heading-container">
      <p className="travel-story-taller-heading create-stroy-heading">
       Create new album
         </p>
      </div>
      <Form
        {...layout}
        className="create-album-form"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item className="lbl-name" label="Title"></Form.Item>
        <Form.Item
          name="title"
          hasFeedback
          rules={[{ required: true, message: 'Please enter a title.' },]}
        >
          <Input name="title" size="large" placeholder="title" />
        </Form.Item>
        <Form.Item className="lbl-name" label="Category Name"></Form.Item>
        <Form.Item
          hasFeedback
          name="albumcats"
          rules={[{ required: true, message: 'Please enter a place.' },]}
        >
          <Select placeholder="select a category" size="large" >

            {
              albumCategories.length > 0 ?
                albumCategories.map((item:any) => <Option selected="selected" value={item._id} >
                  {item.categoryName}
                </Option >)
                :
                <Option value="1">data</Option>
            }
          </Select>
        </Form.Item>
        <Form.Item className="lbl-name" label="Description"></Form.Item>
        <Form.Item
          name="description"
        >
          <TextArea name="description" placeholder="Description" rows={4}
          />
        </Form.Item>
        <Form.Item className="lbl-name" label="Photo"></Form.Item>
        <Form.Item
         hasFeedback
        name="uploadImages"
        rules={[{ required: true, message: 'Please add Atleast one Photo.' },]}
        >
        <Dragger
        beforeUpload={beforeUpload}
                onPreview={onPreview}
                onChange={onChange}
                multiple={true}
                {...prop}
                listType="picture-card">

                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined style={{ color: "#000000" }} />
                  <span className="upld-desc" > Upload or drop photo here</span>
                </p>
              </Dragger>

        </Form.Item>
            <Form.Item >
          <Button className="btn-admin-login" loading={loading} size="large" type="primary" htmlType="submit">
            Add Album
          </Button>
        </Form.Item>
      </Form>
      </div>
    </div>
  )
}
function mapStateToProps(state:any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    albums: state.FetchEditAlbum.fetchEditAlbumObject,
    albumCategories: state.FetchAlbumCategories.getAllAlbumCategoriesObject,
    places: state.FetchPlaceCategories.getAllPlaceCategoriesObject,

  };
}

export const connectedCreateAlbum = connect(mapStateToProps)(CreateAlbum);
