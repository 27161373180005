import React, { useEffect, useState } from 'react';
import NoStory from '../../Assets/icon_no_story.svg'
import NoActivity from '../../Assets/icon_no_activity.svg'
import NoQuestion from '../../Assets/icon_no_question.svg'
import NoPhoto from '../../Assets/icon_no_photo.svg'
import NoExp from '../../Assets/icon_no_experience.svg'
import NoFrnd from '../../Assets/icon_no_friends.svg'
import NoAns from '../../Assets/icon_no_answer.svg'
import NoAlbm from '../../Assets/icon_no_albums.svg'
import NoFav from '../../Assets/icon_no_favorite_place.svg'
import NoVisted from '../../Assets/icon_no_visited_place.svg'

type NoData ={
isFrom:any;
noData:any;
noDataMessage:any;
}

export function NoData(props:NoData) {
  const {isFrom,noData,noDataMessage} = props;
  const[noImage,setNoImage] = useState('');
  useEffect(()=>{
    switch(isFrom) {
      case "photo":
       setNoImage(NoPhoto)
        break;
      case "activity":
       setNoImage(NoActivity)
        break;
        case "question":
          setNoImage(NoQuestion)
           break;
           case "stories":
          setNoImage(NoStory)
           break;
           case "exp":
            setNoImage(NoExp)
            break;
            case "friends":
              setNoImage(NoFrnd)
              break;
              case "ans":
              setNoImage(NoAns)
              break;
              case "fav":
                setNoImage(NoFav)
                break;
                case "visted":
                  setNoImage(NoVisted)
                  break;
              case "albm":
                setNoImage(NoAlbm)
             break;
      default:
        // code block
    }
  },[])

  return (
    <div className="no-data-container">
    <div className="no-data-found-img">
      <img src={noImage} alt={noImage} />
    </div>
    <div className="no-data-found-content">
    <p className="heading">{noData}</p>
    <p className="desc">
      {noDataMessage}
    </p>
    </div>

    </div>
  )
}
