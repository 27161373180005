import React,{useState} from "react";
import {
  Image,
  Avatar,
  Box
} from "@chakra-ui/core";
import { Modal} from 'antd';
import {FacebookShareButton, FacebookIcon} from "react-share";
import {config} from '../../config'
import {history} from '../../Helpers'
const TimeStamp =require("react-timestamp");

export function RelatedStoryCard(props:any) {
  const {storyCardData} = props;
  let story=storyCardData.stories.story;
  if(story.length > 10)
  {
   story=story.substring(0,135);
  }
  const onCardClick =(id:any,title:any)=>{
    let storyTitle='';
    storyTitle=title.replace(/[ ,?]/g,'-')
    history.push('/travel-stories/'+storyTitle+"-"+id)
    window.scrollTo(0, 0)
    }
  const [visible,setVisible] = useState(false);
  const showModal = () => {
    setVisible(true)
  };
  const handleOk = (e:any) => {
    setVisible(false)
  };

  const handleCancel = (e:any)  => {
    setVisible(false)
  };

    return (
            <div>
              <div className="single-story-card-inner-left-container" onClick={()=>onCardClick(storyCardData.stories._id,storyCardData.stories.title)}>
              <Box
              maxW="sm"
              borderWidth="1px"
              rounded="lg"
              overflow="hidden"
              style={{ marginRight: "5px" }}
            >
            <img className="related-card-story-img" src ={config.s3BucketUrl+storyCardData.stories.image} alt="abc" />
               <div className="related-carduser-container">
            <div className="story-card-user-container">

              <div style={{ float: "left", marginBottom: "16px" }}>
                <Avatar
                  name={storyCardData.users.firstName+" "+storyCardData.users.lastName}
                  size="lg"
                  className="question-avatar"
                  src={storyCardData.users.image}
                ></Avatar>
              </div>
              <div className="story-card-desc-container">
                <p className="story-card-decs-txt-container">
                  <small className="story-card-desc-txt">{storyCardData.users.firstName+" "+storyCardData.users.lastName}</small>
                    &nbsp;
                    <small >
                    <TimeStamp className="story-card-timespan-txt" relative date={storyCardData.stories.createdDate} autoUpdate />
                    </small>
                </p>
                <p className="story-card-album-badge related-card-badge">
                <small className="story-card-tourist-txt">published in</small>
                   <span className="story-card-timespan-txt story-card-place-name"> {storyCardData.places.placeName}</span>
                </p>
              </div>
            </div>
          <p className="story-card-story-title related-card-story-title">
            {storyCardData.stories.title}
            </p>
          </div>
         </Box>
          </div>
          <Modal
          title="Share social"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <FacebookShareButton
                url={"https://pr-app.pakrism.com/stories"}
                quote={"Pakrism- World is yours to explore"}
                hashtag="#PakrismStories"
                className="" >
                 <FacebookIcon size={36} round={true} />
              </FacebookShareButton>

        </Modal>
            </div>
    );
}

