import React, { useEffect, useState } from 'react';
import heartLine from '../../Assets/heart-2-line.svg';
import heartFill from '../../Assets/heart-2-fill.svg';
import  Share  from "../../Assets/icon_share_inactive_dark.svg";
import {history} from '../../Helpers';
import {albumActions} from '../../actions';
import {Image } from "@chakra-ui/core";
import { Modal } from 'antd';
import {FacebookShareButton, FacebookIcon} from "react-share";

export function LikeFooterAlbum(props:any) {

  let user = JSON.parse(localStorage.getItem('user')!)

  const {data,dispatch} = props;
  const [placeTitle,setPlaceTitle] = useState('');
  const [visible,setVisible] = useState(false);
  const [likes,setLikes]= useState(data.albums.likes.length)
  const [isliked,setLiked] =useState(false);
  useEffect(()=>{

    setPlaceTitle(data.albums.title.replace(/[ ,?]/g,'-'))

if(user)
{
  data.albums.likes.forEach((element:any) => {
    if(element.toString()===user.id.toString())
    {
     setLiked(true)
    }
   });
}



  },[])
  const onUnlike=()=>{
    let obj={
      userId:user.id
    }
    setLikes(likes-1)
    setLiked(false)
    dispatch(albumActions.RemoveAlbumLike(obj,data.albums._id));
  }
  const onLike=()=>{
    let obj={
      userId:user.id
    }
    setLikes(likes+1)
    setLiked(true)
    dispatch(albumActions.AddAlbumLike(obj,data.albums._id));
  }

  const showModal = () => {
    setVisible(true)
  };
  const handleOk = (e:any) => {
    setVisible(false)
  };

  const handleCancel = (e:any)  => {
    setVisible(false)
  };
  return (
    <div id="footer-main" className="sotry-like-footer">
      {
        user?
        isliked?
        <div className="story-like-footer-reaction-container" onClick={onUnlike}>

      <div
       className="reaction-icon"
      >
        <Image
          src={heartFill}
          alt="inspired Fist"
          height="18px"
          width="18px"
        />
      </div>
      <div  className="icon-txt">
        <small className="small-txt-ftr" style={{color:'#37b44e'}}>Loved({likes})</small>
      </div>
    </div>:
    <div className="story-like-footer-reaction-container" onClick={onLike}>
    <div
     className="reaction-icon"
    >
      <Image
        src={heartLine}
        alt="inspired Fist"
        height="18px"
        width="18px"
      />
    </div>
    <div  className="icon-txt">
      <small className="small-txt-ftr">Love({likes})</small>
    </div>
  </div>
  :
  <div className="story-like-footer-reaction-container" onClick={()=>history.push('/login')}>
  <div
   className="reaction-icon"
  >
    <Image
      src={heartLine}
      alt="inspired Fist"
      height="18px"
      width="18px"
    />
  </div>
  <div  className="icon-txt">
    <small className="small-txt-ftr">Inspire({likes})</small>
  </div>
</div>
      }

    <div className="story-like-footer-reaction-container" onClick={showModal} >
    <div
       className="reaction-icon" >
        <Image
          src={Share}
          alt="inspired Fist"
          height="18px"
          width="18px"
        />
      </div>
      <div className="icon-txt"  >
        <small className="small-txt-ftr">Share</small>
      </div>
    </div>
    <Modal
          title="Share social"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <FacebookShareButton
                url={`https://pr-app.pakrism.com/travel-places/${placeTitle}-${data.albums._id}`}
                quote={"Pakrism- World is yours to explore"}
                hashtag="#PakrismAlbum"
                className="" >
                 <FacebookIcon size={36} round={true} />
              </FacebookShareButton>

        </Modal>
  </div>
  )
}
