import React, { useEffect, useState } from 'react';
import Icon,{RightCircleOutlined } from '@ant-design/icons';
import './hero.css'
import {history} from '../../Helpers';
import travelStoryImage from '../../Assets/travel-stories-hero-bg-min.jpg'
import travelAlbumImage from '../../Assets/travel-albums-hero-bg-min.jpg'



export const Hero=(props:any)=>{
   const {fromParent,allUsers} = props
   const [data,setData] = useState('');
   let userImages:any=[];

   const onEnter=()=>{
    let a:any = document.getElementById("exp-btn");
      a.childNodes[1].style.fill = "#000"
  }
  const onLeave=()=>{
  let a:any = document.getElementById("exp-btn");
  a.childNodes[1].style.fill = "#FFF"
  }

     useEffect(()=>{
     let finalResult:any='';

     const storiesSvg=()=>(
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" fill="rgba(255,255,255,1)"/></svg>
    );
    const StorySvg=()=>(
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 6.757l-2 2V4h-9v5H5v11h14v-2.757l2-2v5.765a.993.993 0 0 1-.993.992H3.993A1 1 0 0 1 3 20.993V8l6.003-6h10.995C20.55 2 21 2.455 21 2.992v3.765zm.778 2.05l1.414 1.415L15.414 18l-1.416-.002.002-1.412 7.778-7.778z" fill="rgba(255,255,255,1)"/></svg>
    );
    const AlbumSvg=()=>(
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm.008-12c.548 0 .992.445.992.993v9.349A5.99 5.99 0 0 0 20 13V5H4l.001 14 9.292-9.293a.999.999 0 0 1 1.32-.084l.093.085 3.546 3.55a6.003 6.003 0 0 0-3.91 7.743L2.992 21A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016zM8 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4z" fill="rgba(255,255,255,1)"/></svg>
    );

    const StoriesIcon = (props:any) => <Icon component={storiesSvg} {...props} />;
    const StoryIcon = (props:any) => <Icon component={StorySvg} {...props} />;
    const AlbumIcon = (props:any) => <Icon component={AlbumSvg} {...props} />;
    if(fromParent==="travelStory")
    {
      finalResult=( <div className="hero-container">
      <div className="hero__background-img">
     <img src={travelStoryImage} alt={travelStoryImage} />
      </div>
      <div className="video-overlay-container">
        <div className="video-overlay-txt hero-heading-travel-stories">
        <span className="hero-heading "><span className="pakrism-txt">Exciting</span> Travel Stories</span>
        </div>
        <p className="banner-desc">
        Travelers shared their mysterious travel stories,
        <br/>
        with love, experience and full of adventures.
          </p>
          <div className="banner-btn-container">
            <div className="explore-btn-container">
            <button id="exp-btn" className="explore-btn" onClick={()=>{ window.scrollTo({top:625,behavior:'smooth'})}} onMouseEnter={onEnter} onMouseLeave={onLeave} >
           <span> All stories</span>
            <StoriesIcon />
            </button>
            <button className="hero-join-btn"  onClick={()=>{history.push('/user/write-story')}}>
              Write your story
             <StoryIcon/>
            </button>
            </div>
            <div>
            </div>
          </div>
      </div>
    </div>);
     setData(finalResult)
    }
    else if(fromParent==="travelAlbum"){
      finalResult=( <div className="hero-container">
      <div className="hero__background-img">
     <img src={travelAlbumImage} alt={travelAlbumImage} />
      </div>
      <div className="video-overlay-container">
        <div className="video-overlay-txt hero-heading-travel-stories">
        <span className="hero-heading "><span className="pakrism-txt">Exciting</span> Travel Albums</span>
        </div>
        <p className="banner-desc">
        Breath taking scenes, full of majesty places,
        <br/>
        Travel memories, captured beauty & random clicks.
          </p>
          <div className="banner-btn-container">
            <div className="explore-btn-container">
            <button id="exp-btn" className="explore-btn" onClick={()=>{ window.scrollTo({top:625,behavior:'smooth'})}} onMouseEnter={onEnter} onMouseLeave={onLeave} >
           <span> All albums</span>
            <StoriesIcon />
            </button>
            <button className="hero-join-btn"  onClick={()=>{history.push('/travel-albums/create-album')}}>
              Create your album
             <AlbumIcon/>
            </button>
            </div>
            <div>
            </div>
          </div>
      </div>
    </div>);
     setData(finalResult)

    }
    else if(fromParent==="home")
    {
      finalResult=( <div className="hero-container">
      <div className="hero__background">
      <video autoPlay loop muted poster='https://www.pexels.com/assets/videos/free-videos-7daa2ef41a140f70c757ce91913a4ecb90570b7d7cd2b401bae868350e02c83a.jpg' >
      <source src="https://static.pexels.com/lib/videos/free-videos.mp4" type="video/mp4"></source>
      </video>
      </div>
      <div className="video-overlay-container">
        <div className="video-overlay-txt">
        <span className="hero-heading"><span className="pakrism-txt">Pakrism </span>helping to promote <span className="pakrism-txt">Pakistan Tourism</span></span>
        </div>
        <p className="banner-desc">
        Let’s discover and explore new travel places
        <br/>
         in Pakistan with tourists as you.
          </p>
          <div className="banner-btn-container">
            <div className="explore-btn-container">
            <button className="explore-btn" onClick={onExploreMapClick}   >
            Explore Map
            <RightCircleOutlined />
            </button>
            <button className="hero-join-btn"  onClick={onJoinClick}>
            Join Now!
            </button>
            </div>
            <div>
            </div>
          </div>
      </div>
    </div>);
     setData(finalResult)
    }
    else if(fromParent==="members")
    {
      allUsers.forEach((element:any) => {
          if(element.users.image!=="")
          {
            userImages.push(element.users.image);
          }
      });

      finalResult=( <div className="hero-container">
      <div className="hero__background-members">
        {
           userImages.map((item: any) =>
           <div style={{float:'left'}}>
             <img className="usr-image-member" src={item} />
           </div>
           )
        }
      </div>
      <div className="video-overlay-container">
        <div className="video-overlay-txt hero-heading-travel-stories">
        <span className="hero-heading "><span className="pakrism-txt">Tourists</span> across Pakistan and World</span>
        </div>
        <p className="banner-desc">
        Connect with the tourists like you and
        Tripmate them to plan your next trip.
          </p>
          <div className="banner-btn-container">
            <div className="explore-btn-container">
            <button id="exp-btn" className="explore-btn" onClick={()=>{ window.scrollTo({top:625,behavior:'smooth'})}} onMouseEnter={onEnter} onMouseLeave={onLeave} >
           <span> All Members</span>
            <StoriesIcon />
            </button>
            {/* <button className="hero-join-btn"  onClick={()=>{history.push('/travel-albums/create-album')}}>
              Create your album
             <AlbumIcon/>
            </button> */}
            </div>
            <div>
            </div>
          </div>
      </div>
    </div>);
     setData(finalResult)

    }
  },[])

  const onExploreMapClick = () =>{
    history.push('/pak-map')
  }
  const onJoinClick = () =>{
    history.push('/signup')
  }
  return (
   <div>
     {data}
   </div>
  )
}
