import { alertActions } from "../actions";
import { config } from "../config";
import axios from "axios";

export const agencyService = {
  FetchAllTravelAgencies,
  FetchEditTravelAgency,
  DeleteTravelAgencyAdmin,
  ChangeStatus,
  UpdateAgencyAdmin,
  AddTravelAgencyAdmin
};

async function AddTravelAgencyAdmin(values: any) {
  return await axios
    .post(`${config.apiurl}/user/add-agancy-admin/`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}


async function FetchAllTravelAgencies(page: number,search: string,pageLimit:number) {
  return await fetch(`${config.apiurl}/user/get-all-agencies-admin/${page}/${search}/${pageLimit}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

async function FetchEditTravelAgency(id: any) {
  return await fetch(`${config.apiurl}/user/get-single-travel-agency/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

async function DeleteTravelAgencyAdmin(id: any) {
  return await fetch(`${config.apiurl}/user/delete-travel-agency-admin/${id}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

async function ChangeStatus(values: any, id: any) {
  return await axios
    .patch(`${config.apiurl}/user/change-status-travel-agencies/${id}`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });

}

async function UpdateAgencyAdmin(values: any, id: any) {
  return await axios
    .patch(`${config.apiurl}/user/update-travel-agencies-admin/${id}`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });

}
