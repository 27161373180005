export const tripConstants = {


  ADD_ACCESSORIES_CATEGORIES_REQUEST: "ADD_ACCESSORIES_CATEGORIES_REQUEST",
  ADD_ACCESSORIES_CATEGORIES_SUCCESS: "ADD_ACCESSORIES_CATEGORIES_SUCCESS",
  ADD_ACCESSORIES_CATEGORIES_FAILURE: "ADD_ACCESSORIES_CATEGORIES_FAILURE",

  // ADD_PRIVACY_REQUEST: "ADD_PRIVACY_REQUEST",
  // ADD_PRIVACY_SUCCESS: "ADD_PRIVACY_SUCCESS",
  // ADD_PRIVACY_FAILURE: "ADD_PRIVACY_FAILURE",

  // ADD_REPORT_REQUEST: "ADD_REPORT_REQUEST",
  // ADD_REPORT_SUCCESS: "ADD_REPORT_SUCCESS",
  // ADD_REPORT_FAILURE: "ADD_REPORT_FAILURE",

  GET_ADMIN_ACCESSORIES_CATEGORIES_REQUEST: "GET_ADMIN_ACCESSORIES_CATEGORIES_REQUEST",
  GET_ADMIN_ACCESSORIES_CATEGORIES_SUCCESS: "GET_ADMIN_ACCESSORIES_CATEGORIES_SUCCESS",
  GET_ADMIN_ACCESSORIES_CATEGORIES_FAILURE: "GET_ADMIN_ACCESSORIES_CATEGORIES_FAILURE",

  // ADD_CONTACT_REQUEST: "ADD_CONTACT_REQUEST",
  // ADD_CONTACT_SUCCESS: "ADD_CONTACT_SUCCESS",
  // ADD_CONTACT_FAILURE: "ADD_CONTACT_FAILURE",

  // GET_CONTACT_REQUEST: "GET_CONTACT_REQUEST",
  // GET_CONTACT_SUCCESS: "GET_CONTACT_SUCCESS",
  // GET_CONTACT_FAILURE: "GET_CONTACT_FAILURE",

  // GET_REPORT_REQUEST: "GET_REPORT_REQUEST",
  // GET_REPORT_SUCCESS: "GET_REPORT_SUCCESS",
  // GET_REPORT_FAILURE: "GET_REPORT_FAILURE",

  // GET_PRIVACY_ADMIN_REQUEST: "GET_PRIVACY_ADMIN_REQUEST",
  // GET_PRIVACY_ADMIN_SUCCESS: "GET_PRIVACY_ADMIN_SUCCESS",
  // GET_PRIVACY_ADMIN_FAILURE: "GET_PRIVACY_ADMIN_FAILURE",


  // GET_PRIVACY_REQUEST: "GET_PRIVACY_REQUEST",
  // GET_PRIVACY_SUCCESS: "GET_PRIVACY_SUCCESS",
  // GET_PRIVACY_FAILURE: "GET_PRIVACY_FAILURE",

  GET_EDIT_ADMIN_ACCESSORIES_CATEGORIES_REQUEST: "GET_EDIT_ADMIN_ACCESSORIES_CATEGORIES_REQUEST",
  GET_EDIT_ADMIN_ACCESSORIES_CATEGORIES_SUCCESS: "GET_EDIT_ADMIN_ACCESSORIES_CATEGORIES_SUCCESS",
  GET_EDIT_ADMIN_ACCESSORIES_CATEGORIES_FAILURE: "GET_EDIT_ADMIN_ACCESSORIES_CATEGORIES_FAILURE",

  UPDATE_ADMIN_ACCESSORIES_CATEGORIES_REQUEST: "UPDATE_ADMIN_ACCESSORIES_CATEGORIES_REQUEST",
  UPDATE_ADMIN_ACCESSORIES_CATEGORIES_SUCCESS: "UPDATE_ADMIN_ACCESSORIES_CATEGORIES_SUCCESS",
  UPDATE_ADMIN_ACCESSORIES_CATEGORIES_FAILURE: "UPDATE_ADMIN_ACCESSORIES_CATEGORIES_FAILURE",

  DELETE_ADMIN_ACCESSORIES_CATEGORIES_REQUEST: "DELETE_ADMIN_ACCESSORIES_CATEGORIES_REQUEST",
  DELETE_ADMIN_ACCESSORIES_CATEGORIES_SUCCESS: "DELETE_ADMIN_ACCESSORIES_CATEGORIES_SUCCESS",
  DELETE_ADMIN_ACCESSORIES_CATEGORIES_FAILURE: "DELETE_ADMIN_ACCESSORIES_CATEGORIES_FAILURE",


};
