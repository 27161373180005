import { alertConstants } from "../constants";
import { message } from "antd";
const success: any = (serverResponse: any) => {
  message.success(serverResponse);
  return { type: alertConstants.SUCCESS, serverResponse };
};
const error: any = (serverError: any) => {
  message.error(serverError);
  return { type: alertConstants.ERROR, serverError };
};
const clear: any = () => ({ type: alertConstants.CLEAR });
export const alertActions = {
  success,
  error,
  clear,
};
