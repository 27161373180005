import React, { useState,useEffect } from 'react';
import image from '../../Assets/Icon_awesome-check-circle.svg';
import ReactGA from 'react-ga';
export function SignupSuccess() {

  const [finala,setfinala] =useState({firstName:'',lastName:'',email:''});

  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    setfinala(JSON.parse(localStorage.getItem('signSussecc')!))
  },[])
  return (
    <div>
      <div className="sign-success-left-container">
      <div className="left-inner-container">
              <p className="signup-success-logo">
               <img width="58" height="58" src={image} alt={image}/>
              </p>
                {
                  finala?<p className="user-desc"> Hello, {finala.firstName} {finala.lastName}! </p>
                  :<p className="user-desc"> Hello, </p>

                }
              <p className="green-txt">
              Your account has been created successfully.
              </p>
              <p className="large-txt">
              Check your mailbox to verify email.
              </p>
              {
                  finala? <p className="email-txt">
                  {finala.email}
                </p>
                  :<p className="user-desc"> email </p>

                }

              <p className="warning-txt">
              We sent you an email with a link to verify your email address.
              <br/>
              Once you verify, you’ll be able to get started with your account.
              </p>
              <p className="last-desc-txt">
              Do not forget to check your SPAM folder for the email.
              </p>
            </div>
      </div>
      <div className="sign-success-right-container">
            <div className="image-div">
            </div>
        </div>
    {/* <div className="top-container-signup">
    </div>
    <div className="main-container">
      <div className="signup-container">
        <div className="signup-header-container">
          <div className="signup-heading-container">
            <p className="register-txt">
              Logo
            </p>
          </div>
        </div>
        <br></br>
        <div className='form-container-sign'>
          <div className="register-form">
            <div>
              <p className="signup-crte-acc-txt">
                Create an account
              </p>
              <p className="user-desc">
                Hello, {final.firstName} {final.lastName}!
              </p>
              <p className="green-txt">
              Your account has been created successfully.
              </p>
            </div>
            <div className="firstStep steps-content">

            </div>
            <div className="steps-action">

            </div>
          </div>
          <div className="signup-social-container">
            <div className="social-container">

            </div>
          </div>
        </div>
        <div className="trm-txt-sign">
        </div>
      </div>
    </div> */}
  </div>
     )

}
