import React,{useState} from 'react';
import { Avatar,Tabs, TabList, TabPanels, Tab, TabPanel} from "@chakra-ui/core";
import {Input} from 'antd'
import {NoData} from '../Places';
import {history} from '../../Helpers'

export function MemberUserCard(props:any) {


const {allUsers,friends,sentRequests,receivedRequests} = props;
const [checkSearch, setcheckSearch] = useState(false);
const [filterData, setfilterData] = useState<any>();
let search = (value: any) => {
  // let result = allUsers.filter((o:any)=> o.users.firstName === value||o.users.lastName===value || o.users.userName===value);
  var matchedValues:any, re:any, i:any, j:any, cur:any;

  matchedValues = [];
  re = new RegExp(value, "i");
  for (i = 0, j = allUsers.length; i < j; i++) {
      cur = allUsers[i];
      if (cur.users.firstName.search(re) !== -1||cur.users.lastName.search(re) !== -1||cur.users.userName.search(re) !== -1) {
          matchedValues.push(cur);
      }
  }
    setfilterData(matchedValues)
setcheckSearch(true);
}
  return (
    <div>
      <div style={{width:'100%',display:'flow-root',padding:'10px'}}>
       <div style={{ width: '50%', float: 'left' }}>

        <Input.Search

          placeholder="Find tourists..."
          enterButton
          name="search"
          size="large"
          onSearch={search}
        />
      </div>
      </div>
        <Tabs>
        <TabList className="my-story-tab-list">
          <Tab className="my-story-tab"> All Tourists</Tab>
          <Tab className="my-story-tab"> My Tripmates</Tab>
          <Tab className="my-story-tab">Tripmate Requests</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
          <div className="all-members-container">
          {
            checkSearch?
            filterData.map((item:any)=>
            <div className="members-friend-card-main-container">
            <div className="user-avatar"  onClick={() => {history.push(`/tourist/${item.users.userName}/about`)}}>
              <Avatar
                size="xl"
                name={item.users.firstName + item.users.lastName}
                src={item.users.image}
              />
               <p className="user-name-txt friend-card-user-txt">
                {item.users.firstName + " " + item.users.lastName}
              </p>
            </div>
            {/* <div className="user-name-container">

              <div className="tripmate-btn-container">
              {
              item._id !== currentUserId?
              <button className="un-friend-btn " onClick={()=>{onFriendRequest(item._id,item.playerId)}}>
                   Add Tripmate
                 <FriendIcon />
                 </button>
                 :null
            }
            </div>
            </div> */}
            </div>
            )
            :
            allUsers.map((item:any)=>
            <div className="members-friend-card-main-container">
            <div className="user-avatar"  onClick={() => {history.push(`/tourist/${item.users.userName}/about`)}}>
              <Avatar
                size="xl"
                name={item.users.firstName + item.users.lastName}
                src={item.users.image}
              />
               <p className="user-name-txt friend-card-user-txt">
                {item.users.firstName + " " + item.users.lastName}
              </p>
            </div>
            {/* <div className="user-name-container">

              <div className="tripmate-btn-container">
              {
              item._id !== currentUserId?
              <button className="un-friend-btn " onClick={()=>{onFriendRequest(item._id,item.playerId)}}>
                   Add Tripmate
                 <FriendIcon />
                 </button>
                 :null
            }
            </div>
            </div> */}
            </div>
            )
          }
        </div>
          </TabPanel>
          <TabPanel>
          <div className="all-members-container">
          {
            friends.length>0?
            friends.map((item:any)=>
            <div className="members-friend-card-main-container">
            <div className="user-avatar" onClick={() => {history.push(`/tourist/${item.user.userName}/about`)}}>
              <Avatar
                size="xl"
                name={item.user.firstName + item.user.lastName}
                src={item.user.image}
              />
              <p className="user-name-txt friend-card-user-txt">
                {item.user.firstName + " " + item.user.lastName}
              </p>
            </div>
            <div className="user-name-container">

              {/* <div className="tripmate-btn-container">
              <button className="un-friend-btn " onClick={()=>{onUnfriend(item.dataId)}}>
                   Remove Tripmate
                 <FriendIcon />
                 </button>
              </div> */}
            </div>

            </div>
            )
            :
            <NoData isFrom="friends"
          noData="No tripmate found."
          noDataMessage="Need to make new tripmates."
       />
          }
        </div>
          </TabPanel>
          <TabPanel>
          <Tabs>
      <TabList className="my-story-tab-list">
        <Tab>Sent Requests</Tab>
        <Tab>Received Requests</Tab>

      </TabList>
      <TabPanels>
        <TabPanel>
          <div className="all-members-container">
          {
            sentRequests.length>0?
            sentRequests.map((item:any)=>
            <div className="members-friend-card-main-container">
            <div className="user-avatar"  onClick={() => {history.push(`/tourist/${item.user.userName}/about`)}}>
              <Avatar

                size="xl"
                name={item.user.firstName + item.user.lastName}
                src={item.user.image}
              />
               <p className="user-name-txt friend-card-user-txt">
                {item.user.firstName + " " + item.user.lastName}
              </p>
            </div>
            {/* <div className="user-name-container">

              <div className="tripmate-btn-container">
              <button className="un-friend-btn " onClick={()=>{onCancelRequest(item._id)}}>
                   Cancel Request
                 <FriendIcon />
                 </button>
               </div>
            </div> */}

            </div>
            )
            : <NoData isFrom="friends"
            noData="No Request Sent."
            noDataMessage="Need to make new tripmates."
         />
          }
        </div></TabPanel>
        <TabPanel>
        <div className="all-members-container">
          {
            receivedRequests.length>0?
            receivedRequests.map((item:any)=>
            <div className="members-friend-card-main-container">
            <div className="user-avatar" onClick={() => {history.push(`/tourist/${item.user.userName}/about`)}}>
              <Avatar
                size="xl"
                name={item.user.firstName +" "+ item.user.lastName}
                src={item.user.image}
              />
               <p className="user-name-txt friend-card-user-txt">
                {item.user.firstName + " " + item.user.lastName}
              </p>
            </div>
            {/* <div className="user-name-container">
              <div className="tripmate-btn-container">
            <button className="un-friend-btn " onClick={()=>{onRespondRequest(item._id,item.requestedId,item.playerId)}}>
                   Respond Request
                 <FriendIcon />
                 </button>
                 </div>
            </div> */}
            </div>
            )
            :<NoData isFrom="friends"
            noData="No Request Received."
            noDataMessage="Need to make new tripmates."
         />
          }
        </div>
        </TabPanel>
      </TabPanels>
    </Tabs>
          </TabPanel>
        </TabPanels>
      </Tabs>
       </div>
  )
}
