import { tripConstants } from "../constants";
import { tripService } from "../services";
import { alertActions } from ".";
import { history } from "../Helpers";

export const tripActions = {
  FetchAllAccessoriesCategories,
  AddAccessoriesCategories,
  FetchEditAccessoriesCategories,
  DeleteAccessoriesCategories,
  UpdateAccessoriesCategories,
};

function FetchAllAccessoriesCategories() {
  return (dispatch: any) => {
    dispatch(request());
    tripService.FetchAllAccessoriesCategories().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: tripConstants.GET_ADMIN_ACCESSORIES_CATEGORIES_REQUEST };
  }
  function success(user: any) {
    return { type: tripConstants.GET_ADMIN_ACCESSORIES_CATEGORIES_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: tripConstants.GET_ADMIN_ACCESSORIES_CATEGORIES_FAILURE, error };
  }
}

function AddAccessoriesCategories(data: any) {
  return (dispatch: any) => {
    dispatch(request(data));
    tripService
      .AddAccessoriesCategories(data)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "201") {
          dispatch(success(data));
          dispatch(
            alertActions.success(data.data.message)
          );
          history.go(0);
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: tripConstants.ADD_ACCESSORIES_CATEGORIES_REQUEST, data };
    }
    function success(user: any) {
      return { type: tripConstants.ADD_ACCESSORIES_CATEGORIES_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: tripConstants.ADD_ACCESSORIES_CATEGORIES_FAILURE, error };
    }
  };
}

function FetchEditAccessoriesCategories(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    tripService.FetchEditAccessoriesCategories(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: tripConstants.GET_EDIT_ADMIN_ACCESSORIES_CATEGORIES_REQUEST, id };
  }
  function success(user: any) {
    return { type: tripConstants.GET_EDIT_ADMIN_ACCESSORIES_CATEGORIES_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: tripConstants.GET_EDIT_ADMIN_ACCESSORIES_CATEGORIES_FAILURE, error };
  }
}

function DeleteAccessoriesCategories(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    tripService
      .DeleteAccessoriesCategories(id)
      .then((res: any) => {
        dispatch(success(res.message));
        dispatch(alertActions.success(res.message));
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });

    function request(id: any) {
      return { type: tripConstants.DELETE_ADMIN_ACCESSORIES_CATEGORIES_REQUEST, id };
    }
    function success(user: any) {
      return { type: tripConstants.DELETE_ADMIN_ACCESSORIES_CATEGORIES_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: tripConstants.DELETE_ADMIN_ACCESSORIES_CATEGORIES_FAILURE, error };
    }
  };
}

function UpdateAccessoriesCategories(data: any, id: any) {
  return (dispatch: any) => {
    dispatch(request(data, id));
    tripService
      .UpdateAccessoriesCategories(data, id)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "200") {
          dispatch(success(data));
          dispatch(
            alertActions.success("You have successfully update an Announcement")
          );
          history.go(0);
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any, id: any) {
      return { type: tripConstants.UPDATE_ADMIN_ACCESSORIES_CATEGORIES_REQUEST, data, id };
    }
    function success(user: any) {
      return { type: tripConstants.UPDATE_ADMIN_ACCESSORIES_CATEGORIES_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: tripConstants.UPDATE_ADMIN_ACCESSORIES_CATEGORIES_FAILURE, error };
    }
  };
}



