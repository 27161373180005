
import React, { useEffect } from 'react';
import {connect} from 'react-redux'
import {questionActions} from '../../actions';
import {NoData} from '../Places';
import ReactGA from 'react-ga';
import {PlaceQuestions} from '../Places'

type Questions = {
  dispatch: any;
  data: any;
  match:any
}
function Questions(props:Questions) {
  const { data, dispatch,match } = props;
  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    let user = JSON.parse(localStorage.getItem('user')!)
    if (user) {
      if (user.loggedIn) {
        dispatch(questionActions.FetchUserQuestions(match.params.userName));
      }
    }

  },[dispatch])
  if(!data) {
    return (<div></div>)
  }
  return (
    <div>
      {
        data.length>0?
        data.map((item:any,i:any)=>
        <PlaceQuestions data={item} key={i}/>
        ):
        <div>
                <NoData isFrom="question"
               noData="No question asked."
               noDataMessage="Ask a question on a place."
            />
             </div>
      }
    </div>
  )
}

function mapStateToProps(state: any) {
  return {
    data: state.FetchUserQuestions.getUserQuestionsObject
  };
}

export const connectedQuestions = connect(mapStateToProps)(Questions);
