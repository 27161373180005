import React from "react";
import {Avatar,Image } from "@chakra-ui/core";
import {history} from '../../Helpers'
import  inspired from "../../Assets/icon_answers_inactive_dark-01.svg";
import  Share  from "../../Assets/icon_share_inactive_dark.svg";
import './places.css'
const TimeStamp =require("react-timestamp");

export const PlaceQuestions= (props: any) => {
  let questionTitle:string='';
  const { data } = props;
  const onQuestionClick=(data:any)=>{
     questionTitle=data.question.replace(/[ ,?]/g,'-')+"/"+data._id;
    history.push(`/question/${questionTitle}/${data.place}`)
}
  return (
    <div className="place-question-card-container story-card-container" onClick={()=>onQuestionClick(data)} >
    <div >
        <div className="question-card-inner-container">
          <div style={{ float: "left", marginBottom: "16px" }}>
            <Avatar
              name={data.user.firstName+" "+data.user.lastName}
              size="lg"
              className="question-avatar"
              src={data.user.image}
            ></Avatar>
          </div>
          <div className="story-card-desc-container">
            <p className="story-card-decs-txt-container">
              <small className="story-card-desc-txt">{data.user.firstName+" "+data.user.lastName}</small>
              &nbsp;<small className="story-card-tourist-txt">Asked question</small>
              &nbsp; <small >
                <TimeStamp className="story-card-timespan-txt" relative date={data.createdDate} autoUpdate />
                </small>
            </p>
            <p className="story-card-tourist-txt">
              {data.user.roles}
              </p>
          </div>
        </div>
        <div  style={{cursor:'pointer',paddingLeft:'16px'}} >
          <p className="question-txt">
            {data.question}
          </p>
        </div>

        <div id="footer-main">
                <div>
                  <div
                   className="reaction-icon"
                  >
                    <Image
                      src={inspired}
                      alt="inspired Fist"
                      height="18px"
                      width="18px"
                    />
                  </div>
                  <div  className="icon-txt">
                    <small className="small-txt-ftr">Answer ({data.answerCount})</small>
                  </div>
                </div>
                <div>
                <div
                   className="reaction-icon"
                  >
                    <Image
                      src={Share}
                      alt="inspired Fist"
                      height="18px"
                      width="18px"
                    />
                  </div>
                  <div className="icon-txt"  >
                    <small className="small-txt-ftr">Share</small>
                  </div>
                </div>
              </div>
      </div>

    {/* <Modal
      title="Share social"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <FacebookShareButton
            url={"http://192.168.100.37:3000/stories"}
            quote={"Pakrism- World is yours to explore"}
            hashtag="#PakrismStories"
            className="" >
             <FacebookIcon size={36} round={true} />
          </FacebookShareButton>

    </Modal> */}
</div>
  );
}
