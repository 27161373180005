import React,{useEffect} from 'react';
import { Button, Form, Input, } from 'antd';
import { userActions } from '../../actions';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';


const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};
const tailLayout = {
  wrapperCol: { span: 16 },
};

type ForgetPassword = {
  dispatch: any;
  match: any;
}


function ResetPassword(props: ForgetPassword) {
  const { dispatch,match } = props

  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  },[])
  const onFinish = (values: any) => {
    dispatch(userActions.ResetPassword(values.password,match.params.token,match.params.id));
  };
  const onFinishFailed = (errorInfo: any) => {
  };
  return (
    <div>
      <div className="top-container-signup">
      </div>
      <div className="main-container">
        <div className="signup-container">
          <div className="signup-header-container">
            <p className="login-txt">
              RESET PASSWORD
            </p>
          </div>
          <br></br>
          <div className='form-container-sign'>
            <div className="register-form">
              <div>
                <p className="signup-crte-acc-txt">
                  Reset your password
                      </p>
                <p className="signup-crte-acc-desc">
                  Enter your new password below and remember it.
                  <br/>
                  You will be using this password while login.
                </p>

              </div>
              <div className="forget-form-container steps-content">
                <Form
                  {...layout}

                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                   <Form.Item className="lbl-name" label="Password"></Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please enter your password.' }, {
                      min: 8,
                      message: 'password must be minimum 8 characters.'
                    }
                    ]}
                    hasFeedback
                  >
                    <Input.Password size="large" />
                  </Form.Item>
                  <Form.Item className="lbl-name" label="Confirm password"></Form.Item>
                  <Form.Item
                    name="confirm"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      {
                        min: 8,
                        message: 'password must be minimum 8 characters.'
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject('Password not matched,try again.');
                        },
                      }),
                    ]}
                  >
                    <Input.Password size="large" />
                  </Form.Item>

                  <Form.Item {...tailLayout}>
                    <Button className="btn-nxt reset-pass-btn" size="large" type="primary" htmlType="submit">
                    Change password
                  </Button>

                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message
  };
}

export const connectedResetPassword = connect(mapStateToProps)(ResetPassword);



