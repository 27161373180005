import React, { useEffect } from 'react';
import { Button, Form, Input, Upload, message } from 'antd';
import { CloudUploadOutlined } from "@ant-design/icons";
import { history,store} from '../../../Helpers';
import { connect } from 'react-redux';
import { albumCategoryActions } from '../../../actions';
import { config } from "../../../config";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};

type EditUser = {
  dispatch: any;
  match: any;
  data: any;
}

const { Dragger } = Upload;

const prop: any = {
  name: 'userImage',
  action: '',
  headers: {
    authorization: 'authorization-text',
  },
};
let image: any = '';

function beforeUpload(file: any) {

  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const { TextArea } = Input;

function EditAlbumCategories(props: EditUser) {
  store.getState().FetchEditAlbumCategory.fetchEditAlbumCategoryObject = "";

  const { dispatch, match, data } = props;


  const onFinish = (values: any) => {

    const data = new FormData()
    data.append("categoryName", values.categoryName)
    data.append("description", values.description)
    if (values.userImage) {
      data.append("categoryImage", values.userImage.file.originFileObj)
    }
    dispatch(albumCategoryActions.UpdateAlbumCategory(data, match.params.id));

  };
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {
      dispatch(albumCategoryActions.FetchEditAlbumCategories(match.params.id));
    }

  }, [dispatch,match]);

  const onFinishFailed = (errorInfo: any) => {
  };

  if (!data) {
    return (<div></div>)
  }

  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
  };

  image = config.apiurl + "/" + data[0].categoryImage
  return (
    <div>
      <Form
        {...layout}
        className="admin-login-form"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item className="lbl-name" label="Category Name"></Form.Item>
        <Form.Item
          initialValue={data[0].categoryName}
          name="categoryName"
          rules={[{ required: true, message: 'Please enter a category name.' },]}
        >
          <Input name="categoryName" size="large" placeholder="Category Name" />
        </Form.Item>

        <Form.Item className="lbl-name" label="Description"></Form.Item>
        <Form.Item
          initialValue={data[0].description}
          name="description"
        >
          <TextArea name="lastName" placeholder="Description" rows={4}
          />
        </Form.Item>
        <Form.Item className="lbl-name" label="Photo"></Form.Item>
        <Form.Item
          name="userImage"
          className="file-upload"
        >
          <Dragger
            beforeUpload={beforeUpload}
            onPreview={onPreview}
            multiple={false}
            {...prop}
            listType="picture-card">

            <p className="ant-upload-drag-icon">
              <CloudUploadOutlined style={{ color: "#000000" }} />
              <span className="upld-desc" > Upload or drop photo here</span>
            </p>
          </Dragger>

        </Form.Item>
        <Form.Item style={{ width: "50%" }}>
          <img src={image} width="30%" style={{ textAlign: 'center' }} alt={image} />
        </Form.Item>
        <Form.Item >
          <Button className="btn-admin-login" size="large" type="primary" htmlType="submit">
            Update Album Category
                    </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    data: state.FetchEditAlbumCategory.fetchEditAlbumCategoryObject,
  };
}

export const connectedEditAlbumCategories = connect(mapStateToProps)(EditAlbumCategories);
