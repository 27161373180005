import React from 'react';
import { Text} from "@chakra-ui/core";
import '../Navbar/Navbar.css'
export function Footer() {
  let d = new Date();
let n = d.getFullYear();
  return (
    <div className="nav-container footer-container">
        <div className="inner-container">
          <div className="half-container logo-container" >
            <Text className="footer-copy-right" >
            &copy; {n}  <span className="visted-txt">PAKRISM</span>. All Rights Reserved.
         </Text>
          </div>
          <div />
        </div>
      </div>
  )
}
