import React, { useEffect } from 'react';
import {history} from '../../Helpers'
import {Tooltip} from '@chakra-ui/core'
export const Marker = (props: any) => {
  const {placeName,pin,placeId,categoryId} = props;
  let categories={_id:categoryId}
  useEffect(() => {

  }, []);
  const onClick=(id:any)=>{
    history.push({
      pathname:`/travel-places/${id}`,
       state:{categories:categories,
        places:id
      }
    })
   }

  return (
    <div className="marker-main">
      <Tooltip label={placeName} aria-label={placeName} placement="top">
      <div onClick={()=>onClick(placeId)}>
       <img src={pin}  width="30" height="24" alt={pin} />
       </div>
       </Tooltip>
    </div>
  );
};

