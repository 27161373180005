import {alertActions} from '.';
import {friendsServices} from '../services';
import {friendsConstants} from '../constants';
import {history} from '../Helpers'


const SendFriendRequest =(data:any)=>{
    return (dispatch: any) => {
      dispatch(request(data));
      friendsServices.SendFriendRequest(data)
        .then((response) => response)
        .then((data: any) => {
          if (data.status == "201") {
            dispatch(success(data));
            dispatch(
              alertActions.success(data.data.message)
            );
            history.go(0);
          }
        })
        .catch((err: any) => {
          dispatch(failure(err));
          dispatch(alertActions.error(err.toString()));
        });
      function request(data: any) {
        return { type: friendsConstants.SEND_FRIEND_REQUEST, data };
      }
      function success(user: any) {
        return { type: friendsConstants.SEND_FRIEND_SUCCESS, user };
      }
      function failure(error: any) {
        return { type: friendsConstants.SEND_FRIEND_FAILURE, error };
      }
    };
}

const GetSentRequests=(id: any)=>{
  return (dispatch: any) => {
    dispatch(request(id));
    friendsServices.FetchSentRequests(id).then(
      (res) => {
        // history.push('/place')
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: friendsConstants.GET_SENT_REQUEST, id };
  }
  function success(user: any) {
    return { type: friendsConstants.GET_SENT_REQUEST_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: friendsConstants.GET_SENT_REQUEST_FAILURE, error };
  }
}
const GetReceivedRequests=(id: any)=>{
  return (dispatch: any) => {
    dispatch(request(id));
    friendsServices.FetchReceivedRequests(id).then(
      (res) => {
        // history.push('/place')
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: friendsConstants.GET_RECEIVED_REQUEST, id };
  }
  function success(user: any) {
    return { type: friendsConstants.GET_RECEIVED_REQUEST_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: friendsConstants.GET_RECEIVED_REQUEST_FAILURE, error };
  }
}
const AcceptFriendRequest =(data:any,requestId:any)=>{
  return (dispatch: any) => {
    dispatch(request(requestId));
    friendsServices.AcceptFriendRequest(data,requestId)
      .then((response) => response)
      .then((data: any) => {
        history.go(0)
        if (data.status == "200") {

          dispatch(success(data));
          dispatch(
            alertActions.success(data.data.message)
          );
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: friendsConstants.ACCEPT_FRIEND_REQUEST, data };
    }
    function success(user: any) {
      return { type: friendsConstants.ACCEPT_FRIEND_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: friendsConstants.ACCEPT_FRIEND_FAILURE, error };
    }
  };
}

const GetFriends=(id: any)=>{
  return (dispatch: any) => {
    dispatch(request(id));
    friendsServices.FetchFriends(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: friendsConstants.GET_MY_FRIENDS, id };
  }
  function success(user: any) {
    return { type: friendsConstants.GET_MY_FRIEND_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: friendsConstants.GET_MY_FRIEND_FAILURE, error };
  }
}

const UnfriendRequest =(id:any)=>{
  return (dispatch: any) => {
    dispatch(request(id));
    friendsServices.UnfriendRequest(id)
      .then((response) => response)
      .then((data: any) => {
        if (data.status=="200") {

          dispatch(success(data));
          dispatch(
            alertActions.success(data.data.message)
          );
          history.go(0)
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: friendsConstants.UNFRIEND_FRIEND_REQUEST, data };
    }
    function success(user: any) {
      return { type: friendsConstants.UNFRIEND_FRIEND_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: friendsConstants.UNFRIEND_FRIEND_FAILURE, error };
    }
  };
}

const CancelFriendRequest =(data:any)=>{
  return (dispatch: any) => {
    dispatch(request(data));
    friendsServices.CancelFriendRequest(data)
      .then((response) => response)
      .then((data: any) => {
        if (data.status=="200") {

          dispatch(success(data));
          dispatch(
            alertActions.success(data.data.message)
          );
          history.go(0);
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: friendsConstants.CANCEL_FRIEND_REQUEST, data };
    }
    function success(user: any) {
      return { type: friendsConstants.CANCEL_FRIEND_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: friendsConstants.CANCEL_FRIEND_FAILURE, error };
    }
  };
}


export const friendsActions={
  SendFriendRequest,
  GetSentRequests,
  AcceptFriendRequest,
  GetFriends,
  UnfriendRequest,
  CancelFriendRequest,
  GetReceivedRequests
};
