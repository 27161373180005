import {albumCategoryConstants} from '../constants';

export function FetchAlbumCategories(state={getAllAlbumCategoriesObject:false},action:any)
{

    switch (action.type) {
        case albumCategoryConstants.GET_ALL_ALBUM_CATEGORY_REQUEST:
          return state;
          case albumCategoryConstants.GET_ALL_ALBUM_CATEGORY_SUCCESS:
          return{
            ...state,
            getAllAlbumCategoriesObject:action.user
          };
      default:
        return state
    }
}

export function FetchEditAlbumCategory(state={fetchEditAlbumCategoryObject:false},action:any)
{
  switch (action.type) {
    case albumCategoryConstants.EDIT_ALBUM_CATEGORY_REQUEST:
      return state;
      case albumCategoryConstants.EDIT_ALBUM_CATEGORY_SUCCESS:
      return{
        ...state,
        fetchEditAlbumCategoryObject:action.user
      };
  default:
    return state
}
}



