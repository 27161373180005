import React, {useEffect } from 'react';
import { Statistic, Card, Row, Col } from 'antd';
import { Pie,Column } from '@ant-design/charts';
import { history } from '../../../Helpers';
import { userActions,storyActions,placesActions,albumActions } from '../../../actions';
import { connect } from 'react-redux';
import './analytics.css';

type Analytics = {
  dispatch: any;
  data: any;
  stories:any;
  places: any;
  usersMonthWise:any;
  albums:any;
}

function Analytics(props: Analytics) {
  const { dispatch, data,stories,places,usersMonthWise,albums } = props;
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {
      dispatch(userActions.fetchUsersCount());
      dispatch(storyActions.FetchStoriesCount());
      dispatch(placesActions.GetPlacesCount());
      dispatch(userActions.fetchUsersCountMonth());
      dispatch(albumActions.fetchAlbumsCount());


    }

  }, [dispatch]);

  if (!data) {
    return (<div></div>)
  }
  console.log(places);
  var chartData = [
    {
      type: 'All Stories',
      value: stories.allStories,
    },
    {
      type: 'Active Stories',
      value: stories.activeStories,
    },
    {
      type: ' InActive Stories',
      value: stories.inActiveStories,
    },
  ];
  // var albumChartData = [
  //   {
  //     type: 'All Albums',
  //     value: albums.allAlbums,
  //   },
  //   {
  //     type: 'Active Albums',
  //     value: albums.activeAlbums,
  //   },
  //   {
  //     type: ' InActive Albums',
  //     value: albums.inActiveAlbums,
  //   },
  // ];
  var config = {
    appendPadding: 10,
    data: chartData,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    label: {
      type: 'inner',
      offset: '-30%',
      content: '{name}',
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    state: {
      active: {
        style: {
          lineWidth: 0,
          fillOpacity: 0.65,
        },
      },
    },
    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
  };
  // var albumConfig = {
  //   appendPadding: 10,
  //   data: chartData,
  //   angleField: 'value',
  //   colorField: 'type',
  //   radius: 0.8,
  //   label: {
  //     type: 'inner',
  //     offset: '-30%',
  //     content: '{name}',
  //     style: {
  //       fontSize: 14,
  //       textAlign: 'center',
  //     },
  //   },
  //   state: {
  //     active: {
  //       style: {
  //         lineWidth: 0,
  //         fillOpacity: 0.65,
  //       },
  //     },
  //   },
  //   interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
  // };
  let userMonth:any=[];
  usersMonthWise.forEach((element:any)=>{
   userMonth.push({
     type:element.month,
     sales:element.count
   })
  })
  var configMonthUser:any = {
    data: userMonth,
    xField: 'type',
    yField: 'sales',
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    meta: {
      type: { alias: 'month/year' },
      sales: { alias: 'users' },
    },
  };
  return (
    <div className="analytics-container">
      <div className="analytics-users-container">
        <h1>Users</h1>
      <Row gutter={16}>
     <Col span={24}>
        <Card  style={{textAlign:'center',margin:'16px'}}>
          <Statistic
            title="All Users"
            value={data.allUsers}
            valueStyle={{ color: '#000'}}
            // prefix={<UserOutlined />}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card  style={{textAlign:'center',margin:'16px'}}>
          <Statistic
            title="Active Users"
            value={data.activeUser}
            valueStyle={{ color: '#3f8600' }}
            // prefix={<ArrowUpOutlined />}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card  style={{textAlign:'center',margin:'16px'}}>
          <Statistic
            title="InActive Users"
            value={data.inActiveUser}
            valueStyle={{ color: '#cf1322' }}
            // prefix={<ArrowDownOutlined />}
          />
        </Card>
      </Col>
    </Row>
      </div>
      <div className="analytics-users-container">
        <h1>Stories</h1>
      <Row gutter={16}>
     <Col span={24}>
        <Card  style={{textAlign:'center',margin:'16px'}}>
          <Statistic
            title="All Stories"
            value={stories.allStories}
            valueStyle={{ color: '#000'}}
            // prefix={<UserOutlined />}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card  style={{textAlign:'center',margin:'16px'}}>
          <Statistic
            title="Active Stories"
            value={stories.activeStories}
            valueStyle={{ color: '#3f8600' }}
            // prefix={<ArrowUpOutlined />}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card  style={{textAlign:'center',margin:'16px'}}>
          <Statistic
            title="InActive Stories"
            value={stories.inActiveStories}
            valueStyle={{ color: '#cf1322' }}
            // prefix={<ArrowDownOutlined />}
          />
        </Card>
      </Col>
    </Row>
      </div>
      <div className="analytics-users-container">
        <h1>Place Data</h1>
      <Row gutter={16}>
     <Col span={12}>
        <Card  style={{textAlign:'center',margin:'16px'}}>
          <Statistic
            title="All Places"
            value={places.allPlacesCount}
            valueStyle={{ color: '#3f8600' }}
            // prefix={<ArrowUpOutlined />}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card  style={{textAlign:'center',margin:'16px'}}>
          <Statistic
            title="All Experience"
            value={places.placeExpCount}
            valueStyle={{ color: '#3f8600' }}
            // prefix={<ArrowUpOutlined />}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card  style={{textAlign:'center',margin:'16px'}}>
          <Statistic
            title="All Images"
            value={places.placeImagesCount}
            valueStyle={{ color: '#3f8600' }}
            // prefix={<ArrowUpOutlined />}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card  style={{textAlign:'center',margin:'16px'}}>
          <Statistic
            title="Question Count"
            value={places.placeQusetionCount}
            valueStyle={{ color: '#3f8600' }}
            // prefix={<ArrowUpOutlined />}
          />
        </Card>
      </Col>
    </Row>
      </div>
      <div className="albums-container-analytics">
        <h1>Albums</h1>
      <Row gutter={16}>
     <Col span={24}>
        <Card  style={{textAlign:'center',margin:'16px'}}>
          <Statistic
            title="All Albums"
            value={albums.allAlbums}
            valueStyle={{ color: '#000'}}
            // prefix={<UserOutlined />}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card  style={{textAlign:'center',margin:'16px'}}>
          <Statistic
            title="Active Albums"
            value={albums.activeAlbums}
            valueStyle={{ color: '#3f8600' }}
            // prefix={<ArrowUpOutlined />}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card  style={{textAlign:'center',margin:'16px'}}>
          <Statistic
            title="InActive Albums"
            value={albums.inActiveAlbums}
            valueStyle={{ color: '#cf1322' }}
            // prefix={<ArrowDownOutlined />}
          />
        </Card>
      </Col>
    </Row>
      </div>
      <div style={{width:'100%',display:'flow-root'}}>
        <div style={{width:'45%',float:'left',display:'flow-root',textAlign:'center',margin:'16px'}}>
        <h1>Stories</h1>
        <Pie {...config} {...chartData} />
        </div>
        {/* <div style={{width:'45%',display:'flow-root',textAlign:'center',margin:'16px'}}>
        <h1>Albums</h1>
        <Pie {...albumConfig} {...albumChartData} />
        </div> */}
      </div>
      <div style={{display:'flow-root',width:'100%'}}>
      <Column {...configMonthUser} />
      </div>
    </div>
  );

}

function mapStateToProps(state: any) {
  return {
    data: state.FetchAllCountAdmin.getAllUserCountAdminObject,
    stories:state.FetchStoryCount.getStoriesCountObject,
    places:state.GetUserAllPlaceCountAdmin.getUserAllPlaceCountAdminObject,
    usersMonthWise:state.FetchAllCountMonth.getAllUserCountMonthObject,
    albums:state.FetchAlbumsCount.getAlbumsCountObject
  };
}

export const connectedAnalytics = connect(mapStateToProps)(Analytics);

