import React,{useEffect} from 'react';
import { connect } from 'react-redux';
import { storyActions } from '../../actions';
import { history } from '../../Helpers';
import NavBar from '../Navbar/Navbar';
import {Box,Image,Avatar} from '@chakra-ui/core';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/core";
import {MetaDecorator} from '../../utils';
import {RightFooter} from '../FooterSection';
import {PublishedStoryCards,DraftedStoryCard} from '../Cards';
import ReactGA from 'react-ga';
import advertise from '../../Assets/bridge.jpg';
import './stories.css';

type Stories = {
  dispatch: any;
  data: any;
  draftStory:any;
  storyTeller:any;
  match:any
}

function MyStories(props:Stories) {
  let user = JSON.parse(localStorage.getItem('user')!)
  const { dispatch, data,draftStory,storyTeller,match} = props;
  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    let user = JSON.parse(localStorage.getItem('user')!)
    if (!user) {
      history.push('/login')
    }
    else
    {
      dispatch(storyActions.GetMyStory(user.id));
      dispatch(storyActions.FethTopSotryTeller());
    }

  }, [dispatch]);
  const onDraftPanelClick =() =>{
    dispatch(storyActions.GetDraftStory(user.id));
  }
  if (!data) {
    return (<div></div>)
  }
  let meta={
    title:`${user.firstName} ${user.lastName} (@${user.user.userName}) - travel stories - Pakrism`,
    description:`See travel reviews, photos, and more contributed by @${user.user.userName} on Pakrism.`,
    imageAlt:"banner image",
    imageUrl:"social-share-image.png"
  }
  return (
    <Box>
      <MetaDecorator title={meta.title} description={meta.description} imageAlt={meta.imageAlt} imageUrl={meta.imageUrl} match={match}/>

      <NavBar/>
      <Box className="stories-main-container">
        <Box className="inner-container-banner-stories">
     <Box className="stories-container-inner-left">
     <div className="travel-story-heading-container">
      <p className="travel-story-taller-heading">
      My stories
         </p>
      </div>
      <div className="stories-container">
      <Tabs>
        <TabList className="my-story-tab-list">
          <Tab className="my-story-tab">Published</Tab>
          <Tab className="my-story-tab"  onClick={onDraftPanelClick}>Draft</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
           <div className="my-stories-container">
          {
           data.map((item: any) =>
           <PublishedStoryCards data={item} key={item._id} dispatch={dispatch}/>
           )
       }
       </div>
          </TabPanel>
          <TabPanel>
          <div className="my-stories-container">
          {
            draftStory.data?
           draftStory.data.map((item: any) =>
           <DraftedStoryCard data={item} key={item._id} dispatch={dispatch}/>
           )
           :<div></div>
       }
       </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
        </div>
     </Box>
     <div className="stories-container-inner-right">
       <div className="stories-advertise-container">
       <Image size="350px" src={advertise} alt="advertise" />
       </div>
       <div className="top-story-taller-container">
         <p className="top-story-taller-heading">
        Top story teller
         </p>
         <div>
           <ul className="top-story-teller-list">
           {
             storyTeller?
            storyTeller.map((item: any) =>
           <li className="top-story-teller-list-item" >
           <div style={{ display: "flow-root",cursor:'pointer'}} onClick={()=>{history.push(`/${item.users.userName}/about`)}} >
              <div style={{ float: "left", marginBottom: "16px" }}>
                <Avatar
                  name={item.users.firstName+" "+item.users.lastName}
                  size="lg"
                  className="question-avatar"
                  src={item.users.image}
                ></Avatar>
              </div>
              <div className="story-card-desc-container">
                <p className="story-card-decs-txt-container">
                  <small className="story-card-desc-txt">{item.users.firstName+" "+item.users.lastName}</small>
                </p>
                <p className="story-card-album-badge">
                    <small className="story-card-timespan-txt">
                     posted {item.storyCount} stories
                    </small>
                </p>
              </div>
            </div>
           </li>
           )
           :null
          }
           </ul>
         </div>
       </div>
       <div className="copyright-footer-container">
       <RightFooter/>
       </div>
     </div>
      </Box>
      </Box>
    </Box>
  )
}

function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    data: state.GetMyStory.getMyStoryStoryObject,
    draftStory:state.GetDraftStory.getDraftStoryObject,
    storyTeller:state.FetchTopSotryTeller.getTopSotryTellerObject
  };
}

export const connectedMyStories = connect(mapStateToProps)(MyStories);
