import React,{useEffect} from 'react'
import NavBar from '../Navbar/Navbar'
import {SupportActions} from '../../actions/';
import { connect } from 'react-redux';
import {UpperFooterSection,Footer} from '../FooterSection';
import ReactGA from 'react-ga';

 const Terms = (props: any) => {
  const {dispatch,match,data} = props;
  useEffect(()=>{
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    dispatch(SupportActions.FetchPrivacies('terms'))
  },[match,dispatch])

  if(!data)
  {
   return(<div></div>)
  }
  return (
    <div className="terms-privacy-main-container">
    <NavBar/>
    <div className="terms-privacy-inner-container">
       <p className="terms-privacy-headings">
          Terms & Conditions
       </p>
       <div className="terms-privacy-data-container">
          <div className="terms-data"  dangerouslySetInnerHTML= {{__html:data.data[0].data}}>

          </div>
       </div>
    </div>
    <div className="user-dashboard-footer">
         <UpperFooterSection/>
         <Footer/>
       </div>
  </div>
  );
};

function mapStateToProps(state: any) {
  return {
    data: state.FetchPrivacy.FetchPrivacyObject,
  };
}
export const connectedTerms = connect(mapStateToProps)(Terms);
