import React,{useState} from 'react';
import {Marker} from '.'
import GoogleMapReact from 'google-map-react';

const style = {
  height: "400px",
  width: "100%",
}
export function Map(props:any) {
  const {data} = props;
  const [center] = useState({ lat: 30.3753, lng: 69.3451 });
  const [zoom] = useState(5);
  return (
    <div style={style}>
          <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAuJYLmzmglhCpBYTn0BjbJhjWYg0fPEEA' }}
          zoom={zoom}
          center={center}
             >
           {
           data.map((item: any) => (
            <Marker
               key={item.placeId}
              lat={item.lat}
              lng={item.lng}
              placeId={item.placeId}
              placeName={item.placeName}
              pin={item.pin}
            />
          ))
           }
        </GoogleMapReact>
  </div>
  )
}
