import { albumConstants } from "../constants";
import { AlbumService } from "../services";
import { alertActions} from ".";
import { history} from "../Helpers";

export const albumActions = {
  FetchAllAlbumsAdmin,
  FetchEditAlbumAdmin,
  UpdateAlbumAdmin,
  DeleteAlbumAdmin,
  FetchAllAlbumsUser,
  FetchMyAlbum,
  FetchLovedAlbums,
  AddAlbum,
  DeleteAlbum,
  AddAlbumComment,
  AddAlbumLike,
  RemoveAlbumLike,
  FeaturedAlbum,
  fetchAlbumsCount
};

function AddAlbum(data: any) {
  return (dispatch: any) => {
    dispatch(request(data));
    AlbumService.AddAlbum(data)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "201") {
          dispatch(success(data));
          dispatch(
            alertActions.success(data.data.message)
          );
          history.push("/travel-albums/my-albums");
        }
        else
        {
          alertActions.error(data.data.message)

        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: albumConstants.ADD_ALBUMS_REQUEST, data };
    }
    function success(user: any) {
      return { type: albumConstants.ADD_ALBUMS_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: albumConstants.ADD_ALBUMS_FAILUR, error };
    }
  };
}

function FetchAllAlbumsAdmin() {
  return (dispatch: any) => {
    dispatch(request());
    AlbumService.FetchAllAlbumsAdmin().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: albumConstants.GET_ALL_ALBUMS_REQUEST_ADMIN };
  }
  function success(user: any) {
    return { type: albumConstants.GET_ALL_ALBUMS_SUCCESS_ADMIN, user };
  }
  function failure(error: any) {
    return { type: albumConstants.GET_ALL_ALBUMS_FAILURE_ADMIN, error };
  }
}

function FetchEditAlbumAdmin(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    AlbumService.GetEditAlbumAdmin(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: albumConstants.EDIT_ALBUMS_REQUEST_ADMIN, id };
  }
  function success(user: any) {
    return { type: albumConstants.EDIT_ALBUMS_ADMIN_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: albumConstants.EDIT_ALBUMS_ADMIN_FAILURE, error };
  }
}

function UpdateAlbumAdmin(data: any, id: any) {
  return (dispatch: any) => {
    dispatch(request(data, id));
    AlbumService
      .UpdateAlbumAdmin(data, id)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "200") {
          dispatch(success(data));
          dispatch(
            alertActions.success(
              "You have successfully update an album "
            )
          );
          history.push("/travel-albums/my-albums");
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any, id: any) {
      return {
        type: albumConstants.UPDATE_ALBUMS_REQUEST_ADMIN,
        data,
        id,
      };
    }
    function success(user: any) {
      return { type: albumConstants.UPDATE_ALBUMS_REQUEST_ADMIN_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: albumConstants.UPDATE_ALBUMS_REQUEST_ADMIN_FAILURE, error };
    }
  };
}

function DeleteAlbumAdmin(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    AlbumService.DeleteAlbumAdmin(id)
      .then((res: any) => {
        dispatch(success(res.message));
        dispatch(alertActions.success(res.message));
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });

    function request(id: any) {
      return { type: albumConstants.DELETE_ALBUMS_REQUEST_ADMIN_REQUEST, id };
    }
    function success(user: any) {
      return { type: albumConstants.DELETE_ALBUMS_REQUEST_ADMIN_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: albumConstants.DELETE_ALBUMS_REQUEST_ADMIN_FAILURE, error };
    }
  };
}


function FetchAllAlbumsUser() {
  return (dispatch: any) => {
    dispatch(request());
    AlbumService.FetchAllAlbumsUser().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: albumConstants.GET_ALL_ALBUMS_REQUEST_USER };
  }
  function success(user: any) {
    return { type: albumConstants.GET_ALL_ALBUMS_SUCCESS_USER, user };
  }
  function failure(error: any) {
    return { type: albumConstants.GET_ALL_ALBUMS_FAILURE_USER, error };
  }
}


function FetchMyAlbum(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    AlbumService.GetMyAlbums(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: albumConstants.GET_MY_ALBUMS_REQUEST, id };
  }
  function success(user: any) {
    return { type: albumConstants.GET_MY_ALBUMS_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: albumConstants.GET_MY_ALBUMS_FAILURE, error };
  }
}

function FetchLovedAlbums() {
  return (dispatch: any) => {
    dispatch(request());
    AlbumService.FetchLovedAlbums().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: albumConstants.GET_LOVED_ALBUMS_REQUEST };
  }
  function success(user: any) {
    return { type: albumConstants.GET_LOVED_ALBUMS_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: albumConstants.GET_LOVED_ALBUMS_FAILURE, error };
  }
}


function DeleteAlbum(data:any,id: any) {
  return (dispatch: any) => {
    dispatch(request(data,id));
    AlbumService.DeleteAlbum(data,id)
      .then((response) => response)
      .then((res: any) => {
        if(res.deletedBy!="delete by user")
        {
         history.push('/travel-albums')
        }
          dispatch(success(res));
          dispatch(alertActions.success(res.data.message)
          );
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });

    function request(data:any,id: any) {
      return { type: albumConstants.DELETE_ALBUMS_REQUEST, data,id };
    }
    function success(user: any) {
      return { type: albumConstants.DELETE_ALBUMS_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: albumConstants.DELETE_ALBUMS_FAILUR, error };
    }
  };
}

function AddAlbumComment(data: any,titleUrl:any,id:any) {
  let url = window.location.pathname;
  let uid = url.slice((url.length - 26))
  let char=uid.charAt(0);
  return (dispatch: any) => {
    dispatch(request(data));
    AlbumService.AddAlbumComment(data,id)
      .then((response) => response)
      .then((data: any) => {
          dispatch(success(data));
          if(data.status===500)
          {
            dispatch(failure(data.error));
            dispatch(alertActions.error(data.data.error.message));
          }
          else
          {
            dispatch(alertActions.success(data.data.message));
            history.go(0)

            // if(char==="-")
            // {
            //   history.push(`/travel-albums/${titleUrl}-${id}`)
            // }
            // else
            // {
            //   history.push(`/travel-albums/${titleUrl}--${id}`)
            // }

          }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: albumConstants.ADD_ALBUM_COMMENT_REQUEST, data };
    }
    function success(user: any) {
      return { type: albumConstants.ADD_ALBUM_COMMENT_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: albumConstants.ADD_ALBUM_COMMENT_FAILURE, error };
    }
  };
}


function AddAlbumLike(data: any,id:any) {
  return (dispatch: any) => {
    dispatch(request(data));
    AlbumService.AddAlbumLike(data,id)
      .then((response) => response)
      .then((data: any) => {
          dispatch(success(data));
          if(data.status===500)
          {
            dispatch(failure(data.error));
            dispatch(alertActions.error(data.data.error.message));
          }
          else
          {
            dispatch(alertActions.success(data.data.message));
          }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: albumConstants.ADD_ALBUM_LIKE_REQUEST, data };
    }
    function success(user: any) {
      return { type: albumConstants.ADD_ALBUM_LIKE_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: albumConstants.ADD_ALBUM_LIKE_FAILURE, error };
    }
  };
}

function RemoveAlbumLike(data: any,id:any) {
  return (dispatch: any) => {
    dispatch(request(data));
    AlbumService.RemoveAlbumLike(data,id)
      .then((response) => response)
      .then((data: any) => {
          dispatch(success(data));
          if(data.status===500)
          {
            dispatch(failure(data.error));
            dispatch(alertActions.error(data.data.error.message));
          }
          else
          {
            dispatch(alertActions.success(data.data.message));
          }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: albumConstants.REMOVE_ALBUM_LIKE_REQUEST, data };
    }
    function success(user: any) {
      return { type: albumConstants.REMOVE_ALBUM_LIKE_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: albumConstants.REMOVE_ALBUM_LIKE_FAILURE, error };
    }
  };
}

function FeaturedAlbum(body:any,id:any) {
  return (dispatch:any) => {
      dispatch(request({ body,id }));
      AlbumService.FeaturedAlbum(body,id)
          .then((data:any)=>{
            if(data.status=="200")
            {
              dispatch(success(data));
              dispatch(alertActions.success(data.data.message))
              history.go(0);
            }

          }).catch((err:any)=>{
            dispatch(failure(err))
          });
          };

          function request(user:any) { return { type: albumConstants.FEATURED_ALBUM_REQUEST, user } }
          function success(user:any) { return { type: albumConstants.FEATURED_ALBUM_SUCCESS, user } }
          function failure(error:any) { return { type: albumConstants.FEATURED_ALBUM_FAILURE, error } }
}


function fetchAlbumsCount(){
  return (dispatch:any) => {
    dispatch(request());
    AlbumService.fetchAlbumsCount()
    .then(
      (res) => {
          dispatch(success(res));
      },
      (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.toString()));
      });
        };

        function request() { return { type: albumConstants.GET_ALBUMS_COUNT_REQUEST_ADMIN,} }
        function success(user:any) { return { type: albumConstants.GET_ALBUMS_COUNT_SUCCESS_ADMIN, user } }
        function failure(error:any) { return { type: albumConstants.GET_ALBUMS_COUNT_FAILURE_ADMIN, error } }
}
