import React, { useEffect } from 'react';
import { Button, Form, Input, Select} from 'antd';
import { CloseOutlined } from "@ant-design/icons";
import { history } from '../../../Helpers';
import { connect } from 'react-redux';
import { albumActions, albumCategoryActions, categoryActions } from '../../../actions';
import { config } from "../../../config";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};

const { Option } = Select;
const { TextArea } = Input;
type EditAlbum = {
  dispatch: any;
  match: any;
  albums: any;
  albumCategories: any,
  places: any,
}

let image: any = [];
let resultArray: any = '';
function EditAlbum(props: EditAlbum) {

  const { dispatch, match, albums, albumCategories, places } = props;
  const [showResults, setShowResults] = React.useState(true)
  const [filterResult, setfilterResult] = React.useState([])


  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {
      image = [];
      setfilterResult([])

      dispatch(albumCategoryActions.FetchAllAlbumCategories());
      dispatch(categoryActions.FetchAllPlaceCategories());
      dispatch(albumActions.FetchEditAlbumAdmin(match.params.id));

    }

  }, [dispatch, match.params.id]);
  const onFinishFailed = (errorInfo: any) => {
  };

  if (!albumCategories) {
    return (<div></div>)
  }
  if (!places) {
    return (<div></div>)
  }

  if (!albums) {
    return (<div></div>)
  }
  image = [];
  console.log('albums',albums)
  albums[0].albums.image.forEach((element: any) => {
    image.push({
      id: element._id,
      image: config.s3BucketUrl+element.imageName,
      imageDesc: element.imageDesc,
      location: element.location
    })
  });
  const onCloseClick = (id: any) => {

    if (resultArray.length == "0") {

      resultArray = image.filter((item: any) => item.id !== id);
      setfilterResult(resultArray)
      setShowResults(false)
    }
    else {
      resultArray = resultArray.filter((item: any) => item.id !== id)
      setfilterResult(resultArray)
    }
  }

  const onFinish = (values: any) => {
    let imagesArray: any = [];
    let location: any = [];
    let imageDesc: any = [];
    if (filterResult.length > 0) {
      if (filterResult.length == 15) {
        if (image.length == 15) {
          location.push(values.location0, values.location1, values.location2, values.location3, values.location4,
            values.location5, values.location6, values.location7, values.location8, values.location9,
            values.location10, values.location11, values.location12, values.location13, values.location14)
          imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4, values.desc5, values.desc6,
            values.desc7, values.desc8, values.desc9, values.desc10, values.desc11, values.desc12,
            values.desc13, values.desc14)
          for (let i = 0; i < filterResult.length; i++) {
            imagesArray.push(image[i].image.substring(27))
          }
        }
      }

      else if (filterResult.length == 14) {
        location.push(values.location0, values.location1, values.location2, values.location3, values.location4,
          values.location5, values.location6, values.location7, values.location8, values.location9,
          values.location10, values.location11, values.location12, values.location13)
        imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4, values.desc5, values.desc6,
          values.desc7, values.desc8, values.desc9, values.desc10, values.desc11, values.desc12,
          values.desc13)
        for (let i = 0; i < filterResult.length; i++) {
          imagesArray.push(image[i].image.substring(27))
        }
      }
      else if (filterResult.length == 13) {
        location.push(values.location0, values.location1, values.location2, values.location3, values.location4,
          values.location5, values.location6, values.location7, values.location8, values.location9,
          values.location10, values.location11, values.location12)
        imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4, values.desc5, values.desc6,
          values.desc7, values.desc8, values.desc9, values.desc10, values.desc11, values.desc12)
        for (let i = 0; i < filterResult.length; i++) {
          imagesArray.push(image[i].image.substring(27))
        }
      }
      else if (filterResult.length == 12) {
        location.push(values.location0, values.location1, values.location2, values.location3, values.location4,
          values.location5, values.location6, values.location7, values.location8, values.location9,
          values.location10, values.location11)
        imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4, values.desc5, values.desc6,
          values.desc7, values.desc8, values.desc9, values.desc10, values.desc11)
        for (let i = 0; i < filterResult.length; i++) {
          imagesArray.push(image[i].image.substring(27))
        }
      }
      else if (filterResult.length == 11) {
        location.push(values.location0, values.location1, values.location2, values.location3, values.location4,
          values.location5, values.location6, values.location7, values.location8, values.location9,
          values.location10)
        imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4, values.desc5, values.desc6,
          values.desc7, values.desc8, values.desc9, values.desc10)
        for (let i = 0; i < filterResult.length; i++) {
          imagesArray.push(image[i].image.substring(27))
        }
      }
      else if (filterResult.length == 10) {
        location.push(values.location0, values.location1, values.location2, values.location3, values.location4,
          values.location5, values.location6, values.location7, values.location8, values.location9)
        imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4, values.desc5, values.desc6,
          values.desc7, values.desc8, values.desc9)
        for (let i = 0; i < filterResult.length; i++) {
          imagesArray.push(image[i].image.substring(27))
        }
      }
      else if (filterResult.length == 9) {
        location.push(values.location0, values.location1, values.location2, values.location3, values.location4,
          values.location5, values.location6, values.location7, values.location8)
        imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4, values.desc5, values.desc6,
          values.desc7, values.desc8)
        for (let i = 0; i < filterResult.length; i++) {
          imagesArray.push(image[i].image.substring(27))
        }
      }
      else if (filterResult.length == 8) {
        location.push(values.location0, values.location1, values.location2, values.location3, values.location4,
          values.location5, values.location6, values.location7)
        imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4, values.desc5, values.desc6,
          values.desc7)
        for (let i = 0; i < filterResult.length; i++) {
          imagesArray.push(image[i].image.substring(27))
        }
      }
      else if (filterResult.length == 7) {
        location.push(values.location0, values.location1, values.location2, values.location3, values.location4,
          values.location5, values.location6)
        imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4, values.desc5, values.desc6)
        for (let i = 0; i < filterResult.length; i++) {
          imagesArray.push(image[i].image.substring(27))
        }
      }
      else if (filterResult.length == 6) {
        location.push(values.location0, values.location1, values.location2, values.location3, values.location4,
          values.location5)
        imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4, values.desc5)
        for (let i = 0; i < filterResult.length; i++) {
          imagesArray.push(image[i].image.substring(27))
        }
      }
      else if (filterResult.length == 5) {
        location.push(values.location0, values.location1, values.location2, values.location3, values.location4)
        imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4)
        for (let i = 0; i < filterResult.length; i++) {
          imagesArray.push(image[i].image.substring(27))
        }
      }
      else if (filterResult.length == 4) {
        location.push(values.location0, values.location1, values.location2, values.location3)
        imageDesc.push(values.desc0, values.desc1, values.desc3)
        for (let i = 0; i < filterResult.length; i++) {
          imagesArray.push(image[i].image.substring(27))
        }
      }
      else if (filterResult.length == 3) {
        location.push(values.location0, values.location1, values.location2)
        imageDesc.push(values.desc0, values.desc1, values.desc2)
        for (let i = 0; i < filterResult.length; i++) {
          imagesArray.push(image[i].image.substring(27))
        }
      }
      else if (filterResult.length == 2) {
        location.push(values.location0, values.location1)
        imageDesc.push(values.desc0, values.desc1)
        for (let i = 0; i < filterResult.length; i++) {
          imagesArray.push(image[i].image.substring(27))
        }
      }
      if (filterResult.length == 1) {
        location.push(values.location0)
        imageDesc.push(values.desc0)
        for (let i = 0; i < filterResult.length; i++) {
          imagesArray.push(image[i].image.substring(27))
        }
      }
    }
    else {
      if (image.length > 0) {
        if (image.length == 15) {
          location.push(values.location0, values.location1, values.location2, values.location3, values.location4,
            values.location5, values.location6, values.location7, values.location8, values.location9,
            values.location10, values.location11, values.location12, values.location13, values.location14)
          imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4, values.desc5, values.desc6,
            values.desc7, values.desc8, values.desc9, values.desc10, values.desc11, values.desc12,
            values.desc13, values.desc14)
          for (let i = 0; i < image.length; i++) {
            imagesArray.push(image[i].image.substring(27))
          }
        }
        else if (image.length == 14) {
          location.push(values.location0, values.location1, values.location2, values.location3, values.location4,
            values.location5, values.location6, values.location7, values.location8, values.location9,
            values.location10, values.location11, values.location12, values.location13)
          imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4, values.desc5, values.desc6,
            values.desc7, values.desc8, values.desc9, values.desc10, values.desc11, values.desc12,
            values.desc13)
          for (let i = 0; i < image.length; i++) {
            imagesArray.push(image[i].image.substring(27))
          }
        }
        else if (image.length == 13) {
          location.push(values.location0, values.location1, values.location2, values.location3, values.location4,
            values.location5, values.location6, values.location7, values.location8, values.location9,
            values.location10, values.location11, values.location12)
          imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4, values.desc5, values.desc6,
            values.desc7, values.desc8, values.desc9, values.desc10, values.desc11, values.desc12)
          for (let i = 0; i < image.length; i++) {
            imagesArray.push(image[i].image.substring(27))
          }
        }
        else if (image.length == 12) {
          location.push(values.location0, values.location1, values.location2, values.location3, values.location4,
            values.location5, values.location6, values.location7, values.location8, values.location9,
            values.location10, values.location11)
          imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4, values.desc5, values.desc6,
            values.desc7, values.desc8, values.desc9, values.desc10, values.desc11)
          for (let i = 0; i < image.length; i++) {
            imagesArray.push(image[i].image.substring(27))
          }
        }
        else if (image.length == 11) {
          location.push(values.location0, values.location1, values.location2, values.location3, values.location4,
            values.location5, values.location6, values.location7, values.location8, values.location9,
            values.location10)
          imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4, values.desc5, values.desc6,
            values.desc7, values.desc8, values.desc9, values.desc10)
          for (let i = 0; i < image.length; i++) {
            imagesArray.push(image[i].image)
          }

        }
        else if (image.length == 10) {
          location.push(values.location0, values.location1, values.location2, values.location3, values.location4,
            values.location5, values.location6, values.location7, values.location8, values.location9)
          imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4, values.desc5, values.desc6,
            values.desc7, values.desc8, values.desc9)
          for (let i = 0; i < image.length; i++) {
            imagesArray.push(image[i].image.substring(27))
          }
        }
        else if (image.length == 9) {
          location.push(values.location0, values.location1, values.location2, values.location3, values.location4,
            values.location5, values.location6, values.location7, values.location8)
          imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4, values.desc5, values.desc6,
            values.desc7, values.desc8)
          for (let i = 0; i < image.length; i++) {
            imagesArray.push(image[i].image.substring(27))
          }
        }
        else if (image.length == 8) {
          location.push(values.location0, values.location1, values.location2, values.location3, values.location4,
            values.location5, values.location6, values.location7)
          imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4, values.desc5, values.desc6,
            values.desc7)
          for (let i = 0; i < image.length; i++) {
            imagesArray.push(image[i].image.substring(27))
          }
        }
        else if (image.length == 7) {
          location.push(values.location0, values.location1, values.location2, values.location3, values.location4,
            values.location5, values.location6)
          imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4, values.desc5, values.desc6)
          for (let i = 0; i < image.length; i++) {
            imagesArray.push(image[i].image.substring(27))
          }
        }
        else if (image.length == 6) {
          location.push(values.location0, values.location1, values.location2, values.location3, values.location4,
            values.location5)
          imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4, values.desc5)
          for (let i = 0; i < image.length; i++) {
            imagesArray.push(image[i].image.substring(27))
          }
        }
        else if (image.length == 5) {
          location.push(values.location0, values.location1, values.location2, values.location3, values.location4)
          imageDesc.push(values.desc0, values.desc1, values.desc3, values.desc4)
          for (let i = 0; i < image.length; i++) {
            imagesArray.push(image[i].image.substring(27))
          }
        }
        else if (image.length == 4) {
          location.push(values.location0, values.location1, values.location2, values.location3)
          imageDesc.push(values.desc0, values.desc1, values.desc3)
          for (let i = 0; i < image.length; i++) {
            imagesArray.push(image[i].image.substring(27))
          }
        }
        else if (image.length == 3) {
          location.push(values.location0, values.location1, values.location2)
          imageDesc.push(values.desc0, values.desc1, values.desc2)
          for (let i = 0; i < image.length; i++) {
            imagesArray.push(image[i].image.substring(27))
          }
        }
        else if (image.length == 2) {
          location.push(values.location0, values.location1)
          imageDesc.push(values.desc0, values.desc1)
          for (let i = 0; i < image.length; i++) {
            imagesArray.push(image[i].image.substring(27))
          }

        }
        else if (image.length == 1) {
          location.push(values.location0)
          imageDesc.push(values.desc0)
          for (let i = 0; i < image.length; i++) {
            imagesArray.push(image[i].image.substring(27))
          }
        }
        else {

        }

      }

    }
    let albums = [{
      title: values.title,
      description: values.description,
      categories: values.albumcats,
      location: location,
      imageDesc: imageDesc,
      images: imagesArray

    }]
    dispatch(albumActions.UpdateAlbumAdmin(albums, match.params.id));
  };
  // if(albums[0].tags[0])
  // {
  //  data= albums[0].tags[0].split(',');
  // }

  return (
    <div>
      <Form
        {...layout}
        className="admin-login-form"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item className="lbl-name" label="Title"></Form.Item>
        <Form.Item
          initialValue={albums[0].albums.title}
          name="title"
          rules={[{ required: true, message: 'Please enter a title.' },]}
        >
          <Input name="title" size="large" placeholder="title" />
        </Form.Item>
        <Form.Item className="lbl-name" label="Category Name"></Form.Item>
        <Form.Item
          hasFeedback
          name="albumcats"
          initialValue={albums[0].albumcategories._id}
          rules={[{ required: true, message: 'Please enter a place.' },]}
        >
          <Select defaultValue={albumCategories[0]._id} placeholder="select a place" size="large" >

            {
              albumCategories.length > 0 ?
                albumCategories.map((item: any) => <Option selected="selected" value={item._id} >
                  {item.categoryName}
                </Option >)
                :
                <Option value="1">data</Option>
            }
          </Select>
        </Form.Item>
        <Form.Item className="lbl-name" label="Description"></Form.Item>
        <Form.Item
          initialValue={albums[0].albums.description}
          name="description"
        >
          <TextArea name="description" placeholder="Description" rows={4}
          />
        </Form.Item>
        <Form.Item className="lbl-name" label="Tags"></Form.Item>
        {/* <Form.Item >
          {
            data.map((item: any) => <Tag color="success">{item}</Tag>)
          }
        </Form.Item> */}
        <Form.Item className="lbl-name" label="Photo"></Form.Item>
        {
          showResults ?
            image.length > 0 ?
              image.map((item: any, i: any) =>
                <div style={{ display: ' flow-root', boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.16)", marginBottom: '20px' }}>
                  <div style={{ float: 'right', padding: "10px 10px 0px 0px", cursor: 'pointer' }} onClick={() => onCloseClick(item.id)}>
                    <CloseOutlined />
                  </div>

                  <div style={{ width: "30%", float: "left", padding: "40px 10px" }}>
                    <img src={item.image} width="100%" alt={item.image} style={{ textAlign: 'center', marginBottom: "20px" }} />
                  </div>
                  <div style={{ width: "70%", float: 'right' }}>
                  <Form.Item className="lbl-name" label="Image Description"></Form.Item>
                    <Form.Item
                      initialValue={item.imageDesc}
                      name={"desc" + i}
                      rules={[{ required: true, message: 'Please enter a description.' },]}
                    >
                      <Input name={"desc" + i} value={item.imageDesc} size="large" placeholder="image Desc" />
                    </Form.Item>
                    <Form.Item className="lbl-name" label="Image Place"></Form.Item>
                    <Form.Item
                      hasFeedback
                      name={"location" + i}
                      initialValue={item.location}
                      rules={[{ required: true, message: 'Please enter a place.' },]}
                    >
                      <Select defaultValue={item.location} placeholder="select a place" size="large" >

                        {
                          places.length > 0 ?
                            places.map((dat: any) => <Option selected="selected" value={dat._id} >
                              {dat.placeName}
                            </Option >)
                            :
                            <Option value="1">data</Option>
                        }
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              )
              : null
            : filterResult.map((item: any, x: any) =>
              <div style={{ display: ' flow-root', boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.16)", marginBottom: '20px' }}>
                <div style={{ float: 'right', padding: "10px 10px 0px 0px", cursor: 'pointer' }} onClick={() => onCloseClick(item.id)}>
                  <CloseOutlined />
                </div>

                <div style={{ width: "30%", float: "left", padding: "20px" }}>
                  <img src={item.image} alt={item.image} width="100%" style={{ textAlign: 'center', marginBottom: "20px" }} />
                </div>
                <div style={{ width: "70%", float: 'right', padding: '60px' }}>
                  <Form.Item
                    initialValue={item.imageDesc}
                    name={"desc" + x}
                    rules={[{ required: true, message: 'Please enter a title.' },]}
                  >
                    <Input name={"desc" + x} size="large" placeholder="image Desc" />
                  </Form.Item>
                  <Form.Item
                    hasFeedback
                    name={"location" + x}
                    initialValue={item.location}
                    rules={[{ required: true, message: 'Please enter a place.' },]}
                  >
                    <Select defaultValue={item.location} placeholder="select a place" size="large" >

                      {
                        places.length > 0 ?
                          places.map((dat: any) => <Option name={"location" + x} selected="selected" value={dat._id} >
                            {dat.placeName}
                          </Option >)
                          :
                          <Option value="1">data</Option>
                      }
                    </Select>
                  </Form.Item>
                </div>
              </div>
            )
        }
        <Form.Item >
          <Button className="btn-admin-login" size="large" type="primary" htmlType="submit">
            Update Album
          </Button>
        </Form.Item>

      </Form>
    </div>
  )
}
function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    albums: state.FetchEditAlbum.fetchEditAlbumObject,
    albumCategories: state.FetchAlbumCategories.getAllAlbumCategoriesObject,
    places: state.FetchPlaceCategories.getAllPlaceCategoriesObject,

  };
}

export const connectedEditAlbum = connect(mapStateToProps)(EditAlbum);
