import React, { useState, useEffect } from 'react';
import { Table, Dropdown, Menu, Button, Modal, Input, Form, Select} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { history } from '../../../Helpers';
import { SupportActions } from '../../../actions';
import { connect } from 'react-redux';
import { SettingOutlined } from '@ant-design/icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {config} from '../../../config'



const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};



type PrivacyTerms = {
  dispatch: any;
  data: any;
}
const { Option } = Select;

function PrivacyTerms(props: PrivacyTerms) {
 let formRef:any = React.createRef<FormInstance>();
  let fetchId: any = '';
  const { dispatch, data } = props;
  const [visible, setvisible] = useState(false);
  const [checkSearch, setcheckSearch] = useState(false);
  const [filterData, setfilterData] = useState();
  const [quilValue, setquilValue] = useState('');

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {
      dispatch(SupportActions.FetchAllPrivaciesAdmin());

    }

  }, [dispatch]);


  const [selectedRowKeys, setselectedRowKeys] = useState([]);


  const onSelectChange = (selectedRowKeys: any) => {
    setselectedRowKeys(selectedRowKeys)

  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  if (!data) {
    return (<div></div>)
  }
  let filterArray: any = [];
  let search = (value: any) => {

    const filterArrayData = data.filter((o: any) =>
      Object.keys(o).some(k =>
        String(o[k])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );
    filterArrayData.forEach((element: any, index: any) => {

      filterArray.push({
        key: element._id,
      id: element._id,
      data: element.data.replace(/(<([^>]+)>)/gi, "").substring(0,20),
      type: element.type,
      createdDate: element.createdDate.split("T")[0]
      })

    });
    setfilterData(filterArray);
    setcheckSearch(true);
  };



  const categoriesArray: any = [];
  if(data.length>0)
  {
    data.forEach((element: any, index: any) => {
      categoriesArray.push({
        key: element._id,
        id: element._id,
        data: element.data.replace(/(<([^>]+)>)/gi, "").substring(0,20),
        type: element.type,
        createdDate: element.createdDate.split("T")[0]
      })

    });
  }


  const showModal = () => {
    setvisible(true);
  };

  const handleOk = (e: any) => {
    setvisible(false);
    // dispatch(facilitiesActions.FetchAllFacilities());
  };

  const handleCancel = (e: any) => {
    setvisible(false);
  };

  const onFinish = (values: any) => {
    let formData={
      type:values.type,
      data:quilValue
    }
    dispatch(SupportActions.AddPrivacyRequest(formData));
    formRef.current.resetFields()
    setquilValue('');
    // dispatch(facilitiesActions.FetchAllFacilities());

  };
  const onFinishFailed = (errorInfo: any) => {
  };
  const onMenuClick = (id: any) => {
    fetchId = id;
  }
  const onSelect = (e: any) => {

    if (e.key == "EU") {
      history.push('/pr-admin-cp/privacy-and-terms/' + fetchId)
    }
    // else {
    //   Modal.warning({
    //     title: 'Opppssss',

    //     content: (
    //       <div className="warning-modal">
    //         <p>Are you sure you want to delete this?</p>
    //       </div>
    //     ),
    //     className: "cancel-modal",
    //     width: 560,
    //     okType: 'danger',
    //     okText: 'ok',
    //     okCancel: true,
    //     centered: true,
    //     cancelText: 'cancel',
    //     onOk: () => {
    //       // dispatch(facilitiesActions.DeleteActivity(fetchId));
    //       // dispatch(facilitiesActions.FetchAllFacilities());
    //     },
    //     onCancel() {
    //     },
    //   });

    // }

  }

  const onQuilValueChange = (value: any,editor:any) => {
    setquilValue(editor.getData());
  }
  const columns = [
    {
      title: 'title',
      dataIndex: 'data',
    },
    {
      title: 'type',
      dataIndex: 'type',
    },
    {
      title: 'Created  Date',
      dataIndex: 'createdDate',
    },
    {
      title: 'Settings',
      render: (records: any) => <Dropdown className="admin-acc" overlay={(
        <Menu onClick={onSelect}>
          <Menu.Item key="EU" onClick={() => onMenuClick(records.id)} className="user-menu-item">
            <span>
              Edit Data
          </span>
          </Menu.Item>
          {/* <Menu.Item key="DU" onClick={() => onMenuClick(records.id)} className="user-menu-item">
            <span>
              Delete Data
          </span>
          </Menu.Item> */}
        </Menu>
      )} trigger={['click']} >
        <SettingOutlined />
      </Dropdown>,
    },
  ];

  return (
    <div>
      <div style={{ width: '50%', float: 'left' }}>
        <Input.Search
          placeholder="Search by..."
          enterButton
          size="large"
          onSearch={search}
        />
      </div>

      <div style={{ marginBottom: "20px", textAlign: "right" }}>
        <Button type="primary" onClick={showModal} >Add Privacy and Terms</Button>
      </div>
      {
        checkSearch ? <Table className="show-table" rowSelection={rowSelection} columns={columns} dataSource={filterData} /> :
          <Table className="show-table" rowSelection={rowSelection} columns={columns} dataSource={categoriesArray} />

      }


      <Modal
        title="Add Privacy and Terms"
        visible={visible}
        onOk={handleOk}
        width="70%"
        onCancel={handleCancel}
      >
        <div className=''>
          <Form
            {...layout}
            ref={formRef}
            className="admin-login-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item className="lbl-name" label="Type"></Form.Item>
            <Form.Item
        hasFeedback
          name="type"
          rules={[{ required: true }]}
        >
         <Select size="large" placeholder="Select a Type">
            <Option value="privacy">Privacy Policies</Option>
            <Option value="terms">Terms of use</Option>
            <Option value="cookie">Cookies</Option>
            <Option value="dd">Data Deletion</Option>
           </Select>
        </Form.Item>
            <Form.Item className="lbl-name" label="Data"></Form.Item>
            <Form.Item>
            <CKEditor
                 value={quilValue} onChange={onQuilValueChange}
                    editor={ ClassicEditor }

                    config={{ckfinder: {
                      // Upload the images to the server using the CKFinder QuickUpload command.
                      uploadUrl: `${config.apiurl}/upload`,
                    }}}
                />
              {/* <ReactQuill style={{ height: '200px', marginBottom: '20px' }} theme="snow" value={quilValue} onChange={onQuilValueChange} /> */}
            </Form.Item>
            <Form.Item >
              <Button className="btn-admin-login" style={{ marginTop: '20px' }} size="large" type="primary" htmlType="submit">
                Add Privcay and Terms
                    </Button>

            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );

}

function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    data: state.FetchPrivacyAdmin.FetchPrivacyAdminObject
  };
}

export const connectedPrivacyTerms = connect(mapStateToProps)(PrivacyTerms);

