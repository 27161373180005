import React, { useState, useEffect } from 'react';
import { Table, Dropdown, Menu, Button, Modal, Input, Form, Avatar, Upload, message,Switch} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CloudUploadOutlined,SettingOutlined,CloseOutlined, CheckOutlined,ExclamationCircleOutlined } from "@ant-design/icons";
import { history } from '../../../Helpers';
import { tripActions } from '../../../actions';
import { connect } from 'react-redux';
import { config } from "../../../config";

const { confirm } = Modal;
const { TextArea } = Input;

function beforeUpload(file: any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};



type Announcement = {
  dispatch: any;
  data: any;
}

const { Dragger } = Upload;

const prop: any = {
  name: 'userImage',
  action: '',
};


const onPreview = async (file: any) => {
  let src = file.url;
  if (!src) {
    src = await new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  }
  const image = new Image();
  image.src = src;
  const imgWindow: any = window.open(src);
  imgWindow.document.write(image.outerHTML);
};

function AccessoriesCategories(props: Announcement) {
 let formRef:any = React.createRef<FormInstance>();
  let fetchId: any = '';
  const { dispatch, data } = props;
  const [visible, setvisible] = useState(false);
  const [checkSearch, setcheckSearch] = useState(false);
  const [filterData, setfilterData] = useState();

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {
      dispatch(tripActions.FetchAllAccessoriesCategories());

    }

  }, [dispatch]);


  const [selectedRowKeys, setselectedRowKeys] = useState([]);


  const onSelectChange = (selectedRowKeys: any) => {
    setselectedRowKeys(selectedRowKeys)

  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  if (!data) {
    return (<div></div>)
  }
  let filterArray: any = [];
  let search = (value: any) => {

    const filterArrayData = data.filter((o: any) =>
      Object.keys(o).some(k =>
        String(o[k])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );
    filterArrayData.forEach((element: any, index: any) => {

      if (element.icon) {
        imagee = config.s3BucketUrl+element.icon
      }
      else {
        imagee = "N/A"
      }

      filterArray.push({
        key: element._id,
        id: element._id,
        Photo: imagee,
        categoryName: element.title,
        createdDate: element.createdDate.split("T")[0]
      })

    });
    setfilterData(filterArray);
    setcheckSearch(true);
  };

  const onFeaturedChange=(records:any)=>{
    // let dat={
    //   isActive:!records.isActive
    // }
    // if(!records.isActive)
    // {
    //   confirm({
    //     title: 'Do you want to active this announcement?',
    //     icon: <ExclamationCircleOutlined />,
    //     onOk() {
    //       dispatch(announcementActions.ActivatedAnnouncement(dat,records.key));
    //     },
    //     onCancel() {
    //     },
    //   });
    // }
    // else
    // {
    //   confirm({
    //     title: 'Do you Want to deactive this announcement?',
    //     icon: <ExclamationCircleOutlined />,
    //     onOk() {
    //       dispatch(announcementActions.ActivatedAnnouncement(dat,records.key));
    //     },
    //     onCancel() {
    //     },
    //   });
    // }
  }

  const categoriesArray: any = [];
  let imagee: any = '';
  data.forEach((element: any, index: any) => {

    if (element.icon) {
      imagee = config.s3BucketUrl+element.icon
    }
    else {
      imagee = "N/A"
    }

    categoriesArray.push({
      key: element._id,
      id: element._id,
      Photo: imagee,
      title: element.title,
      desc:element.description,
      isActive:element.isActive,
      createdDate: element.createdDate.split("T")[0]
    })

  });

  const showModal = () => {
    setvisible(true);
  };

  const handleOk = (e: any) => {
    setvisible(false);
    // dispatch(announcementActions.FetchAllFacilities());
  };

  const handleCancel = (e: any) => {
    setvisible(false);
  };

  const onFinish = (values: any) => {
    const data = new FormData()
    data.append("title", values.title)
    data.append("description", values.description)
    data.append("image", values.icon.file.originFileObj)
    dispatch(tripActions.AddAccessoriesCategories(data));
    formRef.current.resetFields()
  };
  const onFinishFailed = (errorInfo: any) => {
  };
  const onMenuClick = (id: any) => {
    fetchId = id;
  }
  const onSelect = (e: any) => {
    if (e.key == "EU") {
      history.push('/pr-admin-cp/edit-announcement/' + fetchId)
    }
    else {
      Modal.warning({
        title: 'Opppssss',

        content: (
          <div className="warning-modal">
            <p>Are you sure you want to delete this?</p>
          </div>
        ),
        className: "cancel-modal",
        width: 560,
        okType: 'danger',
        okText: 'ok',
        okCancel: true,
        centered: true,
        cancelText: 'cancel',
        onOk: () => {
          dispatch(tripActions.DeleteAccessoriesCategories(fetchId));
          dispatch(tripActions.FetchAllAccessoriesCategories());
        },
        onCancel() {
        },
      });

    }

  }
  const onImageClick = (image: any) => {
    window.open(image);
  }
  const columns = [
    {
      title: 'Photo',
      dataIndex: 'Photo',
      render: (records: any) => <div onClick={() => onImageClick(records)}><Avatar className="table-img" src={records}></Avatar></div>

    },
    {
      title: 'title',
      dataIndex: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'desc',
    },
    {
      title: 'Featured',
      dataIndex: 'isActive',
      render:(e:any,records:any)=> <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked={e}
      onChange={()=>onFeaturedChange(records)}
    />,
    },
    {
      title: 'Created  Date',
      dataIndex: 'createdDate',
    },
    {
      title: 'Settings',
      render: (records: any) => <Dropdown className="admin-acc" overlay={(
        <Menu onClick={onSelect}>
          <Menu.Item key="EU" onClick={() => onMenuClick(records.id)} className="user-menu-item">
            <span>
              Edit Announcement
          </span>
          </Menu.Item>
          <Menu.Item key="DU" onClick={() => onMenuClick(records.id)} className="user-menu-item">
            <span>
              Delete Announcement
          </span>
          </Menu.Item>
        </Menu>
      )} trigger={['click']} >
        <SettingOutlined />
      </Dropdown>,
    },
  ];
  const onPageChange=(e:any)=>{
   console.log(e)
  }

  return (
    <div>
      <div style={{ width: '50%', float: 'left' }}>
        <Input.Search
          placeholder="Search by..."
          enterButton
          size="large"
          onSearch={search}
        />
      </div>

      <div style={{ marginBottom: "20px", textAlign: "right" }}>
        <Button type="primary" onClick={showModal} >Add Accessories Categories</Button>
      </div>
      {
        checkSearch ? <Table className="show-table" rowSelection={rowSelection} columns={columns} dataSource={filterData} /> :
          <Table pagination={{ defaultPageSize: 10, total: categoriesArray , showSizeChanger: false, onChange:onPageChange}} className="show-table" rowSelection={rowSelection} columns={columns} dataSource={categoriesArray} />

      }


      <Modal
        title="Add an activity"
        visible={visible}
        onOk={handleOk}
        width="768px"
        onCancel={handleCancel}
      >
        <div className=''>
          <Form
            {...layout}
            ref={formRef}
            className="admin-login-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item className="lbl-name" label="Title"></Form.Item>
            <Form.Item
              name="title"
              rules={[{ required: true, message: 'Please enter a title.' },]}
            >
              <Input name="title" size="large" placeholder="Title" />
            </Form.Item>
            <Form.Item className="lbl-name" label="Icon"></Form.Item>
            <Form.Item
              name="icon"
              rules={[{ required: true, message: 'Please upload a picture.' },]}
              className="file-upload">
              <Dragger beforeUpload={beforeUpload}
                onPreview={onPreview}
                multiple={false}
                {...prop}
                listType="picture-card">
                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined style={{ color: "#000000" }} />
                  <span className="upld-desc" > Upload or drop photo here</span>
                </p>
              </Dragger>
            </Form.Item>
            <Form.Item className="lbl-name" label="Description"></Form.Item>
            <Form.Item
              hasFeedback
              name="description"
            >
              <TextArea name="lastName" placeholder="Description" rows={4}
              />
            </Form.Item>
            <Form.Item >
              <Button className="btn-admin-login" style={{ marginTop: '20px' }} size="large" type="primary" htmlType="submit">
                Add Accessories Categories
                    </Button>

            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );

}

function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    data: state.FetchAccessoriesCategories.getAllAccessoriesCategoriesObject
  };
}

export const connectedAccessoriesCategories
 = connect(mapStateToProps)(AccessoriesCategories);

