import React, { useEffect, useState } from 'react';
import { Text, Avatar,Image} from "@chakra-ui/core";
import { Button} from 'antd';
import  Icon  from '@ant-design/icons';
import Cookies from 'universal-cookie';
import { DownOutlined } from '@ant-design/icons';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import {useLocation} from 'react-router-dom'
import { connect } from 'react-redux';
import { Menu, Dropdown,message } from 'antd';
import { history } from '../../Helpers';
import { userActions } from '../../actions';
import webLogo from '../../Assets/website-logo.svg'
import './Navbar.css'
import "antd/dist/antd.css";
import { Link } from 'react-router-dom';
const fblogo = require('../../Assets/facebook_login-01.svg')
const googlelogo = require('../../Assets/google_login-01.svg')

type NavBar = {
  dispatch: any;
  data: any;
}

const pakMapSvg = () => (
  <svg version="1.1" id="Layer_1"
  xmlns="http://www.w3.org/2000/svg" width="24" height="24"
	 viewBox="0 0 28.5 24.5">
<g id="icon_map_active" transform="translate(-3518.22 -7061.867)">
	<path id="icon_flag_filled" className="st0" d="M3539.6,7062.1c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.2,0.2,0.3,0.4c0.1,0,0.2,0,0.3,0
		c0.2,0,0.5,0.1,0.5,0.3c0.3,0.6,0,0.6-0.1,0.9c0.2,0.3,0.2,0,0.3,0.5l0.6-0.1c0.2,0.1,0.1,0.3,0.4,0.4l1.2,0.4c0.1,0,0.1,0,0.2,0
		h0.2c0.1,0,0.3,0,0.4,0c0,0.2,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0.2,0.5,0,1,0.7,1.1
		c0.3,0,0.5,0.1,0.7,0.3c-0.2,0.6-0.5-0.2-0.4,1.4c0,0.6,0.4,0.2,0.7,1.2c0,0.3,0.1,0.5,0.3,0.7v0.1c-0.5,0.1-0.2,0.4-0.4,0.4
		c0,0-0.1,0-0.1,0c-0.2-0.1-0.2-0.4-0.3-0.6c-0.2,0-0.5,0.1-0.7,0.3l0.1-0.5c-0.3,0.1-0.3,0.3-0.6,0.3c-0.2-0.2-0.2-0.4-0.4-0.5
		c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.2-0.4,0.2c0,0,0,0-0.1,0c-0.2,0-0.8-0.7-0.9-0.8l-0.2,0.2c0,0,0,0,0,0l0.1,0.1c0,0,0,0,0,0
		c0,0.1,0,0.1-0.1,0.1l-0.9,0.1c-0.2,0-0.3,0-0.4,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,1.1-1.3,0.7-1.9,1.2c-0.3,0.2-0.3,0.8-0.3,1.2
		c-0.1,1,0.4,0.6-0.4,1c0,0.4,0.2,0.2-0.1,0.4c-0.2,0.1-0.3,0.2-0.5,0.3l0.1,0.6c-0.3,0.2-0.7,0.2-0.8,0.4c-0.2,0.3-0.2,0.6-0.2,0.9
		c-0.9,0.5,0.1,0.2-1.2,1.1c-0.5,0.3-0.3,0.2-0.4,0.7c-0.1,0.2-0.1,0.2-0.2,0.3l-0.1,0.4l0,0c0,0,0,0,0,0.1
		c-0.3,0.1-0.6,0.2-0.9,0.2c-0.4,0-0.4,0.2-0.7,0.3c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4-0.1-0.7,0c-0.2,0.3-0.2,0.4-0.5,0.6
		c-0.3,0.2-0.4,0.2-0.5,0.6c-0.1,0.3,0,0.6,0.2,0.8c0.3,0.3,0.5,0.1,0.8,0.2c0.2,0.3-0.5,0.9,0.2,1.5l0.5-0.1c0.1,0.3,0,0.4,0.1,0.6
		c0.1,0.1,0.2,0.2,0.3,0.4c0,0.2,0.1,0.4,0.2,0.5c0.2,0.3,0.1,0,0,0.4c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0,0,0
		l0,0c0,0,0,0,0,0.1c-0.2,0.2-0.4,0.4-0.7,0.4l-0.2-0.3c0,0,0,0,0,0c-0.8,0.1-0.5,0-0.7,0.3h0c-0.4,0-0.5-0.2-0.8-0.2h0
		c-0.3,0-0.7,0-1,0c-0.2,0.4,0.2,0.4-0.5,0.5l-0.7,0.2h-0.3c-0.4-0.2-0.5,0.1-0.6-0.6c-0.2-1.4-0.9-1-1.1-1.3c-0.3-0.4,0-0.5,0-0.9
		c-0.3-0.2-0.4-0.3-0.5-0.3c-0.1,0-0.2,0.2-0.4,0.4c-0.1,0.1-0.2,0.1-0.2,0.1l0,0c0,0,0,0-0.3,0.2c-0.2,0-0.4-0.1-0.6-0.1
		c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1,0-0.3-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.2,0-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2
		c-0.2,0.1-0.1,0.1-0.3,0.1c-0.1,0-0.2,0-0.4,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0.1l-0.2,0.2c-0.2,0-0.6-0.1-1-0.1
		c-0.3,0-0.7,0-1,0.2l-0.1,0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.1-0.7,0.1-0.8,0.2-1.3c0.1-0.6,0.2-0.7,0.3-0.7c0.1,0,0.2,0.1,0.3,0.1
		c0-0.5,0.3-0.7,0.6-0.7c0.2,0,0.5,0,0.7,0.1c0.1-0.3,0-0.4,0.1-0.6s0.2-0.2,0.2-0.4h-0.1c-0.2,0-0.4,0.1-0.5,0.1
		c-0.1,0-0.1,0-0.2,0c-0.1-0.4,0.2-0.5,0.1-1c-0.1-0.5-0.2-0.3-0.1-0.9c-0.1,0-0.2,0-0.2,0c-0.2,0-0.3-0.1-0.5-0.2
		c-0.2-0.1-0.4-0.1-0.7-0.3s-0.3-0.3-0.4-0.5c-0.1-0.3-0.3-0.3-0.5-0.5c0.1-0.3,0.1-0.1,0.1-0.4l-0.6-0.6v-0.2c0.1,0,0.1,0,0.2,0
		c0.5,0,1.2,0.6,1.6,0.7c0.2,0.1,0.4,0.1,0.5,0.1c0.5,0,1-0.2,1.5-0.2c0.1,0,0.2,0,0.4,0c0.3,0.1,0.4,0.1,0.5,0.1
		c0.1-0.1,0.3-0.1,0.5-0.2c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.1,0.6,0.1c0.1,0,0.1,0,0.2,0c0.7-0.1,1.4-0.3,2-0.7
		c-0.1-0.3-0.3-0.2-0.1-0.6c0.1-0.3,0.1-0.5,0.1-0.8c0.1-0.4,0.4-0.8,0.8-1c0,0,0-0.1,0.2-0.1h0l0.3,0.1l0.4,0.1
		c0.3,0,0.3,0,0.3-0.3l-0.2-0.1c0.5-0.4,0.3-0.1,0.9-0.8c0.1,0.2,0,0.1,0.2,0.2c0.1-0.1,0.2-0.2,0.4-0.2c0.1,0,0.2,0.1,0.3,0.3
		c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0
		c0-0.2-0.1-0.4-0.2-0.7c-0.1-0.5,0-0.3,0.2-0.6l-0.1-0.3c0.1-0.2,0.1-0.1,0.2-0.2c0.1-0.1,0,0,0.1-0.1c0.2-0.6,0.6-0.1,1.2-0.6
		c0-0.3-0.1-0.5-0.2-0.7c-0.2-0.2-0.1,0-0.2-0.2c0-0.1-0.1-0.3-0.1-0.4c0.4,0.1,0.7,0.1,1.1,0.1h0.2c0.1-0.2-0.1-0.1,0.1-0.2
		c0.2-0.1,0.2,0,0.4-0.1c0.3-0.2-0.1-0.4-0.1-0.6c0-0.4,0.7-0.7,1-1.3l-0.2-0.2c0.3-0.5,0.1-0.3,0.1-0.8c0-0.4-0.3-0.3-0.5-0.6
		c0.1-0.2,0.3-0.3,0.4-0.5c0.1-0.2,0.2-0.2,0.3-0.2c0.1,0,0.1,0,0.2,0.1c0-0.5,0.1-0.3,0.4-0.4c0.4-0.1,0.1-0.3,0.2-0.5
		c0.1,0,0.2,0,0.3,0c0.7,0,1.9-0.2,2.1-0.2h0c0.1,0,0.2,0.1,0.4,0.1c0.3,0,0.6-0.1,0.9-0.3L3539.6,7062.1"/>
</g>
</svg>
);
const storiesSvg=()=>(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M20 22H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1zM7 6v4h4V6H7zm0 6v2h10v-2H7zm0 4v2h10v-2H7zm6-9v2h4V7h-4z"/></svg>
);
const albumSvg=()=>(
  <svg xmlns="http://www.w3.org/2000/svg"
   viewBox="0 0 24 24" width="24" height="24">
     <path fill="none" d="M0 0h24v24H0z"/>
     <path d="M17.409 19c-.776-2.399-2.277-3.885-4.266-5.602A10.954 10.954 0 0 1 20 11V3h1.008c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3H6V1h2v4H4v7c5.22 0 9.662 2.462 11.313 7h2.096zM18 1v4h-8V3h6V1h2zm-1.5 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/></svg>

);
const AccountSvg=()=>(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zM6.023 15.416C7.491 17.606 9.695 19 12.16 19c2.464 0 4.669-1.393 6.136-3.584A8.968 8.968 0 0 0 12.16 13a8.968 8.968 0 0 0-6.137 2.416zM12 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/></svg>
);

const settingsSvg=()=>(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M2.132 13.63a9.942 9.942 0 0 1 0-3.26c1.102.026 2.092-.502 2.477-1.431.385-.93.058-2.004-.74-2.763a9.942 9.942 0 0 1 2.306-2.307c.76.798 1.834 1.125 2.764.74.93-.385 1.457-1.376 1.43-2.477a9.942 9.942 0 0 1 3.262 0c-.027 1.102.501 2.092 1.43 2.477.93.385 2.004.058 2.763-.74a9.942 9.942 0 0 1 2.307 2.306c-.798.76-1.125 1.834-.74 2.764.385.93 1.376 1.457 2.477 1.43a9.942 9.942 0 0 1 0 3.262c-1.102-.027-2.092.501-2.477 1.43-.385.93-.058 2.004.74 2.763a9.942 9.942 0 0 1-2.306 2.307c-.76-.798-1.834-1.125-2.764-.74-.93.385-1.457 1.376-1.43 2.477a9.942 9.942 0 0 1-3.262 0c.027-1.102-.501-2.092-1.43-2.477-.93-.385-2.004-.058-2.763.74a9.942 9.942 0 0 1-2.307-2.306c.798-.76 1.125-1.834.74-2.764-.385-.93-1.376-1.457-2.477-1.43zM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/></svg>
);

const membersSvg=()=>(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 11a5 5 0 0 1 5 5v6H7v-6a5 5 0 0 1 5-5zm-6.712 3.006a6.983 6.983 0 0 0-.28 1.65L5 16v6H2v-4.5a3.5 3.5 0 0 1 3.119-3.48l.17-.014zm13.424 0A3.501 3.501 0 0 1 22 17.5V22h-3v-6c0-.693-.1-1.362-.288-1.994zM5.5 8a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zm13 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zM12 2a4 4 0 1 1 0 8 4 4 0 0 1 0-8z"/></svg>
);
const logoutSvg=()=>(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M5 2h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zm4 9V8l-5 4 5 4v-3h6v-2H9z"/></svg>
);

const PakMapIcon = (props:any) => <Icon component={pakMapSvg} {...props} />;
const StoriesIcon = (props:any) => <Icon component={storiesSvg} {...props} />;
const AlbumsIcon = (props:any) => <Icon component={albumSvg} {...props} />;
const AccountIcon = (props:any) => <Icon component={AccountSvg} {...props} />;
const SettingIcon = (props:any) => <Icon component={settingsSvg} {...props} />;
const MembersIcon = (props:any) => <Icon component={membersSvg} {...props} />;
const LogoutIcon = (props:any) => <Icon component={logoutSvg} {...props} />;

const cookies = new Cookies();


function NavBar(props: any) {
let playerId = JSON.parse(localStorage.getItem('playerId')!)
  let user = JSON.parse(localStorage.getItem('user')!)
  const { data, dispatch} = props
const location = useLocation();

  const [isLoggedin, setIsLoggedin] = useState(false)
  const [ispakMap, setIsPakMap] = useState(false)
  const [isStories, setIsStories] = useState(false)
  const [isMembers, setIsMembers] = useState(false)
  const [isAlbums, setIsAlbums] = useState(false)
  useEffect(() => {
    if(Notification.permission=="granted")
    {
      if(!playerId)
      {
      //  history.go(0);
      //  return
      }
      else
      {
        console.log(playerId)
      }

    }
    if (user) {
      if (user.loggedIn) {
        setIsLoggedin(true);
        dispatch(userActions.getCurrentUser(user.id));
        if(location.pathname.toString()==='/pak-map')
        {
         setIsPakMap(true);
        }
        if(location.pathname.toString()==='/travel-stories')
        {
         setIsStories(true);
        }
        if(location.pathname.toString()==='/travel-albums')
        {
         setIsAlbums(true);
        }
        if(location.pathname.toString()==='/members')
        {
         setIsMembers(true);
        }
      }
    }
  }, [])
  const responseGoogle = (response: any) => {
    let Data ={
      image:response.profileObj.imageUrl,
      firstName:response.profileObj.givenName,
      lastName:response.profileObj.familyName,
      email:response.profileObj.email,
      fbId:response.profileObj.googleId,
      isGoogle:true
    }
    dispatch(userActions.GoogleLogin(Data));
  }
  const onLoginClick = () => {
    history.push('/login')
  }
  const onSignUpClick = () => {
    history.push('/signup')
  }
  const responseFacebook = (response: any) => {
    if(response.status === 'unknown')
   {
    message.error('something went wrong can you please try again later')
   }
   else{
    let player:any="";
    if(playerId!==null)
    {
       player =playerId
    }
    let name=response.name.split(' ');
    let Data ={
      image:response.picture.data.url,
      firstName:name[0],
      lastName:name[1],
      email:response.email,
      fbId:response.id,
      isFacebook:true,
      playerId:player
    }
dispatch(userActions.SocialLogin(Data));
  }
  }
  const onPakMapEnter=()=>{
    let a:any = document.getElementById("story-btn");
  a.childNodes[0].style.fill = "#37B44E"
  a.childNodes[1].style.color = "#37B44E"
  }
 const onPakMapLeave=()=>{
  let a:any = document.getElementById("story-btn");
  a.childNodes[0].style.fill = "#000"
  a.childNodes[1].style.color = "#000"
 }
 const onAlbumEnter=()=>{
  let a:any = document.getElementById("album-btn");
a.childNodes[0].style.fill = "#37B44E"
a.childNodes[1].style.color = "#37B44E"
}
const onAlbumLeave=()=>{
let a:any = document.getElementById("album-btn");
a.childNodes[0].style.fill = "#000"
a.childNodes[1].style.color = "#000"
}
const onMembersEnter=()=>{
  let a:any = document.getElementById("member-btn");
a.childNodes[0].style.fill = "#37B44E"
a.childNodes[1].style.color = "#37B44E"
}
const onMembersLeave=()=>{
let a:any = document.getElementById("member-btn");
a.childNodes[0].style.fill = "#000"
a.childNodes[1].style.color = "#000"
}
const onPakMapEnter1=()=>{
  let a:any = document.getElementById("pak-map-btn");
a.childNodes[0].style.fill = "#37B44E"
a.childNodes[1].style.color = "#37B44E"
}
const onPakMapLeave1=()=>{
let a:any = document.getElementById("pak-map-btn");
a.childNodes[0].style.fill = "#000"
a.childNodes[1].style.color = "#000"
}

  if (!data) {
    return (
      <div className="nav-container">
        <div className="inner-container">
          <div className="half-container logo-container" >
            <Text className="logo" >
            <img src={webLogo} alt={webLogo} width="135px" onClick={()=>history.push('/')} />
         </Text>
          </div>
          <div className="half-container element-container">
            <div className="right-element-container">
              <div className="header-btns">
                <Button className="login-btn" onClick={onLoginClick}>Login</Button>
                <Button className="join-btn" onClick={onSignUpClick}>Join Now</Button>
                <span className="login-text">or Login with </span>
              </div>
              <div className="social-logins">
                <FacebookLogin
                  appId="204489870992125"
                  autoLoad={false}
                cssClass="custom-fb-class"
                  icon="fa fa-facebook"
                  fields="name,email,picture"
                  callback={responseFacebook}
                  textButton=""
                />
                </div>
                <div className="social-logins-google">
                <GoogleLogin
                  clientId="25711974386-a3gh85ab3p5ch8bg067bb51vnri2fnor.apps.googleusercontent.com"
                  buttonText=""
                  autoLoad={false}
                  className="google-social-btn-header"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                />
                </div>
            </div>
          </div>
          <div />
        </div>
      </div>
    )
  }

  cookies.set('userData', data, { path: '/' });
  const onLogout = () => {
    history.push('/login');
    localStorage.removeItem('user');
    localStorage.removeItem('signSussecc')
  }
  const username = data[0].firstName + " " + data[0].lastName
  const menu = (
    <Menu className="acc-acount-drp-dwn-menu">
       <Menu.Item>
      <Link to={{pathname:`/tourist/${data[0].userName}/about`}}>
          <span className="menu-icon"><AccountIcon/></span>
          <span> Account</span>
      </Link>
      </Menu.Item>
       <Menu.Item>
      <Link to="/user/my-stories">
      <span className="menu-icon"><StoriesIcon/></span>
       <span> My Stories</span>
      </Link>
      </Menu.Item>
      <Menu.Item>
      <Link to="/travel-albums/my-albums">
      <span className="menu-icon"><AlbumsIcon/></span>
      <span> My Albums</span>

      </Link>
      </Menu.Item>
      <Menu.Item>
      <Link to={{pathname:`/user/settings/${data[0]._id}`}}>
      <span className="menu-icon"><SettingIcon/></span>
      <span> Account Settings</span>
      </Link>
      </Menu.Item>
      <Menu.Item>
      <a   onClick={onLogout}>
      <span className="menu-icon"><LogoutIcon/></span>
      <span> Logout</span>
          </a>
      </Menu.Item>
    </Menu>
  );

     return (
    <div>
     {
     location.pathname=="/user/write-story"?
     <div className="nav-container">
     <div className="inner-container">
       <div className="half-container logo-container" >
         <Text className="logo" >
         <img src={webLogo} alt={webLogo} width="135px" onClick={()=>history.push('/')} />
           </Text>
       </div>
       <div className="half-container element-container">
         {
           isLoggedin ? <div className="right-element-container">
             <div className="social-logins">
               <div style={{ float: "left", display: "flow-root" }}>
                 {
                   data[0].image? <Avatar className="fb-logo" name={data[0].firstName +" "+data[0].lastName} src={data[0].image} />
                   :<Avatar className="fb-logo" name={data[0].firstName +" "+data[0].lastName} src="" />
                 }
               </div>
               <div className="dropdown-wrapper" style={{ float: "left", display: "flow-root" }}>
                 <p className="header-user-name">{username}</p>
                 <Dropdown className="user-acc-dropdown" overlay={menu} trigger={['click']}>
                   <p className="ant-dropdown-link" onClick={e => e.preventDefault()} >
                     @{data[0].userName}
                     <DownOutlined />
                   </p>
                 </Dropdown>
               </div>
             </div>
           </div> : <div className="right-element-container">
               <div className="header-btns">
                 <Button className="login-btn" size="large" onClick={onLoginClick}>Login</Button>
                 <Button className="join-btn" onClick={onSignUpClick}>Join Now</Button>
                 <span className="login-text">or Login with </span>
               </div>
               <div className="social-logins">
                 <Image className="fb-logo" src={String(fblogo)} alt='social fb btn' />
                 <Image className="google-logo" src={String(googlelogo)} alt='social fb btn' />
               </div>
             </div>
         }

       </div>
       <div />
     </div>
   </div>
     :
     <div className="nav-container">
     <div className="inner-container">
       <div className="half-container logo-container" >
         <Text className="logo" >
            <img src={webLogo} alt={webLogo} width="135px" onClick={()=>history.push('/')}/>
         </Text>
       </div>

       <div className="half-container element-container">
         {
           isLoggedin ? <div className="right-element-container">
             <div className="header-btns login-btn-header">
               {
                 ispakMap? <Button  className='header-navigate-btn-pak-map' style={{color:'#37b44e'}} type="default" size="large" icon={<PakMapIcon style={{fill:'#37b44e'}}/>} onClick={()=>history.push('/pak-map')}>Pak Map</Button>:
                  <Button id="pak-map-btn"  className='header-navigate-btn-pak-map' style={{color:'#000000'}}  type="default" size="large" icon={<PakMapIcon />} onMouseEnter={onPakMapEnter1} onMouseLeave={onPakMapLeave1} onClick={()=>history.push('/pak-map')}>Pak Map</Button>
               }
                {
                 isStories? <Button className='header-navigate-btn' style={{color:'#37b44e'}} type="default" size="large" icon={<StoriesIcon style={{fill:'#37b44e'}}/>}  onClick={()=>history.push('/travel-stories')}>Stories</Button>:
                  <Button id="story-btn" className='header-navigate-btn' style={{color:'#000000'}}  type="default" size="large" icon={<StoriesIcon  />} onMouseEnter={onPakMapEnter} onMouseLeave={onPakMapLeave} onClick={()=>history.push('/travel-stories')}>Stories</Button>
               }
                {
                 isAlbums? <Button className='header-navigate-btn' style={{color:'#37b44e'}} type="default" size="large" icon={<AlbumsIcon style={{fill:'#37b44e'}}/>}  onClick={()=>history.push('/travel-albums')}>Albums</Button>:
                  <Button id="album-btn" className='header-navigate-btn' style={{color:'#000000'}}  type="default" size="large" icon={<AlbumsIcon  />} onMouseEnter={onAlbumEnter} onMouseLeave={onAlbumLeave} onClick={()=>history.push('/travel-albums')}>Albums</Button>
               }
               {
                 isMembers? <Button className='header-navigate-btn' style={{color:'#37b44e'}} type="default" size="large" icon={<MembersIcon style={{fill:'#37b44e'}}/>}  onClick={()=>history.push('/tourists')}>Tourists</Button>:
                  <Button id="member-btn" className='header-navigate-btn' style={{color:'#000000'}}  type="default" size="large" icon={<MembersIcon  />} onMouseEnter={onMembersEnter} onMouseLeave={onMembersLeave} onClick={()=>history.push('/tourists')}>Tourists</Button>
               }

             </div>
             <Dropdown className="user-acc-dropdown" overlay={menu} trigger={['click']}>
             <div className="social-logins">
               <div style={{ float: "left", display: "flow-root" }}>
                 {
                   data[0].image? <Avatar className="fb-logo" name={data[0].firstName +" "+data[0].lastName} src={data[0].image} />
                   :<Avatar className="fb-logo" name={data[0].firstName +" "+data[0].lastName} src="" />
                 }
               </div>

               <div style={{ float: "left", display: "flow-root" }}>
                 <p className="header-user-name">{username}</p>

                   <p className="ant-dropdown-link" onClick={e => e.preventDefault()} >
                     @{data[0].userName}
                     <DownOutlined />
                   </p>
               </div>
             </div>
             </Dropdown>
           </div> : <div className="right-element-container">
               <div className="header-btns">
                 <Button className="login-btn" size="large" onClick={onLoginClick}>Login</Button>
                 <Button className="join-btn" size="large" onClick={onSignUpClick}>Join Now</Button>
                 <span className="login-text">or Login with </span>
               </div>
               <div className="social-logins">
               <div className="social-logins">
                <FacebookLogin
                  appId="204489870992125"
                  autoLoad={false}
                cssClass="custom-fb-class"
                  icon="fa fa-facebook"
                  fields="name,email,picture"
                  callback={responseFacebook}
                  textButton=""
                />
                </div>
                <div className="social-logins-google">
                <GoogleLogin
                  clientId="25711974386-a3gh85ab3p5ch8bg067bb51vnri2fnor.apps.googleusercontent.com"
                  buttonText=""
                  autoLoad={false}
                  className="google-social-btn-header"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                />
                </div>
               </div>
             </div>
         }

       </div>
       <div />
     </div>
   </div>
     }
    </div>
  )
}

function mapStateToProps(state: any) {
  const { loggingIn } = state.authentication;
  const { type, message } = state.alert;
  return {
    type,
    message,
    loggingIn,
    data: state.DataReducer.retrievedObject
  };
}

export const connectedNavBar = connect(mapStateToProps)(NavBar);
export default connectedNavBar;


