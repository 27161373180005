export const categoryConstants = {
  GET_ALL_REQUEST: 'GET_ALL_REQUEST',
  GET_ALL_SUCCESS: ' GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'GET_ALL_FAILURE',

  ADD_CATEGORY_REQUEST: ' ADD_CATEGORY_REQUEST',
  ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',
  ADD_CATEGORY_FAILURE: 'ADD_CATEGORY_FAILURE',


  EDIT_CATEGORY_REQUEST: ' EDIT_CATEGORY_REQUEST',
  EDIT_CATEGORY_SUCCESS: 'EDIT_CATEGORY_SUCCESS',
  EDIT_CATEGORY_FAILURE: 'EDIT_CATEGORY_FAILURE',

  UPDATE_CATEGORY_REQUEST: ' UPDATE_CATEGORY_REQUEST',
  UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
  UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

  DELETE_CATEGORY_REQUEST: ' DELETE_CATEGORY_REQUEST',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_FAILURE: 'DELETE_CATEGORY_FAILURE',

  GET_ALL_PLACE_CATEGORIES: 'GET_ALL_PLACE_CATEGORIES',
  GET_ALL_PLACE_CATEGORIES_SUCCESS: ' GET_ALL_PLACE_CATEGORIES_SUCCESS',
  GET_ALL_PLACE_CATEGORIES_FAILURE: 'GET_ALL_PLACE_CATEGORIES_FAILURE',

  ADD_PLACE_CATEGORY_REQUEST: ' ADD_PLACE_CATEGORY_REQUEST',
  ADD_PLACE_CATEGORY_SUCCESS: 'ADD_PLACE_CATEGORY_SUCCESS',
  ADD_PLACE_CATEGORY_FAILURE: 'ADD_PLACE_CATEGORY_FAILURE',

  EDIT_PLACE_CATEGORY_REQUEST: ' EDIT_PLACE_CATEGORY_REQUEST',
  EDIT_PLACE_CATEGORY_SUCCESS: 'EDIT_PLACE_CATEGORY_SUCCESS',
  EDIT_PLACE_CATEGORY_FAILURE: 'EDIT_PLACE_CATEGORY_FAILURE',

  UPDATE_PLACE_CATEGORY_REQUEST: ' UPDATE_PLACE_CATEGORY_REQUEST',
  UPDATE_PLACE_CATEGORY_SUCCESS: 'UPDATE_PLACE_CATEGORY_SUCCESS',
  UPDATE_PLACE_CATEGORY_FAILURE: 'UPDATE_PLACE_CATEGORY_FAILURE',

  DELETE_PLACE_REQUEST: ' DELETE_PLACE_REQUEST',
  DELETE_PLACE_SUCCESS: 'DELETE_PLACE_SUCCESS',
  DELETE_PLACE_FAILURE: 'DELETE_PLACE_FAILURE',

  GET_ALL_PAK_MAP_CAT: 'GET_ALL_PAK_MAP_CAT',
  GET_ALL_PAK_MAP_CAT_SUCCESS: ' GET_ALL_PAK_MAP_CAT_SUCCESS',
  GET_ALL_PAK_MAP_CAT_FAILURE: 'GET_ALL_PAK_MAP_CAT_FAILURE',

  GET_WEATHER_REQUEST: 'GET_WEATHER_REQUEST',
  GET_WEATHER_REQUEST_SUCCESS: ' GET_WEATHER_REQUEST_SUCCESS',
  GET_WEATHER_REQUEST_FAILURE: 'GET_WEATHER_REQUEST_FAILURE',

  GET_CURRENT_WEATHER_REQUEST: 'GET_CURRENT_WEATHER_REQUEST',
  GET_CURRENT_WEATHER_REQUEST_SUCCESS: ' GET_CURRENT_WEATHER_REQUEST_SUCCESS',
  GET_CURRENT_WEATHER_REQUEST_FAILURE: 'GET_CURRENT_WEATHER_REQUEST_FAILURE',

  ADD_EXPERIENCE_REQUEST: " ADD_EXPERIENCE_REQUEST",
  ADD_EXPERIENCE_REQUEST_SUCCESS: "ADD_EXPERIENCE_REQUEST_SUCCESS",
  ADD_EXPERIENCE_REQUEST_FAILURE: "ADD_EXPERIENCE_REQUEST_FAILURE",


  FEATURED_PLACE_REQUEST: 'FEATURED_PLACE_REQUEST',
  FEATURED_PLACE_SUCCESS: 'FEATURED_PLACE_SUCCESS',
  FEATURED_PLACE_FAILURE: 'FEATURED_PLACE_FAILURE',



};
