import React,{useEffect} from 'react';
import { Map } from '../Map';
import ReactGA from 'react-ga';
import NavBar from '../Navbar/Navbar';
import { UserCard, VerificationCard, Tab } from '../Cards';
import { userActions } from '../../actions';
import {UpperFooterSection,Footer} from '../FooterSection'
import { Route, Switch } from 'react-router-dom';

import {connectedAbout,connectedUserFriends,connecteduserPlaces,connectedStories,connectedUserAlbums,connectedQuestions,connectedAnswers,connectedPhotos} from '../Users'
import {config} from '../../config'
import { connect } from 'react-redux';
import './user.css';
import { store} from '../../Helpers';

type UserDashBoard = {
  dispatch: any;
  data: any;
  match:any;
  location:any,
  vistedPlaces:any;
  favoritePlace:any;
  checkedUser:any;
}

 function UserDashBoard(props:UserDashBoard) {
  const { data, dispatch,match ,location,vistedPlaces,favoritePlace,checkedUser} = props
  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    store.getState().FetchUserProfile.UserProfile="";
    let user = JSON.parse(localStorage.getItem('user')!)
    if (user) {
      if (user.loggedIn) {
        dispatch(userActions.getUserProfile(match.params.userName));
        dispatch(userActions.getFavPlaces(match.params.userName));
        dispatch(userActions.getVistedPlaces(match.params.userName));
        dispatch(userActions.getCheckedUser(user.user.userName,match.params.userName));
      }
    }

  },[dispatch])
  if(!data) {
    return (<div></div>)
  }
  if(!vistedPlaces)
  {
    return (<div></div>)
  }
  if(!vistedPlaces)
  {
    return (<div></div>)
  }
  let language:any="";

    if(data[0].lang[0])
    {
      language=data[0].lang[0].split(",")
    }

  let profile={
    id:data[0]._id,
    firstName:data[0].firstName,
    lastName:data[0].lastName,
    role:data[0].roles,
    intro:data[0].intro,
    address:data[0].address,
    image:data[0].image,
    joinDate:data[0].createdDate,
    gender:data[0].gender,
    languages:language,
    DOB:data[0].DOB,
    playerId:data[0].playerId
  }
  let places:any=[];
  if(favoritePlace.length || vistedPlaces.length)
  {
     places=[...vistedPlaces,...favoritePlace]
  }
  let markerData:any=[];
  if(places.length>0)
  {
    places.forEach((element:any) => {
      markerData.push({
        placeId:element._id,
        lat:element.latitude,
        lng:element.longititude,
        placeName:element.placeName,
        pin:config.s3BucketUrl+element.categories.pin
    })
    });

  }
  return (
    <Switch>
      <React.Fragment>
    <div className="user-dashboard-container">
      <NavBar />
      <div className="user-dashboard-inner-container">
        <div className="avatar-container-dashboard">
          <div className="">
          <UserCard profile={profile} key={profile.image} checkedUser={checkedUser} dispatch={dispatch}/>
          </div>
          <div className="">
          <VerificationCard />
          </div>
        </div>
        <div className="map-container-dashboard">
          <Map data={markerData} />
          <div className="tab-container-main">
            <Tab match={match} location={location} />
            <div className="news-feed-container">
            <Switch>
                   <Route path ="/tourist/:userName/photos" exact component={connectedPhotos} />
                   <Route path ="/tourist/:userName/answers" exact component={connectedAnswers} />
                   <Route path ="/tourist/:userName/questions" exact component={connectedQuestions} />
                   <Route path ="/tourist/:userName/albums" exact component={connectedUserAlbums} />
                   <Route path ="/tourist/:userName/stories" exact component={connectedStories} />
                   <Route path ="/tourist/:userName/places" exact component={connecteduserPlaces} />
                   <Route path ="/tourist/:userName/friends" exact component={connectedUserFriends} />
                   <Route path ="/tourist/:userName/about" exact component={connectedAbout} />
                  {/* <Route path="/:userName/news-feed" component={NewsFeed} /> */}
             </Switch>
            </div>
          </div>
        </div>
      </div>
      <div className="user-dashboard-footer">
        <UpperFooterSection/>
      <Footer/>
        </div>
    </div>
    </React.Fragment>
    </Switch>
  )
}

function mapStateToProps(state: any) {
  const { loggingIn } = state.authentication;
  const { type, message } = state.alert;
  return {
    type,
    message,
    loggingIn,
    data: state.FetchUserProfile.UserProfile,
    vistedPlaces:state.FetchVistedPlaces.fetchVistedPlacesObject,
    favoritePlace:state.FetchFavPlaces.fetchFavPlacesObject,
    checkedUser:state.FetchCheckedUser.fetchCheckedUsersObject
  };
}

export const connectedUserDashBoard = connect(mapStateToProps)(UserDashBoard);
