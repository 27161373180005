import React, { useEffect } from 'react';
import { Button, Form, Input, message, } from 'antd';
import FacebookLogin from 'react-facebook-login';
import { history } from '../../Helpers';
import { connect } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import NavBar from '../Navbar/Navbar';
import './login.css'
import { userActions } from '../../actions';


const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};
const tailLayout = {
  wrapperCol: { span: 16 },
};

type Login = {
  dispatch: any;
}

function Login(props: Login) {

  let playerId = JSON.parse(localStorage.getItem('playerId')!)
  const { dispatch } = props
useEffect(()=>{
  ReactGA.initialize("UA-168266330-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
},[])
  const onFinish = (values: any) => {
    let socialClick = false;
    dispatch(userActions.login(values.email, values.password, socialClick,playerId));
    const [form] = Form.useForm();
    form.resetFields();

  };

  const onFinishFailed = (errorInfo: any) => {
  };

  const responseFacebook = (response: any) => {
   if(response.status === 'unknown')
   {
    message.error('something went wrong can you please try again later')
   }
   else{
    let player:any="";
    if(playerId!==null)
    {
       player =playerId
    }
    let name=response.name.split(' ');
    let Data ={
      image:response.picture.data.url,
      firstName:name[0],
      lastName:name[1],
      email:response.email,
      fbId:response.id,
      isFacebook:true,
      playerId:player
    }
dispatch(userActions.SocialLogin(Data));
  }
  }
  const responseGoogle = (response: any) => {
    let Data ={
      image:response.profileObj.imageUrl,
      firstName:response.profileObj.givenName,
      lastName:response.profileObj.familyName,
      email:response.profileObj.email,
      fbId:response.profileObj.googleId,
      playerId:playerId,
      isGoogle:true
    }
    dispatch(userActions.GoogleLogin(Data));
  }
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('user')!)
    if (user) {
      if (user.loggedIn) {
        history.push('/')
      }

    }

  })
  const onRegisterClick =() =>{
    history.push('/signup')
  }

  return (
    <div>
      <div className="top-container-signup">
        <NavBar />
      </div>
      <div className="main-container">
        <div className="signup-container">
          <div className="signup-header-container">
            <p className="login-txt">
              LOGIN
                        </p>
          </div>
          <br></br>
          <div className='form-container-sign'>
            <div className="register-form">
              <div>
                <p className="signup-crte-acc-txt">
                  Login to an existing account
                        </p>
                <p className="signup-crte-acc-desc">
                  Use the email you entered while registring
                        </p>
              </div>
              <div className="firstStep steps-content">
                <Form
                  {...layout}

                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item className="lbl-name" label="Email address"></Form.Item>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: "Please enter a valid email address.",
                      },
                      { required: true, message: 'Please enter your email.' }]}
                  >
                    <Input placeholder="Email address" size="large" />
                  </Form.Item>
                  <Form.Item className="lbl-name" label="Password"></Form.Item>
                  <Form.Item

                    name="password"
                    rules={[{ required: true, message: 'Please enter your password.' }, {
                      min: 8,
                      message: 'password must be minimum 8 characters.'
                    }
                    ]}
                  >
                    <Input.Password placeholder="Password" size="large" />
                  </Form.Item>
                  <p className="forget-txt">
                    <Link className="privacy-link" to="/forget-password">Forgot your password?</Link>
                  </p>
                  <Form.Item {...tailLayout}>
                    <Button className="btn-nxt" size="large" type="primary" htmlType="submit">
                      Log In
                    </Button>

                  </Form.Item>
                </Form>

              </div>
            </div>
            <div className="social-container-lgin">
              <p className="conect-social-txt">
                Fast login with social account
                                </p>
                <FacebookLogin
                  appId="204489870992125"
                  autoLoad={false}
                  icon="fa fa-facebook"
                  fields="name,email,picture"
                  callback={responseFacebook}
                  textButton="Continue with facebook"
                />
              <GoogleLogin
                clientId="AIzaSyCD3_32W8jvGAUj2mvcPKvbfc5Yt7zCVq0"
                buttonText="Continue with google"
                className="google-social-btn"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
              />
            </div>
          </div>
          <div className="trm-txt-sign">
            <p className="txt-trm">
            Note: By registering, you are agree to our <a href="https://pakrism.pk/privacy-policy/" className="privacy-link">Privacy Policy</a> and <a href="https://pakrism.pk/terms-of-service/" className="privacy-link">Terms of use.</a>
            </p>
          </div>
        </div>
        <div className="sign-footer">
          <p className="acc-txt">
            Don’t have an account?
                </p>
          <p className="lgin-btn-container">
            <Button type="default" className="btn-lgin" onClick={onRegisterClick} size="large">
             Register
            </Button>
          </p>
        </div>
      </div>
    </div>

  )
}
function mapStateToProps(state: any) {
  const { loggingIn } = state.authentication;
  const { type, message } = state.alert;
  return {
    type,
    message,
    loggingIn
  };
}
const connectedLoginPage = connect(mapStateToProps)(Login);

export default connectedLoginPage;
