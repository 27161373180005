import React from "react";
import PropTypes from "prop-types";
import {config} from '../config'
import { Helmet } from "react-helmet";

type MetaDecorator={
  title:any;
  description:any;
  imageUrl:any;
  imageAlt:any;
  match:any
}

export const MetaDecorator = (props:MetaDecorator) => {
  const {title,description,imageAlt,imageUrl,match} = props;
   return (
    <Helmet>
       <meta charSet="UTF-8"/>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="title" content="Pakrism"></meta>

       {/* Open Graph / Facebook */}
    <meta property="og:type" content="website"/>
    <meta property="og:url" content={config.webUrl+match.url}/>
   <meta property="og:title" content={title}/>
   <meta property="og:description" content={description}/>
   <meta property="og:image" content={config.s3BucketUrl + imageUrl}/>
   <meta name="viewport" content="width=device-width, initial-scale=1"/>

   {/* Twitter  */}
<meta property="twitter:card" content={config.s3BucketUrl +imageUrl}/>
<meta property="twitter:url" content={config.webUrl+match.url}/>
<meta property="twitter:title" content={title}/>
<meta property="twitter:description" content={description}/>
<meta property="twitter:image" content={config.s3BucketUrl +imageUrl}/>
    </Helmet>
  );
};

MetaDecorator.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
};

export default MetaDecorator;
