
import React, { useEffect } from 'react';
import {connect} from 'react-redux'
import {answersActions} from '../../actions';
import {NoData} from '../Places';
import ReactGA from 'react-ga';
import {PlaceAnswers} from '../Places'

type Answers = {
  dispatch: any;
  data: any;
  match:any
}
function Answers(props:Answers) {
  const { data, dispatch, match} = props;
  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    let user = JSON.parse(localStorage.getItem('user')!)
    if (user) {
      if (user.loggedIn) {
        dispatch(answersActions.FetchUserAnswers(match.params.userName));
      }
    }

  },[dispatch])
  if(!data) {
    return (<div></div>)
  }
  return (
    <div>
      {
        data.length>0?
        data.map((item:any,i:any)=>
        <PlaceAnswers data={item} key={i}/>
        ):
        <div>
        <NoData isFrom="ans"
       noData="No answer found."
       noDataMessage="Answer a question on a place."
    />
     </div>
      }
    </div>
  )
}

function mapStateToProps(state: any) {
  return {
    data: state.FetchUserAnswers.getUserAnswersObject
  };
}

export const connectedAnswers = connect(mapStateToProps)(Answers);
