import React, { useState } from 'react';
import {config} from '../../config'
import  Icon  from '@ant-design/icons';
import {Modal,Button} from 'antd'


const storiesSvg=()=>(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11v6h2v-6h-2zm0-4v2h2V7h-2z" fill="rgba(55,180,78,1)"/></svg>
);

const StoriesIcon = (props:any) => <Icon component={storiesSvg} {...props} />;
export const Activities = (props: any) => {
  const {activities} = props;
  const [visible,setVisible]=useState(false);
    const showModal = () => {
      setVisible(true);

  };

  const handleCancel = (e:any) => {
    setVisible(false)
  };

  return (
    <div className="activities-container" >
        <div className="activity-inner-container" onClick={()=>showModal()}>
        <img className="activities-activity-img" src={config.s3BucketUrl+activities.image} alt={activities.title}/>
        <p className="activities-title">{activities.title} </p>
         <div className="activity-learn-more">
         <Button  className='header-navigate-btn activities-learn-more-btn'  type="default" size="large" >
           <span>Learn more</span>
           <StoriesIcon />
           </Button>
           </div>
      </div>
      <Modal
         width="50%"
          title={"Learn more about "+activities.title}
          className="activity-modal"
          closable={true}
        visible={visible}
        onCancel={handleCancel}
        footer={false}
        >
         {
           activities.quilValue?
           <p className="activity-modal-txt"  dangerouslySetInnerHTML= {{__html:activities.quilValue}}>

           </p>:
           <p className="activity-modal-txt no-activity-txt">no Data</p>
         }
        </Modal>
          </div>


  );
};

