
import React, { useEffect} from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga';
import {friendsActions} from '../../actions';
import {NoData} from '../Places'
import {FriendCard} from '.'
export function UserFriends(props:any) {
  const {dispatch,data,match}=props;
  useEffect(()=>{
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    dispatch(friendsActions.GetFriends(match.params.userName))

},[dispatch,match])

if(!data)
{
  return(<div></div>)
}
    return (
    <div>
      {
       data.length>0?
        data.map((item:any)=>
        <div className="friend-card-main-container">
            <FriendCard data={item} key={item._id}/>
          </div>
          )
          :
          <NoData isFrom="friends"
          noData="No tripmate found."
          noDataMessage="Need to make new tripmates."
       />
      }
    </div>
  )
}
function mapStateToProps(state: any) {
  return {
    data:state.FetchFriends.getFriendsObject,
  };
}
export const connectedUserFriends = connect(mapStateToProps)(UserFriends);
