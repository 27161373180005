import {blogConstants} from '../constants'
const initialState ={
  getAllBlogObject:"",
}
export function FetchBlog(state=initialState,action:any)
{

    switch (action.type) {
        case blogConstants.GET_ALL_BLOG_REQUEST:
          return state;
          case blogConstants.GET_ALL_BLOG_SUCCESS:
          return{
            ...state,
            getAllBlogObject:action.user
          };
      default:
        return state
    }
}
