import { alertActions } from "../actions";
import { config } from "../config";
import axios from "axios";

export const tripService = {
  FetchAllAccessoriesCategories,
  AddAccessoriesCategories,
  FetchEditAccessoriesCategories,
  DeleteAccessoriesCategories,
  UpdateAccessoriesCategories,
};
async function FetchAllAccessoriesCategories() {
  return await fetch(`${config.apiurl}/user/get-all-accessories-categories-admin`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

async function AddAccessoriesCategories(values: any) {
  return await axios
    .post(`${config.apiurl}/user/add-accessories-categories`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

async function FetchEditAccessoriesCategories(id: any) {
  return await fetch(`${config.apiurl}/user/get-single-accessories-categories/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

async function DeleteAccessoriesCategories(id: any) {
  return await fetch(`${config.apiurl}/user/delete-accessories-categories-admin/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

async function UpdateAccessoriesCategories(values: any, id: any) {
  return await axios
    .patch(`${config.apiurl}/user/update-accessories-categories/${id}`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });

}
