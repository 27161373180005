
import React,{useEffect} from 'react';
import { connect } from 'react-redux';
import ContentLoader from 'react-content-loader'
import {NoData} from '../Places'
import { history } from '../../Helpers';
import { storyActions } from '../../actions';
import {PublishedStoryCards} from '../Cards'
import ReactGA from 'react-ga';
type Stories = {
  dispatch: any;
  data: any;
  match:any;
}
const MyLoader = () => (
	<ContentLoader viewBox="0 0 380 100">
		{/* Only SVG shapes */}
  	<rect x="80" y="17" rx="4" ry="4" width="250" height="13" />
		<rect x="0" y="0" rx="100" ry="100" width="50" height="50" />
		<rect x="80" y="17" rx="4" ry="4" width="150" height="10" />
		<rect x="80" y="40" rx="3" ry="3" width="150" height="10" />
    <rect x="80" y="40" rx="4" ry="4" width="150" height="10" />
	</ContentLoader>
);
const StoryLoader = () => (
	<ContentLoader viewBox="0 0 380 100">
		{/* Only SVG shapes */}
  	<rect x="80" y="17" rx="4" ry="4" width="250" height="13" />
		<rect x="0" y="0" rx="100" ry="100" width="50" height="50" />
		<rect x="80" y="17" rx="4" ry="4" width="150" height="10" />
		<rect x="80" y="40" rx="3" ry="3" width="150" height="10" />
    <rect x="80" y="40" rx="4" ry="4" width="150" height="10" />
	</ContentLoader>
);

 function Stories(props:Stories) {
  let user = JSON.parse(localStorage.getItem('user')!)
  const { dispatch, data,match} = props;
  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (!user) {
      history.push('/login')
    }
    else
    {
      dispatch(storyActions.GetMyStory(match.params.userName));
    }

  }, [dispatch]);
  if(!data)
  {
    return(<div>
        <MyLoader/>
        <StoryLoader/>
    </div>);
  }
  return (
    <div>
      {
        data.length>0?
        data.map((item:any,i:any)=>
        <PublishedStoryCards data={item} key={i} dispatch={dispatch}/>
        ):
        <NoData isFrom="stories"
        noData="No story found."
        noDataMessage="Need to write a travel story."
     />
      }
    </div>
  )
}

function mapStateToProps(state: any) {
  return {
    data: state.GetMyStory.getMyStoryStoryObject,
  };
}

export const connectedStories = connect(mapStateToProps)(Stories);
