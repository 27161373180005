import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Upload, message, Select, Tag, Modal } from 'antd';
import { CloudUploadOutlined } from "@ant-design/icons";
import { history } from '../../../Helpers';
import { connect } from 'react-redux';
import { storyActions, categoryActions } from '../../../actions';
import { config } from "../../../config";
import ReactQuill from 'react-quill';
import './stories.css';
var Editor = require('react-medium-editor').default;
require('medium-editor/dist/css/themes/tim.css');
require('medium-editor/dist/css/medium-editor.css');
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};

const { Option } = Select;

type EditStories = {
  dispatch: any;
  match: any;
  stories: any;
  places: any
}

const { Dragger } = Upload;

const prop: any = {
  name: 'userImage',
  action: '',
  headers: {
    authorization: 'authorization-text',
  },
};
let image: any = '';

function beforeUpload(file: any) {

  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}


function EditStories(props: EditStories) {
  const { dispatch, match, stories, places } = props;
  const [quilValue, setquilValue] = useState('');
  let reason: any = '';
  const onFinish = (values: any) => {

    const data = new FormData()
    data.append("categoryName", values.categoryName)
    data.append("description", values.description)
    if (values.userImage) {
      data.append("categoryImage", values.userImage.file.originFileObj)
    }
    // dispatch(albumCategoryActions.UpdateAlbumCategory(data, match.params.id));

  };
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {
      dispatch(storyActions.FetchEditStories(match.params.id));
      dispatch(categoryActions.FetchAllPlaceCategories());
    }

  }, [dispatch,match]);
  const onQuilValueChange = (value: any) => {
    setquilValue(value);

  }

  const onFinishFailed = (errorInfo: any) => {
  };
  const onUnpublish = () => {
    Modal.warning({
      title: 'UnPublish',
      content: (
        <div className="warning-modal">
          <Form.Item className="lbl-name" label="Reason"></Form.Item>
          <ReactQuill style={{ height: '200px', marginBottom: '20px' }} theme="snow" onChange={onUnPublishChange} />
        </div>
      ),
      className: "cancel-modal",
      width: 560,
      okType: 'danger',
      okText: 'UnPublish',
      okCancel: true,
      centered: true,
      cancelText: 'cancel',
      onOk: () => {
        let isUnpulishFlag = true;
        dispatch(storyActions.UpdateStory(reason, match.params.id, isUnpulishFlag));

      },
      onCancel() {
      },
    });

  }
  const onUnPublishChange = (value: any) => {
    reason = {
      value: value
    };
  }

  const onDelete = () => {
    Modal.error({
      title: 'Deltete',
      content: (
        <div className="warning-modal">
          <Form.Item className="lbl-name" label="Reason"></Form.Item>
          <ReactQuill style={{ height: '200px', marginBottom: '20px' }} theme="snow" onChange={onUnPublishChange} />
        </div>
      ),
      className: "cancel-modal",
      width: 560,
      okType: 'danger',
      okText: 'Delete',
      okCancel: true,
      centered: true,
      cancelText: 'cancel',
      onOk: () => {
        dispatch(storyActions.DeleteStory(reason.value,match.params.id));


      },
      onCancel() {
      },
    });
  }

  if (!stories) {
    return (<div></div>)
  }

  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
  };

  image = config.apiurl + "/" + stories[0].image
  return (
    <div>
      <Form
        {...layout}
        className="admin-login-form"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item className="lbl-name" label="Title"></Form.Item>
        <Form.Item
          initialValue={stories[0].title}
          name="title"
          rules={[{ required: true, message: 'Please enter a title.' },]}
        >
          <Input name="title" size="large" placeholder="title" />
        </Form.Item>
        <Form.Item className="lbl-name" label="Place Name"></Form.Item>
        <Form.Item
          hasFeedback
          name="places"
          initialValue={stories[0].place._id}
          rules={[{ required: true, message: 'Please enter a place.' },]}
        >
          <Select defaultValue={stories[0].place._id} placeholder="select a place" size="large" >

            {
              places.length > 0 ?
                places.map((item: any) => <Option selected="selected" value={item._id} >
                  {item.placeName}
                </Option >)
                :
                <Option value="1">data</Option>
            }
          </Select>
        </Form.Item>
        {/* <Form.Item className="lbl-name" label="Tags"></Form.Item>
        <Form.Item >
          {

            data.map((item: any) => <Tag color="success">{item}</Tag>)
          }
        </Form.Item> */}
        <Form.Item className="lbl-name" label="Photo"></Form.Item>
        <Form.Item
          name="userImage"
          className="file-upload"
        >
          <Dragger
            beforeUpload={beforeUpload}
            onPreview={onPreview}
            multiple={false}
            {...prop}
            listType="picture-card">

            <p className="ant-upload-drag-icon">
              <CloudUploadOutlined style={{ color: "#000000" }} />
              <span className="upld-desc" > Upload or drop photo here</span>
            </p>
          </Dragger>
        </Form.Item>
        <Form.Item style={{ width: "50%" }}>
          <img src={image} width="30%" alt={image} style={{ textAlign: 'center' }} />
        </Form.Item>
        <Form.Item
          initialValue={stories[0].story}
          name="quil"
        >
          <ReactQuill style={{ height: '200px', marginBottom: '20px' }} theme="snow" value={stories[0].quilValue} onChange={onQuilValueChange} />
        </Form.Item>
        <Form.Item >
          <Button style={{ marginTop: '50px', width: '30%', marginRight: '20px' }} className="btn-admin-login" size="large" type="primary" htmlType="submit">
            Update Story
          </Button>
          <Button className="warning-btn" size="large" onClick={onUnpublish}>
            Unpublish Story
          </Button>
          <Button className="danger-btn" size="large" onClick={onDelete} >
            Delete Story
          </Button>
        </Form.Item>

      </Form>
    </div>
  )
}
function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    stories: state.FetchEditStories.fetchEditStoriesObject,
    places: state.FetchPlaceCategories.getAllPlaceCategoriesObject,
  };
}

export const connectedEditStories = connect(mapStateToProps)(EditStories);
