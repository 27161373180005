import { alertActions } from "../actions";
import { config } from "../config";
import axios from "axios";

export const announcementService = {
  FetchAllAnnouncement,
  AddAnnouncement,
  FetchEditAnnouncement,
  DeleteAnnouncement,
  UpdateAnnouncement,
  ActivateAnnouncement
};
function FetchAllAnnouncement() {
  return fetch(`${config.apiurl}/user/get-all-announcements-admin/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

function AddAnnouncement(values: any) {
  return axios
    .post(`${config.apiurl}/user/add-announcements`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function FetchEditAnnouncement(id: any) {
  return fetch(`${config.apiurl}/user/get-single-announcements/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function DeleteAnnouncement(id: any) {
  return fetch(`${config.apiurl}/user/delete-announcements/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function UpdateAnnouncement(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/user/update-announcements/${id}`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });

}

function ActivateAnnouncement(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/user/activate-announcement/${id}`, values, {
      validateStatus: () => true,
      // receive two parameter endpoint url ,form data
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err.toString());
      }
    });
}

