export const placesConstants ={
  GET_RELATED_PLACES_REQUEST: 'GET_RELATED_PLACES_REQUEST',
  GET_RELATED_PLACES_SUCCESS: ' GET_RELATED_PLACES_SUCCESS',
  GET_RELATED_PLACES_FAILURE: 'GET_RELATED_PLACES_FAILURE',


  GET_USER_IMAGES_ADMIN_REQUEST: 'GET_USER_IMAGES_ADMIN_REQUEST',
  GET_USER_IMAGES_ADMIN_SUCCESS: 'GET_USER_IMAGES_ADMIN_SUCCESS',
  GET_USER_IMAGES_ADMIN_FAILURE: 'GET_USER_IMAGES_ADMIN_FAILURE',

  ADD_USER_PLACE_IMAGES_REQUEST: 'ADD_USER_PLACE_IMAGES_REQUEST',
  ADD_USER_PLACE_IMAGES_SUCCESS: ' ADD_USER_PLACE_IMAGES_SUCCESS',
  ADD_USER_PLACE_IMAGES_FAILURE: 'ADD_USER_PLACE_IMAGES_FAILURE',

  GET_USER_PLACE_IMAGES_LIMIT_REQUEST: 'GET_USER_PLACE_IMAGES_LIMIT_REQUEST',
  GET_USER_PLACE_IMAGES_LIMIT_SUCCESS: ' GET_USER_PLACE_IMAGES_LIMIT_SUCCESS',
  GET_USER_PLACE_IMAGES_LIMIT_FAILURE: 'GET_USER_PLACE_IMAGES_LIMIT_FAILURE',

  GET_USER_PLACE_IMAGES_REQUEST: 'GET_USER_PLACE_IMAGES_REQUEST',
  GET_USER_PLACE_IMAGES_SUCCESS: ' GET_USER_PLACE_IMAGES_SUCCESS',
  GET_USER_PLACE_IMAGES_FAILURE: 'GET_USER_PLACE_IMAGES_FAILURE',

  GET_USER_PLACE_IMAGES_PROFILE_REQUEST: 'GET_USER_PLACE_IMAGES_PROFILE_REQUEST',
  GET_USER_PLACE_IMAGES_PROFILE_SUCCESS: ' GET_USER_PLACE_IMAGES_PROFILE_SUCCESS',
  GET_USER_PLACE_IMAGES_PROFILE_FAILURE: 'GET_USER_PLACE_IMAGES_PROFILE_FAILURE',

  DELETE_USER_IMAGES_ADMIN_REQUEST: 'DELETE_USER_IMAGES_ADMIN_REQUEST',
  DELETE_USER_IMAGES_ADMIN_SUCCESS: 'DELETE_USER_IMAGES_ADMIN_SUCCESS',
  DELETE_USER_IMAGES_ADMIN_FAILURE: 'DELETE_USER_IMAGES_ADMIN_FAILURE',


  GET_USER_PLACE_COUNT_ADMIN_REQUEST: 'GET_USER_PLACE_COUNT_ADMIN_REQUEST',
  GET_USER_PLACE_COUNT_ADMIN_SUCCESS: 'GET_USER_PLACE_COUNT_ADMIN_SUCCESS',
  GET_USER_PLACE_COUNT_ADMIN_FAILURE: 'GET_USER_PLACE_COUNT_ADMIN_FAILURE',

  GET_ALL_PLACES_ADMIN_REQUEST: 'GET_ALL_PLACES_ADMIN_REQUEST',
  GET_ALL_PLACES_ADMIN_SUCCESS: 'GET_ALL_PLACES_ADMIN_SUCCESS',
  GET_ALL_PLACES_ADMIN_FAILURE: 'GET_ALL_PLACES_ADMIN_FAILURE',

  SEND_PLACE_NOTIFICATIONS_ADMIN_REQUEST: 'SEND_PLACE_NOTIFICATIONS_ADMIN_REQUEST',
  SEND_PLACE_NOTIFICATIONS_ADMIN_SUCCESS: 'SEND_PLACE_NOTIFICATIONS_ADMIN_SUCCESS',
  SEND_PLACE_NOTIFICATIONS_ADMIN_FAILURE: 'SEND_PLACE_NOTIFICATIONS_ADMIN_FAILURE',



  GET_USER_ALL_PLACES_ADMIN_REQUEST: 'GET_USER_ALL_PLACES_ADMIN_REQUEST',
  GET_USER_ALL_PLACES_ADMIN_SUCCESS: 'GET_USER_ALL_PLACES_ADMIN_SUCCESS',
  GET_USER_ALL_PLACES_ADMIN_FAILURE: 'GET_USER_ALL_PLACES_ADMIN_FAILURE',

  GET_USER_SINGLE_PLACE_ADMIN_REQUEST: 'GET_USER_SINGLE_PLACE_ADMIN_REQUEST',
  GET_USER_SINGLE_PLACE_ADMIN_SUCCESS: 'GET_USER_SINGLE_PLACE_ADMIN_SUCCESS',
  GET_USER_SINGLE_PLACE_ADMIN_FAILURE: 'GET_USER_SINGLE_PLACE_ADMIN_FAILURE',


}
