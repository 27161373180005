import { storiesConstants } from "../constants";
import { storyService } from "../services";
import { alertActions } from ".";
import {history} from '../Helpers'

export const storyActions = {
  FetchAllStories,
  AddStories,
  FetchEditStories,
  UpdateStory,
  DeleteStory,
  FethStoryUser,
  FethTopSotryTeller,
  GetStory,
  GetRelatedStories,
  GetCategoryStory,
  GetMyStory,
  GetDraftStory,
  GetPlaceStories,
  AddStoryComment,
  AddStoryLike,
  RemoveStoryLike,
  FeaturedStory,
  FetchStoriesCount

};

function FetchAllStories() {
  return (dispatch: any) => {
    dispatch(request());
    storyService.FetchAllStories().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: storiesConstants.GET_ALL_STORIES_REQUEST };
  }
  function success(user: any) {
    return { type: storiesConstants.GET_ALL_STORIES_REQUEST_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: storiesConstants.GET_ALL_STORIES_REQUEST_FAILURE, error };
  }
}


function FethStoryUser() {
  return (dispatch: any) => {
    dispatch(request());
    storyService.FetchStoryUser().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: storiesConstants.GET_ALL_STORY_USER_REQUEST };
  }
  function success(user: any) {
    return { type: storiesConstants.GET_ALL_STORY_USER_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: storiesConstants.GET_ALL_STORY_USER_FAILURE, error };
  }
}

function FethTopSotryTeller() {
  return (dispatch: any) => {
    dispatch(request());
    storyService.FetchTopStoryTeller().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: storiesConstants.GET_TOP_STORY_TELLER_REQUEST };
  }
  function success(user: any) {
    return { type: storiesConstants.GET_TOP_STORY_TELLER_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: storiesConstants.GET_TOP_STORY_TELLER_FAILURE, error };
  }
}

function AddStories(data: any,saveClicked:boolean) {
  return (dispatch: any) => {
    dispatch(request(data));
    storyService.AddStories(data)
      .then((response) => response)
      .then((data: any) => {
          dispatch(success(data));
          if(data.status===500)
          {
            dispatch(failure(data.error));
            dispatch(alertActions.error(data.data.error.message));
          }
          else
          {
            dispatch(alertActions.success(data.data.message));
            localStorage.removeItem("placeData");
            localStorage.removeItem("titleData");
            localStorage.removeItem("storyData");
            if(saveClicked)
            {
              history.push('/user/write-story')
            }
            else
            {
              history.push('/user/my-stories')
            }


          }



      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: storiesConstants.ADD_STORY_REQUEST, data };
    }
    function success(user: any) {
      return { type: storiesConstants.ADD_STORY_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: storiesConstants.ADD_STORY_FAILURE, error };
    }
  };
}

function FetchEditStories(id: any) {
  // store.getState().FetchEditStories.fetchEditStoriesObject = ""
  return (dispatch: any) => {
    dispatch(request(id));
    storyService.GetEditStories(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: storiesConstants.EDIT_STORY_REQUEST, id };
  }
  function success(user: any) {
    return { type: storiesConstants.EDIT_STORY_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: storiesConstants.EDIT_STORY_FAILURE, error };
  }
}

function UpdateStory(data: any, id: any,isUnPublish:any) {
  return (dispatch: any) => {
    dispatch(request(data, id,isUnPublish));
    storyService.UpdateStory(data, id,isUnPublish)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "200") {
          dispatch(success(data));
          dispatch(
            alertActions.success("You have successfully update a  story ")
          );
          history.push('/user/my-stories/')
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any, id: any,isUnPublish:boolean) {
      return { type: storiesConstants.UPDATE_STORY_REQUEST, data, id,isUnPublish };
    }
    function success(user: any) {
      return { type: storiesConstants.UPDATE_STORY_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: storiesConstants.UPDATE_STORY_FAILURE, error };
    }
  };
}

function DeleteStory(data:any,id: any) {
  return (dispatch: any) => {
    dispatch(request(data,id));
    storyService.DeleteStory(data,id)
      .then((response) => response)
      .then((res: any) => {
        if(res.deletedBy!="delete by user")
        {
         history.go(0)
        }
        else
        {
          console.log("by admin")
        }
          dispatch(success(res));
          dispatch(alertActions.success(res.data.message)
          );
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });

    function request(data:any,id: any) {
      return { type: storiesConstants.DELETE_STORY_REQUEST, data,id };
    }
    function success(user: any) {
      return { type: storiesConstants.DELETE_STORY_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: storiesConstants.DELETE_STORY_FAILURE, error };
    }
  };
}


function GetStory(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    storyService.GetStory(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: storiesConstants.GET_STORY_REQUEST, id };
  }
  function success(user: any) {
    return { type: storiesConstants.GET_STORY_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: storiesConstants.GET_STORY_FAILURE, error };
  }
}

function GetRelatedStories(placeId:any,id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    storyService.GetRelatedStories(placeId,id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: storiesConstants.GET_RELETED_STORY_REQUEST, id };
  }
  function success(user: any) {
    return { type: storiesConstants.GET_RELETED_STORY_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: storiesConstants.GET_RELETED_STORY_FAILURE, error };
  }
}

function GetCategoryStory(id: any,placeId:any) {
  return (dispatch: any) => {
    dispatch(request(id));
    storyService.GetReleventStory(id,placeId).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: storiesConstants.GET_CATEGORY_STORY_REQUEST, id };
  }
  function success(user: any) {
    return { type: storiesConstants.GET_CATEGORY_STORY_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: storiesConstants.GET_CATEGORY_STORY_FAILURE, error };
  }
}

function GetMyStory(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    storyService.GetMyStory(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: storiesConstants.GET_MY_STORY_REQUEST,id};
  }
  function success(user: any) {
    return { type: storiesConstants.GET_MY_STORY_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: storiesConstants.GET_MY_STORY_FAILURE, error };
  }
}


function GetDraftStory(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    storyService.GetDraftStory(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: storiesConstants.GET_DRAFT_STORY_REQUEST,id};
  }
  function success(user: any) {
    return { type: storiesConstants.GET_DRAFT_STORY_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: storiesConstants.GET_DRAFT_STORY_FAILURE, error };
  }
}

function GetPlaceStories(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    storyService.GetPlaceStories(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: storiesConstants.GET_PLACE_STORY_REQUEST,id};
  }
  function success(user: any) {
    return { type: storiesConstants.GET_PLACE_STORY_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: storiesConstants.GET_RELETED_STORY_FAILURE, error };
  }
}


function AddStoryComment(data: any,titleUrl:any,id:any) {
  let url = window.location.pathname;
  let uid = url.slice((url.length - 26))
  let char=uid.charAt(0)
  return (dispatch: any) => {
    dispatch(request(data));
    storyService.AddStoryComment(data,id)
      .then((response) => response)
      .then((data: any) => {
          dispatch(success(data));
          if(data.status===500)
          {
            dispatch(failure(data.error));
            dispatch(alertActions.error(data.data.error.message));
          }
          else
          {
            history.go(0);
            // dispatch(alertActions.success(data.data.message));
            // if(char==="-")
            // {
              // history.push(`/travel-stories/${titleUrl}-${id}`)
            // }
            // else
            // {

            //   history.push(`/travel-stories/${titleUrl}--${id}`)
            // }

          }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: storiesConstants.ADD_STORY_COMMENT_REQUEST, data };
    }
    function success(user: any) {
      return { type: storiesConstants.ADD_STORY_COMMENT_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: storiesConstants.ADD_STORY_COMMENT_FAILURE, error };
    }
  };
}

function AddStoryLike(data: any,id:any) {
  return (dispatch: any) => {
    dispatch(request(data));
    storyService.AddStoryLike(data,id)
      .then((response) => response)
      .then((data: any) => {
          dispatch(success(data));
          if(data.status===500)
          {
            dispatch(failure(data.error));
            dispatch(alertActions.error(data.data.error.message));
          }
          else
          {
            dispatch(alertActions.success(data.data.message));
          }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: storiesConstants.ADD_STORY_LIKE_REQUEST, data };
    }
    function success(user: any) {
      return { type: storiesConstants.ADD_STORY_LIKE_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: storiesConstants.ADD_STORY_LIKE_FAILURE, error };
    }
  };
}

function RemoveStoryLike(data: any,id:any) {
  return (dispatch: any) => {
    dispatch(request(data));
    storyService.RemoveStoryLike(data,id)
      .then((response) => response)
      .then((data: any) => {
          dispatch(success(data));
          if(data.status===500)
          {
            dispatch(failure(data.error));
            dispatch(alertActions.error(data.data.error.message));
          }
          else
          {
            dispatch(alertActions.success(data.data.message));
          }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: storiesConstants.REMOVE_STORY_LIKE_REQUEST, data };
    }
    function success(user: any) {
      return { type: storiesConstants.REMOVE_STORY_LIKE_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: storiesConstants.REMOVE_STORY_LIKE_FAILURE, error };
    }
  };
}

function FeaturedStory(body:any,id:any) {
  return (dispatch:any) => {
      dispatch(request({ body,id }));
      storyService.FeaturedAlbum(body,id)
          .then((data:any)=>{
            if(data.status=="200")
            {
              dispatch(success(data));
              dispatch(alertActions.success(data.data.message))
              history.go(0);
            }

          }).catch((err:any)=>{
            dispatch(failure(err))
          });
          };

          function request(user:any) { return { type: storiesConstants.FEATURED_STORY_FAILURE, user } }
          function success(user:any) { return { type: storiesConstants.FEATURED_STORY_SUCCESS, user } }
          function failure(error:any) { return { type: storiesConstants.FEATURED_STORY_FAILURE, error } }
}


function FetchStoriesCount() {
  return (dispatch: any) => {
    dispatch(request());
    storyService.FetchStoriesCount().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: storiesConstants.GET_STORIES_COUNT_REQUEST };
  }
  function success(user: any) {
    return { type: storiesConstants.GET_STORIES_COUNT_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: storiesConstants.GET_STORIES_COUNT_FAILURE, error };
  }
}
