import React, { useState, useEffect } from 'react';
import { Table, Dropdown, Menu, Button, Modal, Input, Form,Switch} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { history } from '../../../Helpers';
import { deviceInfoActions } from '../../../actions';
import { connect } from 'react-redux';
import { SettingOutlined,CheckOutlined,CloseOutlined } from '@ant-design/icons';
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};



type DevoiceInfo = {
  dispatch: any;
  data: any;
}

const {TextArea} = Input

function DevoiceInfo(props: DevoiceInfo) {
 let formRef:any = React.createRef<FormInstance>();
  let fetchId: any = '';
  const { dispatch, data } = props;
  const [visible, setvisible] = useState(false);
  const [checkSearch, setcheckSearch] = useState(false);
  const [filterData, setfilterData] = useState();
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {
      dispatch(deviceInfoActions.FetchDeviceInfo());

    }

  }, [dispatch]);


  const [selectedRowKeys, setselectedRowKeys] = useState([]);


  const onSelectChange = (selectedRowKeys: any) => {
    setselectedRowKeys(selectedRowKeys)

  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  if (!data) {
    return (<div></div>)
  }
  let filterArray: any = [];
  let search = (value: any) => {

    const filterArrayData = data.filter((o: any) =>
      Object.keys(o).some(k =>
        String(o[k])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );
    filterArrayData.forEach((element: any) => {
      filterArray.push({
        key: element._id,
        appStoreLink: element.appStoreLink,
        playStorLink: element.playStorLink,
        latestVersionOnAppStore: element.latestVersionOnAppStore,
        latestVersionOnPlayStore: element.latestVersionOnPlayStore,
        hasUpdate: element.hasUpdate.toString(),
        haseForceUpdate: element.haseForceUpdate.toString(),
        mesageEng: element.mesageEng,
        mesageUrd: element.mesageUrd,
        createdDate: element.createdDate.split("T")[0]
      })

    });
    setfilterData(filterArray);
    setcheckSearch(true);
  };



  const categoriesArray: any = [];

  data.forEach((element: any) => {
    console.log(element)
    categoriesArray.push({
      key: element._id,
      appStoreLink: element.appStoreLink,
      playStorLink: element.playStorLink,
      latestVersionOnAppStore: element.latestVersionOnAppStore,
      latestVersionOnPlayStore: element.latestVersionOnPlayStore,
      hasUpdate: element.hasUpdate.toString(),
      haseForceUpdate: element.haseForceUpdate.toString(),
      mesageEng: element.mesageEng,
      mesageUrd: element.mesageUrd,
      createdDate: element.createdDate.split("T")[0]
    })

  });

  const showModal = () => {
    setvisible(true);
  };

  const handleOk = (e: any) => {
    setvisible(false);
    // dispatch(facilitiesActions.FetchAllFacilities());
  };

  const handleCancel = (e: any) => {
    setvisible(false);
  };

  const onFinish = (values: any) => {
    let data={
      appStoreLink:values.appStoreLink,
      hasUpdate:values.hasUpdate,
      haseForceUpdate:values.haseForceUpdate,
      latestVersionOnAppStore: values.latestVersionOnAppStore,
      latestVersionOnPlayStore:values.latestVersionOnPlayStore,
      mesageEng:values.mesageEng,
      mesageUrd:values.mesageUrd,
      playStorLink:values.playStorLink,
        }
    dispatch(deviceInfoActions.AddDeviceInfo(data));
    formRef.current.resetFields()
  };
  const onFinishFailed = (errorInfo: any) => {
  };
  const onMenuClick = (id: any) => {
    fetchId = id;
  }
  const onSelect = (e: any) => {
    if (e.key == "EU") {
      history.push('/pr-admin-cp/edit-device-info/' + fetchId)
    }
    else {
      Modal.warning({
        title: 'Opppssss',

        content: (
          <div className="warning-modal">
            <p>Are you sure you want to delete this?</p>
          </div>
        ),
        className: "cancel-modal",
        width: 560,
        okType: 'danger',
        okText: 'ok',
        okCancel: true,
        centered: true,
        cancelText: 'cancel',
        onOk: () => {
          dispatch(deviceInfoActions.DeleteDeviceInfo(fetchId));
        },
        onCancel() {
        },
      });
    }
  }
  const columns = [
    {
      title: 'AppStore Link',
      dataIndex: 'appStoreLink',
    },
    {
      title: 'PlayStor Link',
      dataIndex: 'playStorLink',
    },
    {
      title: 'AppStore Version',
      dataIndex: 'latestVersionOnAppStore',
    },
    {
      title: 'PlayStore Version',
      dataIndex: 'latestVersionOnPlayStore',
    },
    {
      title: 'Has Update',
      dataIndex: 'hasUpdate',
    },
    {
      title: 'Force Update',
      dataIndex: 'haseForceUpdate',
    },
    {
      title: 'Message English',
      dataIndex: 'mesageEng',
    },
    {
      title: 'Mesage Urdu ',
      dataIndex: 'mesageUrd',
    },
    {
      title: 'Settings',
      render: (records: any) => <Dropdown className="admin-acc" overlay={(
        <Menu onClick={onSelect}>
          <Menu.Item key="EU" onClick={() => onMenuClick(records.key)} className="user-menu-item">
            <span>
              Edit Info
          </span>
          </Menu.Item>
          <Menu.Item key="DU" onClick={() => onMenuClick(records.key)} className="user-menu-item">
            <span>
              Delete Info
          </span>
          </Menu.Item>
        </Menu>
      )} trigger={['click']} >
        <SettingOutlined />
      </Dropdown>,
    },
  ];
  const onPageChange=(e:any)=>{
   console.log(e)
  }

  return (
    <div>
      <div style={{ width: '50%', float: 'left' }}>
        <Input.Search

          placeholder="Search by..."
          enterButton
          size="large"
          onSearch={search}
        />
      </div>

      <div style={{ marginBottom: "20px", textAlign: "right" }}>
        <Button type="primary" onClick={showModal} >Add Device Information</Button>
      </div>
      {
        checkSearch ? <Table className="show-table" rowSelection={rowSelection} columns={columns} dataSource={filterData} /> :
          <Table pagination={{ defaultPageSize: 10, total: categoriesArray , showSizeChanger: false, onChange:onPageChange}} className="show-table" rowSelection={rowSelection} columns={columns} dataSource={categoriesArray} />

      }


      <Modal
        title="Add Device Information"
        visible={visible}
        onOk={handleOk}
        width="768px"
        onCancel={handleCancel}
      >
        <div className=''>
          <Form
            {...layout}
            ref={formRef}
            className="admin-login-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item className="lbl-name" label="App Store Link"></Form.Item>
            <Form.Item
              name="appStoreLink"
              rules={[{ required: true, message: 'Please enter a link.' },]}
            >
              <Input name="appStoreLink" size="large" placeholder="App Store Link" />
            </Form.Item>
            <Form.Item className="lbl-name" label="Play Store Link"></Form.Item>
            <Form.Item
              name="playStorLink"
              rules={[{ required: true, message: 'Please enter a link.' },]}
            >
              <Input name="playStorLink" size="large" placeholder="Play Store Link" />
            </Form.Item>
            <Form.Item className="lbl-name" label="App Store Version"></Form.Item>
            <Form.Item
              name="latestVersionOnAppStore"
              rules={[{ required: true, message: 'Please enter a App Store Version.' },]}            >
              <Input name="latestVersionOnAppStore" size="large" placeholder="App Store Version" />
            </Form.Item>
            <Form.Item className="lbl-name" label="Play Store Version"></Form.Item>
            <Form.Item
              name="latestVersionOnPlayStore"
              rules={[{ required: true, message: 'Please enter a Play Store Version.' },]}            >
              <Input name="latestVersionOnPlayStore" size="large" placeholder="Play Store Version" />
            </Form.Item>
             <Form.Item className="lbl-name" label="English Message"></Form.Item>
            <Form.Item
              name="mesageEng"
              rules={[{ required: true, message: 'Please enter a message.' },]}            >
              <TextArea name="mesageEng"  rows={4} placeholder="Message in English" />
            </Form.Item>
            <Form.Item className="lbl-name" label="Urdu Message"></Form.Item>
            <Form.Item
              name="mesageUrd"
                         >
              <TextArea name="mesageUrd"  rows={4} placeholder="Message in Urdu" />
            </Form.Item>
            <Form.Item className="lbl-name" label="Has Update"></Form.Item>
            <Form.Item
              name="hasUpdate"
            >
            <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            />
            </Form.Item>
            <Form.Item className="lbl-name" label="Has Force Update"></Form.Item>
            <Form.Item
              name="haseForceUpdate"
            >
            <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            />
            </Form.Item>
            <Form.Item >
              <Button className="btn-admin-login" style={{ marginTop: '20px' }} size="large" type="primary" htmlType="submit">
                Add Information
                    </Button>

            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );

}

function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    data: state.FetchDeviceInfo.getDeviceInfoObject
  };
}

export const connectedDevoiceInfo = connect(mapStateToProps)(DevoiceInfo);

