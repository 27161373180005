import {questionConstants} from '../constants';

export function FetchPlaceQuestions(state={getPlaceQuestionsObject:false},action:any)
{
    switch (action.type) {
        case questionConstants.GET_ALL_PLACE_QUESTION_REQUEST:
          return state;
          case questionConstants.GET_ALL_PLACE_QUESTION_SUCCESS:
          return{
            ...state,
            getPlaceQuestionsObject:action.user
          };
      default:
        return state
    }
}


export function FetchRelatedQuestions(state={getRelatedQuestionsObject:false},action:any)
{
    switch (action.type) {
        case questionConstants.GET_RELATED_QUESTION_REQUEST:
          return state;
          case questionConstants.GET_RELATED_QUESTION_SUCCESS:
          return{
            ...state,
            getRelatedQuestionsObject:action.user
          };
      default:
        return state
    }
}

export function FetchSingleQuestions(state={getSingleQuestionsObject:false},action:any)
{
    switch (action.type) {
        case questionConstants.GET_SINGLE_QUESTION_REQUEST:
          return state;
          case questionConstants.GET_SINGLE_QUESTION_SUCCESS:
          return{
            ...state,
            getSingleQuestionsObject:action.user
          };
      default:
        return state
    }
}

export function FetchUserQuestions(state={getUserQuestionsObject:false},action:any)
{
    switch (action.type) {
        case questionConstants.GET_USER_QUESTION_REQUEST:
          return state;
          case questionConstants.GET_USER_QUESTION_SUCCESS:
          return{
            ...state,
            getUserQuestionsObject:action.user
          };
      default:
        return state
    }
}

export function FetchAllQuestions(state={getALLQuestionsObject:''},action:any)
{
    switch (action.type) {
        case questionConstants.GET_ALL_QUESTION_REQUEST:
          return state;
          case questionConstants.GET_ALL_QUESTION_SUCCESS:
          return{
            ...state,
            getALLQuestionsObject:action.user
          };
      default:
        return state
    }
}

export function FetchAllQuestionsAdmin(state={getALLQuestionsAdminObject:''},action:any)
{
    switch (action.type) {
        case questionConstants.GET_ALL_QUESTION_ADMIN_REQUEST:
          return state;
          case questionConstants.GET_ALL_QUESTION_ADMIN_SUCCESS:
          return{
            ...state,
            getALLQuestionsAdminObject:action.user
          };
      default:
        return state
    }
}


export function FetchEditQuestionsAdmin(state={getEditQuestionsAdminObject:''},action:any)
{
    switch (action.type) {
        case questionConstants.GET_EDIT_QUESTION_ADMIN_REQUEST:
          return state;
          case questionConstants.GET_EDIT_QUESTION_ADMIN_SUCCESS:
          return{
            ...state,
            getEditQuestionsAdminObject:action.user
          };
      default:
        return state
    }
}
