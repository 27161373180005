import { categoryConstants} from "../constants";
import { categoryService } from "../services";
import { history, store } from "../Helpers";
import { alertActions } from "./";

export const categoryActions = {
  FetchAllCategories,
  AddCategories,
  FetchEditCategories,
  UpdateCategory,
  DeleteCategory,
  FetchAllPlaceCategories,
  AddPlaceCategories,
  FetchEditPlaceCategories,
  UpdatePlaceCategory,
  DeletePlace,
  FetchPakMapCategories,
  FetchWeather,
  GetCurrentWeather,
  AddPlaceExperience,
  FeaturedPlace,
  AddUserPlaceAdmin
};

function FetchAllCategories() {
  return (dispatch: any) => {
    dispatch(request());
    categoryService.FetchAllCategories().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: categoryConstants.GET_ALL_REQUEST };
  }
  function success(user: any) {
    return { type: categoryConstants.GET_ALL_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: categoryConstants.GET_ALL_FAILURE, error };
  }
}

function AddCategories(data: any) {
  return (dispatch: any) => {
    dispatch(request(data));
    categoryService
      .AddCategory(data)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "201") {
          dispatch(success(data));
          dispatch(
            alertActions.success("You have successfully add a category ")
          );
          history.push("/pr-admin-cp/categories");
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: categoryConstants.ADD_CATEGORY_REQUEST, data };
    }
    function success(user: any) {
      return { type: categoryConstants.ADD_CATEGORY_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: categoryConstants.ADD_CATEGORY_FAILURE, error };
    }
  };
}

function FetchEditCategories(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    categoryService.GetEditCategory(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: categoryConstants.EDIT_CATEGORY_REQUEST, id };
  }
  function success(user: any) {
    return { type: categoryConstants.EDIT_CATEGORY_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: categoryConstants.EDIT_CATEGORY_FAILURE, error };
  }
}

function UpdateCategory(data: any, id: any) {
  return (dispatch: any) => {
    dispatch(request(data, id));
    categoryService
      .UpdateCategory(data, id)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "200") {
          dispatch(success(data));
          dispatch(
            alertActions.success("You have successfully update a category ")
          );
          history.push("/pr-admin-cp/categories");
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any, id: any) {
      return { type: categoryConstants.UPDATE_CATEGORY_REQUEST, data, id };
    }
    function success(user: any) {
      return { type: categoryConstants.UPDATE_CATEGORY_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: categoryConstants.UPDATE_CATEGORY_FAILURE, error };
    }
  };
}

function DeleteCategory(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    categoryService
      .DeleteCategory(id)
      .then((response) => response)
      .then((res: any) => {
        if (res.status == "200") {
          dispatch(success(res));
          dispatch(alertActions.success(res));
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });

    function request(id: any) {
      return { type: categoryConstants.DELETE_CATEGORY_REQUEST, id };
    }
    function success(user: any) {
      return { type: categoryConstants.DELETE_CATEGORY_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: categoryConstants.DELETE_CATEGORY_FAILURE, error };
    }
  };
}

function FetchAllPlaceCategories() {
  return (dispatch: any) => {
    dispatch(request());
    categoryService.FetchAllPlaceCategories().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: categoryConstants.GET_ALL_PLACE_CATEGORIES };
  }
  function success(user: any) {
    return { type: categoryConstants.GET_ALL_PLACE_CATEGORIES_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: categoryConstants.GET_ALL_PLACE_CATEGORIES_FAILURE, error };
  }
}

function AddPlaceCategories(data: any) {
  return (dispatch: any) => {
    dispatch(request(data));
    categoryService
      .AddPlaceCategory(data)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "201") {
          dispatch(success(data));
          dispatch(
            alertActions.success("You have successfully add a Place category ")
          );
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: categoryConstants.ADD_PLACE_CATEGORY_REQUEST, data };
    }
    function success(user: any) {
      return { type: categoryConstants.ADD_PLACE_CATEGORY_SUCCESS, user };
    }
    function failure(error: any) {
      return {
        type: categoryConstants.GET_ALL_PLACE_CATEGORIES_FAILURE,
        error,
      };
    }
  };
}

function AddUserPlaceAdmin(data: any) {
  return (dispatch: any) => {
    dispatch(request(data));
    categoryService
      .AddUserPlaceAdmin(data)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "201") {
          dispatch(success(data));
          dispatch(
            alertActions.success("You have successfully add a Place category ")
          );
          history.push('/pr-admin-cp/user-places')
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: categoryConstants.ADD_PLACE_CATEGORY_REQUEST, data };
    }
    function success(user: any) {
      return { type: categoryConstants.ADD_PLACE_CATEGORY_SUCCESS, user };
    }
    function failure(error: any) {
      return {
        type: categoryConstants.GET_ALL_PLACE_CATEGORIES_FAILURE,
        error,
      };
    }
  };
}

function FetchEditPlaceCategories(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    categoryService.GetEditPlaceCategory(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: categoryConstants.EDIT_PLACE_CATEGORY_REQUEST, id };
  }
  function success(user: any) {
    return { type: categoryConstants.EDIT_PLACE_CATEGORY_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: categoryConstants.EDIT_PLACE_CATEGORY_FAILURE, error };
  }
}

function UpdatePlaceCategory(data: any, id: any) {
  return (dispatch: any) => {
    dispatch(request(data, id));
    categoryService
      .UpdatePlaceCategory(data, id)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "200") {
          dispatch(success(data));
          dispatch(
            alertActions.success(
              "You have successfully update a place category "
            )
          );
          history.go(0);
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any, id: any) {
      return {
        type: categoryConstants.UPDATE_PLACE_CATEGORY_REQUEST,
        data,
        id,
      };
    }
    function success(user: any) {
      return { type: categoryConstants.UPDATE_PLACE_CATEGORY_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: categoryConstants.UPDATE_PLACE_CATEGORY_FAILURE, error };
    }
  };
}

function DeletePlace(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    categoryService
      .DeletePlace(id)
      .then((response) => response)
      .then((res: any) => {
        if (res.status == "200") {
          dispatch(success(res));
          dispatch(
            alertActions.success("You have successfully deleted a place.")
          );
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });

    function request(id: any) {
      return { type: categoryConstants.DELETE_PLACE_REQUEST, id };
    }
    function success(user: any) {
      return { type: categoryConstants.DELETE_PLACE_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: categoryConstants.DELETE_PLACE_FAILURE, error };
    }
  };
}

function FetchPakMapCategories() {
  return (dispatch: any) => {
    dispatch(request());
    categoryService.FetchAllPakMapCategories().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: categoryConstants.GET_ALL_PAK_MAP_CAT };
  }
  function success(user: any) {
    return { type: categoryConstants.GET_ALL_PAK_MAP_CAT_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: categoryConstants.GET_ALL_PAK_MAP_CAT_FAILURE, error };
  }
}

function FetchWeather(values:any) {
  return (dispatch: any) => {
    dispatch(request(values));
    categoryService.FetchWeather(values).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request(values:any) {
    return { type: categoryConstants.GET_WEATHER_REQUEST,values};
  }
  function success(weather: any) {
    return { type: categoryConstants.GET_WEATHER_REQUEST_SUCCESS, weather };
  }
  function failure(error: any) {
    return { type: categoryConstants.GET_WEATHER_REQUEST_FAILURE, error };
  }
}


function GetCurrentWeather(values:any) {
  store.getState().FetchWeather.fetchWeatherObject = "";
  store.getState().FetchCurrentWeather.fetchCurrentWeatherObject = "";
  return (dispatch: any) => {
    dispatch(request(values));
    categoryService.FetchCurrentWeather(values).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request(values:any) {
    return { type: categoryConstants.GET_CURRENT_WEATHER_REQUEST,values};
  }
  function success(weather: any) {
    return { type: categoryConstants.GET_CURRENT_WEATHER_REQUEST_SUCCESS, weather };
  }
  function failure(error: any) {
    return { type: categoryConstants.GET_CURRENT_WEATHER_REQUEST_FAILURE, error };
  }
}
function FeaturedPlace(body:any,id:any) {
  return (dispatch:any) => {
      dispatch(request({ body,id }));
      categoryService.FeaturedPlace(body,id)
          .then((data:any)=>{
            if(data.status=="200")
            {
              dispatch(success(data));
              dispatch(alertActions.success(data.data.message))
              history.go(0);
            }

          }).catch((err:any)=>{
            dispatch(failure(err))
          });
          };

          function request(user:any) { return { type: categoryConstants.FEATURED_PLACE_REQUEST, user } }
          function success(user:any) { return { type: categoryConstants.FEATURED_PLACE_SUCCESS, user } }
          function failure(error:any) { return { type: categoryConstants.FEATURED_PLACE_FAILURE, error } }
}


function AddPlaceExperience(data: any) {
  return (dispatch: any) => {
    dispatch(request(data));
    categoryService
      .AddExperience(data)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "201") {
          dispatch(success(data));

          dispatch(
            alertActions.success("You have successfully added an experience")
          );
          history.go(0);
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: categoryConstants.ADD_EXPERIENCE_REQUEST, data };
    }
    function success(user: any) {
      return { type: categoryConstants.ADD_EXPERIENCE_REQUEST_SUCCESS, user };
    }
    function failure(error: any) {
      return {
        type: categoryConstants.ADD_EXPERIENCE_REQUEST_FAILURE,
        error,
      };
    }
  };
}

