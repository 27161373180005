import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Upload, message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { history, store } from '../../../Helpers';
import { connect } from 'react-redux';
import { questionActions } from '../../../actions';
import 'react-quill/dist/quill.snow.css';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};

type EditQuestion = {
  dispatch: any;
  match: any;
  data: any;
}

function EditQuestion(props: EditQuestion) {
  store.getState().FetchEditActivity.fetchEditActivityObject = "";
  let formRef:any = React.createRef<FormInstance>();
  let { dispatch, match, data } = props;

  const onFinish = (values: any) => {
    let imageFlag = false;
    let iconFlag = false;
    let formData = new FormData();
    formData.append("title", values.title)
    if (values.image) {
      imageFlag = true;
      formData.append("facilityImage", values.image.file.originFileObj)
    }
    else if (values.icon) {
      iconFlag = true;
      formData.append("facilityImage", values.icon.file.originFileObj)
    }
    formData.append("imageFlag", imageFlag.toString());
    formData.append("iconFlag", iconFlag.toString());

    // dispatch(facilitiesActions.UpdateActivity(formData, match.params.id));
  };
  useEffect(() => {

    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {

      dispatch(questionActions.FetchEditQuestionAdmin(match.params.id));
    }

  }, [dispatch,match]);
  // useEffect(()=>{
  //  return ()=> setFintalData(data)
  // },[])

  const onFinishFailed = (errorInfo: any) => {
  };
  if (!data) {

    return (<div></div>)
  }
  console.log(data);
  return (
    <div>
      <Form
        {...layout}
        ref={formRef}
        className="admin-login-form"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item className="lbl-name" label="Title"></Form.Item>
        <Form.Item
          initialValue={data[0].question}
          name="title"
          rules={[{ required: true, message: 'Please enter a title.' },]}
        >
          <Input name="title" size="large" placeholder="Title" />
        </Form.Item>
        <Form.Item >
          <Button className="btn-admin-login" style={{ marginTop: '20px' }} size="large" type="primary" htmlType="submit">
            Update Question
                    </Button>
        </Form.Item>
      </Form>
    </div>
  )

}

function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    data: state.FetchEditQuestionsAdmin.getEditQuestionsAdminObject,
  };
}

export const connectedEditQuestion = connect(mapStateToProps)(EditQuestion);
