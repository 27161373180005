export const interestConstants = {
  GET_ALL_INTEREST_REQUEST: 'GET_ALL_INTEREST_REQUEST',
  GET_ALL_INTEREST_SUCCESS: ' GET_ALL_INTEREST_SUCCESS',
  GET_ALL_INTEREST_FAILURE: 'GET_ALL_INTEREST_FAILURE',

  ADD_INTEREST_REQUEST: ' ADD_INTEREST_REQUEST',
  ADD_INTEREST_SUCCESS: 'ADD_INTEREST_SUCCESS',
  ADD_INTEREST_FAILURE: 'ADD_INTEREST_FAILURE',


  EDIT_INTEREST_REQUEST: ' EDIT_INTEREST_REQUEST',
  EDIT_INTEREST_SUCCESS: 'EDIT_INTEREST_SUCCESS',
  EDIT_INTEREST_FAILURE: 'EDIT_INTEREST_FAILURE',

  UPDATE_INTEREST_REQUEST: ' UPDATE_INTEREST_REQUEST',
  UPDATE_INTEREST_SUCCESS: 'UPDATE_INTEREST_SUCCESS',
  UPDATE_INTEREST_FAILURE: 'UPDATE_INTEREST_FAILURE',

  DELETE_INTEREST_REQUEST: ' DELETE_INTEREST_REQUEST',
  DELETE_INTEREST_SUCCESS: 'DELETE_INTEREST_SUCCESS',
  DELETE_INTEREST_FAILURE: 'DELETE_INTEREST_FAILURE',
}
