import React from 'react';
import { Box, Heading, Text} from "@chakra-ui/core";
import {RightCircleOutlined } from '@ant-design/icons';
import mapImage from '../../Assets/map-01.svg';
import {history} from '../../Helpers';
import './visted-places.css';


export function VistedPlacesSection() {
  return (
    <Box className="visted-place-container">
    <Box className="inner-container-banner">
        <Box className="travel-places-left-sec">
          <img src={mapImage} alt={mapImage}/>
       </Box>
       <Box className="travel-places-right-sec travel-album-right">
       <Heading className="travel-album-headings">
       Mark your <span className="fav-txt">favorite</span>
       <br/>
         and <span className="visted-txt">visited </span>places
        </Heading>
        <Text className="tour-desc">
        Drop your footprints on the map,
        <br/>
        and let the others know where you have traveled and will be.
        </Text>
        <button className="explore-btn-common" onClick={()=>history.push('/pak-map')}>
           Explore Pakistan Map
            <RightCircleOutlined />
            </button>

       </Box>
     </Box>
    </Box>
  )
}


