import {answerConstants} from '../constants';

export function FetchAnswers(state={getAnswersObject:false},action:any)
{
    switch (action.type) {
        case answerConstants.GET_ANSWER_REQUEST:
          return state;
          case answerConstants.GET_ANSWER_SUCCESS:
          return{
            ...state,
            getAnswersObject:action.user
          };
      default:
        return state
    }
}

export function FetchUserAnswers(state={getUserAnswersObject:false},action:any)
{
    switch (action.type) {
        case answerConstants.GET_USER_ANSWER_REQUEST:
          return state;
          case answerConstants.GET_USER_ANSWER_SUCCESS:
          return{
            ...state,
            getUserAnswersObject:action.user
          };
      default:
        return state
    }
}


export function FetchUserAnswersByQuestionAdmin(state={getUserAnswersByQuestionAdminObject:false},action:any)
{
    switch (action.type) {
        case answerConstants.GET_ANSWER_BY_QUESTION_ADMIN_REQUEST:
          return state;
          case answerConstants.GET_ANSWER_BY_QUESTION_ADMIN_SUCCESS:
          return{
            ...state,
            getUserAnswersByQuestionAdminObject:action.user
          };
      default:
        return state
    }
}


