import React,{useEffect} from 'react';
import { connect } from 'react-redux';
import { albumActions } from '../../actions';
import NavBar from '../Navbar/Navbar';
import {Box,Image} from '@chakra-ui/core';
import {Hero} from '../HeroSection';
import ReactGA from 'react-ga';
import {AlbumCarousel} from '../Carousel';
import ContentLoader from 'react-content-loader'
import {RightFooter} from '../FooterSection'
import advertise from '../../Assets/bridge.jpg';
import {MetaDecorator} from '../../utils'
import {LovedAlbums} from '../Cards'

type Stories = {
  dispatch: any;
  data: any;
  popularAlbums:any;
  match:any
}

function AllAlbums(props:Stories) {

  const { dispatch, data,popularAlbums,match } = props;
  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    // window.onscroll = function() {myFunction()};
    let a:any = document.getElementById("sticky-footer");
  // let sticky = a.offsetTop;
      dispatch(albumActions.FetchAllAlbumsUser());
      dispatch(albumActions.FetchLovedAlbums())
  }, [dispatch]);

  const MyLoader = () => (
    <ContentLoader
    viewBox="0 0 380 100">
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="350" />
    </ContentLoader>
  );
  const LeftLoader = () => (
    <ContentLoader
    viewBox="0 0 500 550">
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="550" />
    </ContentLoader>
  )
  const RighLoader=()=>(
    <ContentLoader
    viewBox="0 0 500 650">
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="650" />
  </ContentLoader>
  )

  if (!data) {
    return (
       <div >
        <div style={{width:'100%'}}>
        <MyLoader/>
        </div>
        <div style={{maxWidth:'1366px',margin:'0px auto'}}>
          <div style={{marginTop:'30px',width:'75%',float:'left'}}>
          <div style={{width:'50%', padding:'16px',float:'left'}}>
        <LeftLoader/>
        </div>
        <div  style={{width:'50%',padding:'16px',float:'left'}}>
        <LeftLoader/>
        </div>
          </div>
          <div style={{marginTop:'30px',width:'25%',float:'left',padding:'16px'}}>
            <RighLoader/>
          </div>
        </div>
      </div>

    )
  }
  if (!popularAlbums) {
    return (<div></div>)
  }
  const fromParent:any='travelAlbum';

  let meta={
    title:"Travel Albums",
    description:"Breath taking scenes, full of majesty places, Travel memories, captured beauty & random clicks.",
    imageAlt:"albums banner",
    imageUrl:"social-share-image.png"
  }
  return (
    <Box>
      <NavBar/>
      <MetaDecorator title={meta.title} description={meta.description} imageAlt={meta.imageAlt} imageUrl={meta.imageUrl} match={match}/>

      <Hero fromParent={fromParent}/>
      <Box className="stories-main-container">
        <Box className="inner-container-banner-stories">
     <Box className="stories-container-inner-left">
      <div className="carousal-container">
       {
           data.map((item: any) =>
           <AlbumCarousel data={item} key={item._id}  />
           )
       }
        </div>
     </Box>
     <div id="sticky-footer" className="album-container-inner-right">
       <div className="stories-advertise-container">
       <Image size="350px" src={advertise} alt="advertise" />
       </div>
       <div className="top-story-taller-container">
         <p className="top-story-taller-heading">
         Most loved albums
         </p>
         <div className="loved-album-container">
         {
           popularAlbums.map((item: any) =>
           <LovedAlbums data={item} key={item._id}  />
           )
       }
         </div>
       </div>
       <div className="copyright-footer-container">
         <RightFooter/>
       </div>
     </div>
      </Box>
      </Box>
    </Box>
  )
}
function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    data: state.FetchAlbumsUser.getAllAlbumsUserObject,
    popularAlbums:state.FetchLovedAlbums.getLovedAlbumsObject
  };
}

export const connectedAllAlbums= connect(mapStateToProps)(AllAlbums);
