import React, { useEffect } from 'react';
import { Button, Form, Input, } from 'antd';
import { history } from '../../../Helpers';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga';
import './login.css'
import { userActions } from '../../../actions';


const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};

type Login = {
  dispatch: any;
}

function AdminLogin(props: Login) {
  const { dispatch } = props

  const onFinish = (values: any) => {
    let socialClick = false;
    dispatch(userActions.loginAdmin(values.email, values.password, socialClick));
    const [form] = Form.useForm();
    form.resetFields();

  };

  const onFinishFailed = (errorInfo: any) => {
  };
  useEffect(() => {
    ReactGA.initialize('UA-168266330-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (user) {
      if (user.loggedIn) {
        history.push('/pr-admin-cp')
      }
    }
  })

  return (
    <div>
      <div className="main-container">
        <div className="admin-login-container">
          <p className="login-txt">
            ADMIN LOGIN
            </p>

          <div className=''>
            <Form
              {...layout}
              className="admin-login-form"
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item className="lbl-name" label="Email address"></Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: "Please enter a valid email address.",
                  },
                  { required: true, message: 'Please enter your email.' }]}
              >
                <Input placeholder="Email address" size="large" />
              </Form.Item>
              <Form.Item className="lbl-name" label="Password"></Form.Item>
              <Form.Item

                name="password"
                rules={[{ required: true, message: 'Please enter your password.' }, {
                  min: 8,
                  message: 'password must be minimum 8 characters.'
                }
                ]}
              >
                <Input.Password placeholder="Password" size="large" />
              </Form.Item>
              <p className="forget-txt">
                <Link className="privacy-link" to="/">Forgot your password?</Link>
              </p>
              <Form.Item >
                <Button className="btn-admin-login" size="large" type="primary" htmlType="submit">
                  Log In
                    </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
function mapStateToProps(state: any) {
  const { loggingIn } = state.authentication;
  const { type, message } = state.alert;
  return {
    type,
    message,
    loggingIn
  };
}
export const connectedAdminLoginPage = connect(mapStateToProps)(AdminLogin);


