import React, { useEffect, useState } from 'react';
import {Avatar } from "@chakra-ui/core";
import {Button} from 'antd';
import Cookies from 'universal-cookie';
import {config} from '../../config';
import {history} from '../../Helpers'
import {friendsActions} from '../../actions'
import './avatarcard.css';

type AvatarCard ={
  user:any;
  dispatch:any;
  friendrequests:any;
  friends:any
}


const cookies = new Cookies();
export function AvatarCard(props:AvatarCard) {

  const {user,dispatch,friendrequests,friends} = props;
  const [isFriends,setIsFriends] = useState(false);
  let userFav=cookies.get('userData');
  const [currentId,setCurrentId]=useState(false);
  const [requestedUser,setRequestedUser]=useState(false);
  const [acceptedUser,setAcceptedUser]=useState(false);
 useEffect(()=>{
  friends.forEach((element:any) => {
      if((user._id.toString()===element.friendId._id.toString()&&userFav[0]._id.toString()===element.userId._id.toString())||(user._id.toString()===element.userId._id.toString()&&userFav[0]._id.toString()===element.friendId._id.toString()))
    {
       setIsFriends(true)
    }
  });
   for(const frnd of friendrequests)
   {
    if((frnd.sender._id.toString()===userFav[0]._id.toString())&& frnd.receiver._id.toString()===user._id.toString())
    {
     setRequestedUser(true)
    }
    if((frnd.receiver._id.toString()===userFav[0]._id.toString())&& frnd.sender._id.toString()===user._id.toString())
    {
      setAcceptedUser(true)
    }

   }
  if(userFav[0]._id.toString()===user._id)
  {
    setCurrentId(true)
  }

 },[])
 let onFriendRequestSend=(receiverId:any)=>{
   let data ={
    senderId:userFav[0]._id,
    receiverId:receiverId
   }
   dispatch(friendsActions.SendFriendRequest(data));
   setRequestedUser(true)
 }
 const onRespondClick=(receiverId:any)=>{
   let data={
    senderId:userFav[0]._id,
    receiverId:receiverId
   }
   dispatch(friendsActions.AcceptFriendRequest(data,friendrequests[0]._id));
 }
 const onUnfriend=()=>{
   let friendId:any='';
   let userId:any='';
  friends.forEach((element:any) => {
    if(user._id.toString()===element.friendId._id.toString())
  {
     friendId=user._id;
     userId=userFav[0]._id
  }
  if(user._id.toString()===element.userId._id.toString())
  {
    userId=user._id;
    friendId=userFav[0]._id
  }
});
  let data={
    friendId:friendId,
    userId:userId
  }
  dispatch(friendsActions.UnfriendRequest(data))
}
const onCancelRequest=()=>{
  let senderId =''
   let receiverId='';
  for(const frnd of friendrequests)
  {
   if(frnd.sender._id.toString()===userFav[0]._id.toString() && frnd.receiver._id.toString()===user._id.toString())
   {
    senderId=userFav[0]._id;
    receiverId=user._id
   }
   if(frnd.receiver._id.toString()===userFav[0]._id.toString()&& frnd.sender._id.toString()===user._id.toString())
   {
    senderId=user._id;
    receiverId=userFav[0]._id
   }
  }
  let data={
    senderId:senderId,
    receiverId:receiverId
  }
  dispatch(friendsActions.CancelFriendRequest(data))
  setRequestedUser(false)
}
  return (
    <div>
      {

        !currentId?
        <div>
        <div className="story-card-user-container">
              <div className="avatar-container-avatar-card">
                <Avatar
                  name={user.firstName+" "+user.lastName}
                  size="lg"
                  className="question-avatar"
                  src={config.s3BucketUrl+user.image}
                ></Avatar>
              </div>
              <div className="user-avatar-container-avatar-card" onClick={()=>{history.push(`/${user.userName}`)}}>
                <p className="avatar-card-userName-container">
                <small className="story-card-desc-txt avatar-card-userName">{user.firstName+" "+user.lastName}</small>
                </p>
                <p className=" avatar-card-user-desc-container">
                <small className="story-card-timespan-txt avatar-card-user-desc">
                  user Desc
                </small>
                </p>
              </div>
            </div>
            {
              isFriends?
              <div className="avatar-card-btn-container">
              <Button type="primary" onClick={onUnfriend}>Un Friend</Button>
          </div>:
              requestedUser?
               <div className="avatar-card-btn-container">
              <Button type="primary" onClick={onCancelRequest}>Cancel Request</Button>
          </div>
          : acceptedUser?
          <div className="avatar-card-btn-container">
          <Button type="primary" onClick={()=>onRespondClick(user._id)}>Respond Request</Button>
      </div>:
       <div className="avatar-card-btn-container">
      <Button type="primary" onClick={()=>onFriendRequestSend(user._id)}>Add Friend</Button>
       </div>
            }
            </div>:null
      }

    </div>
  )
}
