import React, { useState, useEffect } from 'react';
import { Steps, Button, Form, Input,message } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import FacebookLogin from 'react-facebook-login';
import { history } from '../../Helpers';
import { connect } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import './signup.css'
import NavBar from '../Navbar/Navbar';
import ReactGA from 'react-ga';
import { userActions } from '../../actions/users.action';


const { Step } = Steps;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};
const tailLayout = {
  wrapperCol: { span: 16 },
};

type SignUp = {
  dispatch: any;

}

function SignUp(props: SignUp) {
  let playerId = JSON.parse(localStorage.getItem('playerId')!)
  const { dispatch } = props
  const [current, setCurrent] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    let user = JSON.parse(localStorage.getItem('user')!)
    if (user) {
      history.push('/')
    }
  })

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }
  const onFinish = (values: any) => {

    let socialClick = false;
    let sendAdmin = false;
    let isFromMobile=false;

    dispatch(userActions.register(firstName, lastName, values.email, values.password, socialClick, sendAdmin,isFromMobile,playerId));
    const [form] = Form.useForm();
    form.resetFields();
  };


  const onFinishFailed = (errorInfo: any) => {
  };
  const responseFacebook = (response: any) => {
    if(response.status === 'unknown')
    {
     message.error('something went wrong can you please try again later')
    }
    else{
      let player:any="";
      if(playerId!==null)
      {
         player =playerId
      }
     let name=response.name.split(' ');
     let Data ={
       image:response.picture.data.url,
       firstName:name[0],
       lastName:name[1],
       email:response.email,
       fbId:response.id,
       isFacebook:true,
       playerId:player
     }
 dispatch(userActions.SocialLogin(Data));
   }
   }
  const handleChangeFirst = (e: any) => {
    setFirstName(e.target.value);

  }
  const handleChangeLast = (e: any) => {
    setLastName(e.target.value);
  }
  const onChange = (current: any) => {
    setCurrent(current);
  };
  const responseGoogle = (response: any) => {
  }
  const steps = [
    {
      title: 'First',
      content: '',
    },
    {
      title: 'Last',
      content: '',
    },

  ];

  const onLoginClick =() =>{
    history.push('/login')
  }

  const enabled = (firstName.length >= 3 && firstName.match("^[a-zA-Z]*$")) && (lastName.length >= 3 && lastName.match("^[a-zA-Z]*$"));
  return (
    <div>
      <div className="top-container-signup">
        <NavBar />
      </div>

      <div className="main-container">
        <div className="signup-container">
          <div className="signup-header-container">
            <div className="back-arrow">
              {current > 0 && (
                <div className="pre-container" onClick={() => prev()}>
                  <LeftOutlined />
                </div>
              )}
            </div >
            <div className="signup-heading-container">
              <p className="register-txt">
                Register
                    </p>
            </div>
          </div>
          <br></br>
          <div className='form-container-sign'>
            <div className="register-form">
              <div>
                <p className="signup-crte-acc-txt">
                  Create an account
                            </p>
                <p className="signup-crte-acc-desc">
                  Enter personal information to finish up
                            </p>
              </div>
              <Steps current={current} onChange={onChange} className="curr-step">
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <div className="firstStep steps-content">
                <Form
                  {...layout}

                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}

                >
                  {current === 0 ?
                    <div>
                      <Form.Item className="lbl-name" label="First Name"></Form.Item>
                      <Form.Item
                        name="firstName"
                        rules={[{ required: true, message: 'Please enter your first name.' },
                        {
                          max: 24,
                          message: "First name must be maximum 24 characters."
                        },
                        {
                          pattern: new RegExp(/^[A-Z]*$/i),
                          message: "First name can only have alphabets."
                        },
                        {
                          min: 3,
                          message: "First name must be minimum 3 characters."
                        }
                        ]}
                      >
                        <Input name="firstName" placeholder="First Name" onChange={handleChangeFirst} size="large" value={firstName} />
                      </Form.Item>

                      <Form.Item className="lbl-name" label="Last Name"></Form.Item>
                      <Form.Item
                        name="lastName"
                        rules={[{ required: true, message: 'Please enter your last name.' },
                        {
                          max: 24,
                          message: "Last name must be maximum 24 characters."
                        },
                        {
                          pattern: new RegExp(/^[A-Z]*$/i),
                          message: "First name can only have alphabets."
                        },
                        {
                          min: 3,
                          message: "Last name must be minimum 3 characters."
                        }
                        ]}
                      >
                        <Input name="lastName" placeholder="Last Name" size="large" onChange={handleChangeLast} value={lastName} />
                      </Form.Item>
                    </div> :
                    <div>
                      <Form.Item className="lbl-name" label="Email address"></Form.Item>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            type: 'email',
                            message: "Please enter a valid email address.",
                          },
                          { required: true, message: 'Please enter your email.' }]}
                      >
                        <Input placeholder="Email address" size="large" />
                      </Form.Item>
                      <Form.Item className="lbl-name" label="Password"></Form.Item>
                      <Form.Item

                        name="password"
                        rules={[{ required: true, message: 'Please enter your password.' }, {
                          min: 8,
                          message: 'password must be minimum 8 characters.'
                        }
                        ]}
                      >
                        <Input.Password placeholder="Password" size="large" />
                      </Form.Item>
                    </div>

                  }
                  <Form.Item {...tailLayout}>
                    {current < steps.length - 1 && (
                      <Button type="primary" disabled={!enabled} className="btn-nxt" size="large" onClick={() => next()}>
                        Next
                      </Button>
                    )}
                    {current === steps.length - 1 && (
                      <Button className="btn-nxt" size="large" type="primary" htmlType="submit">
                        Register
                      </Button>
                    )}

                  </Form.Item>
                </Form>

              </div>
              <div className="steps-action">

              </div>
            </div>
            <div className="signup-social-container">
              <div className="social-container">
                <p className="conect-social-txt">
                  OR connect your social account to register
                            </p>
                            <FacebookLogin
                  appId="204489870992125"
                  autoLoad={false}
                  icon="fa fa-facebook"
                  fields="name,email,picture"
                  callback={responseFacebook}
                  textButton="Continue with facebook"
                />
                <GoogleLogin
                  clientId="325773595734-ccdkl7p7a6hi8li7icbgmm7ucgtum4ej.apps.googleusercontent.com"
                  buttonText="Continue with google"
                  className="google-social-btn"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                />

              </div>
            </div>
          </div>
          <div className="trm-txt-sign">
            <p className="txt-trm">
              Note: By registering, you are agree to our <a href="https://pakrism.pk/privacy-policy/" className="privacy-link">Privacy Policy</a> and <a href="https://pakrism.pk/terms-of-service/" className="privacy-link">Terms of use.</a>
            </p>
          </div>
        </div>
        <div className="sign-footer">
          <p className="acc-txt">
            Already have an account?
                    </p>
          <p className="lgin-btn-container">
            <Button type="default" className="btn-lgin" onClick={onLoginClick} size="large">
              Log In
            </Button>
          </p>
        </div>
      </div>
    </div>
  )
}
function mapStateToProps(state: any) {
  const { registering } = state.registration;
  const { type, message } = state.alert;

  return {
    type,
    message,
    registering
  };
}
const connectedRegisterPage = connect(mapStateToProps)(SignUp);

export default connectedRegisterPage;
