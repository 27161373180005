import { albumCategoryConstants } from "../constants";
import { albumCategoryService } from "../services";
import { history, store } from "../Helpers";
import { alertActions } from ".";


export const albumCategoryActions = {
  FetchAllAlbumCategories,
  AddAlbumCategories,
  FetchEditAlbumCategories,
  UpdateAlbumCategory,
  DeleteAlbumCategory,
  // FetchAllPlaceCategories,
  // AddPlaceCategories,
  // FetchEditPlaceCategories,
  // UpdatePlaceCategory,
  // DeletePlace,
  // FetchPakMapCategories
};

function FetchAllAlbumCategories() {
  return (dispatch: any) => {
    dispatch(request());
    albumCategoryService.FetchAllAlbumCategories().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: albumCategoryConstants.GET_ALL_ALBUM_CATEGORY_REQUEST };
  }
  function success(user: any) {
    return {
      type: albumCategoryConstants.GET_ALL_ALBUM_CATEGORY_SUCCESS,
      user,
    };
  }
  function failure(error: any) {
    return {
      type: albumCategoryConstants.GET_ALL_ALBUM_CATEGORY_FAILURE,
      error,
    };
  }
}

function AddAlbumCategories(data: any) {
  return (dispatch: any) => {
    dispatch(request(data));
    albumCategoryService
      .AddAlbumCategory(data)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "201") {
          dispatch(success(data));
          dispatch(
            alertActions.success("You have successfully add a category ")
          );
          history.push("/pr-admin-cp/album-categories");
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: albumCategoryConstants.ADD_ALBUM_CATEGORY_REQUEST, data };
    }
    function success(user: any) {
      return { type: albumCategoryConstants.ADD_ALBUM_CATEGORY_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: albumCategoryConstants.ADD_ALBUM_CATEGORY_FAILURE, error };
    }
  };
}

function FetchEditAlbumCategories(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    albumCategoryService.GetEditAlbumCategory(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: albumCategoryConstants.EDIT_ALBUM_CATEGORY_REQUEST, id };
  }
  function success(user: any) {
    return { type: albumCategoryConstants.EDIT_ALBUM_CATEGORY_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: albumCategoryConstants.EDIT_ALBUM_CATEGORY_FAILURE, error };
  }
}

function UpdateAlbumCategory(data: any, id: any) {
  return (dispatch: any) => {
    dispatch(request(data, id));
    albumCategoryService
      .UpdateAlbumCategory(data, id)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "200") {
          dispatch(success(data));
          dispatch(
            alertActions.success("You have successfully update a category ")
          );
          history.push("/pr-admin-cp/album-categories");
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any, id: any) {
      return {
        type: albumCategoryConstants.UPDATE_ALBUM_CATEGORY_REQUEST,
        data,
        id,
      };
    }
    function success(user: any) {
      return {
        type: albumCategoryConstants.UPDATE_ALBUM_CATEGORY_SUCCESS,
        user,
      };
    }
    function failure(error: any) {
      return {
        type: albumCategoryConstants.DELETE_ALBUM_CATEGORY_SUCCESS,
        error,
      };
    }
  };
}

function DeleteAlbumCategory(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    albumCategoryService
      .DeleteCategory(id)
      .then((response) => response)
      .then((res: any) => {
        if (res.status == "200") {
          dispatch(success(res));
          dispatch(alertActions.success(res));
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });

    function request(id: any) {
      return { type: albumCategoryConstants.DELETE_ALBUM_CATEGORY_REQUEST, id };
    }
    function success(user: any) {
      return {
        type: albumCategoryConstants.DELETE_ALBUM_CATEGORY_SUCCESS,
        user,
      };
    }
    function failure(error: any) {
      return {
        type: albumCategoryConstants.DELETE_ALBUM_CATEGORY_FAILURE,
        error,
      };
    }
  };
}
