import React, { useEffect, useState } from 'react';
import {history} from '../../Helpers'
import { Avatar} from "@chakra-ui/core";

export function FriendCard(props:any) {
  const {data} = props;

  useEffect(()=>{


  })

   return (
    <div style={{cursor:'pointer'}} onClick={()=>history.push(`/tourist/${data.user.userName}/about`)}>
        <div className="user-avatar">
            <Avatar
            onClick={()=>window.open(data.user.image)}
              size="xl"
              name={data.user.firstName + data.user.lastName}
              src={data.user.image}
            />
          </div>
          <div className="user-name-container">
            <p className="user-name-txt friend-card-user-txt" >
            {data.user.firstName+" "+ data.user.lastName}
            </p>
          </div>
    </div>
  )
}
