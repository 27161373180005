import React,{useEffect} from 'react';
import { Button, Form, Input, } from 'antd';
import { userActions } from '../../actions';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';


const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};
const tailLayout = {
  wrapperCol: { span: 16 },
};

type ForgetPassword = {
  dispatch: any;
}


function ForgetPassword(props:ForgetPassword) {
  const {dispatch} = props;
  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  },[])
  const onFinish = (values: any) => {
    let isFromMobile=false;
    dispatch(userActions.ForgetPassword(values.email,isFromMobile));
  };
  const onFinishFailed = (errorInfo: any) => {
  };
  return (
    <div>
      <div className="top-container-signup">
      </div>
      <div className="main-container">
        <div className="signup-container">
          <div className="signup-header-container">
            <p className="login-txt">
              FORGET PASSWORD
            </p>
          </div>
          <br></br>
          <div className='form-container-sign'>
            <div className="register-form">
              <div>
                <p className="signup-crte-acc-txt">
                  Forgoten your password?
                      </p>
                <p className="signup-crte-acc-desc">
                  Enter your email you used while creating an account.
                </p>
              </div>
              <div className="forget-form-container steps-content">
                <Form
                  {...layout}

                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item className="lbl-name" label="Email address"></Form.Item>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: "Please enter a valid email address.",
                      },
                      { required: true, message: 'Please enter your email.' }]}
                  >
                    <Input placeholder="Email address" size="large" />
                  </Form.Item>
                  <Form.Item {...tailLayout}>
                    <Button className="btn-nxt reset-pass-btn" size="large" type="primary" htmlType="submit">
                      Reset password
                  </Button>

                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message
  };
}

export const connectedForgetPassword = connect(mapStateToProps)(ForgetPassword);



