import React, { useEffect } from 'react';
import Image from '../../Assets/404_image.png';
import {history} from '../../Helpers';
import ReactGA from 'react-ga';
import {Button} from 'antd'
import './error.css'

export function ErrorComponent() {
  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  },[])
  return (
    <div>

    <div className="error-container">
      <div className="error-heading-container">
      <p className="erro-upper-txt">OOPS, LOOK LIKE YOU'RE LOST</p>
      <p className="error-heading-txt">404</p>
      <p className="erro-upper-txt">PAGE NOT FOUND</p>
      <p className="not-fond-btn">
        <Button className="not-fond-btn" type="primary" onClick={()=>{history.push('/')}}>Go To Home</Button>
      </p>
      </div>
      <img className="error-img"  src={Image} alt={Image}/>
    </div>
    </div>
  )
}
