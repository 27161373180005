import React, { useState, useEffect } from 'react';
import { Table, Input,Button,Modal} from 'antd';
import { history } from '../../../Helpers';
import { SupportActions } from '../../../actions';
import { connect } from 'react-redux';
import 'react-quill/dist/quill.snow.css';
import './support.css'



type Report = {
  dispatch: any;
  data: any;
}

function Report(props: Report) {
  const { dispatch, data } = props;

  const [checkSearch, setcheckSearch] = useState(false);
  const [filterData, setfilterData] = useState();

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {
      dispatch(SupportActions.FetchAllReportForms());

    }

  }, []);

  const [selectedRowKeys, setselectedRowKeys] = useState([]);


  const onSelectChange = (selectedRowKeys: any) => {
    setselectedRowKeys(selectedRowKeys)

  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  if (!data) {
    return (<div></div>)
  }
  let filterArray: any = [];
  let search = (value: any) => {

    const filterArrayData = data.filter((o: any) =>
      Object.keys(o).some(k =>
        String(o[k])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );
    filterArrayData.forEach((element: any) => {

      filterArray.push({
        key: element._id,
        id: element._id,
        firstName: element.firstName,
        lastName: element.lastName,
        email: element.email,
        phone: element.phone,
        category: element.category,
        message: element.message,
        createdDate: element.createdDate.split("T")[0]
      })

    });
    setfilterData(filterArray);
    setcheckSearch(true);
  };



  const categoriesArray: any = [];
  data.forEach((element: any) => {
    categoriesArray.push({
      key: element._id,
      id: element._id,
      firstName: element.firstName,
      lastName: element.lastName,
      email: element.email,
      category: element.category,
      phone: element.phone,
      message: element.message,
      createdDate: element.createdDate.split("T")[0]
    })

  });
  const onViewClick = (data:any)=>{
    Modal.warn({
      title: 'Message',
      content: (
        <div className="warning-modal">
          <p>{data}</p>
        </div>
      ),
      className: "cancel-modal",
      width: 560,
      okType: 'primary',
      okText: 'ok',
      okCancel: true,
      centered: true,
      cancelText: 'cancel',
      onOk: () => {

      },
      onCancel() {
      },
    });
  }
  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Category',
      dataIndex: 'category',
    },
    {
      title: 'Sent  Date',
      dataIndex: 'createdDate',
    },
    {
      title: 'Message',
      render: (records: any) => <Button size="large" type="primary" onClick={()=>{onViewClick(records.message)}}>View</Button>,
    },
  ];

  return (
    <div>
      <div style={{ width: '50%', float: 'left' }}>
        <Input.Search
          placeholder="Search by..."
          enterButton
          size="large"
          onSearch={search}
        />
      </div>
      {
        checkSearch ? <Table className="show-table" rowSelection={rowSelection} columns={columns} dataSource={filterData} /> :
          <Table className="show-table" rowSelection={rowSelection} columns={columns} dataSource={categoriesArray} />

      }
    </div>
  );

}

function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    data: state.FetchReportForm.fetchReportObject
  };
}

export const connectedReport= connect(mapStateToProps)(Report);

