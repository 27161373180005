import React, { useState, useEffect } from 'react';
import { Table, Select,Dropdown, Menu, Button, Modal, Input, Form, Avatar, Upload, message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CloudUploadOutlined } from "@ant-design/icons";
import { history } from '../../../Helpers';
import { blogCategoriesAction } from '../../../actions';
import { connect } from 'react-redux';
import { config } from "../../../config";
import { SettingOutlined } from '@ant-design/icons';




const { Option } = Select;

function beforeUpload(file: any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const { TextArea } = Input;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};



type User = {
  dispatch: any;
  data: any;
}

const { Dragger } = Upload;

const prop: any = {
  name: 'userImage',
  action: '',
  headers: {
    authorization: 'authorization-text',
  },
};


const onPreview = async (file: any) => {
  let src = file.url;
  if (!src) {
    src = await new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  }
  const image = new Image();
  image.src = src;
  const imgWindow: any = window.open(src);
  imgWindow.document.write(image.outerHTML);
};

function BlogCategories(props: User) {
  let formRef:any = React.createRef<FormInstance>();
  let fetchId: any = '';
  const [form] = Form.useForm();
  const { dispatch, data } = props;
  const [visible, setvisible] = useState(false);
  const [checkSearch, setcheckSearch] = useState(false);
  const [filterData, setfilterData] = useState();
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {
      dispatch(blogCategoriesAction.FetchAllBlogCategories());

    }

  }, [dispatch]);


  const [selectedRowKeys, setselectedRowKeys] = useState([]);


  const onSelectChange = (selectedRowKeys: any) => {
    setselectedRowKeys(selectedRowKeys)

  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  if (!data) {
    return (<div></div>)
  }
  console.log(data);
  let filterArray: any = [];
  let search = (value: any) => {

    const filterArrayData = data.filter((o: any) =>
      Object.keys(o).some(k =>
        String(o[k])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );
    filterArrayData.forEach((element: any, index: any) => {

      if (element.categoryImage) {
        imagee = config.s3BucketUrl+ element.categoryImage
      }
      else {
        imagee = "N/A"
      }
        filterArray.push({
        key: element._id,
        id: element._id,
        Photo: imagee,
        categoryName: element.categoryName,
        createdDate: element.createdDate.split("T")[0]
      })

    });
    setfilterData(filterArray);
    setcheckSearch(true);
  };



  const categoriesArray: any = [];
  let imagee: any = '';
  if(data)
  {
    data.forEach((element: any, index: any) => {

      if (element.categoryImage) {
        imagee = config.s3BucketUrl+ element.categoryImage
      }
      else {
        imagee = "N/A"
      }
      categoriesArray.push({
        key: element._id,
        id: element._id,
        Photo: imagee,
        categoryName: element.categoryName,
        createdDate: element.createdDate.split("T")[0]
      })

    });
  }


  const showModal = () => {
    setvisible(true);
  };

  const handleOk = (e: any) => {
    form.resetFields();
    setvisible(false);

  };

  const handleCancel = (e: any) => {
    form.resetFields();
    setvisible(false);
  };

  const onFinish = (values: any) => {
    console.log(values)
    const data = new FormData()
    data.append("categoryName", values.categoryName)
    data.append("description", values.description)
    data.append("categoryImage", values.userImage.file.originFileObj)
    if(values.parentId)
    {
      data.append("parentId", values.parentId)
    }
    dispatch(blogCategoriesAction.AddBlogCategory(data));
    formRef.current.resetFields()
  };

  const onFinishFailed = (errorInfo: any) => {
  };
  const onMenuClick = (id: any) => {
    fetchId = id;
  }
  const onImageClick =(image:any) =>{
         window.open(image);
  }
  const onSelect = (e: any) => {
    if (e.key == "EU") {
      history.push('/pr-admin-cp/edit-categories/' + fetchId);
    }
    else {
      Modal.warning({
        title: 'Opppssss',

        content: (
          <div className="warning-modal">
            <p>Are you sure you want to delete a category?</p>
          </div>
        ),
        width: 560,
        okType: 'danger',
        okText: 'ok',
        okCancel: true,
        centered: true,
        cancelText: 'cancel',
        onOk: () => {
          // dispatch(categoryActions.DeleteCategory(fetchId));
          // dispatch(categoryActions.FetchAllCategories());
        },
        onCancel() {
        },
      });

    }

  }

  const columns = [
    {
      title: 'Photo',
      dataIndex: 'Photo',
      render: (records: any) =><div onClick={()=>onImageClick(records)}><Avatar className="table-img"  src={records}></Avatar></div>
    },
    {
      title: 'Category Name',
      dataIndex: 'categoryName',
    },
    {
      title: 'Created  Date',
      dataIndex: 'createdDate',
    },
    {
      title: 'Settings',
      render: (records: any) => <Dropdown className="admin-acc" overlay={(
        <Menu onClick={onSelect}>
          <Menu.Item key="EU" onClick={() => onMenuClick(records.id)} className="user-menu-item">
            <span>
              Edit Category
          </span>
          </Menu.Item>
          <Menu.Item key="DU" onClick={() => onMenuClick(records.id)} className="user-menu-item">
            <span>
              Delete Category
          </span>
          </Menu.Item>
        </Menu>
      )} trigger={['click']} >
        <SettingOutlined />
      </Dropdown>,
    },
  ];

  return (
    <div>
      <div style={{ width: '50%', float: 'left' }}>
        <Input.Search

          placeholder="Search by..."
          enterButton
          size="large"
          onSearch={search}
        />
      </div>

      <div style={{ marginBottom: "20px", textAlign: "right" }}>
        <Button type="primary" onClick={showModal} >Add Blog Category</Button>
      </div>
      {
        checkSearch ? <Table className="show-table" rowSelection={rowSelection} columns={columns} dataSource={filterData} /> :
          <Table className="show-table" rowSelection={rowSelection} columns={columns} dataSource={categoriesArray} />

      }


      <Modal
        title="Add a Blog Category"
        visible={visible}
        onOk={handleOk}
        width="768px"
        onCancel={handleCancel}
      >
        <div className=''>
          <Form
            {...layout}
            ref={formRef}
            className="admin-login-form"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item className="lbl-name" label="Category Name"></Form.Item>
            <Form.Item
              name="categoryName"
              rules={[{ required: true, message: 'Please enter a category name.' },]}
            >
              <Input name="categoryName" size="large" placeholder="Category Name" />
            </Form.Item>
            <Form.Item className="lbl-name" label="Parent Category "></Form.Item>
        <Form.Item
          hasFeedback
          name="parentId"
          initialValue={data._id}
        >
          <Select defaultValue={data._id} placeholder="select a category" size="large" >

            {
              data.length > 0 ?
                data.map((item: any) => <Option selected="selected" value={item._id} >
                  {item.categoryName}
                </Option >)
                :null

            }
          </Select>
        </Form.Item>
            <Form.Item className="lbl-name" label="Description"></Form.Item>
            <Form.Item
              name="description"
            >
              <TextArea name="lastName" placeholder="Description" rows={4}
              />
            </Form.Item>
            <Form.Item className="lbl-name" label="Photo"></Form.Item>
            <Form.Item
              name="userImage"
              className="file-upload">
              <Dragger beforeUpload={beforeUpload}
                onPreview={onPreview}
                multiple={false}
                {...prop}
                listType="picture-card">

                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined style={{ color: "#000000" }} />
                  <span className="upld-desc" > Upload or drop photo here</span>
                </p>
              </Dragger>
            </Form.Item>
            <Form.Item >
              <Button className="btn-admin-login" size="large" type="primary" htmlType="submit">
                Add Blog Category
                    </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );

}

function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    data: state.FetchBlogCategories.getAllBlogCategoriesObject
  };
}

export const connectedBlogCategories = connect(mapStateToProps)(BlogCategories);

