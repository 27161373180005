import React,{useEffect,useState} from 'react'
import NavBar from '../Navbar/Navbar'
import {questionActions} from '../../actions/';
import {PlaceQuestions} from '../Places';
import {RightFooter} from '../FooterSection';
import {AdvertiseCard} from '../Cards';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import {Modal} from 'antd';


 const AllQuestions = (props: any) => {
  const {dispatch,match,data} = props;
  const [visible, setVisible] = useState(false);
  const [dataImage, setDataImage] = useState("");
  useEffect(()=>{
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    dispatch(questionActions.FetchAllPlaceQuestion(match.params.placeId))
  },[match])

  const handleCancel = (e: any) => {
    setVisible(false)
  };
  const showModal = (image:any) => {
    setDataImage(image)
    setVisible(true);
  }
  if(!data)
  {
   return(<div></div>)
  }
  return (
    <div className="single-question-main-container">
    <NavBar/>
    <div className="single-question-inner-container">
      <div className="single-question-left-container">
  <div className="single-question-all-answers-container-main">
  <div className="answe-card-container">
  {
    data?
    data.map((item:any,i:any)=>
    <div>
        <PlaceQuestions data={item}/>
    </div>
    ):null
   }

  </div>
  </div>
  </div>
  <div className="all-question-right-container">
    <div>
    <AdvertiseCard/>
    </div>
    <div className="copyright-footer-container">
    <RightFooter/>
    </div>
  </div>
  </div>
      <Modal
        title="Place Photos"
        width="65%"
        closable={true}
        visible={visible}
        onCancel={handleCancel}
        footer={false}
      >
        <div className="modal-form">
        <img className="place-img" style={{minHeight:"300px"}} src={dataImage}/>
        </div>
      </Modal>
  </div>
  );
};

function mapStateToProps(state: any) {
  return {
    data: state.FetchAllQuestions.getALLQuestionsObject,
  };
}
export const connectedAllQuestions = connect(mapStateToProps)(AllQuestions);
