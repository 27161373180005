export const userConstants = {
  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_REQUEST_SUCCESS: 'SIGNUP_REQUEST_SUCCESS',
  SIGNUP_REQUEST_FAILURE: 'SIGNUP_REQUEST_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  LOGOUT: 'USERS_LOGOUT',

  FETCH_ALL_USER_ADMIN_REQUEST: 'FETCH_ALL_USER_ADMIN_REQUEST',
  FETCH_ALL_USER_ADMIN_SUCCESS: 'FETCH_ALL_USER_ADMIN_SUCCESS',
  FETCH_ALL_USER_ADMIN_FAILURE: 'FETCH_ALL_USER_ADMIN_FAILURE',


  CURRENT_USER_REQUEST: 'CURRENT_USER_REQUEST',
  CURRENT_USER_SUCCESS: 'CURRENT_USER_SUCCESS',
  CURRENT_USER_FAILURE: 'CURRENT_USER_FAILURE',

  GET_ALL_USER_REQUEST: 'ALL_USER_REQUEST',
  GET_ALL_USER_SUCCESS: 'ALL_USER_SUCCESS',
  GET_ALL_USER_FAILURE: 'ALL_USER_FAILURE',

  EDIT_CURRENT_USER_REQUEST: 'EDIT_CURRENT_USER_REQUEST',
  EDIT_CURRENT_USER_SUCCESS: 'EDIT_CURRENT_USER_SUCCESS',
  EDIT_CURRENT_USER_FAILURE: 'EDIT_CURRENT_USER_FAILURE',

  UPDATE_CURRENT_USER_REQUEST: 'UPDATE__CURRENT_USER_REQUEST',
  UPDATE_CURRENT_USER_SUCCESS: 'UPDATE__CURRENT_USER_SUCCESS',
  UPDATE_CURRENT_USER_FAILURE: 'UPDATE__CURRENT_USER_FAILURE',


  DELETE_USER_REQUEST: 'DELETE_CURRENT_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_CURRENT_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_CURRENT_USER_FAILURE',

  FORGET_PASSWORD_REQUEST: 'FORGET_PASSWORD_REQUEST',
  FORGET_PASSWORD_SUCCESS: 'FORGET_PASSWORD_SUCCESS',
  FORGET_PASSWORD_FAILURE: 'FORGET_PASSWORD_FAILURE',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',


  VERIFY_USER_REQUEST: 'VERIFY_USER_REQUEST',
  VERIFY_USER_SUCCESS: 'VERIFY_USER_SUCCESS',
  VERIFY_CURRENT_USER_FAILURE: 'VERIFY_CURRENT_USER_FAILURE',

  GET_USER_PROFILE: 'GET_USER_PROFILE',
  GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
  GET_USER_PROFILE_FAILURE: 'GET_USER_PROFILE_FAILURE',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

  ADD_FAV_PLACE_REQUEST: 'ADD_FAV_PLACE_REQUEST',
  ADD_FAV_PLACE_REQUEST_SUCCESS: 'ADD_FAV_PLACE_REQUEST_SUCCESS',
  ADD_FAV_PLACE_REQUEST_FAILURE: 'ADD_FAV_PLACE_REQUEST_FAILURE',

  REMOVE_FAV_PLACE_REQUEST: 'REMOVE_FAV_PLACE_REQUEST',
  REMOVE_FAV_PLACE_REQUEST_SUCCESS: 'REMOVE_FAV_PLACE_REQUEST_SUCCESS',
  REMOVE_FAV_PLACE_REQUEST_FAILURE: 'REMOVE_FAV_PLACE_REQUEST_FAILURE',

  ADD_VISTED_PLACE_REQUEST: 'ADD_VISTED_PLACE_REQUEST',
  ADD_VISTED_PLACE_REQUEST_SUCCESS: 'ADD_VISTED_PLACE_REQUEST_SUCCESS',
  ADD_VISTED_PLACE_REQUEST_FAILURE: 'ADD_VISTED_PLACE_REQUEST_FAILURE',

  REMOVE_VISTED_PLACE_REQUEST: 'REMOVE_VISTED_PLACE_REQUEST',
  REMOVE_VISTED_PLACE_REQUEST_SUCCESS: 'REMOVE_VISTED_PLACE_REQUEST_SUCCESS',
  REMOVE_VISTED_PLACE_REQUEST_FAILURE: 'REMOVE_VISTED_PLACE_REQUEST_FAILURE',

  GET_FAV_PLACE_REQUEST: 'GET_FAV_PLACE_REQUEST',
  GET_FAV_PLACE_REQUEST_SUCCESS: 'GET_FAV_PLACE_REQUEST_SUCCESS',
  GET_FAV_PLACE_REQUEST_FAILURE: 'GET_FAV_PLACE_REQUEST_FAILURE',

  GET_VISTED_PLACE_REQUEST: 'GET_VISTED_PLACE_REQUEST',
  GET_VISTED_PLACE_REQUEST_SUCCESS: 'GET_VISTED_PLACE_REQUEST_SUCCESS',
  GET_VISTED_PLACE_REQUEST_FAILURE: 'GET_VISTED_PLACE_REQUEST_FAILURE',

  GET_TOP_USER_REQUEST: 'GET_TOP_USER_REQUEST',
  GET_TOP_USER_SUCCESS: 'GET_TOP_USER_SUCCESS',
  GET_TOP_USER_FAILURE: 'GET_TOP_USER_FAILURE',

  SOCIAL_SIGNUP_REQUEST: 'SOCIAL_SIGNUP_REQUEST',
  SOCIAL_SIGNUP_REQUEST_SUCCESS: 'SOCIAL_SIGNUP_REQUEST_SUCCESS',
  SOCIAL_SIGNUP_REQUEST_FAILURE: 'SOCIAL_SIGNUP_REQUEST_FAILURE',

  GOOGLE_SIGNUP_REQUEST: 'GOOGLE_SIGNUP_REQUEST',
  GOOGLE_SIGNUP_REQUEST_SUCCESS: 'GOOGLE_SIGNUP_REQUEST_SUCCESS',
  GOOGLE_SIGNUP_REQUEST_FAILURE: 'GOOGLE_SIGNUP_REQUEST_FAILURE',

  ACTIVATE_USER_REQUEST: 'ACTIVATE_USER_REQUEST',
  ACTIVATE_USER_SUCCESS: 'ACTIVATE_USER_SUCCESS',
  ACTIVATE_USER_FAILURE: 'ACTIVATE_USER_FAILURE',

  FEATURED_USER_REQUEST: 'FEATURED_USER_REQUEST',
  FEATURED_USER_SUCCESS: 'FEATURED_USER_SUCCESS',
  FEATURED_USER_FAILURE: 'FEATURED_USER_FAILURE',

  GET_CHECKED_USER_REQUEST: 'GET_CHECKED_USER_REQUEST',
  GET_CHECKED_USER_REQUEST_SUCCESS: 'GET_CHECKED_USER_REQUEST_SUCCESS',
  GET_CHECKED_USER_REQUEST_FAILURE: 'GET_CHECKED_USER_REQUEST_FAILURE',

  RESENT_EMAIL_REQUEST: 'RESENT_EMAIL_REQUEST',
  RESENT_EMAIL_REQUEST_SUCCESS: 'RESENT_EMAIL_REQUEST_SUCCESS',
  RESENT_EMAIL_REQUEST_FAILURE: 'RESENT_EMAIL_REQUEST_FAILURE',

  FETCH_USERS_COUNT_ADMIN_REQUEST: 'FETCH_USERS_COUNT_ADMIN_REQUEST',
  FETCH_USERS_COUNT_ADMIN_SUCCESS: 'FETCH_USERS_COUNT_ADMIN_SUCCESS',
  FETCH_USERS_COUNT_ADMIN_FAILURE: 'FETCH_USERS_COUNT_ADMIN_FAILURE',

  FETCH_USERS_COUNT_MONTH_WISE_ADMIN_REQUEST: 'FETCH_USERS_COUNT_MONTH_WISE_ADMIN_REQUEST',
  FETCH_USERS_COUNT_MONTH_WISE_ADMIN_SUCCESS: 'FETCH_USERS_COUNT_MONTH_WISE_ADMIN_SUCCESS',
  FETCH_USERS_COUNT_MONTH_WISE_ADMIN_FAILURE: 'FETCH_USERS_COUNT_MONTH_WISE_ADMIN_FAILURE',
  }

