import React, { useState, useEffect } from 'react';
import { Table, Dropdown, Menu, Badge, Button, Modal, Input, Form, Avatar,Switch } from 'antd';
import { history } from '../../../Helpers';
import { userActions } from '../../../actions';
import { connect } from 'react-redux';
import './users.css'
import { SettingOutlined,CloseOutlined, CheckOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

const { confirm } = Modal;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};



type User = {
  dispatch: any;
  data: any;
}



function User(props: User) {
  let fetchId: any = '';
  let playerId = JSON.parse(localStorage.getItem('playerId')!)
  const [form] = Form.useForm();
  const { dispatch, data } = props;
  const [visible, setvisible] = useState(false);
  const [page,setPage]=useState(1);
  const [querySearch,setQuerySearch]=useState("null");
  const [pageLimit,setPageLimit]=useState(10);


  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {

      dispatch(userActions.fetchAllUsersAdmin(page,querySearch,pageLimit));
    }

  }, [dispatch]);



  const [selectedRowKeys, setselectedRowKeys] = useState([]);


  const onSelectChange = (selectedRowKeys: any) => {
    setselectedRowKeys(selectedRowKeys)

  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  if (!data) {
    return (<div></div>)
  }
  console.log({data})
  const format = "YYYY-MM-DD HH:mm"

  let filterArray: any = [];
  let userStatus: any = '';
  let image: any = '';
  let device:any='N/A';
  let brand:any='N/A';
  let modal:any='N/A';
  let os :any='N/A';
  let version:any='N/A';
  let plateform:any='N/A';
  let isFrom:any='N/A';
  let search = (value: any) => {
    if(value)
    {
     dispatch(userActions.fetchAllUsersAdmin(page,value,pageLimit));
     setQuerySearch(value)
    }
    else
    {
      dispatch(userActions.fetchAllUsersAdmin(page,"null",pageLimit));
      setQuerySearch("null")
    }

  };
  const usersArray: any = [];
  let lastActivity: any = '';

  let isDeleted: any = '';
  data.docs.forEach((element: any, index: any) => {
    if (element.users.image) {

      image =element.users.image
    }
    else {
      image = "N/A"
    }
    if (element.users.isActive) {
      userStatus = <Badge status="success" text="Verified" />

    }
    else {
      userStatus = <Badge status="default" text="Unverified" />
    }
    if (!element.users.isDeleted) {
      isDeleted = <Badge status="success" text="Activated" />

    }
    else {
      isDeleted = <Badge status="default" text="Deactivated" />
    }
    if (element.users.isGoogle) {
      isFrom = <Badge status="success" text="Google" />

    }
    else if(element.users.isFacebook) {
      isFrom = <Badge status="success" text="Facebook" />
    }
    else
    {
      isFrom = <Badge status="success" text="Email" />

    }
    usersArray.push({
      key: element._id,
      id: element._id,
      email: element.users.email,
      fullName: element.users.firstName + " " + element.users.lastName,
      photo: image,
      isActive:element.users.isActive,
      isFeatured:element.users.isFeatured,
      userStatus: isDeleted,
      isFrom:isFrom,
      createdDate: moment(element.users.createdDate).format(format),

    })
  });
  const onPageChange=(e:any)=>{
   dispatch(userActions.fetchAllUsersAdmin(e,querySearch,pageLimit));
    setPage(e);
  }

  const showModal = () => {
    setvisible(true);
  };

  const handleOk = (e: any) => {
    form.resetFields();

    setvisible(false);
  };

  const handleCancel = (e: any) => {
    form.resetFields();

    setvisible(false);
  };

  const onFinish = (values: any) => {
    let socialClick = false;
    let sendAdmin = true;
    let isFromMobile=false;
    dispatch(userActions.register(values.firstName, values.lastName, values.email, values.password, sendAdmin, socialClick,isFromMobile,playerId));

  };

  const onFinishFailed = (errorInfo: any) => {
  };
  const onMenuClick = (id: any) => {
    fetchId = id;
  }
  const onSelect = (e: any) => {
    if (e.key == "EU") {
      history.push('/pr-admin-cp/edit-user/' + fetchId)
    }
    else {
      Modal.warning({
        title: 'Opppssss',

        content: (
          <div className="warning-modal">
            <p>Are you sure you want to Deactivate?</p>
          </div>
        ),
        className: "cancel-modal",
        width: 560,
        okType: 'danger',
        okText: 'ok',
        okCancel: true,
        centered: true,
        cancelText: 'cancel',
        onOk: () => {
          dispatch(userActions.DeActiveUser(fetchId));
          dispatch(userActions.fetchAllUsers());
        },
        onCancel() {
        },
      });

    }

  }

  const onpagesizeChange=(current:any,size:any) => {
    setPageLimit(size);
    dispatch(userActions.fetchAllUsersAdmin(page,querySearch,size));

  }
  const onStatusChange=(records:any)=>{
    let dat={
      isActive:!records.isActive
    }
    if(!records.isActive)
    {
      confirm({
        title: 'Do you Want to Activate this user?',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          dispatch(userActions.ActivatedUser(dat,records.key));
        },
        onCancel() {
        },
      });
    }
    else
    {
      confirm({
        title: 'Do you Want to Deactivate this user?',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          dispatch(userActions.ActivatedUser(dat,records.key));
        },
        onCancel() {
        },
      });
    }
  }
  const onFeaturedChange=(records:any)=>{
    let dat={
      isFeatured:!records.isFeatured
    }
    if(!records.isFeatured)
    {
      confirm({
        title: 'Do you Want to add this user as featured user?',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          dispatch(userActions.FeaturedUser(dat,records.key));
        },
        onCancel() {
        },
      });
    }
    else
    {
      confirm({
        title: 'Do you Want to add this user as featured user',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          dispatch(userActions.FeaturedUser(dat,records.key));
        },
        onCancel() {
        },
      });
    }
  }
  const onSessionClick=(id:any) => {
alert(id);
  }
  const onImageClick =(image:any) =>{
    window.open(image);
}
  const columns = [
    {
      title: 'Photo',
      dataIndex: 'photo',
      render: (records: any) =><div onClick={()=>onImageClick(records)}><Avatar className="table-img"  src={records}></Avatar></div>

    },
    {
      title: 'Display Name',
      dataIndex: 'fullName',
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
    },
    // {
    //   title: 'Session',
    //   render: (records: any) =>
    //     <a className="table-img" onClick={()=>onSessionClick(records.id)} >
    //       check session
    //     </a>
    // },
    {
      title: 'social Media',
      dataIndex: 'isFrom',
    },
    {
      title: 'Joined Date',
      dataIndex: 'createdDate',
    },
    // {
    //   title: 'brand',
    //   dataIndex: 'brand',
    // },
    // {
    //   title: 'modal',
    //   dataIndex: 'modal',
    // },
    // {
    //   title: 'os',
    //   dataIndex: 'os',
    // },
    // {
    //   title: 'version',
    //   dataIndex: 'version',
    // },
    // {
    //   title: 'platform',
    //   dataIndex: 'platform',
    // },
    // {
    //   title: 'IP Address',
    //   dataIndex: 'ipAddress',
    // },
    {
      title: 'Verified',
      dataIndex: 'isActive',
      render:(e:any,records:any)=> <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked={e}
      onChange={()=>onStatusChange(records)}
    />,
    },
    {
      title: 'Featured',
      dataIndex: 'isFeatured',
      render:(e:any,records:any)=> <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked={e}
      onChange={()=>onFeaturedChange(records)}
    />,
    },
    {
      title: 'User Status',
      dataIndex: 'userStatus',
    },
    {
      title: 'Settings',
      render: (records: any) => <Dropdown className="admin-acc" overlay={(
        <Menu onClick={onSelect}>
          <Menu.Item key="EU" onClick={() => onMenuClick(records.id)} className="user-menu-item">
            <span>
              Edit User
          </span>
          </Menu.Item>
          <Menu.Item key="DU" onClick={() => onMenuClick(records.id)} className="user-menu-item">
            <span>
              Delete User
          </span>
          </Menu.Item>
        </Menu>
      )} trigger={['click']} >
        <SettingOutlined />
      </Dropdown>,
    },
  ];

  return (
    <div>
      <div style={{ width: '50%', float: 'left' }}>
        <Input.Search
          placeholder="Search by..."
          enterButton
          size="large"
          onSearch={search}
        />
      </div>
      <p style={{textAlign:'center',fontWeight:'bold',fontSize:'30px'}}>Total: {data.totalDocs}</p>
      <div style={{ marginBottom: "20px", textAlign: "right" }}>
        <Button type="primary" onClick={showModal} >Add User</Button>
      </div>
          <Table className="show-table"  pagination={{total:data.totalDocs,pageSizeOptions:["10","20","50","100"],onShowSizeChange:onpagesizeChange,onChange:onPageChange}} rowSelection={rowSelection} columns={columns} dataSource={usersArray} />
      <Modal
        title="Add a user"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className=''>
          <Form
            {...layout}
            className="admin-login-form"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item className="lbl-name" label="First Name"></Form.Item>
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: 'Please enter your first name.' },
              {
                max: 24,
                message: "First name must be maximum 24 characters."
              },
              {
                pattern: new RegExp(/^[A-Z]*$/i),
                message: "First name can only have alphabets."
              },
              {
                min: 3,
                message: "First name must be minimum 3 characters."
              }
              ]}
            >
              <Input name="firstName" size="large" placeholder="First Name" />
            </Form.Item>

            <Form.Item className="lbl-name" label="Last Name"></Form.Item>
            <Form.Item
              name="lastName"
              rules={[{ required: true, message: 'Please enter your last name.' },
              {
                max: 24,
                message: "Last name must be maximum 24 characters."
              },
              {
                pattern: new RegExp(/^[A-Z]*$/i),
                message: "First name can only have alphabets."
              },
              {
                min: 3,
                message: "Last name must be minimum 3 characters."
              }
              ]}
            >
              <Input name="lastName" placeholder="Last Name" size="large"
              />
            </Form.Item>
            <Form.Item className="lbl-name" label="Email address"></Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  type: 'email',
                  message: "Please enter a valid email address.",
                },
                { required: true, message: 'Please enter your email.' }]}
            >
              <Input placeholder="Email address" size="large" />
            </Form.Item>
            <Form.Item className="lbl-name" label="Password"></Form.Item>
            <Form.Item

              name="password"
              rules={[{ required: true, message: 'Please enter your password.' }, {
                min: 8,
                message: 'password must be minimum 8 characters.'
              }
              ]}
            >
              <Input.Password placeholder="Password" size="large" />
            </Form.Item>
            <Form.Item >
              <Button className="btn-admin-login" size="large" type="primary" htmlType="submit">
                Add User
                    </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );

}

function mapStateToProps(state: any) {
  const { registering } = state.authentication;
  const { type, message } = state.alert;
  return {
    type,
    message,
    registering,
    data: state.FetchAllUsersAdmin.getAllUserAdminObject
  };
}

export const connectedUsers = connect(mapStateToProps)(User);

