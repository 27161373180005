export const config = {
    apiurl:'https://ua4poje1el.execute-api.ap-south-1.amazonaws.com/prod',
    // apiurl:'http://pakrismserver-env.eba-g35jiaw6.ap-south-1.elasticbeanstalk.com/',
    webUrl:"https://pakrism.pk",
    s3BucketUrl:'https://d3b4d950pk995t.cloudfront.net/',

    //------------------------------------------------------
    //dev bucket
    // s3BucketUrl:'https://d3vddv05dfl0oz.cloudfront.net/',
    //dev url
    // apiurl:'https://unvt8qxigd.execute-api.ap-south-1.amazonaws.com/dev',
//---------------------------------------------------------
//local url
// apiurl:'http://192.168.18.191:4000',
    // apiurl:'https://pr-server.pakrism.pk',
}



