import React, { useEffect } from "react";
import { categoryActions } from '../../actions';
import { connect } from 'react-redux';
import 'remixicon/fonts/remixicon.css'
import moment from 'moment'
import './places.css'

const WeatherCard = (props: any) => {
  let finalResult: any = [];
  const { data, dispatch, weather, currentWeather } = props;
  useEffect(() => {
    dispatch(categoryActions.GetCurrentWeather(data[0]));
    dispatch(categoryActions.FetchWeather(data[0]));

  }, [dispatch])

  useEffect(()=>{
   return ()=>{
  // store.getState().FetchWeather.fetchWeatherObject = "";
  // store.getState().FetchCurrentWeather.fetchCurrentWeatherObject = "";
   }
  },[])
  if (!weather) {
    return (
      <div ></div>
    )
  }
  if (!currentWeather) {
    return (
      <div ></div>
    )
  }
  let date = moment().format("YYYY-MM-DD HH:mm:ss");
  //
  if (weather) {
    for (let i = 0; i < weather.data.list.length; i++) {
      if (date <= weather.data.list[i].dt_txt) {
        finalResult.push(weather.data.list[i])
      }

    }
  }
  return (

    <div className="weather-container-main" >
      <div className="weather-card-inner-container">
        <div className="weather-card-current-weather-container">
          <div>
            <p className="story-card-timespan-txt">{moment().format('MMM DD, YYYY')}</p>
          </div>
          <div className="place-card-headings-container">
            <p className="place-card-place-name-heading">
              {currentWeather.data.weather[0].main}
            </p>
          </div>
          <div className="weather-card-weather-icon">
           <img src={`http://openweathermap.org/img/wn/${currentWeather.data.weather[0].icon}@2x.png`} alt={currentWeather.data.weather[0].icon}/>


          </div>
          <div className="weather-card-weather">
            <p className="temp-heading">{(currentWeather.data.main.temp - 273.15).toFixed(0) + '\xB0C'}</p>
          </div>

        </div>
        <div className="weather-card-weather-desc">
          <ul className="weather-card-weather-list">
            <li className="weather-card-weather-list-item">
              <div className="weather-card-list-data-container">Feel like</div>
              <div className="weather-card-list-value-container">{(currentWeather.data.main.feels_like - 273.15).toFixed(0) + '\xB0C'}</div>
            </li>
            <li className="weather-card-weather-list-item">
              <div className="weather-card-list-data-container">Humidity</div>
              <div className="weather-card-list-value-container">{currentWeather.data.main.humidity}%</div>
            </li>
            <li className="weather-card-weather-list-item">
              <div className="weather-card-list-data-container">Wind</div>
              <div className="weather-card-list-value-container">{currentWeather.data.wind.speed} m/s</div>
            </li>
            <li className="weather-card-weather-list-item">
              <div className="weather-card-list-data-container">Pressure</div>
              <div className="weather-card-list-value-container">{currentWeather.data.main.pressure} hpa</div>
            </li>
          </ul>
        </div>
      </div>
      <div className="five-day-weather-container">
        <ul className="five-day-weather-list">

          {
            finalResult.length > 0 ?
              finalResult.map((item: any,i:any) =>
                <li className="five-day-weather-list-item">
                  <div className="day">{moment(item.dt_txt).format("MMM DD ")}</div>
                  <div className="time">{moment(item.dt_txt).format("hh:mm a")}</div>
                    <div className="icon"> <img width="60px" height="60px" src={`http://openweathermap.org/img/wn/${item.weather[0].icon}@2x.png`} alt={item.weather[0].icon}/></div>
                  <div className="temp">{(item.main.temp - 273.15).toFixed(0) + '\xB0C'}</div>
                </li>
              )
              :
              null
          }

        </ul>
      </div>
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    weather: state.FetchWeather.fetchWeatherObject,
    currentWeather: state.FetchCurrentWeather.fetchCurrentWeatherObject,
  };
}
export const connectedWeatherCard = connect(mapStateToProps)(WeatherCard);
export default connectedWeatherCard;

