import React, { useEffect } from 'react';
import {Stack} from "@chakra-ui/core";
import {Link} from 'react-router-dom'
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga';
import ContentLoader from 'react-content-loader';
import {MetaDecorator} from '../../utils';
import {userActions} from '../../actions';
import moment from 'moment'
import {connect} from 'react-redux'

type About={
  dispatch:any;
  data:any;
  match:any;}

const MyLoader = () => (
	<ContentLoader viewBox="0 0 380 100">
		{/* Only SVG shapes */}
  	<rect x="80" y="17" rx="4" ry="4" width="250" height="13" />
		<rect x="0" y="0" rx="100" ry="100" width="50" height="50" />
		<rect x="80" y="17" rx="4" ry="4" width="150" height="10" />
		<rect x="80" y="40" rx="3" ry="3" width="150" height="10" />
    <rect x="80" y="40" rx="4" ry="4" width="150" height="10" />
	</ContentLoader>
);

const cookies = new Cookies();

 function About(props:About) {

  let userFav=cookies.get('userData');

  const {data,dispatch,match} = props
  useEffect(()=>{
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    dispatch(userActions.getUserProfile(match.params.userName));
    // dispatch(userActions.getCurrentUser(user.id));


  },[dispatch])
  if(!data)
  {
    return(<div>
      <MyLoader/>
    </div>)
  }
  let date ='';
  if(data[0].DOB)
  {
    date=moment(data[0].DOB).format("yyyy-DD-MM")
  }

  let meta={
    title:`${userFav[0].firstName} ${userFav[0].lastName} (@${userFav[0].userName}) - profile - Pakrism`,
    description:`See travel reviews, photos, and more contributed by @${userFav[0].userName} on Pakrism.`,
    imageAlt:"",
    imageUrl:""
  }
  return (
    <div className="user-about-container">
      <MetaDecorator title={meta.title} description={meta.description} imageAlt={meta.imageAlt} imageUrl={meta.imageUrl} match={match}/>
      <div className="user-about-first-container">
    <div className="user-about-inner-container">
      <div className="user-about-about-me-container">
         <p className="story-card-story-title">
           About me
         </p>
         {
           data[0].aboutMe?
            <p className="user-card-desc">
              {data[0].aboutMe}
             </p>
             :
             userFav[0]._id.toString()===data[0]._id.toString()?
             <p className="user-card-desc">
             <div className="user-card-link-wrapper">
               <Link to={`/user/settings/${userFav[0]._id}`} className="user-data-link">Update your bio</Link>
               </div>
              </p>:<p className="user-card-desc">
              {data[0].aboutMe}
             </p>
         }

      </div>
      </div>
      <div className="user-about-profession-container">
      <Stack isInline className="icon-stack mar-tp">
      <div className="user-data-icon-wrapper-about">
        <i className="ri-book-fill user-data-icons"></i>
      </div>
       <p className="user-about-profession-txt"><span className="user-data-category-text">Education:</span>
       {
           data[0].education?
           <span className="user-card-desc"> {data[0].education} </span>
             :
           null
         }

       </p>
      </Stack>
      <Stack isInline className="icon-stack mar-tp">
      <div className="user-data-icon-wrapper-about">
        <i className="ri-briefcase-4-fill user-data-icons"></i>
      </div>
       <p className="user-about-profession-txt"><span className="user-data-category-text">Profession:</span>
       {
           data[0].Profession?
           <span className="user-card-desc"> {data[0].Profession} </span>
             :
           null
         }

       </p>
      </Stack>
      </div>
      </div>
      <div className="user-about-personal-container">
      <p className="story-card-story-title">
           Personal info
         </p>
      <div className="user-about-personal-left-container">
      <Stack isInline className="icon-stack mar-tp">
      <div className="user-data-icon-wrapper-about">
        <i className="ri-phone-fill user-data-icons"></i>
      </div>
       <p className="user-about-profession-txt"><span className="user-data-category-text">Phone no:</span>
       {
           data[0].showMobile==="Global"?
           <span className="user-card-desc"> {data[0].phone} </span>
             :
             <span className="user-card-desc">phone no is private</span>
         }
      </p>
      </Stack>
      <Stack isInline className="icon-stack mar-tp">
      <div className="user-data-icon-wrapper-about ">
        <i className="ri-mail-fill user-data-icons"></i>
      </div>
       <p className="user-about-profession-txt"><span className="user-data-category-text">Email:</span>
       {
           userFav[0]._id.toString()===data[0]._id.toString()?
           <span className="user-card-desc"> {data[0].email} </span>:
           data[0].showEmail==="Global"?
           <span className="user-card-desc"> {data[0].email} </span>
             :
             <span className="user-card-desc"> email is private</span>

         }
       </p>
      </Stack>
      </div>
      <div className="user-about-personal-left-container">
      <Stack isInline className="icon-stack mar-tp">
      <div className="user-data-icon-wrapper-about">
        <i className="ri-calendar-2-fill user-data-icons"></i>
      </div>
       <p className="user-about-profession-txt"><span className="user-data-category-text">Date of birth:</span>
       {
           data[0].showBirthDay==="Global"?
           data[0].DOB==="undefined"? <span className="user-card-desc"></span>:
           <span className="user-card-desc"> {date} </span>
             :
             <span className="user-card-desc"> date of birth is private</span>
         }

       </p>
      </Stack>
      <Stack isInline className="icon-stack mar-tp">
      <div className="user-data-icon-wrapper-about ">
        <i className="ri-map-pin-fill user-data-icons"></i>
      </div>
       <p className="user-about-profession-txt"><span className="user-data-category-text">Address:</span>
        <span className="user-card-desc"> {data[0].address.city +", "+data[0].address.country}</span>
       </p>
      </Stack>
      </div>
      </div>
    </div>
  )
}

function mapStateToProps(state: any) {
  return {
    data: state.FetchUserProfile.UserProfile
    // data: state.DataReducer.retrievedObject
  };
}
export const connectedAbout = connect(mapStateToProps)(About);
