import React,{useEffect, useState} from 'react';
import NavBar from '../Navbar/Navbar';
import { connect } from 'react-redux';
import {Form,Input,Button} from 'antd';
import ContentLoader from 'react-content-loader';
import ReactGA from 'react-ga';
// import Carousel from "react-multi-carousel";
import {Avatar} from "@chakra-ui/core";
import {MetaDecorator} from '../../utils';
// import {history} from '../../Helpers'
import {UpperFooterSection,Footer} from '../FooterSection'
import {config} from '../../config';
import {history} from '../../Helpers'
import noUser from '../../Assets/no-user.png'

import moment from 'moment';
import { storyActions,friendsActions} from '../../actions';
import {AvatarCard,SingleStoryCards,RelatedStoryCard,AdvertiseCard,LikeFooter} from '../Cards'
import './stories.css'
import "react-multi-carousel/lib/styles.css";

type SingleStory={
  dispatch:any;
  match:any;
  data:any;
  relatedStories:any;
  categoryStory:any;
  friendRequests:any;
  myFriends:any;
  location:any;
}
const { TextArea } = Input;
 function SingleStory(props:SingleStory) {
  const {match,dispatch,data,relatedStories,categoryStory,myFriends,location} = props;

  let user = JSON.parse(localStorage.getItem('user')!)
  let id = match.params.id.slice((match.params.id.length - 24))
  // let place =JSON.parse(localStorage.getItem('placeName')!)
  // let category =JSON.parse(localStorage.getItem('placeName')!)
  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
      if(user)
      {
        // dispatch(friendsActions.GetFriendRequests(user._id));
        // dispatch(friendsActions.GetFriends(user.user.userName));
      }

    // dispatch(storyActions.GetRelatedStories(place.placeId,id));
    // dispatch(storyActions.GetCategoryStory(category.categoryId,place.placeId));
    dispatch(storyActions.GetStory(id));



  }, []);
  const UserLoader = () => (
    <ContentLoader
      height={140}
      viewBox="0 0 380 70"
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="50" ry="50" width="70" height="70" />
      <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
      <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
    </ContentLoader>
  )
  const LeftLoader = () => (
    <ContentLoader
    viewBox="0 0 200 100">
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="550" />
    </ContentLoader>
  )
  const UserCommentLoader = () => (
    <ContentLoader
      height={140}
      viewBox="0 0 380 70"
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="50" ry="50" width="70" height="70" />
      <rect x="80" y="17" rx="4" ry="4" width="300" height="50" />
    </ContentLoader>
  )
  const RighLoader=()=>(
    <ContentLoader
    viewBox="0 0 500 500">
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="450" />
  </ContentLoader>
  )
  const TopLoader = () => (
    <ContentLoader
    viewBox="0 0 2680 100">
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="800px" />
    </ContentLoader>
  )
  if(!data)
  {
    return(
    <div style={{maxWidth:'1366px',margin:'auto'}}>
    <div style={{
      padding:'30px',
      marginTop:'150px'
    }}>
      <UserLoader/>
    </div>
    <div style={{width:'75%',float:'left',padding:'16px'}}>
    <LeftLoader/>
    <div style={{width:'100%',padding:'16px',marginTop:'60px',marginLeft:'8px',float:'left'}}>
          <TopLoader />
    </div>
    <div>
      <UserCommentLoader/>
    </div>
    </div>
    <div style={{width:'25%',float:'left',padding:'4px'}}>
    <RighLoader />
    </div>
    </div>
    )
  }
  // if(!relatedStories)
  // {
  //   return(<div></div>)
  // }
  // if(!categoryStory)
  // {
  //   return(<div></div>)
  // }
  let reverseArray:any=[];
  if(data)
  {
    reverseArray=[...data[0].stories.comments].reverse();
  }
  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 4000, min: 3000 },
  //     items:3 ,
  //   },
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items:3,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items:2,
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //   },
  // };
  const onFinish = (values: any) => {
    let image:any="";

    if(user.image)
    {
      image=user.image;
    }
    let formData={
      comment: values.comment,
      userId:user.id,
      firstName:user.firstName,
      lastName:user.lastName,
      userName:user.user.userName,
      image:image,
       title:data[0].stories.title,
       playerId:data[0].users.playerId,

    }
    dispatch(storyActions.AddStoryComment(formData,data[0].stories.urlTitle,id));
    dispatch(storyActions.GetStory(id));
   }
   const onFinishFailed = (errorInfo: any) => {
  };
  let finalData={
    title:data[0].stories.title+" - Story - Pakrism",
    description:data[0].stories.story,
    imageAlt:data[0].stories.image,
    imageUrl:config.apiurl+data[0].stories.image

  }
  return (
    <div className="single-story-container">
       <MetaDecorator title={finalData.title} description={finalData.description} imageAlt={finalData.imageAlt} imageUrl={finalData.imageUrl} match={match}/>
       <NavBar/>
       <div className="single-story-inner-container">
         {/* {
           friendRequests.length>0?myFriends.length>0? <div className="single-story-add-friend-container">
           <AvatarCard friendrequests={friendRequests} user={data[0].users} key={data[0].users._id}  dispatch={dispatch} friends={myFriends}/>
        </div>:null
        :
        null

         } */}
           <div className="single-story-data-container">
             <SingleStoryCards data={data[0]} key={data[0].users._id}/>
             <div className="post-story-comment-container">
              <LikeFooter data={data[0]} dispatch={dispatch}/>
               <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
             >
               <div style={{width:'9%',float:'left'}}>
                 {
                   user?
                   <Avatar
               name={user.firstName+" "+ user.lastName}
                 src={user.image}
               />
               :
               <Avatar
               name={"User Avatar"}
                 src={noUser}
               />
                 }

                 </div>
                 <div style={{paddingTop:'5px'}}>
                 <Form.Item
               name="comment"
               rules={[{ required: true, message: 'Please enter an answer.' },]}
               >
                 <TextArea  rows={4} name="comment" placeholder="Write your inspiration..."/>
               </Form.Item>
                 </div>
               <Form.Item style={{textAlign:'right'}}>
                 {
                   user?
                   <Button style={{width: '30%',marginTop:'0px'}} className="btn-admin-login" size="large" type="primary" htmlType="submit" >
                   Post
                   </Button>
                   :
                   <Button style={{width: '30%',marginTop:'0px'}} className="btn-admin-login" size="large" type="primary" onClick={()=>{history.push('/login')}} >
                   Post
                   </Button>
                 }

               </Form.Item>
             </Form>
             <p className="story-card-desc-txt" style={{paddingLeft:'20px'}}>{reverseArray.length} inspirations</p>
             </div>
             {
               reverseArray.map((item:any)=>
               <div className="show-comments-single-story-container">
                 <div className="show-comment-left-container" onClick={()=>{history.push(`/tourist/${item.userName}/about`)}}>
                 <Avatar
               name={item.firstName+" "+ item.lastName}
                 src={item.image}
                />
                 </div>
             <p><span className="story-card-desc-txt">{item.firstName+" "+ item.lastName}</span> <span className="story-card-timespan-txt">{moment(item.createdDate).fromNow()}</span></p>
                 <p>{item.comment}</p>
                 {
                   user?
                 item.likes.toString() ===user.id.toString()?
                  <p style={{color:'#37b44e',fontWeight:'bold'}}>
                   likes ({
                   (item.likes.length)
                   })

                 </p>:
                  <p>
                  likes ({
                  (item.likes.length)
                  })
                </p>
                :
                <p>
                likes ({
                (item.likes.length)
                })
              </p>
                 }

               </div>)
             }
           </div>
           <div className="single-story-advertise-container">
               <AdvertiseCard />
           </div>
       </div>
       {/* <div className="single-story-inner-container">
       <div className="travel-story-heading-container">
      <p className="travel-story-taller-heading">
      Related stories
         </p>
      </div>
       <Carousel responsive={responsive} className="story-carousel">

       {
           relatedStories.map((item: any) =>
           <div>
           <RelatedStoryCard storyCardData={item} storyLikes={item.storylikes} storyComments={item.storycomments} key={item._id}/>
           </div>
           )

       }
        </Carousel>
       </div> */}
       {/* <div className="single-story-inner-container">
       <div className="travel-story-heading-container">
      <p className="travel-story-taller-heading">
      Relevant {categoryStory[0].categories.categoryName} stories
         </p>
      </div>
       <Carousel responsive={responsive} className="story-carousel">
       {
           categoryStory.map((item: any) =>
           <div>
           <RelatedStoryCard storyCardData={item} key={item._id}/>
           </div>
           )
       }
        </Carousel>
       </div> */}
       <div className="user-dashboard-footer">
         <UpperFooterSection/>
         <Footer/>
       </div>
    </div>
  )
}


function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    data: state.GetStory.getStoryObject,
    // relatedStories:state.GetRelatedStory.getRelatedStoryObject,
    // categoryStory:state.GetCategoryStory.getCategoryStoryObject,
    // friendRequests:state.FetchFriendRequests.getFriendRequestsObject,
    // myFriends:state.FetchFriends.getFriendsObject,
  };
}

export const connectedStory = connect(mapStateToProps)(SingleStory);

