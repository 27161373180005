import React, { useState } from "react";
import {Avatar } from "@chakra-ui/core";
import {config} from '../../config';
import { MoreOutlined } from '@ant-design/icons';
import {Dropdown, Menu,Modal} from 'antd';
import {history} from '../../Helpers'

const TimeStamp =require("react-timestamp");


type SingleStoryCards={
data:any;
}

export function SingleStoryCards(props:SingleStoryCards) {
   const {data} = props;
   let imageUrl:any= config.s3BucketUrl+data.stories.image
   const [visible, setvisible] = useState(false);
   imageUrl= imageUrl.replace(/\\/g, '/');
    let story=data.stories.story;

    const onPlaceClick=(id:any,placeName:any)=>{
      let placeTitle='';
      placeTitle=placeName.replace(/[ ,?]/g,'-')
      history.push(`/travel-places/${placeTitle}-${id}`)
    }
    const handleCancel = () => {
      setvisible(false);
    };
    const showModal = ()=>{

        setvisible(true);

      };
  return (

        <div className="single-story-card-inner-left-container">
          <div className="single-story-card-user-container">
          <div className="story-card-user-container single-story-card-container-user"
          onClick={()=>{history.push(`/tourist/${data.users.userName}/about`)}}
          >
              <div style={{ float: "left", marginBottom: "16px" }}>
                <Avatar
                  name={data.users.firstName+" "+data.users.lastName}
                  size="lg"
                  className="question-avatar"
                  src={data.users.image}
                ></Avatar>
              </div>
              <div className="story-card-desc-container">
                <p className="story-card-decs-txt-container">
                  <small className="story-card-desc-txt">{data.users.firstName+" "+data.users.lastName}</small>
                  &nbsp;<small className="story-card-tourist-txt">published in</small>
                   <span className="story-card-timespan-txt story-card-place-name" onClick={()=>{onPlaceClick(data.places._id,data.places.placeName)}}> {data.places.placeName}</span>

                </p>
                <p className="story-card-album-badge">
                  <small className="story-card-category-name story-card-place-name">
                  {data.categories.categoryName} .
                    </small>
                    &nbsp;
                    <small >
                    <TimeStamp className="story-card-timespan-txt" relative date={data.stories.createdDate} autoUpdate />
                    </small>
                </p>
              </div>
            </div>
            <div className="single-story-card-menu-container">
            <Dropdown overlay={(
                  <Menu>
                    <Menu.Item key="Ap" >Suggesion</Menu.Item>
                    <Menu.Item key="Ap" >Report</Menu.Item>
                  </Menu>)} trigger={['click']} >
                  <MoreOutlined  style={{float:'right'}}/>
            </Dropdown>
            </div>
            <div style={{marginBottom:'30px'}}>
            <div className="story-card-story-container" >
            <p className="single-story-card-story-title">
            {data.stories.title}
            </p>
            <div className="single-story-img-banner" style={{backgroundImage: 'url("'+imageUrl+'")',cursor:'pointer'}} onClick={showModal}>
            </div>
            <div className="story-card-story-desc-container">
              <div className="story-card-story-desc single-story-desc" dangerouslySetInnerHTML= {{__html:story}}>

              </div>
            </div>
            </div>
          </div>
          </div>
          <Modal
        title="Story feature image"

        className="place-photo-modal"
        closable={true}
        visible={visible}
        onCancel={handleCancel}
        footer={false}
      >
        <div>
        <img className="place-single-img"  src={imageUrl} alt={imageUrl}/>
        </div>
      </Modal>
          </div>
  );
}


