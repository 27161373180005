
import React, { useState, useEffect } from 'react';
import { Table, Dropdown, Menu, Button, Modal, Input, Form, Avatar, Select, Badge, DatePicker, Row, Col,message, Upload} from 'antd';
import { history } from '../../../Helpers';
import { agenciesActions } from '../../../actions';
import { connect } from 'react-redux';
import { SettingOutlined,CloudUploadOutlined} from '@ant-design/icons';
import moment from 'moment';
import { config } from '../../../config';


const { Option } = Select;
const { Dragger } = Upload;
function beforeUpload(file: any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const onPreview = async (file: any) => {
  let src = file.url;
  if (!src) {
    src = await new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  }
  const image = new Image();
  image.src = src;
  const imgWindow: any = window.open(src);
  imgWindow.document.write(image.outerHTML);
};

type TravelAgencies = {
  dispatch: any;
  data: any;
}
const prop: any = {
  name: 'images',
  action: '',
  headers: {
    authorization: 'authorization-text',
  },
};


function TravelAgencies(props: TravelAgencies) {
  let fetchId: any = '';
  const [form] = Form.useForm();
  const { dispatch, data } = props;
  const [visible, setvisible] = useState(true);
  const [page, setPage] = useState(1);
  const [querySearch, setQuerySearch] = useState('""');
  const [pageLimit, setPageLimit] = useState(10);


  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {

      dispatch(agenciesActions.FetchAllTravelAgencies(page, querySearch, pageLimit));
    }

  }, [dispatch]);


  const { TextArea } = Input;

  const [selectedRowKeys, setselectedRowKeys] = useState([]);


  const onSelectChange = (selectedRowKeys: any) => {
    setselectedRowKeys(selectedRowKeys)

  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  if (!data) {
    return (<div></div>)
  }
  console.log({ data })
  const format = "YYYY-MM-DD HH:mm"
  let image: any = '';
  let search = (value: any) => {
    if (value) {
      dispatch(agenciesActions.FetchAllTravelAgencies(page, value, pageLimit));
      setQuerySearch(value)
    }
    else {
      dispatch(agenciesActions.FetchAllTravelAgencies(page, '""', pageLimit));
      setQuerySearch('""')
    }

  };
  const usersArray: any = [];
  let agencyStatus: any = '';
  data.docs.forEach((element: any, index: any) => {
    if (element.logo) {

      image = element.logo
    }
    else {
      image = "N/A"
    }
    if (element.isActive) {
      agencyStatus = <Badge status="success" text="Active" />

    }
    else {
      agencyStatus = <Badge status="default" text="InActive" />
    }
    usersArray.push({
      key: element._id,
      id: element._id,
      agentName: element.agent.firstName + " " + element.agent.lastName,
      agentEmail: element.agent.email,
      agencyEmail: element.agancyEmail,
      agencyName: element.agancyName,
      photo: config.s3BucketUrl + image,
      verifyIdCard: element.verifyIdCard,
      verifyNtn: element.verifyNtn,
      status: agencyStatus,
      createdDate: moment(element.createdDate).format(format),

    })
  });
  const onPageChange = (e: any) => {
    dispatch(agenciesActions.FetchAllTravelAgencies(e, querySearch, pageLimit));
    setPage(e);
  }

  const showModal = () => {
    setvisible(true);
  };
  const onMenuClick = (id: any) => {
    fetchId = id;
  }
  const onSelect = (e: any) => {
    if (e.key == "EU") {
      history.push('/pr-admin-cp/edit-travel-agency/' + fetchId)
    }
    else {
      Modal.warning({
        title: 'Opppssss',

        content: (
          <div className="warning-modal">
            <p>Are you sure you want to Deactivate?</p>
          </div>
        ),
        className: "cancel-modal",
        width: 560,
        okType: 'danger',
        okText: 'ok',
        okCancel: true,
        centered: true,
        cancelText: 'cancel',
        onOk: () => {
          dispatch(agenciesActions.DeleteTravelAgencyAdmin(fetchId));
          // dispatch(userActions.fetchAllUsers());
        },
        onCancel() {
        },
      });

    }

  }
  const handleOk = (e: any) => {
    form.resetFields();

    setvisible(false);
  };

  const handleCancel = (e: any) => {
    form.resetFields();

    setvisible(false);
  };
  const onFinish = (values: any) => {
    let socialClick = false;
    let sendAdmin = true;
    let isFromMobile = false;
    // dispatch(userActions.register(values.firstName, values.lastName, values.email, values.password, sendAdmin, socialClick,isFromMobile,playerId));

  };

  const onFinishFailed = (errorInfo: any) => {
  };
  const handleNtnChange = (value: any, id: any) => {

    let formData = {
      status: value,
      statusFlag: "Ntn"
    };
    dispatch(agenciesActions.ChangeStatus(formData, id));

    console.log(`selected ${id}`);
  }
  const handleIdCardChange = (value: any, id: any) => {
    let formData = {
      status: value,
      statusFlag: "idCard"
    };
    dispatch(agenciesActions.ChangeStatus(formData, id));
    console.log(`selected ${value}`);
  }

  const onpagesizeChange = (current: any, size: any) => {
    setPageLimit(size);
    dispatch(agenciesActions.FetchAllTravelAgencies(page, querySearch, size));

  }

  const onImageClick = (image: any) => {
    window.open(image);
  }
  const columns = [
    {
      title: 'Photo',
      dataIndex: 'photo',
      render: (records: any) => <div onClick={() => onImageClick(records)}><Avatar className="table-img" src={records}></Avatar></div>

    },
    {
      title: 'Agancy Name',
      dataIndex: 'agencyName',
    },
    {
      title: 'Agency Email',
      dataIndex: 'agencyEmail',
    },
    {
      title: 'Agent Name',
      dataIndex: 'agentName',
    },
    {
      title: 'Agent Email',
      dataIndex: 'agentEmail',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Joined Date',
      dataIndex: 'createdDate',
    },

    {
      title: 'Verify Ntn',
      render: (records: any) => <Select defaultValue={records.verifyNtn} style={{ width: 120 }} onChange={(value) => handleNtnChange(value, records.id)}>
        <Option value="Pending">Pending</Option>
        <Option value="Not Verified">Not Verified</Option>
        <Option value="Verified">Verified</Option>
      </Select>,
    },
    {
      title: 'Verify Id Card',
      render: (records: any) => <Select defaultValue={records.verifyIdCard} style={{ width: 120 }} onChange={(value) => handleIdCardChange(value, records.id)}>
        <Option value="Pending">Pending</Option>
        <Option value="Not Verified">Not Verified</Option>
        <Option value="Verified">Verified</Option>
      </Select>,
    },
    {
      title: 'Settings',
      render: (records: any) => <Dropdown className="admin-acc" overlay={(
        <Menu onClick={onSelect}>
          <Menu.Item key="EU" onClick={() => onMenuClick(records.id)} className="user-menu-item">
            <span>
              Edit User
            </span>
          </Menu.Item>
          <Menu.Item key="DU" onClick={() => onMenuClick(records.id)} className="user-menu-item">
            <span>
              Delete User
            </span>
          </Menu.Item>
        </Menu>
      )} trigger={['click']} >
        <SettingOutlined />
      </Dropdown>,
    },
  ];

  return (
    <div>
      <div style={{ width: '50%', float: 'left' }}>
        <Input.Search
          placeholder="Search by..."
          enterButton
          size="large"
          onSearch={search}
        />
      </div>
      <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '30px' }}>Total: {data.totalDocs}</p>
      <div style={{ marginBottom: "20px", textAlign: "right" }}>
        <Button type="primary" onClick={showModal} >Add Agency</Button>
      </div>
      <Table className="show-table" pagination={{ total: data.totalDocs, pageSizeOptions: ["10", "20", "50", "100"], onShowSizeChange: onpagesizeChange, onChange: onPageChange }} rowSelection={rowSelection} columns={columns} dataSource={usersArray} />
      <Modal
        title="Add agency"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width="50%"
      >
        <div className=''>
          <Form
            // {...layout}
            className="agency-modal"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
              <p style={{ fontSize: "24px", fontWeight: "bold" }}>Agent information</p>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item className="lbl-name" label="Agent First Name"></Form.Item>
                <Form.Item
                  name="firstName"
                  rules={[{ required: true, message: 'Please enter your first name.' },
                  {
                    max: 24,
                    message: "First name must be maximum 24 characters."
                  },
                  {
                    pattern: new RegExp(/^[A-Z]*$/i),
                    message: "First name can only have alphabets."
                  },
                  {
                    min: 3,
                    message: "First name must be minimum 3 characters."
                  }
                  ]}
                >
                  <Input name="firstName" size="large" placeholder="Agent First Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className="lbl-name" label="Agent Last Name"></Form.Item>
                <Form.Item
                  name="lastName"
                  rules={[{ required: true, message: 'Please enter your last name.' },
                  {
                    max: 24,
                    message: "Last name must be maximum 24 characters."
                  },
                  {
                    pattern: new RegExp(/^[A-Z]*$/i),
                    message: "First name can only have alphabets."
                  },
                  {
                    min: 3,
                    message: "Last name must be minimum 3 characters."
                  }
                  ]}
                >
                  <Input name="lastName" placeholder="Agent Last Name" size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className="lbl-name" label="Agent Email address"></Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: "Please enter a valid email address.",
                    },
                    { required: true, message: 'Please enter your email.' }]}
                >
                  <Input placeholder="Agent Email address" size="large" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className="lbl-name" label="Password"></Form.Item>
                <Form.Item

                  name="password"
                  rules={[{ required: true, message: 'Please enter your password.' }, {
                    min: 8,
                    message: 'password must be minimum 8 characters.'
                  }
                  ]}
                >
                  <Input.Password placeholder="Password" size="large" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className="lbl-name" label="Agent Mobile"></Form.Item>
                <Form.Item
                  name="mobile"
                  rules={[{ required: true, message: 'Please enter Mobile No.' },
                  {
                    max: 13,
                    message: "mobile name must be maximum 13 characters."
                  },
                  {
                    min: 11,
                    message: "mobile No must be minimum 13 characters."
                  }
                  ]}
                >
                  <Input name="mobile" type="tel" size="large" placeholder="Agent Mobile" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className="lbl-name" label="Gender"></Form.Item>
                <Form.Item
                  name="gender"
                  hasFeedback
                  rules={[{ required: true, message: 'Please select your Gender!' }]}
                >
                  <Select size="large" placeholder="Please select a Gender">
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item className="lbl-name" label="DOB" style={{ maxWidth: "300px" }}></Form.Item>
                <Form.Item
                  name="dob"
                  hasFeedback                            >
                  <DatePicker name="dob" size="large" />
                </Form.Item>
              </Col>
              </Row>
              <p style={{ fontSize: "24px", fontWeight: "bold" }}>Agency information</p>
              <Row gutter={8}>
              <Col span={12}>
                <Form.Item className="lbl-name" label="Agency Name"></Form.Item>
                <Form.Item
                  name="agencyName"
                  rules={[{ required: true, message: 'Please enter agency name.' },
                  {
                    max: 24,
                    message: "agancy name must be maximum 24 characters."
                  },
                  {
                    min: 3,
                    message: "agancy name must be minimum 3 characters."
                  }
                  ]}
                >
                  <Input name="agencyName" placeholder="Agency Name" size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className="lbl-name" label="Agency Phone"></Form.Item>
                <Form.Item
                  name="agancyPhone"
                  rules={[
                    // { required: true, message: 'Please enter Mobile No.' },
                    {
                      max: 13,
                      message: "mobile must be maximum 13 characters."
                    },
                    {
                      min: 11,
                      message: "mobile No must be minimum 13 characters."
                    }
                  ]}
                >
                  <Input name="agancyPhone" type="tel" size="large" placeholder="Agency Phone" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className="lbl-name" label="Agency Email address"></Form.Item>
                <Form.Item
                  name="agencyEmail"
                  rules={[
                    {
                      type: 'email',
                      message: "Please enter a valid email address.",
                    },
                    { required: true, message: 'Please enter your email.' }]}
                >
                  <Input placeholder="Agency Email address" size="large" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className="lbl-name" label="Agency Slogan"></Form.Item>
                <Form.Item
                  name="slogan"
                >
                  <Input name="slogan" placeholder="Agency slogan" size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className="lbl-name" label="Languages"></Form.Item>
                <Form.Item
                  hasFeedback
                  name="lang"
                  rules={[{ required: true, message: 'Please select Languages!', type: 'array' }]}
                >
                  <Select mode="multiple" size="large" placeholder="Please select Language">
                    <Option value="English">English</Option>
                    <Option value="Urdu">Urdu</Option>
                    <Option value="Punjabi">Punjabi</Option>
                    <Option value="Pashto">Pashto</Option>
                    <Option value="Sindhi">Sindhi</Option>
                    <Option value="Saraiki">Saraiki</Option>
                    <Option value="Blochi">Blochi</Option>
                    <Option value="Hindko">Hindko</Option>
                    <Option value="Brahui">Brahui</Option>
                    <Option value="Kashmiri">Kashmiri</Option>
                    <Option value="Burushaski">Burushaski</Option>
                    <Option value="Kalash">Kalash</Option>
                    <Option value="Khowar">Khowar</Option>
                    <Option value="Shina">Shina</Option>
                    <Option value="Balti">Balti</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className="lbl-name" label="Experience"></Form.Item>
                <Form.Item
                  name="experience"
                >
                  <Input name="experience" type="number" size="large" placeholder="Agency Experience" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className="lbl-name" label="What type of tours do you offer?"></Form.Item>
                <Form.Item
                  hasFeedback
                  name="tourTypes"
                  rules={[{ required: true, message: 'Please select TourTypes', type: 'array' }]}
                >
                  <Select mode="multiple" size="large" placeholder="Please select Language">
                    <Option value="Family Trips">Family Trips</Option>
                    <Option value="Couple Trips">Couple Trips</Option>
                    <Option value="Female Trips">Female Trips</Option>
                    <Option value="Male Trips">Male Trips</Option>
                    <Option value="Combine Trips">Combine Trips</Option>
                    <Option value="Honeymoon Trips">Honeymoon Trips</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className="lbl-name" label="Ntn"></Form.Item>
                <Form.Item
                  name="ntn"
                >
                  <Input name="ntn" type="number" size="large" placeholder="Agency Ntn" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item className="lbl-name" label="About us"></Form.Item>
                <Form.Item
                  hasFeedback
                  name="about"
                >
                  <TextArea name="about" placeholder="About Us" rows={4}
                  />
                </Form.Item>
              </Col>
            </Row>
            <p style={{ fontSize: "24px", fontWeight: "bold" }}>Address</p>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item className="lbl-name" label="City"></Form.Item>
                <Form.Item
                  name="city"
                >
                  <Input name="city" placeholder="City" size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className="lbl-name" label="Zip Code"></Form.Item>
                <Form.Item
                  name="zipCode"
                >
                  <Input name="zipCode" placeholder="Zip Code" size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className="lbl-name" label="State"></Form.Item>
                <Form.Item
                  name="state"            >
                  <Input name="state" placeholder="State" size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className="lbl-name" label="Country"></Form.Item>
                <Form.Item
                  name="country"            >
                  <Input name="country" placeholder="Country" size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
            <p style={{ fontSize: "24px", fontWeight: "bold" }}>Photos</p>
            <Row gutter={2}>
              <Col span={12}>
              <Form.Item className="lbl-name" label="logo"></Form.Item>
            <Form.Item
              name="logo"
              rules={[{ required: true, message: 'Logo is required.' }]}
              className="file-upload">
              <Dragger  name="logo" beforeUpload={beforeUpload}
                onPreview={onPreview}
                {...prop}
                listType="picture-card">

                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined style={{ color: "#000000" }} />
                  <span className="upld-desc" > Upload or drop photo here</span>
                </p>
              </Dragger>
            </Form.Item>
              </Col>
              <Col span={12}>
              <Form.Item className="lbl-name" label="Cover"></Form.Item>
            <Form.Item
              name="cover"
              // rules={[{ required: true, message: 'Logo is required.' }]}
              className="file-upload">
              <Dragger  name="cover" beforeUpload={beforeUpload}
                onPreview={onPreview}
                {...prop}
                listType="picture-card">

                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined style={{ color: "#000000" }} />
                  <span className="upld-desc" > Upload or drop photo here</span>
                </p>
              </Dragger>
            </Form.Item>
              </Col>
              <Col span={12}>
              <Form.Item
               className="lbl-name"
                label="ID Card front side"
                ></Form.Item>
            <Form.Item
              name="idCardFront"
              // rules={[{ required: true, message: 'Logo is required.' }]}
              className="file-upload">
              <Dragger  name="idCardFront" beforeUpload={beforeUpload}
                onPreview={onPreview}
                multiple={false}
                {...prop}
                listType="picture-card">
                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined style={{ color: "#000000" }} />
                  <span className="upld-desc" > Upload or drop front side photo here</span>
                </p>
              </Dragger>
            </Form.Item>
              </Col>
              <Col span={12}>
              <Form.Item
               className="lbl-name"
                label="ID Card"
                ></Form.Item>
            <Form.Item
              name="idCardBack"
              // rules={[{ required: true, message: 'Logo is required.' }]}
              className="file-upload">
              <Dragger  name="idCardBack" beforeUpload={beforeUpload}
                onPreview={onPreview}
                multiple={false}
                {...prop}
                listType="picture-card">
                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined style={{ color: "#000000" }} />
                  <span className="upld-desc" > Upload or drop back side photo here</span>
                </p>
              </Dragger>
            </Form.Item>
              </Col>
            </Row>
            <Form.Item >
              <Button className="btn-admin-login" size="large" type="primary" htmlType="submit">
                Add Agency
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );

}

function mapStateToProps(state: any) {
  return {
    data: state.FetchTravelAgencies.getAllTravelAgenciesObject
  };
}

export const connectedTravelAgencies = connect(mapStateToProps)(TravelAgencies);

