import React, { useEffect, useState } from "react";
import {Box, Avatar} from "@chakra-ui/core";
import Cookies from 'universal-cookie';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Badge } from "antd";
import { config } from "../../config";
import {history} from '../../Helpers'
import './albumcarousal.css'
import { Carousel } from 'react-responsive-carousel';
const TimeStamp =require("react-timestamp");


const cookies = new Cookies();

type AlbumCarousel={
  data:any;
}
const onAlbumTitleClick=(id:any,title:string)=>{
  history.push(`/travel-albums/${title.replace(/ /g,"-")}-${id}`);
}
export function AlbumCarousel(props:AlbumCarousel){

  const {data} = props;
  useEffect(()=>{
  },[])
    return (
      <div className="carousel-inner-container">
            <Box
            onClick={()=>onAlbumTitleClick(data.albums._id,data.albums.title)}
              maxW="sm"
              borderWidth="1px"
              rounded="lg"
              overflow="hidden"
              style={{position: "relative",cursor:'pointer' }}
            >
              <Carousel autoPlay={false} infiniteLoop showThumbs={false} showArrows={false} className="carosel-container">
              {
             data.albums.image.length > 0 ?
             data.albums.image.map((item: any) =>
             <div>
             <img alt="Lake" src={config.s3BucketUrl+item.imageName} />
           </div>
             )
                :null

            }
              </Carousel>
              <div className="txt-img-container" >
                <div className="lefted-album-txt">
                  <p className="album-txt">{data.albums.title}</p>
                </div>
                <div className="lefted">
                  <small className="photos-txt">Photos</small>

                  <Badge status="default" text={data.albumcategories.categoryName} />
                </div>
              </div>
                <div className="album-card-user-container">
              <div style={{ float: "left"}}>
                <Avatar
                  name={data.users.firstName+" "+data.users.lastName}
                  size="lg"
                  className="question-avatar"
                  src={data.users.image}
                ></Avatar>
              </div>
              <div className="story-card-desc-container">
                <p className="story-card-decs-txt-container">
                  <small className="story-card-desc-txt">{data.users.firstName+" "+data.users.lastName}</small>
                </p>
                <p className="story-card-album-badge">
                    <small style={{fontSize:"14px",color:"#7d7d7d"}} >
                      Created album &nbsp;
                    <TimeStamp className="story-card-timespan-txt" relative date={data.albums.createdDate} autoUpdate />
                    </small>
                </p>
              </div>
            </div>
            <div>
            </div>
            </Box>
          </div>
    );
  }
