import { alertActions } from "../actions";
import { config } from "../config";
import axios from "axios";

export const questionsService = {
  FetchPlaceQuestions,
  AddQuestion,
  FetchRelatedQuestions,
  FetchQuestionsById,
  FetchUserQuestions,
  FetchAllPlaceQuestions,
  FetchAllPlaceQuestionsAdmin,
  FetchEditQuestionAdmin,
  DeleteQuestionAdmin
};

function FetchQuestionsById(id:any) {
  return fetch(`${config.apiurl}/user/get-questions/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

function FetchPlaceQuestions(id:any) {
  return fetch(`${config.apiurl}/user/get-top-questions-place/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

function FetchAllPlaceQuestions(id:any) {
  return fetch(`${config.apiurl}/user/get-questions-place/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

function FetchRelatedQuestions(ids:any) {
  return fetch(`${config.apiurl}/user/get-related-questions/${ids.placeId}/${ids.questionId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

function FetchUserQuestions(userId:any) {
  return fetch(`${config.apiurl}/user/get-questions-user/${userId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

function AddQuestion(values: any) {
  return axios
    .post(`${config.apiurl}/user/add-question/`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function FetchAllPlaceQuestionsAdmin(page:any) {
  return fetch(`${config.apiurl}/user/get-questions-admin/${page}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

function FetchEditQuestionAdmin(id:any) {
  return fetch(`${config.apiurl}/user/get-questions/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

function DeleteQuestionAdmin(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/user/delete-questions-admin/${id}`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}
