import React, { useEffect } from 'react';
import { Button, Form, Input, Select, Upload, DatePicker, message,Switch } from 'antd';
import { CloudUploadOutlined,CloseOutlined, CheckOutlined } from "@ant-design/icons";
import {Avatar} from '@chakra-ui/core'
import { history,store} from '../../../Helpers';
import { config } from "../../../config";
import { connect } from 'react-redux';
import { userActions } from '../../../actions';
import moment from 'moment';

import './edituser.css'

const { Option } = Select;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};

type EditUser = {
  dispatch: any;
  match: any;
  data: any;
}

const { Dragger } = Upload;

const prop: any = {
  name: 'userImage',
  action: '',
  headers: {
    authorization: 'authorization-text',
  },
};

function beforeUpload(file: any) {

  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const dateFormat = 'YYYY/MM/DD';

function EditUser(props: EditUser) {
  store.getState().FetchEditUser.fetchEditUserObject = "";
  const { dispatch, match, data } = props;
  let image: any = '';


  const onFinish = (values: any) => {

    // console.log(values)
    const data = new FormData()

    if(values.dob)
    {
      data.append("DOB", moment(values.dob).toISOString())
    }
    else
    {
      data.append("DOB", DOB)
    }

    data.append("firstName", values.firstName)
    data.append("lastName", values.lastName)
    data.append("prefix", values.prefix)
    data.append("phone", values.phone)
    data.append("profession", values.profession)
    data.append("street", values.street)
    data.append("apartment", values.apartment)
    data.append("city", values.city)
    data.append("state", values.state)
    data.append("country", values.country)
    data.append("postalCode", values.postalCode)
    data.append("roles", values.roles)
    data.append("lang", values.lang)
    data.append("gender", values.gender)
    if (values.userImage) {
      data.append("userImage", values.userImage.file.originFileObj)
    }

    data.append("image", values.image)

    dispatch(userActions.UpdateUser(data, match.params.id));

  };
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {
      dispatch(userActions.fetchEditUser(match.params.id));
    }

  }, [dispatch, match.params.id]);

  const onFinishFailed = (errorInfo: any) => {
  };



  if (!data) {
    return (<div></div>)
  }
  let DOB:any="";
  image = config.apiurl + "/" + data[0].image;
  let prefix: any = '';
  let phone: any = ''
  if (data[0].phone) {
    prefix = data[0].phone.substring(0, 3);
  }
  if (data[0].phone) {
    phone = data[0].phone.substring(3)
  }
  if(data[0].DOB)
  {

   DOB=moment(data[0].DOB).format(dateFormat);
  }
  console.log(data[0].socialId);
  const prefixSelector = (
    <Form.Item initialValue={prefix} name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="+92">+92</Option>
        <Option value="+91">+91</Option>
      </Select>
    </Form.Item>
  );
  return (
    <div>
      <Form
        {...layout}
        className="admin-login-form"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
          {/* <Form.Item className="lbl-name" label="Social Id"></Form.Item>
        <Form.Item
          name="socialId"
          initialValue={data[0].socialId}
          hasFeedback
        >
          <Input name="socialId" placeholder="Last Name" size="large"
          />
        </Form.Item>
        <Form.Item className="lbl-name" label="Image Url"></Form.Item>
        <Form.Item
          name="image"
          initialValue={data[0].image}
          hasFeedback
        >
          <Input name="image" placeholder="Last Name" size="large"
          />
        </Form.Item> */}
        <Form.Item className="lbl-name" label="First Name"></Form.Item>
        <Form.Item
          name="firstName"
          initialValue={data[0].firstName}
          hasFeedback
          rules={[{ required: true, message: 'Please enter your first name.' },
          {
            max: 24,
            message: "First name must be maximum 24 characters."
          },
          {
            pattern: new RegExp(/^[A-Z]*$/i),
            message: "First name can only have alphabets."
          },
          {
            min: 3,
            message: "First name must be minimum 3 characters."
          }
          ]}
        >
          <Input name="firstName" size="large" placeholder="First Name" />
        </Form.Item>

        <Form.Item className="lbl-name" label="Last Name"></Form.Item>
        <Form.Item
          name="lastName"
          initialValue={data[0].lastName}
          hasFeedback
          rules={[{ required: true, message: 'Please enter your last name.' },
          {
            max: 24,
            message: "Last name must be maximum 24 characters."
          },
          {
            pattern: new RegExp(/^[A-Z]*$/i),
            message: "First name can only have alphabets."
          },
          {
            min: 3,
            message: "Last name must be minimum 3 characters."
          }
          ]}
        >
          <Input name="lastName" placeholder="Last Name" size="large"
          />
        </Form.Item>
        <Form.Item className="lbl-name" label="User Name"></Form.Item>
        <Form.Item
          name="userName"
          initialValue={data[0].userName}
          hasFeedback
        >
          <Input disabled placeholder="user Name" size="large" />
        </Form.Item>
        <small >Attempts count ({data[0].userNameChangeAttempt})</small>
        <Form.Item
          name="userNameChangeAttempt"
          initialValue={data[0].userNameChangeAttempt}
          hasFeedback
        >
          <Input name="userNameChangeAttempt" style={{ display: 'none' }} placeholder="user Name" size="large"
          />
        </Form.Item>


        <Form.Item className="lbl-name" label="Email address"></Form.Item>
        <Form.Item
          name="email"
          initialValue={data[0].email}
          hasFeedback
          rules={[
            {
              type: 'email',
              message: "Please enter a valid email address.",
            },
            { required: true, message: 'Please enter your email.' }]}
        >
          <Input disabled placeholder="Email address" size="large" />
        </Form.Item>
        <Form.Item className="lbl-name" label="Phone"></Form.Item>
        <Form.Item
          initialValue={phone}
          hasFeedback
          name="phone"
          >
          <Input addonBefore={prefixSelector} size="large" />
        </Form.Item>
        <Form.Item className="lbl-name" label="Photo"></Form.Item>
        <Form.Item
          name="userImage"
          className="file-upload">
          <Dragger
            {...prop}
            listType="picture"
            beforeUpload={beforeUpload}
            multiple={false}

          >
            <p className="ant-upload-drag-icon">
              <CloudUploadOutlined style={{ color: "#000000" }} />
              <span className="upld-desc" > Upload or drop photo here</span>
            </p>
          </Dragger>
        </Form.Item>
        <Form.Item style={{ width: "50%" }}>
        {
          data[0].image? <img src={image} width="30%"  alt="image" style={{ textAlign: 'center' }} />
          :<Avatar name={data[0].firstName+" "+data[0].lastName}/>
        }
        </Form.Item>
        <Form.Item className="lbl-name" label="Street"></Form.Item>
        <Form.Item
          name="street"
          initialValue={data[0].address.street}
          hasFeedback
          rules={[{ required: true, message: 'Please enter Street.' },]}
        >
          <Input name="street" placeholder="Street" size="large"
          />
        </Form.Item>
        <Form.Item className="lbl-name" label="Apartment"></Form.Item>
        <Form.Item
          name="apartment"
          initialValue={data[0].address.apartment}
          hasFeedback
        >
          <Input name="apartment" placeholder="Apartment" size="large"
          />
        </Form.Item>
        <Form.Item className="lbl-name" label="City"></Form.Item>
        <Form.Item
          name="city"
          initialValue={data[0].address.city}
          hasFeedback
          rules={[{ required: true, message: 'Please enter City.' },]}
        >
          <Input name="city" placeholder="City" size="large"
          />
        </Form.Item>
        <Form.Item className="lbl-name" label="State"></Form.Item>
        <Form.Item
          initialValue={data[0].address.state}
          name="state"
          hasFeedback
          rules={[{ required: true, message: 'Please enter state.' },]}
        >
          <Input name="state" placeholder="State" size="large"
          />
        </Form.Item>

        <Form.Item className="lbl-name" label="Country"></Form.Item>
        <Form.Item
          initialValue={data[0].address.country}
          name="country"
          hasFeedback
          rules={[{ required: true, message: 'Please enter country.' },]}
        >
          <Input name="country" placeholder="Country" size="large"
          />
        </Form.Item>

        <Form.Item className="lbl-name" label="Postal Code"></Form.Item>
        <Form.Item
          initialValue={data[0].address.postalCode}
          name="postalCode"
          hasFeedback
        >
          <Input name="postalCode" placeholder="Postal Code" size="large"
          />
        </Form.Item>

        <Form.Item className="lbl-name" label="Profession"></Form.Item>
        <Form.Item
          name="profession"
          initialValue={data[0].Profession}
          hasFeedback
          rules={[{ required: true, message: 'Please enter profession.' },]}
        >
          <Input name="profession" placeholder="Profession" size="large"
          />
        </Form.Item>

        <Form.Item className="lbl-name" label="Gender"></Form.Item>
        <Form.Item
          name="gender"
          initialValue={data[0].gender}
          hasFeedback
          rules={[{ required: true, message: 'Please select your Gender!' }]}
        >
          <Select size="large" placeholder="Please select a Gender">
            <Option value="Male">Male</Option>
            <Option value="Female">Female</Option>
            <Option value="Other">Other</Option>
          </Select>
        </Form.Item>
        <Form.Item className="lbl-name" label="DOB"></Form.Item>
                              {
                                DOB?
                                <Form.Item
                                // initialValue={DOB}
                                name="dob"
                                hasFeedback
                              >
                                <DatePicker  name="dob" size="large"  defaultValue={moment(data[0].DOB, dateFormat)}/>
                              </Form.Item>
                              :
                              <Form.Item
                              name="dob"
                              initialValue={DOB}
                              hasFeedback
                            >
                              <DatePicker  name="dob" size="large" defaultValue={moment('2020/10/13', dateFormat)}  />
                            </Form.Item>
                       }
        <Form.Item className="lbl-name" label="Status"></Form.Item>
        <Form.Item
          name="status"
          initialValue={data[0].isActive}
          hasFeedback
        >
         <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
        defaultChecked={data[0].isActive}
    />
        </Form.Item>
        <Form.Item className="lbl-name" label="Featured"></Form.Item>
        <Form.Item
          name="featured"
          initialValue={data[0].isFeatured}
          hasFeedback
        >
         <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
        defaultChecked={data[0].isFeatured}
    />
        </Form.Item>

        <Form.Item className="lbl-name" label="Roles"></Form.Item>
        <Form.Item
          hasFeedback
          initialValue={data[0].roles.toString().split(',')}
          name="roles"
          rules={[{ required: true, message: 'Please select Roles!', type: 'array' }]}
        >
          <Select mode="multiple" size="large" placeholder="Please select Roles">
            <Option value="Tourist">Tourist</Option>
            <Option value="TourGuide">Tour Guide</Option>
            <Option value="Club">Club</Option>
          </Select>
        </Form.Item>

        <Form.Item className="lbl-name" label="Languages"></Form.Item>
        <Form.Item
          hasFeedback
          initialValue={
            data[0].lang?
            data[0].lang.toString().split(',')
            :null
          }
          name="lang"
          rules={[{ required: true, message: 'Please select Languages!', type: 'array' }]}
        >
          <Select mode="multiple" size="large" placeholder="Please select Language">
            <Option value="English">English</Option>
            <Option value="Urdu">Urdu</Option>
            <Option value="Punjabi">Punjabi</Option>
            <Option value="Pashto">Pashto</Option>
            <Option value="Sindhi">Sindhi</Option>
            <Option value="Saraiki">Saraiki</Option>
            <Option value="Blochi">Blochi</Option>
            <Option value="Hindko">Hindko</Option>
            <Option value="Brahui">Brahui</Option>
            <Option value="Kashmiri">Kashmiri</Option>
            <Option value="Burushaski">Burushaski</Option>
            <Option value="Kalash">Kalash</Option>
            <Option value="Khowar">Khowar</Option>
            <Option value="Shina">Shina</Option>
            <Option value="Balti">Balti</Option>
          </Select>
        </Form.Item>

        <Form.Item >
          <Button className="btn-admin-login" size="large" type="primary" htmlType="submit">
            Update
                    </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
function mapStateToProps(state: any) {
  const { loggingIn } = state.authentication;
  const { type, message } = state.alert;
  return {
    type,
    message,
    loggingIn,
    data: state.FetchEditUser.fetchEditUserObject,
  };
}
export const connectedEditUser = connect(mapStateToProps)(EditUser);


