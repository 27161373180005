import {experiencesConstants} from '../constants';

export function FetchPlaceExperiences(state={getAllPlaceExperiencesObject:false},action:any)
{

    switch (action.type) {
        case experiencesConstants.GET_EXPERIENCE_BY_PLACE_REQUEST:
          return state;
          case experiencesConstants.GET_EXPERIENCE_BY_PLACE_SUCCESS:
          return{
            ...state,
            getAllPlaceExperiencesObject:action.user
          };
      default:
        return state
    }
}

export function FetchAllPlaceExperiences(state={getAllPlaceExperiencesObjectFull:''},action:any)
{

    switch (action.type) {
        case experiencesConstants.GET_ALL_EXPERIENCE_BY_PLACE_REQUEST:
          return state;
          case experiencesConstants.GET_ALL_EXPERIENCE_BY_PLACE_SUCCESS:
          return{
            ...state,
            getAllPlaceExperiencesObjectFull:action.user
          };
      default:
        return state
    }
}


export function FetchAllPlaceExperiencesAdmin(state={getAllPlaceExperiencesAdminObject:''},action:any)
{

    switch (action.type) {
        case experiencesConstants.GET_ALL_EXPERIENCE_ADMIN_REQUEST:
          return state;
          case experiencesConstants.GET_ALL_EXPERIENCE_ADMIN_SUCCESS:
          return{
            ...state,
            getAllPlaceExperiencesAdminObject:action.user
          };
      default:
        return state
    }
}


export function FetchExperiencesAdmin(state={getExperiencesAdminObject:''},action:any)
{

    switch (action.type) {
        case experiencesConstants.GET_EXPERIENCE_ADMIN_REQUEST:
          return state;
          case experiencesConstants.GET_EXPERIENCE_ADMIN_SUCCESS:
          return{
            ...state,
            getExperiencesAdminObject:action.user
          };
      default:
        return state
    }
}
