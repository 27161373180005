import {agenciesConstants} from '../constants';

export function FetchTravelAgencies(state={getAllTravelAgenciesObject:false},action:any)
{

    switch (action.type) {
        case agenciesConstants.GET_ADMIN_TRAVEL_AGENCIES_REQUEST:
          return state;
          case agenciesConstants.GET_ADMIN_TRAVEL_AGENCIES_SUCCESS:
          return{
            ...state,
            getAllTravelAgenciesObject:action.user
          };
      default:
        return state
    }
}

export function FetchEditTravelAgencies(state={fetchEditTravelAgenciesObject:""},action:any)
{
  switch (action.type) {
    case agenciesConstants.GET_EDIT_ADMIN_TRAVEL_AGENCIES_REQUEST:
      return state;
      case agenciesConstants.GET_EDIT_ADMIN_TRAVEL_AGENCIES_SUCCESS:
      return{
        ...state,
        fetchEditTravelAgenciesObject:action.user
      };
  default:
    return state
}
}
