export const answerConstants = {

  ADD_ANSWER_REQUEST: ' ADD_ANSWER_REQUEST',
  ADD_ANSWER_SUCCESS: 'ADD_ANSWER_SUCCESS',
  ADD_ANSWER_FAILURE: 'ADD_ANSWER_FAILURE',

  GET_ALL_ANSWER_REQUEST: ' GET_ALL_ANSWER_REQUEST',
  GET_ALL_ANSWER_SUCCESS: 'GET_ALL_ANSWER_SUCCESS',
  GET_ALL_ANSWER_FAILURE: 'GET_ALL_ANSWER_FAILURE',

  GET_ALL_PLACE_ANSWER_REQUEST: ' GET_ALL_PLACE_ANSWER_REQUEST',
  GET_ALL_PLACE_ANSWER_SUCCESS: 'GET_ALL_PLACE_ANSWER_SUCCESS',
  GET_ALL_PLACE_ANSWER_FAILURE: 'GET_ALL_PLACE_ANSWER_FAILURE',

  GET_ANSWER_REQUEST: ' GET_ANSWER_REQUEST',
  GET_ANSWER_SUCCESS: 'GET_ANSWER_SUCCESS',
  GET_ANSWER_FAILURE: 'GET_ANSWER_FAILURE',

  GET_USER_ANSWER_REQUEST: ' GET_USER_ANSWER_REQUEST',
  GET_USER_ANSWER_SUCCESS: 'GET_USER_ANSWER_SUCCESS',
  GET_USER_ANSWER_FAILURE: 'GET_USER_ANSWER_FAILURE',

  GET_ANSWER_BY_QUESTION_ADMIN_REQUEST: ' GET_ANSWER_BY_QUESTION_ADMIN_REQUEST',
  GET_ANSWER_BY_QUESTION_ADMIN_SUCCESS: 'GET_ANSWER_BY_QUESTION_ADMIN_SUCCESS',
  GET_ANSWER_BY_QUESTION_ADMIN_FAILURE: 'GET_ANSWER_BY_QUESTION_ADMIN_FAILURE',


  DELETE_ANSWER_ADMIN_REQUEST: 'DELETE_ANSWER_ADMIN_REQUEST',
  DELETE_ANSWER_ADMIN_SUCCESS: 'DELETE_ANSWER_ADMIN_SUCCESS',
  DELETE_ANSWER_ADMIN_FAILURE: 'DELETE_ANSWER_ADMIN_FAILURE',
};
