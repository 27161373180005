import React, { useEffect} from 'react';
import { Button, Form, Input,Switch} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { history, store } from '../../../Helpers';
import { CheckOutlined,CloseOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { deviceInfoActions } from '../../../actions';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};

type EditDeviceInfo = {
  dispatch: any;
  match: any;
  data: any;
}
const {TextArea} = Input

function EditDeviceInfo(props: EditDeviceInfo) {
  store.getState().FetchEditActivity.fetchEditActivityObject = "";
  let formRef:any = React.createRef<FormInstance>();
  let { dispatch, match, data } = props;

  const onFinish = (values: any) => {
     console.log(values)


    dispatch(deviceInfoActions.UpdateDeviceInfo(values, match.params.id));
  };
  useEffect(() => {

    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {
      dispatch(deviceInfoActions.FetchDeviceInfoById(match.params.id));
    }

  }, [dispatch,match]);

  const onFinishFailed = (errorInfo: any) => {
  };
  if (!data) {

    return (<div></div>)
  }
  return (
    <div>
      <Form
        {...layout}
        ref={formRef}
        className="admin-login-form"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
         <Form.Item className="lbl-name" label="App Store Link"></Form.Item>
            <Form.Item
              name="appStoreLink"
              initialValue={data.appStoreLink}
              rules={[{ required: true, message: 'Please enter a link.' },]}
            >
              <Input name="appStoreLink" size="large" placeholder="App Store Link" />
            </Form.Item>
            <Form.Item className="lbl-name" label="Play Store Link"></Form.Item>
            <Form.Item
             initialValue={data.playStorLink}
              name="playStorLink"
              rules={[{ required: true, message: 'Please enter a link.' },]}
            >
              <Input name="playStorLink" size="large" placeholder="Play Store Link" />
            </Form.Item>
            <Form.Item className="lbl-name" label="App Store Version"></Form.Item>
            <Form.Item
              name="latestVersionOnAppStore"
             initialValue={data.latestVersionOnAppStore}
              rules={[{ required: true, message: 'Please enter a App Store Version.' },]}            >
              <Input name="latestVersionOnAppStore" size="large" placeholder="App Store Version" />
            </Form.Item>
            <Form.Item className="lbl-name" label="Play Store Version"></Form.Item>
            <Form.Item
              name="latestVersionOnPlayStore"
             initialValue={data.latestVersionOnPlayStore}
              rules={[{ required: true, message: 'Please enter a Play Store Version.' },]}            >
              <Input name="latestVersionOnPlayStore" size="large" placeholder="Play Store Version" />
            </Form.Item>
             <Form.Item className="lbl-name" label="English Message"></Form.Item>
            <Form.Item
              name="mesageEng"
             initialValue={data.mesageEng}
              rules={[{ required: true, message: 'Please enter a message.' },]}            >
              <TextArea name="mesageEng"  rows={4} placeholder="Message in English" />
            </Form.Item>
            <Form.Item className="lbl-name" label="Urdu Message"></Form.Item>
            <Form.Item
              name="mesageUrd"
             initialValue={data.mesageUrd}
              >
              <TextArea name="mesageUrd"  rows={4} placeholder="Message in Urdu" />
            </Form.Item>
            <Form.Item className="lbl-name" label="Has Update"></Form.Item>
            <Form.Item
              name="hasUpdate"
              initialValue={data.haseForceUpdate}
            >
            <Switch
            defaultChecked={data.hasUpdate}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            />
            </Form.Item>
            <Form.Item className="lbl-name" label="Has Force Update"></Form.Item>
            <Form.Item
              name="haseForceUpdate"
              initialValue={data.haseForceUpdate}

            >
            <Switch
             defaultChecked={data.haseForceUpdate}
             checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            />
            </Form.Item>

        <Form.Item >
          <Button className="btn-admin-login" style={{ marginTop: '20px' }} size="large" type="primary" htmlType="submit">
            Update Information
                    </Button>
        </Form.Item>
      </Form>
    </div>
  )

}

function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    data: state.FetchDeviceInfoById.getDeviceInfoByIdObject,
  };
}

export const connectedEditDeviceInfo = connect(mapStateToProps)(EditDeviceInfo);
