import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Upload, message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import {Avatar} from '@chakra-ui/core'
import { CloudUploadOutlined } from "@ant-design/icons";
import { history, store } from '../../../Helpers';
import { connect } from 'react-redux';
import { facilitiesActions } from '../../../actions';
import { config } from "../../../config";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};

type EditActivity = {
  dispatch: any;
  match: any;
  data: any;
}

const { Dragger } = Upload;

const prop: any = {
  name: 'userImage',
  action: '',
  headers: {
    authorization: 'authorization-text',
  },
};
let image: any = '';
let icon: any = '';

function beforeUpload(file: any) {

  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}


function EditActivity(props: EditActivity) {
  store.getState().FetchEditActivity.fetchEditActivityObject = "";
  let formRef:any = React.createRef<FormInstance>();
  let { dispatch, match, data } = props;
  const [quilValue, setquilValue] = useState('');

  // const [finalData,setFintalData] = useState(data);

  const onFinish = (values: any) => {
    let imageFlag = false;
    let iconFlag = false;
    let formData = new FormData();
    formData.append("title", values.title)
    formData.append("quilValue", quilValue)
    if (values.image) {
      imageFlag = true;
      formData.append("facilityImage", values.image.file.originFileObj)
    }
    else if (values.icon) {
      iconFlag = true;
      formData.append("facilityImage", values.icon.file.originFileObj)
    }
    formData.append("imageFlag", imageFlag.toString());
    formData.append("iconFlag", iconFlag.toString());

    dispatch(facilitiesActions.UpdateActivity(formData, match.params.id));
  };
  useEffect(() => {

    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {

      dispatch(facilitiesActions.FetchEditActivities(match.params.id));
    }

  }, [dispatch,match]);
  // useEffect(()=>{
  //  return ()=> setFintalData(data)
  // },[])

  const onFinishFailed = (errorInfo: any) => {
  };
  if (!data) {

    return (<div></div>)
  }
  const onQuilValueChange = (value: any) => {
    setquilValue(value);

  }

  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
  };

  image = config.s3BucketUrl+ data[0].image;
  icon = config.s3BucketUrl+data[0].icon;
  return (
    <div>
      <Form
        {...layout}
        ref={formRef}
        className="admin-login-form"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item className="lbl-name" label="Title"></Form.Item>
        <Form.Item
          initialValue={data[0].title}
          name="title"
          rules={[{ required: true, message: 'Please enter a title.' },]}
        >
          <Input name="title" size="large" placeholder="Title" />
        </Form.Item>
        <Form.Item className="lbl-name" label="Photo"></Form.Item>
        <Form.Item
          name="image"
          className="file-upload">
          <Dragger beforeUpload={beforeUpload}
            onPreview={onPreview}
            multiple={false}
            {...prop}
            listType="picture-card">

            <p className="ant-upload-drag-icon">
              <CloudUploadOutlined style={{ color: "#000000" }} />
              <span className="upld-desc" > Upload or drop photo here</span>
            </p>
          </Dragger>
        </Form.Item>
        <Form.Item style={{ width: "50%" }}>
        {
          data[0].image? <img src={image} width="30%"  alt={data[0].title} style={{ textAlign: 'center' }} />
          :<Avatar name={data[0].title}/>
        }

        </Form.Item>
        <Form.Item className="lbl-name" label="Icon"></Form.Item>
        <Form.Item
          name="icon"
          className="file-upload">
          <Dragger beforeUpload={beforeUpload}
            onPreview={onPreview}
            multiple={false}
            {...prop}
            listType="picture-card">
            <p className="ant-upload-drag-icon">
              <CloudUploadOutlined style={{ color: "#000000" }} />
              <span className="upld-desc" > Upload or drop photo here</span>
            </p>
          </Dragger>
        </Form.Item>
        <Form.Item style={{ width: "50%" }}>
          <img src={icon} width="30%" alt={icon} style={{ textAlign: 'center' }} />
        </Form.Item>
        <Form.Item
          initialValue={data[0].quilValue}
          name="quil"
        >
          <ReactQuill style={{ height: '200px', marginBottom: '20px' }} theme="snow" value={data[0].quilValue} onChange={onQuilValueChange} />
        </Form.Item>
        <Form.Item >
          <Button className="btn-admin-login" style={{ marginTop: '20px' }} size="large" type="primary" htmlType="submit">
            Update Activity
                    </Button>
        </Form.Item>
      </Form>
    </div>
  )

}

function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    data: state.FetchEditActivity.fetchEditActivityObject,
  };
}

export const connectedEditActivity = connect(mapStateToProps)(EditActivity);
