export const albumCategoryConstants = {
  GET_ALL_ALBUM_CATEGORY_REQUEST: "GET_ALL_ALBUM_CATEGORY_REQUEST",
  GET_ALL_ALBUM_CATEGORY_SUCCESS: " GET_ALL_ALBUM_CATEGORY_SUCCESS",
  GET_ALL_ALBUM_CATEGORY_FAILURE: "GET_ALL_ALBUM_CATEGORY_FAILURE",

  ADD_ALBUM_CATEGORY_REQUEST: " ADD_ALBUM_CATEGORY_REQUEST",
  ADD_ALBUM_CATEGORY_SUCCESS: "ADD_ALBUM_CATEGORY_SUCCESS",
  ADD_ALBUM_CATEGORY_FAILURE: "ADD_ALBUM_CATEGORY_FAILURE",

  EDIT_ALBUM_CATEGORY_REQUEST: " EDIT_ALBUM_CATEGORY_REQUEST",
  EDIT_ALBUM_CATEGORY_SUCCESS: "EDIT_ALBUM_CATEGORY_SUCCESS",
  EDIT_ALBUM_CATEGORY_FAILURE: "EDIT_ALBUM_CATEGORY_FAILURE",

  UPDATE_ALBUM_CATEGORY_REQUEST: " UPDATE_ALBUM_CATEGORY_REQUEST",
  UPDATE_ALBUM_CATEGORY_SUCCESS: "UPDATE_ALBUM_CATEGORY_SUCCESS",
  UPDATE_ALBUM_CATEGORY_FAILURE: "UPDATE_ALBUM_CATEGORY_FAILURE",

  DELETE_ALBUM_CATEGORY_REQUEST: " DELETE_ALBUM_CATEGORY_REQUEST",
  DELETE_ALBUM_CATEGORY_SUCCESS: "DELETE_ALBUM_CATEGORY_SUCCESS",
  DELETE_ALBUM_CATEGORY_FAILURE: "DELETE_ALBUM_CATEGORY_FAILURE",
};
