import { questionConstants } from "../constants";
import { questionsService } from "../services";
import { alertActions } from ".";
import { history } from "../Helpers";

export const questionActions = {
 FetchPlaceQuestion,
 AddQuestion,
 FetchRelatedUestion,
 FetchQuestionById,
 FetchUserQuestions,
 FetchAllPlaceQuestion,
 FetchAllPlaceQuestionAdmin,
 FetchEditQuestionAdmin,
 DeleteQuestionAdmin
};

function FetchPlaceQuestion(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    questionsService.FetchPlaceQuestions(id).then(
      (res) => {
        // history.push('/place')
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };


  function request(id: any) {
    return { type: questionConstants.GET_ALL_PLACE_QUESTION_REQUEST, id };
  }
  function success(user: any) {
    return { type: questionConstants.GET_ALL_PLACE_QUESTION_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: questionConstants.GET_ALL_PLACE_QUESTION_FAILURE, error };
  }
}

function FetchAllPlaceQuestion(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    questionsService.FetchAllPlaceQuestions(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };


  function request(id: any) {
    return { type: questionConstants.GET_ALL_QUESTION_REQUEST, id };
  }
  function success(user: any) {
    return { type: questionConstants.GET_ALL_QUESTION_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: questionConstants.GET_ALL_QUESTION_FAILURE, error };
  }
}

function FetchUserQuestions(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    questionsService.FetchUserQuestions(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: questionConstants.GET_USER_QUESTION_REQUEST, id };
  }
  function success(user: any) {
    return { type: questionConstants.GET_USER_QUESTION_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: questionConstants.GET_QUESTION_FAILURE, error };
  }
}

function AddQuestion(data: any) {

  return (dispatch: any) => {
    dispatch(request(data));
    questionsService
      .AddQuestion(data)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "201") {
          dispatch(success(data));
          dispatch(
            alertActions.success("You have successfully add a question ")
          );
          history.go(0);
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: questionConstants.ADD_QUESTION_REQUEST, data };
    }
    function success(user: any) {
      return { type: questionConstants.ADD_QUESTION_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: questionConstants.ADD_QUESTION_FAILURE, error };
    }
  };
}

function FetchRelatedUestion(ids: any) {
  return (dispatch: any) => {
    dispatch(request(ids));
    questionsService.FetchRelatedQuestions(ids).then(
      (res) => {
        // history.push('/place')
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(ids: any) {
    return { type: questionConstants.GET_RELATED_QUESTION_REQUEST, ids };
  }
  function success(user: any) {
    return { type: questionConstants.GET_RELATED_QUESTION_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: questionConstants.GET_RELATED_QUESTION_FAILURE, error };
  }
}


function FetchQuestionById(ids: any) {
  return (dispatch: any) => {
    dispatch(request(ids));
    questionsService.FetchQuestionsById(ids).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(ids: any) {
    return { type: questionConstants.GET_SINGLE_QUESTION_REQUEST, ids };
  }
  function success(user: any) {
    return { type: questionConstants.GET_SINGLE_QUESTION_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: questionConstants.GET_SINGLE_QUESTION_FAILURE, error };
  }
}


function FetchEditQuestionAdmin(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    questionsService.FetchEditQuestionAdmin(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(ids: any) {
    return { type: questionConstants.GET_EDIT_QUESTION_ADMIN_REQUEST, ids };
  }
  function success(user: any) {
    return { type: questionConstants.GET_EDIT_QUESTION_ADMIN_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: questionConstants.GET_EDIT_QUESTION_ADMIN_FAILURE, error };
  }
}

function FetchAllPlaceQuestionAdmin(page: any) {
  return (dispatch: any) => {
    dispatch(request(page));
    questionsService.FetchAllPlaceQuestionsAdmin(page).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };


  function request(page: any) {
    return { type: questionConstants.GET_ALL_QUESTION_ADMIN_REQUEST, page };
  }
  function success(user: any) {
    return { type: questionConstants.GET_ALL_QUESTION_ADMIN_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: questionConstants.GET_ALL_QUESTION_ADMIN_FAILURE, error };
  }
}

function DeleteQuestionAdmin(data: any, id: any) {
  return (dispatch: any) => {
    dispatch(request(data, id));
    questionsService
      .DeleteQuestionAdmin(data, id)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "200") {
          dispatch(success(data));
          dispatch(alertActions.success("You have successfully delete a Question ")
          );
          history.go(0);
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any, id: any) {
      return { type: questionConstants.DELETE_QUESTION_ADMIN_REQUEST, data, id };
    }
    function success(user: any) {
      return { type: questionConstants.DELETE_QUESTION_ADMIN_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: questionConstants.DELETE_QUESTION_ADMIN_FAILURE, error };
    }
  };
}
