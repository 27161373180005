import React from "react";
import {Avatar} from "@chakra-ui/core";
import './cards.css';

import {history} from '../../Helpers'
import {config} from '../../config'
import NoImage from '../../Assets/place-holder-image.svg'
import flagLine from '../../Assets/icon_place_mark_visited_inactive_white.svg';
import flagFill from '../../Assets/icon_place_mark_visited_active_white.svg';
import heartLine from '../../Assets/icon_place_mark_favorite_inactive_white.svg';
import heartFill from '../../Assets/icon_place_mark_favorite_active_white.svg';
const TimeStamp =require("react-timestamp");


type PlaceCards={
  data:any;
}

export function PlaceCards(props:PlaceCards)
{
 let user = JSON.parse(localStorage.getItem('user')!)

  const {data} =props;

  let actIcons:any=[];

  if(data.activities)
  {

    data.activities.forEach((element:any) => {

      actIcons.push({
       icon:element.icons,
       title:element.title,
      })
    });
  }

  const onPlaceClick=(id:any,placeName:string)=>{
    let placeTitle='';
    placeTitle=placeName.replace(/[ ,?]/g,'-')
    history.push(`/travel-places/${placeTitle}-${id}`)
  }
  return (
    <div className="main-cont pak-map-place-container" onClick={()=>{onPlaceClick(data.id,data.placeName)}}>
        <div className="place-container" style={{ position: "relative" }}>
          <div >
            <div >
              {
                data.placeImage?<img className="place-img" src={config.s3BucketUrl+data.placeImage}  alt={data.placeImage} />
                :
                <img className="place-img-broken" src={NoImage} height="211"  alt={NoImage} />
              }

            </div>
            <div className="bottom-right-icon-left">
              {
                user?
               user.user.favPlaces.includes(data.id)?
               <img src={heartFill} alt={heartFill} width="32" height="32"/>:
               <img src={heartLine} alt={heartLine} width="32" height="32"/>
               :<img src={heartLine} alt={heartLine} width="32" height="32"/>
              }
            </div>
            <div className="bottom-right-icon-right">
            {
               user?
              user.user.vistedPlaces.includes(data.id)?
              <img src={flagFill} alt={flagFill} width="32" height="32" />:
              <img src={flagLine} width="32" alt={flagLine} height="32"/>
              : <img src={flagLine} width="32" alt={flagLine} height="32"/>
              }
            </div>
            <div className="story-txt-container">
              <p className="place-txt">{data.placeName}</p>
              <p>
                <small className="timespan-txt"> in </small>
             <small className="category-txt">{data.categoryName}</small>{" "}
                <small className="timespan-txt">updated <TimeStamp relative date={data.createdDate} autoUpdate /></small>
              </p>
            </div>
            <div id="main-place-body-count">
              <div>
                <small className="small-txt-ftr-plc">{data.storiesCount} Stories</small>
              </div>
              <div>
                <small className="small-txt-ftr-plc">{data.questionsCount} Questions</small>
              </div>
              <div>
                <small className="small-txt-ftr-plc">{data.experienceCount} Experiences</small>
              </div>
            </div>
            <div className="footer-plc-container">
              <div>
              {
                  actIcons?
                    actIcons.map((item: any) => (
                      <div className="tooltip">
                      <span className="tooltiptext">{item.title}</span>
                         <Avatar className="place-activity-icon"  size="sm" name={item.title}  src={config.s3BucketUrl+item.icon} >
                          </Avatar>
                      </div>
                    ))
                  :null
                }

              </div>
            </div>
          </div>
        </div>
    </div>
  );
}
