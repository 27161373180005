import React, { useState, useEffect } from 'react';
import { Table, Dropdown, Menu,Modal, Input,Avatar} from 'antd';
import { history } from '../../../Helpers';
import { placesActions } from '../../../actions';
import { connect } from 'react-redux';
import { config } from "../../../config";
import { SettingOutlined } from '@ant-design/icons';

type AdminUserPhotos={
  dispatch:any;
  data:any;
}

function AdminUserPhotos(props: AdminUserPhotos) {
  let fetchId: any = '';
  const { dispatch, data } = props;
  const [checkSearch, setcheckSearch] = useState(false);
  const [page,setPage]=useState(1);
  const [filterData, setfilterData] = useState<any>();
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {
      dispatch(placesActions.GetUserAllPlaceImageAdmin(page));
    }

  }, [dispatch]);

  const [selectedRowKeys, setselectedRowKeys] = useState([]);


  const onSelectChange = (selectedRowKeys: any) => {
    setselectedRowKeys(selectedRowKeys)

  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  if (!data) {
    return (<div></div>)
  }
  let filterArray: any = [];
  let search = (value: any) => {
    const filterArrayData = data.docs.filter((o: any) =>
      Object.keys(o).some(k =>
        String(o[k])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );
    filterArrayData.forEach((element: any) => {

      if (element.userplaceimages.images) {
        imagee = config.s3BucketUrl+element.userplaceimages.images
      }
      else {
        imagee = "N/A"
      }

      filterArray.push({
        key: element.userplaceimages._id,
        id: element.userplaceimages._id,
        Photo: imagee,
        createdDate: element.userplaceimages.createdDate.split("T")[0],
        userName:element.users.firstName+" "+element.users.lastName,
        placeName:element.places.placeName,
      })

    });
    setfilterData(filterArray);
    setcheckSearch(true);
  };



  const categoriesArray: any = [];
  let imagee: any = '';
  data.docs.forEach((element: any, index: any) => {

    if (element.userplaceimages.images) {
      imagee = config.s3BucketUrl+element.userplaceimages.images
    }
    else {
      imagee = "N/A"
    }

    categoriesArray.push({
          key: element.userplaceimages._id,
        id: element.userplaceimages._id,
        Photo: imagee,
        createdDate: element.userplaceimages.createdDate.split("T")[0],
        userName:element.users.firstName+" "+element.users.lastName,
        placeName:element.places.placeName,
    })

  });

  const onMenuClick = (id: any) => {
    fetchId = id;
  }
  const onSelect = (e: any) => {
    alert(fetchId);
    if (e.key == "DU") {
      Modal.warning({
        title: 'Opppssss',

        content: (
          <div className="warning-modal">
            <p>Are you sure you want to delete this?</p>
          </div>
        ),
        className: "cancel-modal",
        width: 560,
        okType: 'danger',
        okText: 'ok',
        okCancel: true,
        centered: true,
        cancelText: 'cancel',
        onOk: () => {
          dispatch(placesActions.DeletePhotoAdmin(fetchId));
          dispatch(placesActions.GetUserAllPlaceImageAdmin(page));
        },
        onCancel() {
        },
      });
    }


  }
  const onImageClick = (image: any) => {
    window.open(image);
  }
  const columns = [
    {
      title: 'Photo',
      dataIndex: 'Photo',
      render: (records: any) => <div onClick={() => onImageClick(records)}><Avatar size="large" className="table-img" src={records}></Avatar></div>

    },
    {
      title: 'User Name',
      dataIndex: 'userName',
    },
    {
      title: 'Place Name',
      dataIndex: 'placeName',
    },
    {
      title: 'Created  Date',
      dataIndex: 'createdDate',
    },
    {
      title: 'Settings',
      render: (records: any) => <Dropdown className="admin-acc" overlay={(
        <Menu onClick={onSelect}>
          <Menu.Item key="DU" onClick={() => onMenuClick(records.id)} className="user-menu-item">
            <span>
              Delete Photo
          </span>
          </Menu.Item>
        </Menu>
      )} trigger={['click']} >
        <SettingOutlined />
      </Dropdown>,
    },
  ];
  const onPageChange=(e:any)=>{
    dispatch(placesActions.GetUserAllPlaceImageAdmin(e));
    setPage(e);
  }

  return (
    <div>
      <div style={{ width: '50%', float: 'left' }}>
        <Input.Search

          placeholder="Search by..."
          enterButton
          size="large"
          onSearch={search}
        />
      </div>
      {
        checkSearch ? <Table className="show-table" pagination={{total:filterData.length, onChange:onPageChange}} rowSelection={rowSelection} columns={columns} dataSource={filterData} /> :
          <Table pagination={{total:data.totalDocs, defaultPageSize: 10, onChange:onPageChange}} className="show-table" rowSelection={rowSelection} columns={columns} dataSource={categoriesArray} />

      }
    </div>
  );

}

function mapStateToProps(state: any) {
  return {
    data: state.GetUserAllPlaceImageAdmin.getUserAllPlaceImageAdminObject
  };
}

export const connectedAdminUserPhotos = connect(mapStateToProps)(AdminUserPhotos);

