import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { FormInstance } from 'antd/lib/form';
import { userActions } from '../../actions'
import {MetaDecorator} from '../../utils';
import Navbar from '../Navbar/Navbar'
import { Layout, Menu, Input, Form, message, Upload, Button, Select, DatePicker, Modal, Switch, Collapse } from 'antd';
import moment from 'moment';
import './usersettings.css'
import { connect } from 'react-redux';
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  CloudUploadOutlined
} from '@ant-design/icons';

type UserSettings = {
  dispatch: any;
  data: any;
  match: any

}
const { TextArea } = Input;
const { Dragger } = Upload;
const { Option } = Select;
const prop: any = {
  name: 'userImage',
  action: '',
};

const dateFormat = 'YYYY/MM/DD';
const { Panel } = Collapse;
function UserSettings(props: UserSettings) {

  let DOB:any="";
  let formRef: any = React.createRef<FormInstance>();
  const { data, dispatch, match } = props;
  const [visible, setVisible] = useState(false);
  const [isProfile, setIsProfile] = useState(true);
  const [isPrivacy, setIsPrivacy] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [isSocail, setIsSocial] = useState(false);

  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    dispatch(userActions.getCurrentUser(match.params.id));

  }, []);

  const showModal = () => {
    setVisible(true);
  };
  const handleOk = () => {
    setVisible(false)
  };

  const handleCancel = () => {
    setVisible(false);
  };
  if (!data) {
    return (<div></div>)
  }

  // const image = config.apiurl + "/" + data[0].image;
  const onFinish = (values: any) => {

    const formData = new FormData();

    if(values.dob)
    {
      formData.append("DOB", moment(values.dob).toISOString())
    }
    else
    {
      formData.append("DOB", DOB)
    }
    if(values.city)
    {
      formData.append("city", values.city)
    }
    else
    {
      formData.append("city", data[0].address.city)
    }
    if(values.country)
    {
      formData.append("country", values.country)
    }
    else
    {
      formData.append("country", data[0].address.country)
    }
    if(values.street)
    {
      formData.append("street", values.street)
    }
    else
    {
      formData.append("street", data[0].address.street)
    }
    if(values.state)
    {
      formData.append("state", values.state)
    }
    else
    {
      formData.append("state", data[0].address.state)
    }
    if (data[0].userName !== values.userName) {
      formData.append("userName", values.userName)
    }
    formData.append("lang", values.lang)
    formData.append("aboutMe", values.about)
    formData.append("intro", values.intro)
    formData.append("education", values.education)
    formData.append("email", values.email)
    formData.append("firstName", values.firstName)
    formData.append("gender", values.gender)
    formData.append("lastName", values.lastName)
    formData.append("phone", values.phone)
    formData.append("showBirthDay", values.privacyBirth)
    formData.append("showEmail", values.privacyEmail)
    formData.append("showMobile", values.privacyPhone)
    formData.append("profession", values.profession)
    formData.append("userNameChangeAttempt", values.userNameChangeAttempt)
    if (values.image) {
      formData.append("userImage", values.image.file.originFileObj)
    }
    dispatch(userActions.UpdateUser(formData, match.params.id));
  };
  const onFinishFailed = (errorInfo: any) => {
  };

  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 8 },
  };
  const layoutModal = {
    labelCol: { span: 24 },
    wrapperCol: { span: 22 },
  };

  const onNavigate = (e: any) => {

    if (e.key === "1") {
      setIsProfile(true);
      setIsPrivacy(false)
      setIsNotification(false)
      setIsSocial(false)
    }
    else if (e.key === "2") {
      setIsPrivacy(true)
      setIsProfile(false);
      setIsNotification(false)
      setIsSocial(false)
    }
    else if (e.key === "3") {
      setIsNotification(true)
      setIsProfile(false);
      setIsPrivacy(false)
      setIsSocial(false)
    }
    else {
      setIsNotification(false)
      setIsProfile(false);
      setIsPrivacy(false)
      setIsSocial(true)
    }
  }
  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow: any = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  const onChange = (checked: any) => {
    console.log(`switch to ${checked}`);
  }
  if(data[0].DOB)
  {

   DOB=moment(data[0].DOB).format(dateFormat);
  }
   const { Sider, Content } = Layout;
   let meta={
    title:`Member Profile - Pakrism`,
    description:``,
    imageAlt:"",
    imageUrl:""
  }
  return (
    <React.Fragment>
      <MetaDecorator title={meta.title} description={meta.description} imageAlt={meta.imageAlt} imageUrl={meta.imageUrl} match={match}/>
      <Layout>
        <Navbar />
        <Layout>
          <Sider>
            <div className="settings-txt-container">
              <p className="settings-heading travel-story-taller-heading">settings</p>
            </div>
            <Menu onClick={onNavigate} className="user-settings-left-menu-list" theme="light" defaultSelectedKeys={["1"]} mode="inline">
              <Menu.Item key='1' icon={<UserOutlined />}>
                Profile
                 </Menu.Item>
              <Menu.Item key="2" icon={<VideoCameraOutlined />}>
                Privacy
                 </Menu.Item>
              <Menu.Item key="3" icon={<UploadOutlined />}>
                Email & notifications
                 </Menu.Item>
              <Menu.Item key="4" icon={<UploadOutlined />}>
                Social media
                 </Menu.Item>
            </Menu>
          </Sider>
          <Content className="user-setting-content">
            <Form
              {...layout}
              ref={formRef}
              className=""
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <div className="settings-header-container">
                <div className="setting-heading-txt-container">
                  {
                    isProfile ? <p className="setting-heading-txt">Profile</p> : null
                  }
                  {
                    isPrivacy ? <p className="setting-heading-txt">Privacy</p> : null
                  }
                  {
                    isNotification ? <p className="setting-heading-txt">Email & notification</p> : null
                  }
                  {
                    isSocail ? <p className="setting-heading-txt">Social Media</p> : null
                  }

                </div>
                <div className="setting-btn-txt-container">
                  <Button className="user-setting-btn" size="large" type="primary" htmlType="submit">
                    Save
                    </Button>
                </div>
              </div>
              <div>
                {
                  isProfile ?
                    <div className="basic-info-container">
                      <div className="basic-info-inner-container">
                        <div>
                          <p className="basic-info-heading">Basic info</p>
                        </div>
                        <div>
                          <Form.Item className="lbl-name" label="Featured image"></Form.Item>
                          <Form.Item
                            className="setting-drager-container"
                            name="image"
                          >
                            <Dragger beforeUpload={beforeUpload}
                              className="seting-drager"
                              onPreview={onPreview}
                              name="userImage"
                              multiple={false}
                              {...prop}
                              listType="picture-card">
                              <CloudUploadOutlined className="user-setting-upload-icon" />
                              <p className="user-setting-drager-item">
                                <span className="user-setting-upld-desc" > Upload image</span>
                              </p>
                            </Dragger>
                          </Form.Item>
                          <Form.Item className="lbl-name" label="Introduction"></Form.Item>
                          <Form.Item
                           rules={[
                           {
                             max: 160,
                             message: "Intro should be less then 160 characters ."
                           }

                           ]}
                            initialValue={data[0].intro}
                            name="intro"
                            className="user-setting-about"
                          >
                            <TextArea name="intro" placeholder="Write your short intro" rows={4}
                            />
                          </Form.Item>
                          <Form.Item className="lbl-name" label="About me"></Form.Item>
                          <Form.Item
                            initialValue={data[0].aboutMe}
                            name="about"
                            className="user-setting-about"
                          >
                            <TextArea name="about" placeholder="Write about yourself" rows={4}
                            />
                          </Form.Item>
                          <div style={{ display: 'flow-root' }}>
                            <div style={{ float: 'left', width: "50%" }}>
                              <Form.Item className="lbl-name" label="First name"></Form.Item>
                              <Form.Item
                                initialValue={data[0].firstName}
                                name="firstName"
                                className="user-setting-first-name"
                                hasFeedback
                              >
                                <Input name="firstName" placeholder="First name" size="large"
                                />
                              </Form.Item>

                            </div>
                            <div style={{ float: 'left', width: "50%" }}>
                              <Form.Item className="lbl-name" label="Last name"></Form.Item>
                              <Form.Item
                                initialValue={data[0].lastName}
                                name="lastName"
                                className="user-setting-first-name"
                                hasFeedback
                              >
                                <Input name="lastName" placeholder="Last name" size="large"
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <Form.Item className="lbl-name" label="User Name"></Form.Item>
                          <Form.Item
                            name="userName"
                            initialValue={data[0].userName}
                            hasFeedback
                            rules={[{
                              pattern: new RegExp(/^\S*$/),
                              message: "space is not allowed"
                            }

                            ]}
                          >
                            <Input placeholder="user Name" size="large" />
                          </Form.Item>
                          <small >Attempts count ({data[0].userNameChangeAttempt})</small>
                          <Form.Item
                            className="user-setting-usr-name-attempt"
                            name="userNameChangeAttempt"
                            initialValue={data[0].userNameChangeAttempt}
                            hasFeedback
                          >
                            <Input name="userNameChangeAttempt" style={{ display: 'none' }} placeholder="user Name" size="large"
                            />
                          </Form.Item>
                          <Form.Item className="lbl-name" label="Gender"></Form.Item>
                          <Form.Item
                            name="gender"
                            initialValue={data[0].gender}
                            hasFeedback
                          >
                            <Select size="large" placeholder="Please select a Gender">
                              <Option value="Male">Male</Option>
                              <Option value="Female">Female</Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </Form.Item>
                          <Form.Item className="lbl-name" label="Education"></Form.Item>
                          <Form.Item
                            initialValue={data[0].education}
                            name="education"
                            hasFeedback
                          >
                            <Input name="education" placeholder="education" size="large"
                            />
                          </Form.Item>
                          <Form.Item className="lbl-name" label="Profession"></Form.Item>
                          <Form.Item
                            initialValue={data[0].Profession}
                            name="profession"
                            hasFeedback
                          >
                            <Input name="profession" placeholder="profession" size="large"
                            />
                          </Form.Item>
                          <Form.Item className="lbl-name" label="Languages"></Form.Item>
                          <Form.Item
                            hasFeedback
                            initialValue={
                              data[0].lang.length > 0 ?
                                data[0].lang.toString().split(',')
                                : null
                            }
                            name="lang"
                          >
                            <Select mode="multiple" size="large" placeholder="Please select Language">
                              <Option value="English">English</Option>
                              <Option value="Urdu">Urdu</Option>
                              <Option value="Punjabi">Punjabi</Option>
                              <Option value="Pashto">Pashto</Option>
                              <Option value="Sindhi">Sindhi</Option>
                              <Option value="Saraiki">Saraiki</Option>
                              <Option value="Blochi">Blochi</Option>
                              <Option value="Hindko">Hindko</Option>
                              <Option value="Brahui">Brahui</Option>
                              <Option value="Kashmiri">Kashmiri</Option>
                              <Option value="Burushaski">Burushaski</Option>
                              <Option value="Kalash">Kalash</Option>
                              <Option value="Khowar">Khowar</Option>
                              <Option value="Shina">Shina</Option>
                              <Option value="Balti">Balti</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="basic-info-second-container">
                        <div>
                          <p className="basic-info-heading">Personal Info</p>
                        </div>
                        <div>
                          <div style={{ display: 'flow-root' }}>
                            <div style={{ float: 'left', width: "50%" }}>
                              <Form.Item className="lbl-name" label="Phone no"></Form.Item>
                              <Form.Item
                                initialValue={data[0].phone}
                                name="phone"
                                className="user-setting-first-name"
                                hasFeedback
                              >
                                <Input name="phone" placeholder="Phone" size="large"
                                />
                              </Form.Item>

                            </div>
                            <div style={{ float: 'left', width: "50%" }}>
                              <Form.Item className="lbl-name" label=""></Form.Item>
                              <Form.Item
                                hasFeedback
                                initialValue={data[0].showMobile}
                                name="privacyPhone"
                              >
                                <Select size="large" placeholder="Privacy">
                                  <Option value="Global">Global</Option>
                                  <Option value="OnlyMe">Only me</Option>
                                  <Option value="Friends">Friends</Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <div style={{ display: 'flow-root' }}>
                            <div style={{ float: 'left', width: "50%" }}>
                              <Form.Item className="lbl-name" label="Email"></Form.Item>
                              <Form.Item
                                name="email"
                                initialValue={data[0].email}
                                className="user-setting-first-name"
                                hasFeedback
                              >
                                <Input name="email" disabled={true} placeholder="email" size="large"
                                />
                              </Form.Item>

                            </div>
                            <div style={{ float: 'left', width: "50%" }}>
                              <Form.Item className="lbl-name" label=""></Form.Item>
                              <Form.Item
                                hasFeedback
                                name="privacyEmail"
                                initialValue={data[0].showEmail}
                              >
                                <Select size="large" placeholder="Privacy">
                                  <Option value="Global">Global</Option>
                                  <Option value="OnlyMe">Only me</Option>
                                  <Option value="Friends">Friends</Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <div style={{ display: 'flow-root' }}>
                            <div style={{ float: 'left', width: "50%" }}>
                              <Form.Item className="lbl-name" label="DOB"></Form.Item>
                              {
                                DOB?
                                <Form.Item
                                // initialValue={DOB}
                                name="dob"
                                className="user-setting-first-name"
                                hasFeedback
                              >
                                <DatePicker  name="dob" size="large"  defaultValue={moment(data[0].DOB, dateFormat)}/>
                              </Form.Item>
                              :
                              <Form.Item
                              name="dob"
                              initialValue={DOB}
                              className="user-setting-first-name"
                              hasFeedback
                            >
                              <DatePicker  name="dob" size="large" defaultValue={moment('2020/10/13', dateFormat)}  />
                            </Form.Item>
                              }


                            </div>
                            <div style={{ float: 'left', width: "50%" }}>
                              <Form.Item className="lbl-name" label=""></Form.Item>
                              <Form.Item
                                hasFeedback
                                name="privacyBirth"
                                initialValue={data[0].showBirthDay}
                              >
                                <Select size="large" placeholder="Privacy">
                                  <Option value="Global">Global</Option>
                                  <Option value="OnlyMe">Only me</Option>
                                  <Option value="Friends">Friends</Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <Form.Item className="lbl-name" label="Address"></Form.Item>
                          <p>{data[0].address.apartment} {data[0].address.city} {data[0].address.state} {data[0].address.country}</p>
                          <span className="user-settings-change-address-btn" onClick={showModal}>Change address</span>
                        </div>
                      </div>
                    </div>
                    :
                    null
                }
                {
                  isPrivacy ?
                    <div className="user-setting-privacy-container">
                      <ul className="user-setting-list">
                        <li className="user-setting-list-item">
                          <div className="user-setting-first-child-div-first">
                            <p>Only allow logged in members to view my profile </p>
                          </div>
                          <div className="user-setting-first-child-div-second">
                            <Switch onChange={onChange} />
                          </div>
                        </li>
                        <li className="user-setting-list-item">
                          <div className="user-setting-first-child-div-first">
                            <p>Show my first name instead of full name </p>
                          </div>
                          <div className="user-setting-first-child-div-second">
                            <Switch onChange={onChange} />
                          </div>
                        </li>
                      </ul>
                    </div>
                    :
                    null
                }
                {
                  isNotification ?
                    <div className="user-settings-notification-container">

                      <Collapse accordion className="user-setting-map-accordian" ghost>
                        <Panel className="user-settings-header-heading-color" header="Map notification" key="1" >
                          <ul className="user-setting-list-pannel">
                            <li className="user-setting-list-item-pannel">
                              <div className="user-setting-first-child-div-first">
                                <p>Push </p>
                              </div>
                              <div className="user-setting-first-child-div-second">
                                <Switch onChange={onChange} />
                              </div>
                            </li>
                            <li className="user-setting-list-item-pannel">
                              <div className="user-setting-first-child-div-first">
                                <p>Email</p>
                              </div>
                              <div className="user-setting-first-child-div-second">
                                <Switch onChange={onChange} />
                              </div>
                            </li>
                            <li className="user-setting-list-item-pannel">
                              <div className="user-setting-first-child-div-first">
                                <p>SMS</p>
                              </div>
                              <div className="user-setting-first-child-div-second">
                                <Switch onChange={onChange} />
                              </div>
                            </li>
                          </ul>
                        </Panel>
                      </Collapse>
                      <Collapse accordion className="user-setting-place-accordian" ghost>
                        <Panel className="user-settings-header-heading-color" header="Place notification" key="1">
                          <ul className="user-setting-list-pannel">
                            <li className="user-setting-list-item-pannel">
                              <div className="user-setting-first-child-div-first">
                                <p>Anyone </p>
                              </div>
                              <div className="user-setting-first-child-div-second">
                                <input type="radio" name="places" value="anyone" />
                              </div>
                            </li>
                            <li className="user-setting-list-item-pannel">
                              <div className="user-setting-first-child-div-first">
                                <p>Friends</p>
                              </div>
                              <div className="user-setting-first-child-div-second">
                                <input type="radio" name="places" value="friends" />
                              </div>
                            </li>
                            <li className="user-setting-list-item-pannel">
                              <div className="user-setting-first-child-div-first">
                                <p>Globaly</p>
                              </div>
                              <div className="user-setting-first-child-div-second">
                                <input type="radio" name="places" value="globaly" />
                              </div>
                            </li>
                          </ul>
                        </Panel>
                      </Collapse>
                      <Collapse accordion className="user-setting-place-accordian" ghost>
                        <Panel className="user-settings-header-heading-color" header="Category notification" key="1">
                        <ul className="user-setting-list-pannel">
                            <li className="user-setting-list-item-pannel">
                              <div className="user-setting-first-child-div-first">
                                <p>Anyone </p>
                              </div>
                              <div className="user-setting-first-child-div-second">
                                <input type="radio" name="categories" value="anyone" />
                              </div>
                            </li>
                            <li className="user-setting-list-item-pannel">
                              <div className="user-setting-first-child-div-first">
                                <p>Friends</p>
                              </div>
                              <div className="user-setting-first-child-div-second">
                                <input type="radio" name="categories" value="friends" />
                              </div>
                            </li>
                            <li className="user-setting-list-item-pannel">
                              <div className="user-setting-first-child-div-first">
                                <p>Globaly</p>
                              </div>
                              <div className="user-setting-first-child-div-second">
                                <input type="radio" name="categories" value="globaly" />
                              </div>
                            </li>
                          </ul>
                        </Panel>
                      </Collapse>
                    </div>
                    :
                    null
                }
                {
                  isSocail ?
                    <div className="user-setting-privacy-container">
                      <ul className="user-setting-list">
                        <li className="user-setting-list-item">
                          <div className="user-setting-first-child-div-first">
                            <p>Link your account with facebook profile </p>
                          </div>
                          <div className="user-setting-first-child-div-second">
                          <Button className="user-setting-btn" size="large" type="primary" htmlType="submit">
                            connect
                            </Button>
                          </div>
                        </li>
                        <li className="user-setting-list-item">
                          <div className="user-setting-first-child-div-first">
                            <p>Link your account with Gmail profile </p>
                          </div>
                          <div className="user-setting-first-child-div-second">
                          <Button className="user-setting-btn" size="large" type="primary" htmlType="submit">
                            connect
                            </Button>
                          </div>
                        </li>
                      </ul>
                   </div>
                    :
                    null
                }
              </div>
              <Modal
                className="user-setting-address-modal"
                title="Change personal address"
                visible={visible}
                width="600px"
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <div style={{ display: 'flow-root', padding: "24px 24px 0 24px" }}>
                  <div style={{ float: 'left', width: "45%", marginRight: '10px' }}>
                    <Form.Item className="lbl-name" label="Street"></Form.Item>
                    <Form.Item
                    className="modal-form-item"
                      initialValue={data[0].address.street}
                      name="street"
                      hasFeedback
                    >
                      <Input name="street" placeholder="Street" size="large"
                      />
                    </Form.Item>

                  </div>
                  <div style={{ float: 'left', width: "45%" }}>
                    <Form.Item className="lbl-name" label="City"></Form.Item>
                    <Form.Item
                     className="modal-form-item"
                      initialValue={data[0].address.city}
                      hasFeedback
                      name="city"
                    >
                      <Input name="city" placeholder="City" size="large"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div style={{ display: 'flow-root', padding: "0px 24px" }}>
                  <div style={{ float: 'left', width: "45%", marginRight: '10px' }}>
                    <Form.Item className="lbl-name" label="State"></Form.Item>
                    <Form.Item
                     className="modal-form-item"
                      initialValue={data[0].address.state}
                      name="state"
                      hasFeedback
                    >
                      <Input name="state" placeholder="State" size="large"
                      />
                    </Form.Item>

                  </div>
                  <div style={{ float: 'left', width: "45%" }}>
                    <Form.Item className="lbl-name" label="Country"></Form.Item>
                    <Form.Item
                     className="modal-form-item"
                      hasFeedback
                      initialValue={data[0].address.country}
                      name="country"
                    >
                      <Input name="country" placeholder="Country" size="large"
                      />
                    </Form.Item>
                  </div>
                </div>
                {/* <div style={{ display: 'flow-root', padding: "0px 24px" }}>
                  <div style={{ float: 'left', width: "45%", marginRight: '10px' }}>
                    <Form.Item className="lbl-name" label="Zip code"></Form.Item>
                    <Form.Item
                      name="zipCode"
                      hasFeedback
                    >
                      <Input name="zipCode" placeholder="Zip Code" size="large"
                      />
                    </Form.Item>
                    <Form.Item className="lbl-name" label="Get current address"></Form.Item>
                  </div>
                </div> */}
              </Modal>
              <div className="setting-btn-txt-container">
                  <Button className="user-setting-btn" size="large" type="primary" htmlType="submit">
                    Save
                    </Button>
                </div>
            </Form>
          </Content>
        </Layout>
      </Layout>
    </React.Fragment>

  )
}

function mapStateToProps(state: any) {
  return {
    data: state.DataReducer.retrievedObject
  };
}
export const connectedUserSettings = connect(mapStateToProps)(UserSettings);

