import {blogCategoryConstants} from '../constants'
const initialState ={
  getAllBlogCategoriesObject:"",
}
export function FetchBlogCategories(state=initialState,action:any)
{

    switch (action.type) {
        case blogCategoryConstants.GET_ALL_REQUEST:
          return state;
          case blogCategoryConstants.GET_ALL_SUCCESS:
          return{
            ...state,
            getAllBlogCategoriesObject:action.user
          };
      default:
        return state
    }
}
