import React from "react";
import {config} from '../../config';
import { MoreOutlined } from '@ant-design/icons';
import {Dropdown, Menu,Modal} from 'antd';
import {albumActions} from '../../actions';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import moment from 'moment';
import {history} from '../../Helpers';
import { Carousel } from 'react-responsive-carousel';
type MyAlbumCarousel={
data:any;
dispatch:any;
}

export function MyAlbumCarousel(props:MyAlbumCarousel) {

   const {data,dispatch} = props;
  const handleAction= (e:any,id:any) =>{
    if(e.key == "Del")
    {

      Modal.warning({
        title: 'Opppssss',

        content: (
          <div className="warning-modal">
            <p>Are you sure you want to delete a story?</p>
          </div>
        ),
        width: 560,
        okType: 'danger',
        okText: 'ok',
        okCancel: true,
        centered: true,
        cancelText: 'cancel',
        onOk: () => {
         let reason="delete by user"
          dispatch(albumActions.DeleteAlbum(reason, id));

        },
        onCancel() {
        },
      });
    }
    if(e.key==="Ed")
    {
      history.push('/user/edit-album/'+id)
    }
  }
   const onAlbumChange=(id:any,title:any)=>
   {
    history.push(`/travel-albums/${title.replace(/ /g,"-")}-${id}`);
   }
  return (

        <div className="published-card-container">
          <div className="publish-card-image-banner">
          <Carousel autoPlay infiniteLoop showThumbs={false} showArrows={false} className="carosel-container">
              {
             data.albums.image.length > 0 ?
             data.albums.image.map((item: any) =>
             <div>
             <img alt="Lake" className="my-album-carousel-images"  src={config.s3BucketUrl+item.imageName} />
           </div>
             )
                :null

            }
             </Carousel>
          </div>
          <div className="published-card-data-container">
            <div className="published-card-title-container">
            <p className="published-card-story-title" onClick={()=>onAlbumChange(data.albums._id,data.albums.title)}>
            {data.albums.title}
            </p>
            <div className="story-card-story-desc-container">
            <p className="story-card-story-desc">
             {data.albums.description}
              </p>
            </div>
            <div className="draft-story-btn-container">
          <span className="story-card-tourist-txt">{data.albums.image.length} photos </span>
          <span className="story-card-timespan-txt story-card-place-name">{data.albumcategories?data.albumcategories.categoryName:null} </span>
          <span className="story-card-tourist-txt">{moment(data.albums.createdDate).format("MMM DD,YYYY")}</span>
            </div>
            </div>
            <div className="published-card-menu-container">
            <Dropdown overlay={(
                    <Menu  onClick={(e:any)=>handleAction(e,data.albums._id)}>
                      <Menu.Item key="Ed" >Edit</Menu.Item>
                      <Menu.Item key="Del" >Delete</Menu.Item>
                    </Menu>)} trigger={['click']} >
                    <MoreOutlined  style={{float:'right'}}/>
              </Dropdown>
            </div>
          </div>
          </div>
  );
}


