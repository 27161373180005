import React, { useState, useEffect } from 'react';
import { Table, Dropdown, Menu, Badge, Modal, Input, Avatar,Switch} from 'antd';
import { history } from '../../../Helpers';
import { albumActions } from '../../../actions';
import { connect } from 'react-redux';
import { config } from "../../../config";
import {SettingOutlined,CloseOutlined, CheckOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

type User = {
  dispatch: any;
  albums: any;
}

const { confirm } = Modal;

function Albums(props: User) {
  let fetchId: any = '';
  const { dispatch, albums } = props;
  const [checkSearch, setcheckSearch] = useState(false);
  const [filterData, setfilterData] = useState();
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {
      dispatch(albumActions.FetchAllAlbumsAdmin());

    }

  }, [dispatch]);


  const [selectedRowKeys, setselectedRowKeys] = useState([]);


  const onSelectChange = (selectedRowKeys: any) => {
    setselectedRowKeys(selectedRowKeys)

  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  if (!albums) {
    return (<div></div>)
  }

  let filterArray: any = [];
  let search = (value: any) => {

    const filterArrayData = albums.filter((o: any) =>
      Object.keys(o).some(k =>
        String(o[k])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );
    filterArrayData.forEach((element: any, index: any) => {
      if (element.image[0].imageName) {
        imagee = config.s3BucketUrl +element.image[0].imageName
      }
      else {
        imagee = "N/A"
      }
      if (element.isActive) {
        storyStatus = <Badge status="success" text="Enabled" />

      }
      else {
        storyStatus = <Badge status="default" text="Disabled" />
      }

      filterArray.push({
        key: element._id,
        id: element._id,
        Photo: imagee,
        albumName: element.title,
        userName: element.user.firstName + " " + element.user.lastName,
        userId: element.user._id,
        title: element.title,
        categoryName: element.categories.categoryName,
        tags: element.tags,
        albumStatus: storyStatus,
        createdDate: element.createdDate.split("T")[0],
        isFeatured:element.isFeatured
      })

    });
    setfilterData(filterArray);
    setcheckSearch(true);
  };



  const categoriesArray: any = [];
  let storyStatus: any = '';
  let imagee: any = '';

  albums.forEach((element: any, index: any) => {
    console.log(`the id is ${element._id} and the image is  ${element.image[0]}`)
    if (element.image[0]) {
      imagee = config.s3BucketUrl + element.image[0].imageName
    }
    else {
      imagee = "N/A"
    }
    if (element.isActive) {
      storyStatus = <Badge status="success" text="Enabled" />

    }
    else {
      storyStatus = <Badge status="default" text="Disabled" />
    }
    categoriesArray.push({
      key: element._id,
      id: element._id,
      Photo: imagee,
      albumName: element.title,
      userName: element.user.firstName + " " + element.user.lastName,
      userId: element.user._id,
      title: element.title,
      categoryName: element.categories.categoryName,
      tags: element.tags,
      albumStatus: storyStatus,
      createdDate: element.createdDate.split("T")[0],
      isFeatured:element.isFeatured
    })

  });
  const onMenuClick = (id: any) => {
    fetchId = id;
  }
  const onSelect = (e: any) => {
    if (e.key == "EU") {
      history.push('/pr-admin-cp/edit-albums/' + fetchId)
    }
    else {
      Modal.warning({
        title: 'Opppssss',

        content: (
          <div className="warning-modal">
            <p>Are you sure you want to delete this?</p>
          </div>
        ),
        className: "cancel-modal",
        width: 400,
        okType: 'danger',
        okText: 'ok',
        okCancel: true,
        centered: true,
        cancelText: 'cancel',
        onOk: () => {
          dispatch(albumActions.DeleteAlbumAdmin(fetchId));
          dispatch(albumActions.FetchAllAlbumsAdmin());
        },
        onCancel() {
        },
      });


    }

  }
  const onStatusChange=(records)=>{

    let dat={
      isFeatured:!records.isFeatured
    }
    if(!records.isFeatured)
    {
      confirm({
        title: 'Do you Want to Activate this user?',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          dispatch(albumActions.FeaturedAlbum(dat,records.key));
        },
        onCancel() {
        },
      });
    }
    else
    {
      confirm({
        title: 'Do you Want to Deactivate this user?',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          dispatch(albumActions.FeaturedAlbum(dat,records.key));
        },
        onCancel() {
        },
      });
    }
  }
  const onImageClick = (image: any) => {
    window.open(image);
  }
  const columns = [
    {
      title: 'User Name',
      render: (records: any) =>
        <Link to={`/profile/${records.userId}`} className="table-img" >
          {records.userName}
        </Link>
    },
    // {
    //   title: 'Photo',
    //   dataIndex: 'Photo',
    //   render: (records: any) => <div onClick={() => onImageClick(records)}><Avatar className="table-img" src={records}></Avatar></div>
    // },
    {
      title: 'Photo',
      dataIndex: 'Photo',
      render: (records: any) => <div onClick={() => onImageClick(records)}><Avatar className="table-img" src={records}></Avatar></div>
    },
    {
      title: 'Album Title',
      render: (records: any) =>
        <Link to={`/story/${records.albumName.split(' ').join('-').toLowerCase()}-${records.id}`} className="table-img" >
          {records.albumName}
        </Link>
    },
    {
      title: 'Featured',
      dataIndex: 'isFeatured',
      render:(e:any,records:any)=> <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked={e}
      onChange={()=>onStatusChange(records)}
    />,
    },
    {
      title: 'Status',
      dataIndex: 'albumStatus',
    },
    {
      title: 'Settings',
      render: (records: any) => <Dropdown className="admin-acc" overlay={(
        <Menu onClick={onSelect}>
          <Menu.Item key="EU" onClick={() => onMenuClick(records.id)} className="user-menu-item">
            <span>
              Edit Album
          </span>
          </Menu.Item>
          <Menu.Item key="DU" onClick={() => onMenuClick(records.id)} className="user-menu-item">
            <span>
              Delete Album
          </span>
          </Menu.Item>
        </Menu>
      )} trigger={['click']} >
        <SettingOutlined />
      </Dropdown>,
    },
  ];

  return (
    <div>

      <div style={{ width: '50%', float: 'left', marginBottom: "20px" }}>
        <Input.Search

          placeholder="Search by..."
          enterButton
          size="large"
          onSearch={search}
        />
      </div>
      {
        checkSearch ? <Table className="show-table" rowSelection={rowSelection} columns={columns} dataSource={filterData} /> :
          <Table className="show-table" rowSelection={rowSelection} columns={columns} dataSource={categoriesArray} />

      }
    </div>
  );

}

function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    albums: state.FetchAlbumsAdmin.getAllAlbumsObject
  };
}

export const connectedAlbums = connect(mapStateToProps)(Albums);

