import React,{useEffect} from 'react';
import { connect } from 'react-redux';
import { albumActions } from '../../actions';
import { history } from '../../Helpers';
import ReactGA from 'react-ga';
import NavBar from '../Navbar/Navbar';
import {MetaDecorator} from '../../utils';
import {Box,Image} from '@chakra-ui/core';
import {MyAlbumCarousel} from '../Carousel';
import {LovedAlbums} from '../Cards'
import advertise from '../../Assets/bridge.jpg';

type Stories = {
  dispatch: any;
  data: any;
  popularAlbums:any;
  match:any;
}

function MyAlbums(props:Stories) {
  let user = JSON.parse(localStorage.getItem('user')!)
  const { dispatch, data,popularAlbums,match} = props;
  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (!user) {
      history.push('/login')
    }
    else
    {
      dispatch(albumActions.FetchMyAlbum(user.id));
      dispatch(albumActions.FetchLovedAlbums())
    }

  }, [dispatch]);

  if (!data) {
    return (<div></div>)
  }
  if (!popularAlbums) {
    return (<div></div>)
  }
  let meta={
    title:`${user.firstName} ${user.lastName} (@${user.user.userName}) - travel albums - Pakrism`,
    description:`See travel reviews, photos, and more contributed by @${user.user.userName} on Pakrism.`,
    imageAlt:"banner image",
    imageUrl:"social-share-image.png"
  }
  return (
    <Box>
      <MetaDecorator title={meta.title} description={meta.description} imageAlt={meta.imageAlt} imageUrl={meta.imageUrl} match={match}/>
      <NavBar/>
      <Box className="stories-main-container">
        <Box className="inner-container-banner-stories">
     <Box className="stories-container-inner-left">
     <div className="travel-story-heading-container">
      <p className="travel-story-taller-heading">
      My albums
         </p>
      </div>
      <div className="stories-container">
      {
             data.length > 0 ?
             data.map((item: any) =>
            < MyAlbumCarousel data={item} key={item._id} dispatch={dispatch}/>
             )
                :null

            }
        </div>
     </Box>
     <div className="stories-container-inner-right">
       <div className="stories-advertise-container">
       <Image size="350px" src={advertise} alt="advertise" />
       </div>
       <div className="top-story-taller-container">
       <p className="top-story-taller-heading">
         Most loved albums
         </p>
         <div className="loved-album-container">
         {
           popularAlbums.map((item: any) =>
           <LovedAlbums data={item} key={item._id}  />
           )
       }
         </div>
       </div>
     </div>
      </Box>
      </Box>
    </Box>
  )
}

function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    data: state.FetchMyAlbums.getMyAlbumsObject,
    popularAlbums:state.FetchLovedAlbums.getLovedAlbumsObject
  };
}

export const connectedMyAlbums = connect(mapStateToProps)(MyAlbums);
