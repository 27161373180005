import React from "react";
import {config} from '../../config';
import { MoreOutlined } from '@ant-design/icons';
import {Dropdown, Menu,Button,Modal} from 'antd';
import {Avatar} from '@chakra-ui/core';
import {history} from '../../Helpers'
import {storyActions} from '../../actions'
type DraftedStoryCard={
data:any;
dispatch:any;
}

export function DraftedStoryCard(props:DraftedStoryCard) {
  let user = JSON.parse(localStorage.getItem('user')!)
   const {data,dispatch} = props;
   let story=data.story.replace(/<[^>]*>?/gm,' ');
   if(story.length > 10)
  {
   story=story.substring(0,135);
  }
  const handleAction= (e:any,id:any) =>{

    if(e.key == "Del")
    {

      Modal.warning({
        title: 'Opppssss',

        content: (
          <div className="warning-modal">
            <p>Are you sure you want to delete a story?</p>
          </div>
        ),
        width: 560,
        okType: 'danger',
        okText: 'ok',
        okCancel: true,
        centered: true,
        cancelText: 'cancel',
        onOk: () => {
          let Data={
            reason:"delete by user",
            user:user.id,
          }
          dispatch(storyActions.DeleteStory(Data, id));

        },
        onCancel() {
        },
      });
    }
    else if(e.key="Ed")
    {
      history.push(`/user/edit-stories/${id}`)
    }
  }
  return (
        <div className="published-card-container">
          <div className="publish-card-image-banner">
            {
              data.image?
              <img className="published-img-banner" src ={config.s3BucketUrl+data.image} alt="abc" />:
              <Avatar size="2xl" name="Story Image" />
            }

          </div>
          <div className="published-card-data-container">
            <div className="published-card-title-container">
            <p className="published-card-story-title">
            {data.title}
            </p>
            <div className="story-card-story-desc-container">
            <p className="story-card-story-desc">
              {story+"..."}
              </p>
            </div>
            <div className="draft-story-btn-container">
            <Button style={{ width: '30%', marginRight: '20px' }} className="btn-admin-login" size="large" type="primary" htmlType="submit">
            Publish
          </Button>
          <Button className="danger-btn" size="large" >
            Delete
          </Button>
            </div>
            </div>
            <div className="published-card-menu-container">
              {
                user? <Dropdown overlay={(
                  <Menu onClick={(e:any)=>handleAction(e,data._id)}>

                    <Menu.Item key="Ed" >Edit</Menu.Item>
                    <Menu.Item key="Pub" >Publish</Menu.Item>
                    <Menu.Item key="Del" >Delete</Menu.Item>
                  </Menu>)} trigger={['click']} >
                  <MoreOutlined  style={{float:'right'}}/>
            </Dropdown>
            :null
              }

            </div>
          </div>
          </div>
  );
}


