import {deviceInfoConstants} from '../constants';

export function FetchDeviceInfo(state={getDeviceInfoObject:false},action:any)
{

    switch (action.type) {
        case deviceInfoConstants.GET_ALL_REQUEST:
          return state;
          case deviceInfoConstants.GET_ALL_SUCCESS:
          return{
            ...state,
            getDeviceInfoObject:action.user
          };
      default:
        return state
    }
}

export function FetchDeviceInfoById(state={getDeviceInfoByIdObject:false},action:any)
{

    switch (action.type) {
        case deviceInfoConstants.EDIT_REQUEST:
          return state;
          case deviceInfoConstants.EDIT_SUCCESS:
          return{
            ...state,
            getDeviceInfoByIdObject:action.user
          };
      default:
        return state
    }
}
