import React, { useEffect, useState } from 'react';
import {config} from '../../config'
import {Avatar,Image } from "@chakra-ui/core";
import {Tag,Modal} from 'antd';

import  Share  from "../../Assets/icon_share_inactive_dark.svg";
import moment from 'moment';



export const ExperienceCard = (props: any) => {
  const {data} = props;
  const [experienceType,setExperienceType]=useState('')
  const [visible,setVisible]=useState(false);
  const showModal = () => {
   setVisible(true);
  };
  useEffect(()=>{
    switch (data.experience) {
      case 5:
          setExperienceType("Out standing")
        break;
      case 4:
        setExperienceType("Good")
        break;
        case 3:
          setExperienceType("Average")
          break;
          case 2:
            setExperienceType("Bad")
            break;
            case 1:
              setExperienceType("Very Bad")
              break;
      default:
        break;
    }
  },[])

  const handleCancel = (e:any) => {
    setVisible(false)
  };

  return (
    <div className="experience-container">
       <div className="exp-card-body" >
        <div className="story-card-user-container">
          <div style={{ float: "left", marginBottom: "16px" }}>
            <Avatar
              name={data.users.firstName+" "+data.users.lastName}
              size="lg"
              className="question-avatar"
              src={data.users.image}
            ></Avatar>
          </div>
          <div className="story-card-desc-container">
            <p className="story-card-decs-txt-container">
              <small className="story-card-desc-txt">{data.users.firstName+" "+data.users.lastName}</small>
              &nbsp;<small className="story-card-tourist-txt">Shared an experience</small>
              &nbsp; <small className="story-card-timespan-txt">
                {moment(data.createdDate).fromNow(true)} ago
                </small>
            </p>
            <p className="story-card-tourist-txt answer-card-user-role">
              {data.users.roles}
              </p>
          </div>
        </div>
        <div>
          <p className="experience-txt">
            {data.title}
          </p>
        </div>
        <div className="traveled-container">
          <div className="traveled-inner-container">
              <ul className="travelled-list">
                <li className="travelled-list-item">
                 Travelled when:<span className="tag-container"><Tag>{moment(data.tourDate).format("MMM DD,YYYY")}</Tag></span>
                </li>
                <li className="travelled-list-item">
                Travelled with:<span className="tag-container"><Tag>{data.tourType}</Tag></span>
                </li>
                <li className="travelled-list-item">
                  Experiences:<span className="tag-container"><Tag>{experienceType}</Tag></span>
                </li>
              </ul>
          </div>
        </div>
        <div className="experience-container-1">
        { data.images?
              data.images.map((item:any)=>
              <div className="experiences-img-container">
                  <img className="experiences-img" src={config.s3BucketUrl+item} />
              </div>
              ):null

             }
        </div>
    </div>
    <div id="footer-main">
                <div>
                <div
                   className="reaction-icon"
                  >
                    <Image
                      src={Share}
                      alt="inspired Fist"
                      height="18px"
                      width="18px"
                    />
                  </div>
                  <div className="icon-txt"  >
                    <small className="small-txt-ftr">Share</small>
                  </div>
                </div>
              </div>
              {/* <Modal
        title="Place Photos"

        className="place-photo-modal"
        closable={true}
        visible={visible}
        onCancel={handleCancel}
        footer={false}
      >
        <div>
        <img className="place-single-img"  src={dataImage}/>
        </div>
      </Modal> */}
    </div>
  );
};

