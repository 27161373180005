import React,{useEffect} from 'react';
import { connect } from 'react-redux';
import { albumActions } from '../../actions';
import {config} from '../../config';
import NavBar from '../Navbar/Navbar';
import moment from 'moment';
import {UpperFooterSection,Footer} from '../FooterSection'
import {Form,Input,Button} from 'antd';
import noUser from '../../Assets/no-user.png'
import {history} from '../../Helpers'
import ContentLoader from 'react-content-loader'
import ReactGA from 'react-ga';
import {MetaDecorator} from '../../utils';
import {Image,Avatar} from '@chakra-ui/core'
import advertise from '../../Assets/bridge.jpg';
import {LovedAlbums} from '../Cards'

import {SingleAlbumCard,LikeFooterAlbum} from '../Cards'

type Stories = {
  dispatch: any;
  data: any;
  match: any;
  popularAlbums:any;
}
const { TextArea } = Input;
function SingleAlbum(props:Stories) {
  // store.getState().FetchEditAlbum.fetchEditAlbumObject=""
  let user = JSON.parse(localStorage.getItem('user')!)
  const { dispatch, data,match,popularAlbums} = props;
  let id = match.params.id.slice((match.params.id.length - 24))
  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    // if (user) {
    //   // history.push('/login')
    // }
    // else
    // {
      dispatch(albumActions.FetchEditAlbumAdmin(id));
      dispatch(albumActions.FetchLovedAlbums())
    // }

  }, [dispatch]);
  const onFinish = (values: any) => {
    let image:any="";

    if(user.image)
    {
      image=user.image;
    }
    let formData={
      comment: values.comment,
      userId:user.id,
      firstName:user.firstName,
      lastName:user.lastName,
      userName:user.user.userName,
      image:image,
      title:data[0].albums.title,
      playerId:data[0].users.playerId,
    }
    dispatch(albumActions.AddAlbumComment(formData,data[0].albums.title.replace(/ /g,"-"),id));
    // dispatch(albumActions.FetchEditAlbumAdmin(data.albums._id))
   }

   const onFinishFailed = (errorInfo: any) => {
  };
  const UserLoader = () => (
    <ContentLoader
      height={140}
      viewBox="0 0 380 70"
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="50" ry="50" width="50" height="50" />
      <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
      <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
    </ContentLoader>
  )
    const LeftLoader = () => (
      <ContentLoader
      viewBox="0 0 500 300">
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="350" />
      </ContentLoader>
    )
    const TopLoader = () => (
      <ContentLoader
      viewBox="0 0 2680 100">
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="800px" />
      </ContentLoader>
    )
    const RighLoader=()=>(
      <ContentLoader
      height="300"
      viewBox="0 0 500 500">
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="700" />
    </ContentLoader>
    )
  if (!data) {
    return (<div style={{maxWidth:'1366px',margin:'auto'}}>
             <div style={{width:'75%',float:'left',padding:'16px',marginTop:'60px'}}>
               <UserLoader />
               <div style={{width:'30%', padding:'16px',float:'left'}}>
               <LeftLoader/>
                </div>
                <div style={{width:'30%', padding:'16px',float:'left'}}>
               <LeftLoader/>
                </div>
                <div style={{width:'30%', padding:'16px',float:'left'}}>
               <LeftLoader/>
                </div>
                <div style={{width:'30%', padding:'16px',float:'left'}}>
               <LeftLoader/>
                </div>
                <div style={{width:'30%', padding:'16px',float:'left'}}>
               <LeftLoader/>
                </div>
                <div style={{width:'30%', padding:'16px',float:'left'}}>
               <LeftLoader/>
                </div>
                <div style={{width:'30%', padding:'16px',float:'left'}}>
               <LeftLoader/>
                </div>
                <div style={{width:'30%', padding:'16px',float:'left'}}>
               <LeftLoader/>
                </div>
                <div style={{width:'30%', padding:'16px',float:'left'}}>
               <LeftLoader/>
                </div>
                <div style={{width:'100%',padding:'16px',marginTop:'60px',marginLeft:'8px',float:'left'}}>
               <TopLoader />
              </div>
             </div>
             <div style={{width:'25%',float:'left',marginTop:'60px',padding:'16px'}}>
             <RighLoader />
              </div>
              <div style={{width:'25%',float:'left',marginTop:'60px',padding:'16px'}}>
             <RighLoader />
              </div>
         </div>)
  }
  if (!popularAlbums) {
    return (<div></div>)
  }
  let reverseArray:any=[];
  if(data)
  {
    reverseArray=[...data[0].albums.comments].reverse();
  }

  let finalData={
    title:data[0].albums.title+" - Travel Album - Pakrism",
    description:data[0].albums.description,
    imageAlt:data[0].albums.image[0].imageName,
    imageUrl:config.apiurl+data[0].albums.image[0].imageName
  }
  console.log(data[0]);
  return (
    <div>
       <MetaDecorator title={finalData.title} description={finalData.description} imageAlt={finalData.imageAlt} imageUrl={finalData.imageUrl} match={match}/>

      <NavBar/>
      <div className="stories-main-container">
        <div className="inner-container-banner-stories">
     <div className="stories-container-inner-left">
      <div className="stories-container">
      <SingleAlbumCard data={data[0]} dispatch={dispatch} match={match}/>
        </div>
         <LikeFooterAlbum data={data[0]} dispatch={dispatch}/>
        <div className="post-story-comment-container" style={{width:'90%',float:'left'}}>
               <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
             >
               <div style={{width:'9%',float:'left'}}>
               {
                   user?
                   <Avatar
               name={user.firstName+" "+ user.lastName}
                 src={user.image}
               />
               :
               <Avatar
               name={"User Avatar"}
                 src={noUser}
               />
                 }
                 </div>
                 <div style={{paddingTop:'5px'}}>
                 <Form.Item
               name="comment"
               rules={[{ required: true, message: 'Please enter an answer.' },]}
               >
                 <TextArea  rows={4} name="comment" placeholder="Write your inspirartion..."/>
               </Form.Item>
                 </div>
               <Form.Item style={{textAlign:'right'}}>
               {
                   user?
                   <Button style={{width: '30%',marginTop:'0px'}} className="btn-admin-login" size="large" type="primary" htmlType="submit" >
                   Post
                   </Button>
                   :
                   <Button style={{width: '30%',marginTop:'0px'}} className="btn-admin-login" size="large" type="primary" onClick={()=>{history.push('/login')}} >
                   Post
                   </Button>
                 }
               {/* <Button style={{width: '30%',marginTop:'0px'}} className="btn-admin-login" size="large" type="primary" htmlType="submit" >
                Post
                </Button> */}
               </Form.Item>
             </Form>
             <p className="story-card-desc-txt" style={{paddingLeft:'20px'}}>{reverseArray.length} inspirations</p>
             </div>
             <div style={{width:'100%',float:'left'}}>
             {
               reverseArray.map((item:any)=>
               <div className="show-comments-single-story-container">
                 <div className="show-comment-left-container">
                 <Avatar
               name={item.firstName+" "+ item.lastName}
                 src={item.image}
                />
                 </div>
             <p><span className="story-card-desc-txt">{item.firstName+" "+ item.lastName}</span> <span className="story-card-timespan-txt">{moment(item.createdDate).fromNow()}</span></p>
                 <p>{item.comment}</p>
                 {
                   user?
                 item.likes.toString() ===user.id.toString()?
                  <p style={{color:'#37b44e',fontWeight:'bold'}}>
                   likes ({
                   (item.likes.length)
                   })

                 </p>:
                  <p>
                  likes ({
                  (item.likes.length)
                  })

                </p>:
                 <p>
                 likes ({
                 (item.likes.length)
                 })
               </p>

                 }

               </div>)
             }
             </div>
     </div>
     <div id="sticky-footer" className="album-container-inner-right">
       <div className="stories-advertise-container">
       <Image size="350px" src={advertise} alt="advertise" />
       </div>
       <div className="top-story-taller-container">
         <p className="top-story-taller-heading">
         Most loved albums
         </p>
         <div className="loved-album-container">
         {
           popularAlbums.map((item: any) =>
           <LovedAlbums data={item} key={item._id}  />
           )
       }
         </div>
       </div>
     </div>
     </div>
      </div>
      <div className="user-dashboard-footer">
      <UpperFooterSection/>
      <Footer/>
      </div>
      </div>

  )
}

function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    data: state.FetchEditAlbum.fetchEditAlbumObject,
    popularAlbums:state.FetchLovedAlbums.getLovedAlbumsObject
  };
}

export const connectedSingleAlbum = connect(mapStateToProps)(SingleAlbum);
