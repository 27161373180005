import React from "react";
import {Image,Box } from "@chakra-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Badge } from "antd";
import { config } from "../../config";
import { Carousel } from 'react-responsive-carousel';


import inspiration from "../../Assets/inspiration_comment-01.svg" ;
import inspired from "../../Assets/inspired_fist-01.svg";
import Share from "../../Assets/share_icon-01.svg";
type UserPlaceCards={
  data:any;
}
export function UserAlbumsCards(props:UserPlaceCards){
  const {data} =props;

  return (
    <div className="carousel-inner-container user-place-carusal-container">
            <Box
              maxW="sm"
              borderWidth="1px"
              rounded="lg"
              overflow="hidden"
              style={{position: "relative" }}
            >
              <Carousel autoPlay infiniteLoop showThumbs={false} showArrows={false} className="carosel-container">
              {
             data.albums.image.length > 0 ?
             data.albums.image.map((item: any) =>
             <div>
             <img alt="Lake" src={config.s3BucketUrl+item.imageName} />
           </div>
             )
                :null

            }
              </Carousel>
              <div className="txt-img-container user-place-img-container">
                <div className="lefted-album-txt">
                  <p className="album-txt">{data.albums.title}</p>
                </div>
                <div className="lefted">
                  <small className="photos-txt">Photos</small>

                  <Badge status="default" text={data.albumcategories.categoryName} />
                </div>
              </div>
                <div id="footer-main">
                <div>
                  <div
                    style={{
                      float: "left",
                      marginTop: "6px",
                      marginRight: "5px",
                    }}
                  >
                    <Image
                      src={inspired}
                      alt="inspired Fist"
                      height="12px"
                      width="12px"
                    />
                  </div>
                  <div style={{ float: "left" }}>
                      <small className="small-txt-ftr">Inspired({data.albums.likes.length})</small>

                  </div>
                </div>
                <div>
                  <div
                    style={{
                      float: "left",
                      marginTop: "6px",
                      marginRight: "5px",
                    }}
                  >
                    <Image
                      src={inspiration}
                      alt="inspired Fist"
                      height="12px"
                      width="12px"
                    />
                  </div>
                  <div style={{ float: "left" }}>
                    <small className="small-txt-ftr">Inspirations({data.albums.comments.length})</small>
                  </div>
                </div>
                <div style={{cursor:'pointer'}}>
                  <div
                    style={{
                      float: "left",
                      marginTop: "6px",
                      marginRight: "5px",
                    }}
                  >
                    <Image
                      src={Share}
                      alt="inspired Fist"
                      height="12px"
                      width="12px"
                    />
                  </div>
                  <div style={{ float: "left" }}>
                    <small className="small-txt-ftr">Share</small>
                  </div>
                </div>
              </div>
            </Box>
          </div>
  );
}
