export const blogConstants = {
  GET_ALL_BLOG_REQUEST: 'GET_ALL_BLOG_REQUEST',
  GET_ALL_BLOG_SUCCESS: 'GET_ALL_BLOG_SUCCESS',
  GET_ALL_BLOG_FAILURE: 'GET_ALL_BLOG_FAILURE',

  ADD_BLOG_REQUEST: 'ADD_BLOG_REQUEST',
  ADD_BLOG_SUCCESS: 'ADD_BLOG_SUCCESS',
  ADD_BLOG_FAILURE: 'ADD_BLOG_FAILURE',


  EDIT_BLOG_REQUEST: 'EDIT_BLOG_REQUEST',
  EDIT_BLOG_SUCCESS: 'EDIT_BLOG_SUCCESS',
  EDIT_BLOG_FAILURE: 'EDIT_BLOG_FAILURE',

  UPDATE_BLOG_REQUEST: 'UPDATE_BLOG_REQUEST',
  UPDATE_BLOG_SUCCESS: 'UPDATE_BLOG_SUCCESS',
  UPDATE_BLOG_FAILURE: 'UPDATE_BLOG_FAILURE',

  DELETE_BLOG_REQUEST: 'DELETE_BLOG_REQUEST',
  DELETE_BLOG_SUCCESS: 'DELETE_BLOG_SUCCESS',
  DELETE_BLOG_FAILURE: 'DELETE_BLOG_FAILURE',
};
