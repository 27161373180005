import { agenciesConstants } from "../constants";
import { agencyService } from "../services";
import { alertActions } from ".";
import { history } from "../Helpers";

export const agenciesActions = {
  FetchAllTravelAgencies,
  FetchEditTravelAgency,
  UpdateAgencyAdmin,
  // FetchEditAccessoriesCategories,
  DeleteTravelAgencyAdmin,
  ChangeStatus,
  AddTravelAgencyAdmin
};
function AddTravelAgencyAdmin(data: any) {
  return (dispatch: any) => {
    dispatch(request(data));
    agencyService.AddTravelAgencyAdmin(data)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "201") {
          dispatch(success(data));
          dispatch(
            alertActions.success(data.data.message)
          );
          history.push("/travel-albums/my-albums");
        }
        else
        {
          alertActions.error(data.data.message)

        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: agenciesConstants.ADD_ADMIN_TRAVEL_AGENCIES_REQUEST, data };
    }
    function success(user: any) {
      return { type: agenciesConstants.ADD_ADMIN_TRAVEL_AGENCIES_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: agenciesConstants.ADD_ADMIN_TRAVEL_AGENCIES_FAILURE, error };
    }
  };
}
function FetchAllTravelAgencies(page: number,search:string,pageLimit:number) {
  return (dispatch: any) => {
    dispatch(request(page,search,pageLimit));
    agencyService.FetchAllTravelAgencies(page,search,pageLimit).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };


  function request(page: number,search:string,pageLimit:number) {
    return { type: agenciesConstants.GET_ADMIN_TRAVEL_AGENCIES_REQUEST,page,search,pageLimit };
  }
  function success(user: any) {
    return { type: agenciesConstants.GET_ADMIN_TRAVEL_AGENCIES_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: agenciesConstants.GET_ADMIN_TRAVEL_AGENCIES_FAILURE, error };
  }
}


function FetchEditTravelAgency(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    agencyService.FetchEditTravelAgency(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: agenciesConstants.GET_EDIT_ADMIN_TRAVEL_AGENCIES_REQUEST, id };
  }
  function success(user: any) {
    return { type: agenciesConstants.GET_EDIT_ADMIN_TRAVEL_AGENCIES_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: agenciesConstants.GET_EDIT_ADMIN_TRAVEL_AGENCIES_FAILURE, error };
  }
}

function DeleteTravelAgencyAdmin(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    agencyService
      .DeleteTravelAgencyAdmin(id)
      .then((res: any) => {
        dispatch(success(res.message));
        dispatch(alertActions.success(res.message));
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });

    function request(id: any) {
      return { type: agenciesConstants.DELETE_ADMIN_TRAVEL_AGENCIES_REQUEST, id };
    }
    function success(user: any) {
      return { type: agenciesConstants.DELETE_ADMIN_TRAVEL_AGENCIES_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: agenciesConstants.DELETE_ADMIN_TRAVEL_AGENCIES_FAILURE, error };
    }
  };
}

function ChangeStatus(data: any, id: any) {
  return (dispatch: any) => {
    dispatch(request(data, id));
    agencyService
      .ChangeStatus(data, id)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "200") {
          dispatch(success(data));
          dispatch(
            alertActions.success("You have successfully Changed Status")
          );
          history.go(0);
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any, id: any) {
      return { type: agenciesConstants.UPDATE_STATUS_ADMIN_TRAVEL_AGENCIES_REQUEST, data, id };
    }
    function success(user: any) {
      return { type: agenciesConstants.UPDATE_STATUS_ADMIN_TRAVEL_AGENCIES_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: agenciesConstants.UPDATE_STATUS_ADMIN_TRAVEL_AGENCIES_FAILURE, error };
    }
  };
}

function UpdateAgencyAdmin(data: any, id: any) {
  return (dispatch: any) => {
    dispatch(request(data, id));
    agencyService
      .UpdateAgencyAdmin(data, id)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "200") {
          dispatch(success(data));
          dispatch(
            alertActions.success("You have successfully Update Agency")
          );
          history.go(0);
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any, id: any) {
      return { type: agenciesConstants.UPDATE_ADMIN_TRAVEL_AGENCIES_REQUEST, data, id };
    }
    function success(user: any) {
      return { type: agenciesConstants.UPDATE_ADMIN_TRAVEL_AGENCIES_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: agenciesConstants.UPDATE_ADMIN_TRAVEL_AGENCIES_FAILURE, error };
    }
  };
}



