
import { userConstants } from '../constants';
import { userService } from '../services';
import { history} from '../Helpers';
import { alertActions } from '.';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
export const userActions ={
    register,
    login,
    loginAdmin,
     getCurrentUser,
     fetchAllUsers,
     fetchEditUser,
     UpdateUserAdmin,
     DeActiveUser,
     ForgetPassword,
     ResetPassword,
     VerifyUser,
     getUserProfile,
     UpdateUser,
     AddFavPlace,
     RemoveFavPlace,
     AddVistedPlace,
     RemoveVistedPlace,
     getFavPlaces,
     getVistedPlaces,
     FetchTopUsers,
     SocialLogin,
     GoogleLogin,
     ActivatedUser,
     FeaturedUser,
     getCheckedUser,
     ResentEmail,
     fetchAllUsersAdmin,
     fetchUsersCount,
     fetchUsersCountMonth
}

function register(firstName:String,lastName:String,email:String,password:String,socialClick:boolean,sendAdmin:boolean,isFromMobile:any,playerId:any){
    return (dispatch:any)=>{
        dispatch(request({firstName,lastName,email,password,socialClick,sendAdmin,isFromMobile,playerId}));
        userService.register(firstName,lastName,email,password,socialClick,sendAdmin,isFromMobile,playerId)
        .then(response=>response.json())
        .then((data:any)=>{
            if(data.registring)
            {
              let sign={
                firstName:firstName,
                lastName:lastName,
                email:email,
              }
              localStorage.setItem('signSussecc',JSON.stringify(sign));
                dispatch(success(data));
                alertActions.success(data.message);
                history.push('/email-varification')
                return data;
            }
            else
            {
                dispatch(failure(data.message))
                return alertActions.error(data.message);
            }
        }).catch((err:any)=>{
            if(err=="TypeError: Failed to fetch")
            {
                dispatch(failure(err))
            alertActions.error("Server is not responding");

            }
            else{
                dispatch(failure(err))
                alertActions.error(err.toString());
            }

        });


        function request(user:any) { return { type: userConstants.SIGNUP_REQUEST, user } }
        function success(user:any) { return { type: userConstants.SIGNUP_REQUEST_SUCCESS, user } }
        function failure(error:any) { return { type: userConstants.SIGNUP_REQUEST_SUCCESS, error } }
    }

}

function login(email:any, password:any,socialClick:any,playerId:any) {
    return (dispatch:any) => {
        dispatch(request({ email }));
        userService.login(email, password,socialClick,playerId)
        .then((response) => response.json())
            .then((data:any)=>{
              cookies.set('user',data);
            localStorage.setItem('user', JSON.stringify(data));
            if(data.status == "401")
            {
              dispatch(alertActions.error(data.data.message));
            }
            if(data.loggedIn)
            {
                dispatch(success(data))
                history.push('/pak-map');
                return data;

            }

            }).catch((err:any)=>{
              dispatch(failure(err))
            });
            };

            function request(user:any) { return { type: userConstants.LOGIN_REQUEST, user } }
            function success(user:any) { return { type: userConstants.LOGIN_SUCCESS, user } }
            function failure(error:any) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function loginAdmin(email:any, password:any,socialClick:any) {
  return (dispatch:any) => {
      dispatch(request({ email }));
      userService.loginAdmin(email, password,socialClick)
          .then(response=>response.json())
          .then((data:any)=>{
          if(data.loggedIn)
          {
              dispatch(success(data))
              history.push('/pr-admin-cp/analytics');
              return data;
          }

          }).catch((err:any)=>{
               dispatch(failure(err))
          });
          };

          function request(user:any) { return { type: userConstants.LOGIN_REQUEST, user } }
          function success(user:any) { return { type: userConstants.LOGIN_SUCCESS, user } }
          function failure(error:any) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function getCurrentUser(id:any)
{
  return (dispatch:any) => {
    dispatch(request(id));
    userService.getCurrentUser(id)
    .then(
      (res) => {
          dispatch(success(res));
      },
      (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.toString()));
      });
        };

        function request(id:any) { return { type: userConstants.CURRENT_USER_REQUEST, id } }
        function success(user:any) { return { type: userConstants.CURRENT_USER_SUCCESS, user } }
        function failure(error:any) { return { type: userConstants.CURRENT_USER_FAILURE, error } }
}

function getUserProfile(id:any)
{
  return (dispatch:any) => {
    dispatch(request(id));
    userService.getUserProfile(id)
    .then(
      (res) => {
          dispatch(success(res));
      },
      (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.toString()));
      });
        };

        function request(id:any) { return { type: userConstants.GET_USER_PROFILE, id } }
        function success(user:any) { return { type: userConstants.GET_USER_PROFILE_SUCCESS, user } }
        function failure(error:any) { return { type: userConstants.GET_USER_PROFILE_SUCCESS, error } }
}

function fetchAllUsers(){
  return (dispatch:any) => {
    dispatch(request());
    userService.fetchAllUsers()
    .then(
      (res) => {
          dispatch(success(res));
      },
      (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.toString()));
      });
        };

        function request() { return { type: userConstants.GET_ALL_USER_REQUEST,} }
        function success(user:any) { return { type: userConstants.GET_ALL_USER_SUCCESS, user } }
        function failure(error:any) { return { type: userConstants.GET_ALL_USER_FAILURE, error } }
}

function fetchAllUsersAdmin(page:any,search:any,pageLimit:any){
  return (dispatch:any) => {
    dispatch(request(page,search,pageLimit));
    userService.fetchAllUsersAdmin(page,search,pageLimit)
    .then(
      (res) => {
          dispatch(success(res));
      },
      (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.toString()));
      });
        };

        function request(page,search,pageLimit) { return { type: userConstants.FETCH_ALL_USER_ADMIN_REQUEST} }
        function success(user:any) { return { type: userConstants.FETCH_ALL_USER_ADMIN_SUCCESS, user } }
        function failure(error:any) { return { type: userConstants.FETCH_ALL_USER_ADMIN_FAILURE, error } }
}

function fetchEditUser(id:any){
  return (dispatch:any) => {
    dispatch(request(id));
    userService.getCurrentUser(id)
    .then(
      (res) => {
          dispatch(success(res));
      },
      (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.toString()));
      });
        };

        function request(id:any) { return { type: userConstants.EDIT_CURRENT_USER_REQUEST, id } }
        function success(user:any) { return { type: userConstants.EDIT_CURRENT_USER_SUCCESS, user } }
        function failure(error:any) { return { type: userConstants.EDIT_CURRENT_USER_FAILURE, error } }
}
function UpdateUserAdmin( data:any,id:any)
{

  return (dispatch:any) => {
    dispatch(request(data,id));
    userService.UdateUserAdmin(data,id)
    .then(response=>response)
    .then((data:any)=>{
      if(data.status=="200")
      {
        dispatch(success(data));
        dispatch(alertActions.success(data.data.message))
        history.push('/admin/users')
      }
      if(data.status == "409")
      {
        dispatch(alertActions.error(data.data.message));
      }
    }).catch((err:any)=>{
      dispatch(failure(err));
      dispatch(alertActions.error(err.toString()));
    });
        function request(data:any,id:any) { return { type: userConstants.UPDATE_CURRENT_USER_REQUEST, data,id } }
        function success(user:any) { return { type: userConstants.UPDATE_CURRENT_USER_SUCCESS, user } }
        function failure(error:any) { return { type: userConstants.UPDATE_CURRENT_USER_FAILURE, error } }
}
}

function DeActiveUser(id:any)
{

  return (dispatch:any) => {
    dispatch(request(id));
    userService.DeActiveUser(id)
    .then(response=>response)
    .then((res:any)=>{
      if(res.status=="200")
      {
        dispatch(success(res));
        dispatch(alertActions.success('You have successfully Deactivate a user '))

      }

    }).catch((err:any)=>{
      dispatch(failure(err));
      dispatch(alertActions.error(err.toString()));
    });

        function request(id:any) { return { type: userConstants.DELETE_USER_REQUEST, id } }
        function success(user:any) { return { type: userConstants.DELETE_USER_SUCCESS, user } }
        function failure(error:any) { return { type: userConstants.DELETE_USER_FAILURE, error } }
}
}

function ForgetPassword(email:any,isFromMobile:any) {
  return (dispatch:any) => {
      dispatch(request({ email }));
       userService.ForgetPassword(email,isFromMobile)
          .then((data:any)=>{
            if(data.status =='409')
            {
              dispatch(alertActions.error(data.message))
            }
            else
            {
              dispatch(success(data))
              dispatch(alertActions.success(data.message))

            }
          }).catch((err:any)=>{
            dispatch(failure(err))
          });
          };

          function request(user:any) { return { type: userConstants.FORGET_PASSWORD_REQUEST, user } }
          function success(user:any) { return { type: userConstants.FORGET_PASSWORD_SUCCESS, user } }
          function failure(error:any) { return { type: userConstants.FORGET_PASSWORD_FAILURE, error } }
}


function ResetPassword(password:any,token:any,id:any) {
  return (dispatch:any) => {
      dispatch(request({ password,token,id }));
       userService.ResetPassword(password,token,id)
          .then((data:any)=>{

              dispatch(success(data))
              dispatch(alertActions.success(data.message))

          }).catch((err:any)=>{
            dispatch(failure(err))
          });
          };

          function request(user:any) { return { type: userConstants.RESET_PASSWORD_REQUEST, user } }
          function success(user:any) { return { type: userConstants.RESET_PASSWORD_SUCCESS, user } }
          function failure(error:any) { return { type: userConstants.RESET_PASSWORD_FAILURE, error } }
}


function VerifyUser(id:any)
{

  return (dispatch:any) => {
    dispatch(request(id));
    userService.VerifyUser(id)
    .then(response=>response)
    .then((data:any)=>{
      localStorage.setItem('user', JSON.stringify(data));
      history.push(`/pak-map`)
      history.go(0);
      if(data.status=="200")
      {

        dispatch(success(data));
        dispatch(alertActions.success(data.data.message))

      }
      if(data.status == "409")
      {
        dispatch(alertActions.error(data.data.message));
      }
    }).catch((err:any)=>{
      dispatch(failure(err));
      dispatch(alertActions.error(err.toString()));
    });
        function request(id:any) { return { type: userConstants.VERIFY_USER_REQUEST,id } }
        function success(user:any) { return { type: userConstants.VERIFY_USER_SUCCESS, user } }
        function failure(error:any) { return { type: userConstants.VERIFY_CURRENT_USER_FAILURE, error } }
}
}


function UpdateUser( data:any,id:any)
{
  return (dispatch:any) => {
    dispatch(request(data,id));
    userService.UpdateUser(data,id)
    .then(response=>response)
    .then((data:any)=>{
      if(data.status=="200")
      {
        dispatch(success(data));
        dispatch(alertActions.success(data.data.message))
        // history.go(0)
      }
      if(data.status == "409")
      {
        dispatch(alertActions.error(data.data.message));
      }
    }).catch((err:any)=>{
      dispatch(failure(err));
      dispatch(alertActions.error(err.toString()));
    });
        function request(data:any,id:any) { return { type: userConstants.UPDATE_USER_REQUEST, data,id } }
        function success(user:any) { return { type: userConstants.UPDATE_USER_SUCCESS, user } }
        function failure(error:any) { return { type: userConstants.UPDATE_USER_FAILURE, error } }
}
}


function AddFavPlace(body:any,id:any) {
  return (dispatch:any) => {
      dispatch(request({ body,id }));
       userService.AddFavPlace(body,id)
          .then((data:any)=>{
              dispatch(success(data))

          }).catch((err:any)=>{
            dispatch(failure(err))
          });
          };

          function request(user:any) { return { type: userConstants.ADD_FAV_PLACE_REQUEST, user } }
          function success(user:any) { return { type: userConstants.ADD_FAV_PLACE_REQUEST_SUCCESS, user } }
          function failure(error:any) { return { type: userConstants.ADD_FAV_PLACE_REQUEST_FAILURE, error } }
}

function ActivatedUser(body:any,id:any) {
  return (dispatch:any) => {
      dispatch(request({ body,id }));
       userService.ActivateUser(body,id)
          .then((data:any)=>{
              dispatch(success(data))
      if(data.status=="200")
      {
        dispatch(success(data));
        dispatch(alertActions.success(data.data.message))
        history.go(0)
      }

          }).catch((err:any)=>{
            dispatch(failure(err))
          });
          };

          function request(user:any) { return { type: userConstants.ACTIVATE_USER_REQUEST, user } }
          function success(user:any) { return { type: userConstants.ACTIVATE_USER_SUCCESS, user } }
          function failure(error:any) { return { type: userConstants.ACTIVATE_USER_FAILURE, error } }
}


function FeaturedUser(body:any,id:any) {
  return (dispatch:any) => {
      dispatch(request({ body,id }));
       userService.FeaturedUser(body,id)
          .then((data:any)=>{
            if(data.status=="200")
            {
              dispatch(success(data));
              dispatch(alertActions.success(data.data.message))
              history.go(0);
            }

          }).catch((err:any)=>{
            dispatch(failure(err))
          });
          };

          function request(user:any) { return { type: userConstants.FEATURED_USER_REQUEST, user } }
          function success(user:any) { return { type: userConstants.FEATURED_USER_SUCCESS, user } }
          function failure(error:any) { return { type: userConstants.FEATURED_USER_FAILURE, error } }
}


function RemoveFavPlace(body:any,id:any) {
  return (dispatch:any) => {
      dispatch(request({ body,id }));
       userService.RemoveFavPlace(body,id)
          .then((data:any)=>{
              dispatch(success(data))
          }).catch((err:any)=>{
            dispatch(failure(err))
          });
          };

          function request(user:any) { return { type: userConstants.REMOVE_FAV_PLACE_REQUEST, user } }
          function success(user:any) { return { type: userConstants.REMOVE_FAV_PLACE_REQUEST_SUCCESS, user } }
          function failure(error:any) { return { type: userConstants.REMOVE_FAV_PLACE_REQUEST_FAILURE, error } }
}

function AddVistedPlace(body:any,id:any) {
  return (dispatch:any) => {
      dispatch(request({ body,id }));
       userService.AddVistedPlace(body,id)
          .then((data:any)=>{

              dispatch(success(data))
          }).catch((err:any)=>{
            dispatch(failure(err))
          });
          };

          function request(user:any) { return { type: userConstants.ADD_VISTED_PLACE_REQUEST, user } }
          function success(user:any) { return { type: userConstants.ADD_VISTED_PLACE_REQUEST_SUCCESS, user } }
          function failure(error:any) { return { type: userConstants.ADD_VISTED_PLACE_REQUEST_FAILURE, error } }
}


function RemoveVistedPlace(body:any,id:any) {
  return (dispatch:any) => {
      dispatch(request({ body,id }));
       userService.RemoveVistedPlace(body,id)
          .then((data:any)=>{
              dispatch(success(data))
          }).catch((err:any)=>{
            dispatch(failure(err))
          });
          };

          function request(user:any) { return { type: userConstants.REMOVE_VISTED_PLACE_REQUEST, user } }
          function success(user:any) { return { type: userConstants.REMOVE_VISTED_PLACE_REQUEST_SUCCESS, user } }
          function failure(error:any) { return { type: userConstants.REMOVE_VISTED_PLACE_REQUEST_FAILURE, error } }
}



function getFavPlaces(id:any)
{
  return (dispatch:any) => {
    dispatch(request(id));
    userService.getFavPlaces(id)
    .then(
      (res) => {
          dispatch(success(res));
      },
      (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.toString()));
      });
        };

        function request(id:any) { return { type: userConstants.GET_FAV_PLACE_REQUEST, id } }
        function success(user:any) { return { type: userConstants.GET_FAV_PLACE_REQUEST_SUCCESS, user } }
        function failure(error:any) { return { type: userConstants.GET_FAV_PLACE_REQUEST_FAILURE, error } }
}


function getVistedPlaces(id:any)
{
  return (dispatch:any) => {
    dispatch(request(id));
    userService.getVisetdPlaces(id)
    .then(
      (res) => {
          dispatch(success(res));
      },
      (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.toString()));
      });
        };

        function request(id:any) { return { type: userConstants.GET_VISTED_PLACE_REQUEST, id } }
        function success(user:any) { return { type: userConstants.GET_VISTED_PLACE_REQUEST_SUCCESS, user } }
        function failure(error:any) { return { type: userConstants.GET_VISTED_PLACE_REQUEST_FAILURE, error } }
}
function FetchTopUsers(){
  return (dispatch:any) => {
    dispatch(request());
    userService.FetchTopUsers()
    .then(
      (res) => {
          dispatch(success(res));
      },
      (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.toString()));
      });
        };

        function request() { return { type: userConstants.GET_TOP_USER_REQUEST,} }
        function success(user:any) { return { type: userConstants.GET_TOP_USER_SUCCESS, user } }
        function failure(error:any) { return { type: userConstants.GET_TOP_USER_FAILURE, error } }
}


function SocialLogin(body:any) {
  return (dispatch:any) => {
      dispatch(request({ body}));
       userService.SocialLogin(body)
          .then((data:any)=>{
            localStorage.setItem('user', JSON.stringify(data.data));
            if(data.data.loggedIn)
            {
                dispatch(success(data))
                history.push('/pak-map');
                return data;

            }

            }).catch((err:any)=>{
              dispatch(failure(err))
            });
          };

          function request(user:any) { return { type: userConstants.SOCIAL_SIGNUP_REQUEST, user } }
          function success(user:any) { return { type: userConstants.SOCIAL_SIGNUP_REQUEST_SUCCESS, user } }
          function failure(error:any) { return { type: userConstants.SOCIAL_SIGNUP_REQUEST_FAILURE, error } }
}


function GoogleLogin(body:any) {
  return (dispatch:any) => {
      dispatch(request({ body}));
       userService.GoogleLogin(body)
          .then((data:any)=>{
            localStorage.setItem('user', JSON.stringify(data.data));
            if(data.status == "409")
            {
              dispatch(alertActions.error(data.data.message));
            }
            if(data.data.loggedIn)
            {
                dispatch(success(data))
                history.push('/pak-map');
                return data;

            }

            }).catch((err:any)=>{
              dispatch(failure(err))
            });
          };

          function request(user:any) { return { type: userConstants.GOOGLE_SIGNUP_REQUEST, user } }
          function success(user:any) { return { type: userConstants.GOOGLE_SIGNUP_REQUEST_SUCCESS, user } }
          function failure(error:any) { return { type: userConstants.GOOGLE_SIGNUP_REQUEST_FAILURE, error } }
}


function getCheckedUser(id:any,friendId:any)
{
  return (dispatch:any) => {
    dispatch(request(id,friendId));
    userService.getCheckedUser(id,friendId)
    .then(
      (res) => {
          dispatch(success(res));
      },
      (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.toString()));
      });
        };

        function request(id:any,friendId:any) { return { type: userConstants.GET_CHECKED_USER_REQUEST, id,friendId} }
        function success(user:any) { return { type: userConstants.GET_CHECKED_USER_REQUEST_SUCCESS, user } }
        function failure(error:any) { return { type: userConstants.GET_CHECKED_USER_REQUEST_FAILURE, error } }
}

function ResentEmail(email:String,isFromMobile:any){
  return (dispatch:any)=>{
      dispatch(request({email,isFromMobile}));
      userService.ResentEmail(email,isFromMobile)
      .then(response=>response.json())
      .then((data:any)=>{
        console.log(data)
        if(data.status==="401")
        {
          alertActions.error(data.message);
        }
        if(data.status==="200")
        {
          dispatch(success(data));
          alertActions.success(data.message)
        }
      }).catch((err:any)=>{
          if(err=="TypeError: Failed to fetch")
          {
              dispatch(failure(err))
          alertActions.error("Server is not responding");

          }
          else{
              dispatch(failure(err))
              alertActions.error(err.toString());
          }

      });


      function request(user:any) { return { type: userConstants.RESENT_EMAIL_REQUEST, user } }
      function success(user:any) { return { type: userConstants.RESENT_EMAIL_REQUEST_SUCCESS, user } }
      function failure(error:any) { return { type: userConstants.RESENT_EMAIL_REQUEST_FAILURE, error } }
  }

}
function fetchUsersCount(){
  return (dispatch:any) => {
    dispatch(request());
    userService.fetchUsersCount()
    .then(
      (res) => {
          dispatch(success(res));
      },
      (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.toString()));
      });
        };

        function request() { return { type: userConstants.FETCH_USERS_COUNT_ADMIN_REQUEST,} }
        function success(user:any) { return { type: userConstants.FETCH_USERS_COUNT_ADMIN_SUCCESS, user } }
        function failure(error:any) { return { type: userConstants.FETCH_USERS_COUNT_ADMIN_FAILURE, error } }
}

function fetchUsersCountMonth(){
  return (dispatch:any) => {
    dispatch(request());
    userService.fetchUsersCountMonth()
    .then(
      (res) => {
          dispatch(success(res));
      },
      (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.toString()));
      });
        };

        function request() { return { type: userConstants.FETCH_USERS_COUNT_MONTH_WISE_ADMIN_REQUEST,} }
        function success(user:any) { return { type: userConstants.FETCH_USERS_COUNT_MONTH_WISE_ADMIN_SUCCESS, user } }
        function failure(error:any) { return { type: userConstants.FETCH_USERS_COUNT_MONTH_WISE_ADMIN_FAILURE, error } }
}
