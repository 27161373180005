import React, { useEffect } from 'react';
import { Select, Button, Input, Form, Upload, message, } from 'antd';
import { PlusOutlined } from "@ant-design/icons";
import { history, store } from '../../../Helpers';
import { connect } from 'react-redux';
import { CloseOutlined } from "@ant-design/icons";
import { placesActions, facilitiesActions,categoryActions } from '../../../actions';
import { config } from "../../../config";
const { Option } = Select;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};

type EditUserPlace = {
  dispatch: any;
  match: any;
  data: any;
  categories: any;
  facilities: any;
}

const prop: any = {
  // name: 'userImage',
  // action: '',
  // headers: {
  //   authorization: 'authorization-text',
  // },
};
let image: any = '';

function beforeUpload(file: any) {

  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const { TextArea } = Input;
let resultArray: any = '';
function EditUserPlace(props: EditUserPlace) {
   store.getState().FetchEditPlaceCategory.fetchEditPlaceCategoryObject="";
  const { dispatch, match, data, categories, facilities } = props;
  const [showResults, setShowResults] = React.useState(true)
  const [filterResult, setfilterResult] = React.useState([])
  const [checkEmpty, setCheckEmpty] = React.useState(false)



  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {
      dispatch(placesActions.fetchEditUserPlace(match.params.id));
      dispatch(categoryActions.FetchAllCategories());
      dispatch(facilitiesActions.FetchAllFacilities());
    }

  }, [dispatch,match]);

  const onFinishFailed = (errorInfo: any) => {
  };


  if (!data) {
    return (<div></div>)
  }
   let facArray: any = [];
  let facIds: any = [];
  console.log(data)
  if(data[0].userplaces.facilities)
  {
    data[0].userplaces.facilities.forEach((element: any) => {
      facArray.push(
        element
      )
      facIds.push(
        element._id,
      )
    });
  }

  const onPreview = async (file: any) => {
      let src = file.url;
      if (!src) {
        src = await new Promise(resolve => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow: any = window.open(src);
      imgWindow.document.write(image.outerHTML);
    };
  let prefix: any = '';
  let phone: any = '';
  if (data[0].phone) {
    prefix = data[0].phone.substring(0, 3);
  }
  if (data[0].phone) {
    phone = data[0].phone.substring(3)
  }

  const onFinish = (values: any) => {

    let selectedFac:any=[];
    if(values.facilities)
    {
      values.facilities.forEach((element:any) => {
        selectedFac.push({
          _id:element
        })
      });
    }
    let result = facilities.filter((o1:any) => selectedFac.some((o2:any) => o1._id === o2._id));
    let imagesArray: any = [];

    if(filterResult.length > 0)
    {
      for (let i = 0; i < filterResult.length; i++) {
        imagesArray.push(fileList[i].url)
      }
    }
    else
    {
      if(!checkEmpty)
      {
        if(fileList.length>0)
        {
          for (let i = 0; i < fileList.length; i++) {
            imagesArray.push(fileList[i].url)
          }
        }

      }

    }
    const formData = new FormData()
    formData.append("placeName", values.placeName)
    formData.append("address", values.address)
    formData.append("latitude", values.latitude)
    formData.append("longititude", values.longitude)
    formData.append("categories", values.categoryName)
    formData.append("facilities", JSON.stringify(result))
    formData.append("description", values.description)
    formData.append("city", values.city)
    formData.append("province", values.province)
    formData.append("phone", values.phone)
    formData.append("images",imagesArray)
    formData.append("prefix", values.prefix)
    formData.append("availableFor", values.availableFor)
    formData.append("accessibleVia", values.accessibleVia)
    formData.append("foodsAvailable", values.foodsAvailable)
    formData.append("roadCondition", values.roadCondition)
    formData.append("userPlaceId", match.params.id)
    formData.append("playerId", data[0].users.playerId)
    formData.append("firstName", data[0].users.firstName)
    formData.append("lastName", data[0].users.lastName)

    if (values.placeImage) {
      for (let i = 0; i <  values.placeImage.fileList.length; i++) {
        formData.append("placeImage",values.placeImage.fileList[i].originFileObj)
     }
    }
    dispatch(categoryActions.AddUserPlaceAdmin(formData));
  };
  const onFacChange = (value: any) => {
  }

  const prefixSelector = (
    <Form.Item initialValue={prefix} name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="+92">+92</Option>
        <Option value="+91">+91</Option>
      </Select>
    </Form.Item>
  );
  let fileList:any=[];
  data[0].userplaces.image.forEach((element:any,i:any) => {
    if(element)
    {
      fileList.push({
        url:element,
       })
    }
  });
     if( data[0].userplaces.image[0])
     {
      image = config.s3BucketUrl+ data[0].userplaces.image[0];
     }
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const onCloseClick = (id: any) => {

    if (resultArray.length == "0") {
      resultArray = fileList.filter((item: any) => item.url !== id);
      if(resultArray.length===0)
      {
        setCheckEmpty(true)
      }

      setfilterResult(resultArray)
      setShowResults(false)
    }
    else {
      resultArray = resultArray.filter((item: any) => item.uid !== id)
      if(resultArray.length===0)
      {
        setCheckEmpty(true)
      }
      setfilterResult(resultArray)
    }
  }
  const onImagePreview =(url:any)=>{
    window.open(url);
  }
  return (
    <div>
      <Form
        {...layout}
        className="admin-login-form"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item className="lbl-name" label="Place Name"></Form.Item>
        <Form.Item
          initialValue={data[0].userplaces.placeName}
          hasFeedback
          name="placeName"
          rules={[{ required: true, message: 'Please enter a Place name.' },]}
        >
          <Input name="placeName" size="large" placeholder="Place Name" />
        </Form.Item>

        <Form.Item className="lbl-name" label="Category Name"></Form.Item>
        <Form.Item
          hasFeedback
          name="categoryName"
          initialValue={data[0].categories._id}
          rules={[{ required: true, message: 'Please enter a category.' },]}
        >
          <Select defaultValue={data[0].categories._id} placeholder="select a category" size="large" >

            {
              categories.length > 0 ?
                categories.map((item: any) => <Option selected="selected" value={item._id} >
                  {item.categoryName}
                </Option >)
                :
                <Option value="1">data</Option>
            }
          </Select>
        </Form.Item>
        <Form.Item className="lbl-name" label="Activities"></Form.Item>
        <Form.Item
          hasFeedback
          initialValue={facIds}
          name="facilities"
          rules={[{ type: 'array' }]}
        >
          <Select defaultValue={facIds}  onChange={onFacChange} mode="multiple" size="large" >
            {
              facilities.length > 0 ?
                facilities.map((item: any) => <Option selected="selected" value={item._id} >
                  {item.title}
                </Option >)
                :
                <Option value="1">data</Option>
            }
          </Select>
        </Form.Item>
        <Form.Item className="lbl-name" label="Address"></Form.Item>
        <Form.Item
          hasFeedback
          initialValue={data[0].userplaces.address}
          name="address"
          rules={[{ required: true, message: 'Please enter an address .' },]}
        >
          <Input name="address" size="large" placeholder="Address" />
        </Form.Item>
        <Form.Item className="lbl-name" label="Latitude"></Form.Item>
        <Form.Item
          hasFeedback
          initialValue={data[0].userplaces.latitude}
          name="latitude"
          rules={[
            {
              pattern: new RegExp(/^\S*$/),
              message:'space is not allowed'
            },
            { required: true, message: 'Please enter a latitude.' },]}
        >
          <Input name="latitude" size="large" placeholder="Latitude" />
        </Form.Item>

        <Form.Item className="lbl-name" label="Longitude"></Form.Item>
        <Form.Item
          hasFeedback
          initialValue={data[0].userplaces.longitude}
          name="longitude"
          rules={[
            {
              pattern: new RegExp(/^\S*$/),
              message:'space is not allowed'
            },
            { required: true, message: 'Please enter a longitude.' },]}
        >
          <Input name="longitude" size="large" placeholder="Longitude" />
        </Form.Item>
        <Form.Item className="lbl-name" label="Province"></Form.Item>
        <Form.Item
          initialValue={data[0].userplaces.province}
          hasFeedback
          name="province"
          rules={[{ required: true, message: 'Please enter a Province.' },]}
        >
          <Input name="province" size="large" placeholder="Province" />
        </Form.Item>
        <Form.Item className="lbl-name" label="City"></Form.Item>
        <Form.Item
          initialValue={data[0].userplaces.city}
          hasFeedback
          name="city"
          rules={[{ required: true, message: 'Please enter a city .' },]}
        >
          <Input name="city" size="large" placeholder="City" />
        </Form.Item>
        <Form.Item className="lbl-name" label="Available for"></Form.Item>
            <Form.Item
             initialValue={data[0].userplaces.availableFor}
              hasFeedback
              name="availableFor"
            >
              <Select mode="multiple" placeholder="Available for" size="large" >
                  <Option value="Everyone">
                    Everyone
                  </Option>
                  <Option value="Family">
                    Family
                  </Option>
              </Select>
            </Form.Item>
            <Form.Item className="lbl-name" label="Accessible via"></Form.Item>
            <Form.Item
             initialValue={data[0].userplaces.accessibleVia}
              hasFeedback
              name="accessibleVia"
            >
              <Select mode="multiple" placeholder="Accessible Via" size="large" >
                  <Option value="Walk">
                     Walk
                  </Option>
                  <Option value="Bike">
                    Bike
                  </Option>
                  <Option value="Car">
                    Car
                  </Option>
                  <Option value="Bus">
                    Bus
                  </Option>
                  <Option value="Jeep">
                    Jeep
                  </Option>
              </Select>
            </Form.Item>
            <Form.Item className="lbl-name" label="Foods available"></Form.Item>
            <Form.Item
            initialValue={data[0].userplaces.foodsAvailable}
              hasFeedback
              name="foodsAvailable"
            >
              <Input  size="large" placeholder="Foods available" />
            </Form.Item>
            <Form.Item className="lbl-name" label="Road condition"></Form.Item>
            <Form.Item
             initialValue={data[0].userplaces.roadCondition}
              hasFeedback
              name="roadCondition"
            >
              <Input  size="large" placeholder="Road Condition" />
            </Form.Item>
        <Form.Item className="lbl-name" label="Phone"></Form.Item>
        <Form.Item
          hasFeedback
          initialValue={phone}
          name="phone"
          >
          <Input addonBefore={prefixSelector} size="large" />
        </Form.Item>
        <Form.Item className="lbl-name" label="Description"></Form.Item>
        <Form.Item
          hasFeedback
          initialValue={data[0].userplaces.description}
          name="description"
        >
          <TextArea name="lastName" placeholder="Description" rows={6}
          />
        </Form.Item>
        <Form.Item className="lbl-name" label="Photo"></Form.Item>
        <Form.Item
          name="placeImage"
          className="file-upload">
             <Upload
           beforeUpload={beforeUpload}
          onPreview={onPreview}
          listType="picture-card"
          {...prop}

        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        </Form.Item>
        <Form.Item >
        {
           showResults ?
              fileList.length > 0 ?
                fileList.map((item: any,i:any) =>
                <div style={{width:'20%',height:'250px', float:'left',margin:'15px',padding:'5px', boxShadow:'0 0 3px 0 rgba(0, 0, 0, 0.16)'}}>
                   <div style={{ float: 'right', padding: "1px 4px 0px 0px", cursor: 'pointer' }} onClick={() => onCloseClick(item.url)}>
                    <CloseOutlined />
                  </div>
                <img src={config.s3BucketUrl+item.url} width="90%" height='150px' alt={config.s3BucketUrl+item.url} style={{ textAlign: 'center',cursor: 'pointer' }}/>

                </div>
                )
                :null
                :
                 filterResult.map((item: any, x: any) =>
                <div style={{width:'20%',float:'left',height:'250px',margin:'15px',padding:'5px', boxShadow:'0 0 3px 0 rgba(0, 0, 0, 0.16)'}}>
                <div style={{ float: 'right', padding: "1px 4px 0px 0px", cursor: 'pointer' }} onClick={() => onCloseClick(item.uid)}>
                 <CloseOutlined />
               </div>
               {
                 image?
                <img src={config.s3BucketUrl+item.url}  width="90%" alt={config.s3BucketUrl+item.url} style={{ textAlign: 'center' ,cursor: 'pointer'}} onClick={() => onImagePreview(item.url)} />
              :null
               }

             </div>)
        }

        </Form.Item>
        <Form.Item >
          <Button className="btn-admin-login" size="large" type="primary" htmlType="submit">
            Update Place
                    </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

function mapStateToProps(state: any) {
  return {
    data: state.GetUserPlace.getUserPlaceObject,
    categories: state.FetchCategories.getAllCategoriesObject,
    facilities: state.FetchFacilities.getAllFacilitiesObject,

  };
}

export const connectedEditUserPlace = connect(mapStateToProps)(EditUserPlace);
