import React, { useEffect, useState } from "react";
import Navbar from '../Navbar/Navbar';
import { categoryActions, storyActions, questionActions,experiencesAction,placesActions} from '../../actions';
import { CloudUploadOutlined } from "@ant-design/icons";
import { FormInstance } from 'antd/lib/form';
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga';
import { config } from '../../config'
import { history } from '../../Helpers';
import {MetaDecorator} from '../../utils';
import {UpperFooterSection,Footer} from '../FooterSection'
import { Button, Modal, Form, Upload, Input, Select,DatePicker,message } from 'antd';
import { connect } from 'react-redux';
import MultiCarousal from "react-multi-carousel";
import { PlaceDataCard, Activities, PlaceQuestions,ExperienceCard,UserImagePlaceCard,NoData} from '.';
import {AdvertiseCard} from '../Cards'
import { RelatedStoryCard } from '../Cards';
import  Icon  from '@ant-design/icons';
// import { Map } from '../Map';
import WeatherCard from './weather-card';
import ImageGallery from 'react-image-gallery';
import moment from 'moment'
import './places.css'

function beforeUpload(file: any) {

  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const { Dragger } = Upload;
const { TextArea } = Input;
type Place = {
  match: any;
  dispatch: any;
  data: any;
  relatedStories: any;
  questions: any;
  experiences:any;
  location:any;
  relatedPlaces:any;
  userPlaceLimit:any
};
const prop: any = {
  name: 'userImage',
  action: '',
  headers: {
    authorization: 'authorization-text',
  },
};
const QuestionSvg=()=>(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M6.455 19L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zM11 14v2h2v-2h-2zM8.567 8.813l1.962.393A1.5 1.5 0 1 1 12 11h-1v2h1a3.5 3.5 0 1 0-3.433-4.187z" fill="rgba(255,255,255,1)"/></svg>
);
const StorySvg=()=>(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0L24 0 24 24 0 24z"/><path d="M20 2c.552 0 1 .448 1 1v3.757l-8.999 9-.006 4.238 4.246.006L21 15.242V21c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V3c0-.552.448-1 1-1h16zm1.778 6.808l1.414 1.414L15.414 18l-1.416-.002.002-1.412 7.778-7.778zM12 12H7v2h5v-2zm3-4H7v2h8V8z" fill="rgba(255,255,255,1)"/></svg>
);
const AlbumSvg=()=>(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm.008-12c.548 0 .992.445.992.993v9.349A5.99 5.99 0 0 0 20 13V5H4l.001 14 9.292-9.293a.999.999 0 0 1 1.32-.084l.093.085 3.546 3.55a6.003 6.003 0 0 0-3.91 7.743L2.992 21A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016zM8 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4z" fill="rgba(255,255,255,1)"/></svg>
);
const ExperienceSvg=()=>(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0H24V24H0z"/><path d="M21 3c.552 0 1 .448 1 1v14c0 .552-.448 1-1 1H6.455L2 22.5V4c0-.552.448-1 1-1h18zM10.962 8.1l-.447-.688C8.728 8.187 7.5 9.755 7.5 11.505c0 .995.277 1.609.792 2.156.324.344.837.589 1.374.589.966 0 1.75-.784 1.75-1.75 0-.92-.711-1.661-1.614-1.745-.16-.015-.324-.012-.479.01v-.092c.006-.422.092-1.633 1.454-2.466l.185-.107-.447-.688zm4.553-.688c-1.787.775-3.015 2.343-3.015 4.093 0 .995.277 1.609.792 2.156.324.344.837.589 1.374.589.966 0 1.75-.784 1.75-1.75 0-.92-.711-1.661-1.614-1.745-.16-.015-.324-.012-.479.01 0-.313-.029-1.762 1.639-2.665z" fill="rgba(255,255,255,1)"/></svg>
);

const QuestionIcon = (props:any) => <Icon component={QuestionSvg} {...props} />;
const StoryIcon = (props:any) => <Icon component={StorySvg} {...props} />;
const AlbumIcon = (props:any) => <Icon component={AlbumSvg} {...props} />;
const ExperienceIcon = (props:any) => <Icon component={ExperienceSvg} {...props} />;

const dateFormat = 'YYYY/MM/DD';
const { Option } = Select;

const Place = (props: Place) => {
  let formRef:any = React.createRef<FormInstance>();
  let user = JSON.parse(localStorage.getItem('user')!)
  const { match, dispatch, data, relatedStories, questions,experiences,location,userPlaceLimit } = props;
  let id = match.params.id.substr(match.params.id.length-24)
  const [visible, setVisible] = useState(false);
  const [questionVisible, setQuestionVisible] = useState(false);
  const [experience, setExperience] = useState(false);
  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    dispatch(categoryActions.FetchEditPlaceCategories(id));
    dispatch(placesActions.GetUserPlaceImageLimit(id))
    dispatch(experiencesAction.FetchPlaceExperiences(id));
    dispatch(storyActions.GetPlaceStories(id));
    dispatch(questionActions.FetchPlaceQuestion(id));

  }, [match, dispatch,location])

  if (!data) {
    return (<div> </div>)
  }
  let weatherData: any = [];
  weatherData.push({
    lat: data[0].latitude,
    lng: data[0].longititude,
    placeName: data[0].placeName
  })
  const writeStory = () => {
    history.push('/user/write-story')
  }
  const seeAllStories = () => {
    history.push('/travel-stories')
  }
  const handleCancel = (e: any) => {
    setVisible(false)
    setExperience(false);
  };
  const onPictureChange = (file: any) => {
    let obejectUrl: any = [];
    file.fileList.forEach((element: any) => {
      obejectUrl.push({
        image: URL.createObjectURL(element.originFileObj)

      })
    });

  }
  const onFinish = (values: any) => {

    const date = new Date();
    const formData = new FormData();
    if(values.uploadImage.fileList.length>5)
    {
      return message.error('you cannot exeed from maximum 5 photos')
    }
    else
    {

      for (let i = 0; i < values.uploadImage.fileList.length; i++) {
        formData.append("placeImages",values.uploadImage.fileList[i].originFileObj)
       }
       formData.append("placeId",id)
       formData.append("firstName",user.firstName);
       formData.append("lastName",user.lastName);
       formData.append("placeName",data[0].placeName);
       formData.append("userId",user.id)
       formData.append("createdDate",date.toISOString())
       formData.append("updateDate",date.toISOString())
       dispatch(placesActions.AddUserPlaceImages(formData));
    }
  };
  const onFinishFailed = (errorInfo: any) => {
  };

  const onFinishQuestion = (values: any) => {
    let sendData = {
      placeId: id,
      userId: user.id,
      question: values.askQuestion,
      firstName:user.firstName,
      lastName:user.lastName,
      placeName:data[0].placeName,
    };
    dispatch(questionActions.AddQuestion(sendData));
    setQuestionVisible(false)

  };
  const onFinishFailedQuestion = (errorInfo: any) => {
  };
  const onInputChange = (event: any) => {
    let inputs: any = [];
    let abc = { [event.target.name]: event.target.value }
    inputs.push(abc)
  }
  const showModal = () => {
    setQuestionVisible(true);
  }
  const showPhotosModal = () => {
    setVisible(true);
  }

  const showExperienceModal = () => {
    setExperience(true);
  }
  let questionResult: any = [];

  if (questions) {
    for (let i = 0; i < 2; i++) {
      questionResult.push(
        questions[i]
      )
    }
  }
  let images:any=[];
  if( data[0].image[0].length>0)
  {
    data[0].image.forEach((element:any) => {
      images.push({
        original:config.s3BucketUrl+element,
        thumbnail:config.s3BucketUrl+element
      })
    });
  }
  else
  {
    images.push(
      {
        original:"https://pakrism-web.s3.ap-south-1.amazonaws.com/place-holder-image.svg",
        thumbnail:"https://pakrism-web.s3.ap-south-1.amazonaws.com/place-holder-image.svg"
      }
    )
  }
   const onFinishExperience = (values: any) => {
    const formData = new FormData();
       formData.append("title",values.description)
       formData.append("placeId",id)
       formData.append("userId",user.id)
       formData.append("tourType",values.traveled)
       formData.append("experience",values.experience)
       formData.append("firstName",user.firstName)
       formData.append("lastName",user.lastName)
       formData.append("placeName",data[0].placeName)
       formData.append("tourDate",moment(values.travelDate._d).toISOString())
       if(values.userImage)
       {
         values.userImage.fileList.forEach((element:any) => {
          formData.append("experienceImages",element.originFileObj)
         });
       }
       setExperience(false);
       dispatch(categoryActions.AddPlaceExperience(formData));
       formRef.current.resetFields();
  }
  let finalData={
    title:data[0].placeName+" - Travel Place - Pakrism",
    description:data[0].description,
    imageAlt:data[0].image[0],
    imageUrl:config.apiurl+data[0].image[0]

  }
  return (

    <div >
       <MetaDecorator title={finalData.title} description={finalData.description} imageAlt={finalData.imageAlt} imageUrl={finalData.imageUrl} match={match}/>

      <Navbar />
      <div className="place-component-container">
        <div className="place-component-inner-container">
          <div className="place-component-left-container-place-data">
            <PlaceDataCard data={data[0]} placeId={id} dispatch={dispatch} match={match} key={data[0]._id} />
          </div>
          <div className="place-component-carosal-container">
          <ImageGallery  items={images} showPlayButton={false} showNav={false} />
          </div>
        </div>
      </div>
      <div className="place-component-inner-container">
        <div className="place-card-weather-container">
          <div className="place-card-heading-container">
          <p className="place-card-section-heading">
            Local weather
          </p>
          <p className="place-card-section-desc">Complete local weather report of the travel place for you to plan your trip accordingly.</p>
          </div>

          <div className="place-component-weather-main-container">
          <WeatherCard data={weatherData} dispatch={dispatch} />
        </div>
        <div className="place-card-adv-container">
         <AdvertiseCard />
        </div>
        </div>
        {/*<div className="direction-container-main">
           <p className="place-card-section-heading">
            Get direction
            </p>
             <p className="place-card-section-desc">Local weather report by "Third party"</p>
           <div className="place-card-map-container">
            <Map data={weatherData} />
          </div>
          </div>*/}
          <div className="place-component-activity-container-main">
          <div className="place-card-heading-container">
            <p className="place-card-section-heading">
              Activities
            </p>
            <p className="place-card-section-desc">Tourists can do the following activities on this place.</p>
            </div>
              <div className="place-component-activities-container">
              {
              data[0].facilities.length>0?
              data[0].facilities.map((item: any) =>
              <Activities activities={item} />
                )
                : <div>
                 <NoData isFrom="activity"
            noData="No activity found."
             noDataMessage="This place is still need to be explored for activities."
             />
                </div>
            }

              </div>
            </div>
          <div className="place-component-photos-container">
          <div className="place-card-story-header">
            <div className="place-component-related-story-heading">
              <p className="place-card-section-heading">
              Photos
            </p>
            <p className="place-card-section-desc">Beautiful memories and breath taking scenes shared by tourists.</p>
              <p className="place-component-see-stories">
              <Link to={`/place-photo/${match.params.id}`}>
              See all photos
           </Link>
            </p>
            </div>
            <div className="place-component-related-write-story">
              {

            user?
            <Button className="user-place-btns"  type="primary" onClick={showPhotosModal} size="large" icon={<AlbumIcon/>}>Upload your photos</Button>
            :
            <Button className="user-place-btns"  type="primary" onClick={()=>history.push('/login')} size="large" icon={<AlbumIcon/>}>Upload your photos</Button>
              }
            </div>
            </div>
            <div className="place-component-photo-container">
              <Modal
                title="Post your photo"
                width="65%"
                closable={false}
                visible={visible}
                onCancel={handleCancel}
                footer={false}
              >
                <div className="modal-form">
                  <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                     <Form.Item className="lbl-name" label="You can upload maximum 5 photos"></Form.Item>
                    <Form.Item
                      name="uploadImage"
                      rules={[{ required: true, message: 'Please upload atleast one picture.' }]}
                    >
                      <Dragger
                        name="uploadImage"
                        onChange={onPictureChange}
                        listType="picture-card"
                        multiple={true}
                      >
                        <p className="ant-upload-drag-icon">
                          <CloudUploadOutlined style={{ color: "#000000" }} />
                          <span className="upld-desc" > Upload or drop photo here</span>
                        </p>
                      </Dragger>
                    </Form.Item>
                    <Form.Item>
                      <Button style={{ marginTop: '50px', width: '50%' }} className="btn-admin-login" size="large" type="primary" htmlType="submit" >
                        Save
                </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Modal>
          <div className="place-card-photo-container">
            {
              userPlaceLimit.length>0?
              userPlaceLimit.map((item: any) =>
                  < UserImagePlaceCard data={item} placeId={match.params.id} />
                )

                : <div>
                   <NoData isFrom="photo"
                noData="No photo uploaded."
                noDataMessage="Be the first to upload photos."
             />
                </div>
            }

          </div>
          <div className="place-card-adv-container">
          <AdvertiseCard />
        </div>
            </div>
          </div>
          <div className="place-component-related-stories">
            <div className="place-card-story-header">
            <div className="place-component-related-story-heading">
              <p className="place-card-section-heading">
                Travel Stories
            </p>
            <p className="place-card-section-desc">Exciting and adventuring travel stories shared by tourists.</p>
              <p className="place-component-see-stories" onClick={seeAllStories}>
                See all stories
            </p>
            </div>
            <div className="place-component-related-write-story">
            <Button className="user-place-btns"  type="primary" size="large" icon={<StoryIcon/>} onClick={writeStory}>Write your story</Button>
            </div>
            </div>

            <div className="place-card-story-carousel-container" >
            {relatedStories.length >0 ?
              <MultiCarousal responsive={responsive} className="story-carousel">
                {
                  relatedStories.map((item: any) =>
                    <div>
                      <RelatedStoryCard storyCardData={item} storyLikes={item.storylikes} storyComments={item.storycomments} key={item._id} />
                    </div>
                  )
                }
              </MultiCarousal>
              :
              <div>
                 <NoData isFrom="stories"
                noData="No story found."
                noDataMessage="Be the first to write a travel story."
             />
              </div>
               }
            </div>
          </div>
          <div className="place-component-QA-container-main">
          <div className="place-card-story-header">
            <div className="place-component-related-story-heading">
              <p className="place-card-section-heading">
              Questions & Answers
            </p>
            <p className="place-card-section-desc">Questions asked by tourists and their answers by other helping tourists.</p>
              <p className="place-component-see-stories">
              <Link to={`/place-questions/${id}`}>
              See all questions
           </Link>
            </p>
            </div>
            <div className="place-component-related-write-story">
              {
                user?
            <Button className="user-place-btns"  type="primary" onClick={showModal} size="large" icon={<QuestionIcon/>}>Ask your question</Button>
            :
            <Button className="user-place-btns"  type="primary" onClick={()=>{history.push('/login')}} size="large" icon={<QuestionIcon/>}>Ask your question</Button>

              }
            </div>
            </div>
            <div>
              <Modal
                className="post-question-modal"
                title="Post your question"
                width="60%"
                visible={questionVisible}
                closable={false}
                onCancel={handleCancel}
                footer={false}
              >
                <div className="modal-form">
                  <Form
                    onFinish={onFinishQuestion}
                    onFinishFailed={onFinishFailedQuestion}
                  >
                    <Form.Item
                      className="lbl-name"
                      label="Ask Question"
                    >
                    </Form.Item>
                    <Form.Item
                      name="askQuestion"
                      rules={[{ required: true, message: 'Please enter a question.' },]}
                    >
                      <Input name="askQuestion" placeholder="write your question" />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'right' }}>
                      <Button style={{ marginTop: '50px', width: '40%', marginRight: '10px' }} size="large" type="default" onClick={() => setQuestionVisible(false)}  >
                        Cancel
               </Button>
                      <Button style={{ marginTop: '50px', width: '40%' }} className="btn-admin-login" size="large" type="primary" htmlType="submit" >
                        Save
                </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Modal>
            </div>
            <div className="question-card-container-main">
            {
              questions.length > 0 ?
                questions.map((item: any) =>
                  < PlaceQuestions data={item} />
                )

                : <div>
                <NoData isFrom="question"
               noData="No question asked."
               noDataMessage="Be the first to ask a question."
            />
             </div>
            }

          </div>
          <div className="place-card-adv-container">
          <AdvertiseCard />
        </div>
          </div>

          <div className="place-component-exp-container">
          <div className="place-card-story-header">
            <div className="place-component-related-story-heading">
              <p className="place-card-section-heading">
              experience
            </p>
            <p className="place-card-section-desc">View and experiences shared by tourists.</p>
              <p className="place-component-see-stories">
              <Link to={`/place-experience/${id}`}>
              See all experiences
           </Link>
            </p>
            </div>
            <div className="place-component-related-write-story">
              {
                user?
            <Button className="user-place-btns"  type="primary" onClick={showExperienceModal} size="large" icon={<ExperienceIcon/>}> Share your experience</Button>
            :
            <Button className="user-place-btns"  type="primary" onClick={()=>{history.push('/login')}} size="large" icon={<ExperienceIcon/>}> Share your experience</Button>


              }
            </div>
            </div>
            <div className="place-component-related-write-story">
              <Modal
                className="post-question-modal"
                title="Post your experience"
                closable={false}
                width="50%"
                visible={experience}
                onCancel={handleCancel}
                footer={false}
              >
                <div className="modal-form">
                  <Form
                     ref={formRef}
                    onFinish={onFinishExperience}
                    onFinishFailed={onFinishFailed}
                  >
                    <Form.Item className="lbl-name" label="Description"></Form.Item>
                    <Form.Item
                      name="description"
                    >
                      <TextArea name="description" placeholder="write description" rows={3}
                      />
                    </Form.Item>
                    <Form.Item className="lbl-name" label="Travelled with"></Form.Item>
                    <Form.Item
                      className="traveled-with"
                      name="traveled"
                    >
                      <Select placeholder="Travel with" size="large" >
                        <Option value="Family">Family</Option>
                        <Option value="Friends">Friends</Option>
                        <Option value="Solo">Solo</Option>
                        <Option value="School Trip">School Trip</Option>
                        <Option value="College Trip">College Trip</Option>
                        <Option value="University Trip">University Trip</Option>
                        <Option value="Business Trip">Business Trip</Option>
                        <Option value="Colleagues">Colleagues</Option>
                        <Option value="Travel Agent">Travel Agent</Option>
                        <Option value="Travel Agency">Travel Agency</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item className="lbl-name" label="Travelled when"></Form.Item>
                    <Form.Item name="travelDate">
                    <DatePicker name="travelDate" size="large"  className="traveled-with"  format={dateFormat} />
                    </Form.Item>
                    <Form.Item className="lbl-name" label="How was your experience?"></Form.Item>
                    <Form.Item
                      className="traveled-with"
                      name="experience"
                    >
                      <Select  placeholder="experience" size="large" >
                        <Option value="5">Out standing</Option>
                        <Option value="4">Good</Option>
                        <Option value="3">Average</Option>
                        <Option value="2">Bad</Option>
                        <Option value="1">Very Bad</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                    name={prop.name}
                    >
                      <Dragger
                        {...prop}
                        listType="picture-card"
                        beforeUpload={beforeUpload}
                        multiple={true}
                      >
                        <p className="ant-upload-drag-icon">
                          <CloudUploadOutlined style={{ color: "#000000" }} />
                          <span className="upld-desc" > Upload or drop photo here</span>
                        </p>
                      </Dragger>
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'right' }}>
                      <Button style={{ marginTop: '50px', width: '40%', marginRight: '10px' }} size="large" type="default" onClick={() => setQuestionVisible(false)}  >
                        Cancel
                       </Button>
                      <Button style={{ marginTop: '50px', width: '40%' }} className="btn-admin-login" size="large" type="primary" htmlType="submit" >
                        Save
                     </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Modal>
            </div>
            <div className="experiences-main-container">
             { experiences.length>0?
              experiences.map((item:any)=>
              <ExperienceCard data={item}/>
              ):
              <NoData isFrom="exp"
              noData="No experience shared."
              noDataMessage="Be the first to share an experience."
           />
             }
            </div>
            <div className="place-card-adv-container">
            <AdvertiseCard />
        </div>
          </div>
      </div>
      <UpperFooterSection/>
      <Footer/>
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    data: state.FetchEditPlaceCategory.fetchEditPlaceCategoryObject,
    relatedStories: state.GetPlaceStory.getPlaceStoryObject,
    questions: state.FetchPlaceQuestions.getPlaceQuestionsObject,
    experiences:state.FetchPlaceExperiences.getAllPlaceExperiencesObject,
    relatedPlaces:state.FetchRelatedPlaces.getRelatedPlacesObject,
    userPlaceLimit:state.GetUserPlaceImageLimit.getUserPlaceImageLimitObject
  };
}
export const connectedPlace = connect(mapStateToProps)(Place);
