import React, { useEffect, useState } from 'react';
import {Button, Form, Input, Upload, message, Select} from 'antd';
import { CloudUploadOutlined } from "@ant-design/icons";
import { FormInstance } from 'antd/lib/form';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import NavBar from '../../components/Navbar/Navbar'
import {categoryActions,storyActions } from '../../actions';
import { history } from '../../Helpers';
import './stories.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

const { Option } = Select;

type WriteStory = {
  dispatch: any;
  places: any;
  location: any
}


const { Dragger } = Upload;

const prop: any = {
  name: 'userImage',
  action: '',
  headers: {
    authorization: 'authorization-text',
  },
};
function beforeUpload(file: any) {

  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt10M = file.size / 1024 / 1024 < 10;
  if (!isLt10M) {
    message.error('Image must smaller than 10MB!',5);
  }
  return isJpgOrPng && isLt10M;
}


const modules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    [ 'image'],
    ['clean']
  ],
}

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
]



function WriteStory(props: WriteStory) {


  let formRef:any = React.createRef<FormInstance>();
  const { dispatch,places} = props;
  let user = JSON.parse(localStorage.getItem('user')!)
  const [title, setTitle] = useState('');
  const [selectedPlace, setSelectedPlace] = useState('');
  const [otherPlace, setOtherPlace] = useState('');
  const [image, setImage] = useState<any>();
  const [story, setStory] = useState('');
  const [ohterValue, setOhterValue] = useState(false);
  const [loading,setLoading]= useState(false);

  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (!user) {
      history.push('/login')
    }
    else {
      dispatch(categoryActions.FetchAllPlaceCategories());
    }

  }, [dispatch]);


  const onFinish = (values: any) => {
    let saveClicked:boolean=true
    const data = new FormData()
    if(ohterValue)
    {
      data.append("other", "true")
      data.append("title", values.title)
      data.append("otherValue", otherPlace)
      data.append("story", values.quil)
      data.append("user", user.id)
      data.append("firstName", user.firstName)
      data.append("lastName", user.lastName)
    if (values.userImage) {
      data.append("storyImage", values.userImage.file.originFileObj)
    }
    }
    else
    {
      data.append("other", "false")
      data.append("title", values.title)
      data.append("story", values.quil)
      data.append("place", selectedPlace)
      data.append("user", user.id)
      data.append("firstName", user.firstName)
      data.append("lastName", user.lastName)
    if (values.userImage) {
      data.append("storyImage", values.userImage.file.originFileObj)
    }
    }
    setLoading(true)
    dispatch(storyActions.AddStories(data,saveClicked));
    formRef.current.resetFields()

  };

  const onQuilValueChange = (value: any) => {
        setStory(value)

  }
  const onTitleChange=(e:any)=>{
   setTitle(e.target.value);
}
  const onplaceValueChange = (value: any) => {
      setOtherPlace(value.target.value)

}
const saveDraft =()=>{
  if(!selectedPlace)
  {
   message.error('please select a place.')
  }
  else
  {
    let saveClicked:boolean=false
    const data = new FormData()
    if(ohterValue)
    {
      data.append("isDrafted", "true")
      data.append("other", "true")
      data.append("title", title)
      data.append("otherValue", otherPlace)
      data.append("story", story)
      data.append("user", user.id)
      data.append("firstName", user.firstName)
      data.append("lastName", user.lastName)
    if (image) {
      data.append("storyImage", image.file.originFileObj)
    }
    }
    else
    {
      data.append("isDrafted", "true")
      data.append("other", "false")
      data.append("title", title)
      data.append("story", story)
      data.append("place",selectedPlace)
      data.append("user", user.id)
      data.append("firstName", user.firstName)
      data.append("lastName", user.lastName)



    if (image) {
      data.append("storyImage",image.file.originFileObj)
    }
    }
    dispatch(storyActions.AddStories(data,saveClicked));
  }

}
  const onFinishFailed = (errorInfo: any) => {
  };
  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
  };
  const onImageChange=(e:any)=>{
     setImage(e);
  }
  const onPlaceChange =(e:any)=>{

  if(e==="oth")
  {

    setOhterValue(true);
  }
  else
  {
    setSelectedPlace(e);

  }
  }
  if (!places) {
    return (<div></div>)
  }

  return (
    <div className="write-story-main-container">
        <NavBar/>
          <div className="inner-container-banner-stories write-story-container">
         <Form
        {...layout}
        ref={formRef}
        name="basic"
        className="write-story-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >

        <Form.Item
        name="title"
        className="story-title"
        label="Story Title"
        rules={[{ required: true, message: 'Please enter a title.' },]}>
        <Input onChange={onTitleChange} className="story-title-input" name="title" size="large" placeholder="Story Title" />
      </Form.Item>

        <Form.Item
          name="quil"
          label="Write story"
          initialValue=""
          className="composer-title"
          rules={[{ required: true, message: 'please write your story.' }]}
        >
           <ReactQuill className="quill-composer-main" modules={modules} formats={formats}  style={{ height: '80vh', marginBottom: '20px' }} theme="snow"  placeholder="write your story here..." onChange={onQuilValueChange} />
        </Form.Item>
             <Form.Item
          hasFeedback
          name="places"
          rules={[{ required: true, message: 'please choose a place.' }]}
          label="Travel Place"
          className="place-title"
          >
              <Select
              showSearch
               className="select-places"
                  placeholder="Search or select travel place"
                  filterOption={(input, option:any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                   onChange={onPlaceChange}
                   size="large" >

                {
                  places.length > 0 ?
                    places.map((item: any) =>
                     <Option value={item._id} >
                      {item.placeName}
                    </Option >
                    )
                    :
                    null
                }
                 <Option value="oth">
                     Other
                   </Option>
              </Select>

             </Form.Item>
        {
          ohterValue?
          <Form.Item
          name="otherPlace"
          className="place-title"
          label="Other Place"
        >
          <Input className="story-input-other-title"  value={otherPlace} onChange={onplaceValueChange} name="otherPlace" size="large" placeholder="write place" />
          <p className="write-sotry-desc-txt">Your story will be published on that travel place</p>
        </Form.Item>
        :null
        }
        <Form.Item
          name="userImage"
          label ="Feature photo"
          className="photo-title"
        >
          <Dragger
             onChange={onImageChange}
            beforeUpload={beforeUpload}
            onPreview={onPreview}
            multiple={false}
            {...prop}
            className="write-story-drager"
            listType="picture-card">

            <p>
              <CloudUploadOutlined style={{ color: "#000000" }} />
              <span> Upload or drop photo here</span>
            </p>
          </Dragger>
        </Form.Item>
        {/* <p className="write-sotry-desc-txt">This photo will be show along side your story title everywhere</p> */}
        <Form.Item className="write-stroy-btns">
          <div style={{textAlign:'right'}}>
          <Button style={{ marginTop: '50px', width: '20%'}} className="btn-admin-login" size="large" type="primary" htmlType="submit">
            Publish Story
          </Button>
          <Button onClick={saveDraft} style={{ marginTop: '50px', width: '20%',marginLeft:'10px'}} className="btn-admin-login" size="large"  type="primary">
           Save Draft
          </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
    </div>

  )
}
function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    places: state.FetchPlaceCategories.getAllPlaceCategoriesObject,
  };
}

export const connectedWriteStory = connect(mapStateToProps)(WriteStory);
