import React, { useEffect, useState } from 'react';
import {config} from '../../config';
import {history} from '../../Helpers/history'

export const UserImagePlaceCard = (props: any) => {
  const {data,placeId} = props;
  return (
    <div className="user-image-photo-container" onClick={()=>{history.push(`/place-photo/${placeId}`)}}>
        <img className="user-image-photo" src={config.s3BucketUrl+data.images} />
    </div>
  );
};

