
import React, { useEffect, useState } from 'react';
import { Button} from 'antd';
import {NoData} from '../Places';
import  Icon  from '@ant-design/icons';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import ContentLoader from 'react-content-loader'
import {userActions} from '../../actions';
import ReactGA from 'react-ga';
import {UserPlaceCards} from '.'


const MyLoader = () => (
	<ContentLoader viewBox="0 0 380 100">
		{/* Only SVG shapes */}
  	<rect x="80" y="17" rx="4" ry="4" width="250" height="13" />
		<rect x="0" y="0" rx="100" ry="100" width="50" height="50" />
		<rect x="80" y="17" rx="4" ry="4" width="150" height="10" />
		<rect x="80" y="40" rx="3" ry="3" width="150" height="10" />
    <rect x="80" y="40" rx="4" ry="4" width="150" height="10" />
	</ContentLoader>
);

const cookies = new Cookies();
export function Places(props:any) {
let vistedChecked ={
  backgroundColor:'#eee',
  color:'#656565',
}
let favCheck={
  backgroundColor:'#eee',
  color:'#656565',
}
  const {dispatch,favoritePlace,visteddPlaces,match}=props;
  const [favPlaces,setFavPlaces]=useState(false);
  const [vistedBackColor,setVistedBackColor] =useState('rgba(55, 180, 78,0.1)')
  const [favBackColor,setFavBackColor] =useState('#eeeeee')

  const [vistedColor,setVistedColor] =useState("#37b44e")
  const [favColor,setFavColor] =useState("#fd5a5a")
  const [vistedPlace,setVistedPlace]=useState(true);

  useEffect(()=>{
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  let userFav=cookies.get('userData');
  dispatch(userActions.getFavPlaces(match.params.userName));
  dispatch(userActions.getVistedPlaces(match.params.userName));
},[dispatch])

if(vistedPlace)
{
  vistedChecked ={
    backgroundColor:'rgba(55, 180, 78,0.1)',
    color:'#37b44e',
  }
}
if(favPlaces)
{
  favCheck ={
    backgroundColor:'rgba(55, 180, 78,0.1)',
    color:'#37b44e',
  }
}
  const vistedPlaces=()=>{
    setVistedPlace(true);
    setFavPlaces(false)
  }
  const favoritePlaces=()=>{
    setVistedPlace(false);
    setFavPlaces(true)
  }
  if(!visteddPlaces || !favoritePlace)
  {
    return(<div>
      <MyLoader/>
    </div>)
  }
  const vistedSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16">
    <g id="Group_3610" data-name="Group 3610" transform="translate(-51 -55)">
      <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(51 55)" fill="none" stroke={vistedColor} stroke-width="1">
        <circle cx="8" cy="8" r="8" stroke="none"/>
        <circle cx="8" cy="8" r="7.5" fill="none"/>
      </g>
      <g id="Group_3177" data-name="Group 3177" transform="translate(52.988 56.988)">
        <path id="Path_4179" data-name="Path 4179" d="M0,0H11.929V11.929H0Z" fill="none"/>
        <path id="Path_4180" data-name="Path 4180" d="M10,8.865a3.479,3.479,0,1,0-5.054,0,2.983,2.983,0,0,1,5.054,0ZM7.473,12.8,4.31,9.637a4.473,4.473,0,1,1,6.327,0Zm0-5.829A1.491,1.491,0,1,1,8.965,5.479,1.491,1.491,0,0,1,7.473,6.971Z" transform="translate(-1.509 -1.006)" fill="#37b44e"/>
      </g>
    </g>
  </svg>

  );
  const favSvg=()=>(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16">
  <g id="Group_3605" data-name="Group 3605" transform="translate(-54 -55)">
    <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(54 55)" fill="none" stroke={favColor} stroke-width="1">
      <circle cx="8" cy="8" r="8" stroke="none"/>
      <circle cx="8" cy="8" r="7.5" fill="none"/>
    </g>
    <g id="Group_3176" data-name="Group 3176" transform="translate(56 57)">
      <path id="Path_4177" data-name="Path 4177" d="M0,0H12V12H0Z" fill="none"/>
      <path id="Path_4178" data-name="Path 4178" d="M10.5,8.5V10H12v1H10.5v1.5h-1V11H8V10H9.5V8.5Zm.622-4.622a3,3,0,0,1,.253,3.955A3,3,0,0,0,7.344,11.9L7,12.243,2.76,8A3,3,0,0,1,7,3.765a3,3,0,0,1,4.121.114Z" transform="translate(-1 -1.5)" fill="#fd5a5a"/>
    </g>
  </g>
</svg>

  );

  const VistedIcon = (props:any) => <Icon component={vistedSvg} {...props} />;
  const HeartIcon = (props:any) => <Icon component={favSvg} {...props} />;
  return (
    <div>
      <div>
      <Button className="user-place-btns" style={vistedChecked} autoFocus={vistedPlace}  type="default" size="large" icon={<VistedIcon />} onClick={vistedPlaces}>Marked as visited</Button>
      <Button className="user-place-btns" autoFocus={favPlaces} style={favCheck}  type="default" size="large" icon={<HeartIcon/>} onClick={favoritePlaces} >Marked as favorite</Button>
      </div>
      <div>
        {
           vistedPlace?
          visteddPlaces.length>0?
          visteddPlaces.map((item: any,i:any) =>
          <UserPlaceCards data={item} key={i}/>
          ):
          <div className="user-visted-place-container">
     <NoData isFrom="visted"
    noData="No visited place found."
    noDataMessage="Need to mark travel places as visited."
 />
  </div>
     :null
        }
      </div>
        <div>
        {
          favPlaces?
          favoritePlace.length>0?
          favoritePlace.map((item: any,i:any) =>
          <UserPlaceCards data={item} key={i}/>
          ):
          <div className="fav-place-container">
          <NoData isFrom="fav"
          noData="No favorite place found."
          noDataMessage="Need to mark travel places as favorite."/>
          </div>
          :
          null

        }
      </div>
    </div>
  )
}
function mapStateToProps(state: any) {
  return {
    visteddPlaces:state.FetchVistedPlaces.fetchVistedPlacesObject,
    favoritePlace:state.FetchFavPlaces.fetchFavPlacesObject

  };
}
export const connecteduserPlaces = connect(mapStateToProps)(Places);
