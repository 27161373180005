import React,{useState,useEffect} from 'react';
import { Table, Dropdown, Menu, Badge, Modal, Input, Avatar,Switch} from 'antd';
import { history } from '../../../Helpers';
import { experiencesAction } from '../../../actions';
import { config } from "../../../config";
import { SettingOutlined,CloseOutlined, CheckOutlined,ExclamationCircleOutlined  } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
const { confirm } = Modal;


type Experience = {
  dispatch: any;
  data: any;
}
const Experience=(props:Experience)=>{
  let fetchId: any = '';
  const { dispatch, data } = props;
  const [checkSearch, setcheckSearch] = useState(false);
  const [filterData, setfilterData] = useState();
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {
      dispatch(experiencesAction.FetchPlaceExperiencesAdmin());

    }

  }, []);

  const [selectedRowKeys, setselectedRowKeys] = useState([]);


  const onSelectChange = (selectedRowKeys: any) => {
    setselectedRowKeys(selectedRowKeys)

  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

if(!data)
{
  return(<div></div>)
}
let filterArray: any = [];
  const categoriesArray: any = [];
  let storyStatus: any = '';
  let imagee: any = '';
  data.forEach((element: any, index: any) => {

    if (element.images) {
      imagee = config.s3BucketUrl+ element.images[0]
    }
    else {
      imagee = "N/A"
    }
    if (element.isActive) {
      storyStatus = <Badge status="success" text="Enabled" />

    }
    else {
      storyStatus = <Badge status="default" text="Disabled" />
    }
    if(element.place)
    {
    categoriesArray.push({
      key: element._id,
      id: element._id,
      Photo: imagee,
      title: element.title.substring(0,30),
      placeName: element.place.placeName,
      placeId: element.place._id,
      userName: element.users.firstName + " " + element.users.lastName,
      user:element.users.userName,
      userId: element.users._id,
      tourDate: element.tourDate.split("T")[0],
      tourType:element.tourType,
      status: storyStatus,
      rating: element.experience,
      createdDate: element.createdDate.split("T")[0],

    })
  }

  });
  let search = (value: any) => {

    const filterArrayData = data.filter((o: any) =>
      Object.keys(o).some(k =>
        String(o[k])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );
    filterArrayData.forEach((element: any, index: any) => {
      if (element.images) {
        imagee = config.s3BucketUrl+ element.images[0]
      }
      else {
        imagee = "N/A"
      }
      if (element.image) {
      filterArray.push({
        key: element._id,
        id: element._id,
        Photo: imagee,
        title: element.title.substring(0,30),
        placeName: element.place.placeName,
        placeId: element.place._id,
        userName: element.users.firstName + " " + element.users.lastName,
        userId: element.users._id,
        tourDate: element.tourDate.split("T")[0],
        user:element.users.userName,
        tourType: element.tourType,
        status: storyStatus,
        rating: element.experience,
        createdDate: element.createdDate.split("T")[0],
      })
    }

    });
    setfilterData(filterArray);
    setcheckSearch(true);
  };


  const onMenuClick = (id: any) => {
    fetchId = id;
  }
  const onSelect = (e: any) => {
    if (e.key == "EU") {
      history.push('/pr-admin-cp/edit-experience/' + fetchId)
    }
    else {
      Modal.warning({
        title: 'Opppssss',

        content: (
          <div className="warning-modal">
            <p>Are you sure you want to delete this?</p>
          </div>
        ),
        className: "cancel-modal",
        width: 400,
        okType: 'danger',
        okText: 'ok',
        okCancel: true,
        centered: true,
        cancelText: 'cancel',
        onOk: () => {

        },
        onCancel() {
        },
      });
      let formData:any={
          place:fetchId.placeId,
          user:fetchId.userId
      }
      dispatch(experiencesAction.DeleteExperienceAdmin(formData,fetchId.id));
      dispatch(experiencesAction.FetchPlaceExperiencesAdmin());
    }

  }
  const onStatusChange=(records)=>{

    let dat={
      isFeatured:!records.isFeatured
    }
    if(!records.isFeatured)
    {
      confirm({
        title: 'Do you Want to Activate this user?',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          // dispatch(storyActions.FeaturedStory(dat,records.key));
        },
        onCancel() {
        },
      });
    }
    else
    {
      confirm({
        title: 'Do you Want to Deactivate this user?',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          // dispatch(storyActions.FeaturedStory(dat,records.key));
        },
        onCancel() {
        },
      });
    }
  }

  const onImageClick = (image: any) => {
    window.open(image);
  }

  const columns = [
    {
      title: 'User Name',
      render: (records: any) =>
        <Link to={`/tourist/${records.user}/about`} className="table-img" >
          {records.userName}
        </Link>
    },
    {
      title: 'Photo',
      dataIndex: 'Photo',
      render: (records: any) => <div onClick={() => onImageClick(records)}><Avatar className="table-img" src={records}></Avatar></div>

    },
    {
      title: 'Place Name',
      render: (records: any) =>
        <Link to={`/place/${records.placeName.split(' ').join('-').toLowerCase()}-${records.placeId}`} className="table-img" >
          {records.placeName}
        </Link>
    },
    {
      title: 'Tour Date',
      dataIndex: 'tourDate',
    },
    {
      title: 'Tour Type',
      dataIndex: 'tourType',
    },
    {
      title: 'Ratings',
      dataIndex: 'rating',
    },
    {
      title: 'Tour With',
      dataIndex: 'tourDate',
    },
    {
      title: 'Created  Date',
      dataIndex: 'createdDate',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Title',
     dataIndex: 'title'
    },

    {
      title: 'Settings',
      render: (records: any) => <Dropdown className="admin-acc" overlay={(
        <Menu onClick={onSelect}>
          <Menu.Item key="EU" onClick={() => onMenuClick(records.id)} className="user-menu-item">
            <span>
              Edit Experience
          </span>
          </Menu.Item>
          <Menu.Item key="DU" onClick={() => onMenuClick(records)} className="user-menu-item">
            <span>
              Delete Experience
          </span>
          </Menu.Item>
        </Menu>
      )} trigger={['click']} >
        <SettingOutlined />
      </Dropdown>,
    },
  ];

  return (
    <div>

      <div style={{ width: '50%', float: 'left', marginBottom: "20px" }}>
        <Input.Search

          placeholder="Search by..."
          enterButton
          size="large"
          onSearch={search}
        />
      </div>
      {
        checkSearch ? <Table className="show-table" rowSelection={rowSelection} columns={columns} dataSource={filterData} /> :
          <Table className="show-table" rowSelection={rowSelection} columns={columns} dataSource={categoriesArray} />

      }
    </div>
  );


}

const mapStateToProps = (state: any) => {
  const { type, message } = state.alert;
  return {
    type,
    message,
    data:state.FetchAllPlaceExperiencesAdmin.getAllPlaceExperiencesAdminObject
  };
}

export const connectedExperience = connect(mapStateToProps)(Experience);
