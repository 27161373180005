export const supportConstants = {


  ADD_HELP_REQUEST: "ADD_HELP_REQUEST",
  ADD_HELP_SUCCESS: "ADD_HELP_SUCCESS",
  ADD_HELP_FAILURE: "ADD_HELP_FAILURE",

  ADD_PRIVACY_REQUEST: "ADD_PRIVACY_REQUEST",
  ADD_PRIVACY_SUCCESS: "ADD_PRIVACY_SUCCESS",
  ADD_PRIVACY_FAILURE: "ADD_PRIVACY_FAILURE",

  ADD_REPORT_REQUEST: "ADD_REPORT_REQUEST",
  ADD_REPORT_SUCCESS: "ADD_REPORT_SUCCESS",
  ADD_REPORT_FAILURE: "ADD_REPORT_FAILURE",

  GET_HELP_REQUEST: "GET_HELP_REQUEST",
  GET_HELP_SUCCESS: "GET_HELP_SUCCESS",
  GET_HELP_FAILURE: "GET_HELP_FAILURE",

  ADD_CONTACT_REQUEST: "ADD_CONTACT_REQUEST",
  ADD_CONTACT_SUCCESS: "ADD_CONTACT_SUCCESS",
  ADD_CONTACT_FAILURE: "ADD_CONTACT_FAILURE",

  GET_CONTACT_REQUEST: "GET_CONTACT_REQUEST",
  GET_CONTACT_SUCCESS: "GET_CONTACT_SUCCESS",
  GET_CONTACT_FAILURE: "GET_CONTACT_FAILURE",

  GET_REPORT_REQUEST: "GET_REPORT_REQUEST",
  GET_REPORT_SUCCESS: "GET_REPORT_SUCCESS",
  GET_REPORT_FAILURE: "GET_REPORT_FAILURE",

  GET_PRIVACY_ADMIN_REQUEST: "GET_PRIVACY_ADMIN_REQUEST",
  GET_PRIVACY_ADMIN_SUCCESS: "GET_PRIVACY_ADMIN_SUCCESS",
  GET_PRIVACY_ADMIN_FAILURE: "GET_PRIVACY_ADMIN_FAILURE",


  GET_PRIVACY_REQUEST: "GET_PRIVACY_REQUEST",
  GET_PRIVACY_SUCCESS: "GET_PRIVACY_SUCCESS",
  GET_PRIVACY_FAILURE: "GET_PRIVACY_FAILURE",

  GET_EDIT_PRIVACY_REQUEST: "GET_EDIT_PRIVACY_REQUEST",
  GET_EDIT_PRIVACY_SUCCESS: "GET_EDIT_PRIVACY_SUCCESS",
  GET_EDIT_PRIVACY_FAILURE: "GET_EDIT_PRIVACY_FAILURE",

  UPDATE_PRIVACY_REQUEST: "UPDATE_PRIVACY_REQUEST",
  UPDATE_PRIVACY_SUCCESS: "UPDATE_PRIVACY_SUCCESS",
  UPDATE_PRIVACY_FAILURE: "UPDATE_PRIVACY_FAILURE",


};
