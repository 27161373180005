import {categoryConstants} from '../constants';

export function FetchCategories(state={getAllCategoriesObject:false},action:any)
{

    switch (action.type) {
        case categoryConstants.GET_ALL_REQUEST:
          return state;
          case categoryConstants.GET_ALL_SUCCESS:
          return{
            ...state,
            getAllCategoriesObject:action.user
          };
      default:
        return state
    }
}

export function FetchEditCategory(state={fetchEditCategoryObject:false},action:any)
{
  switch (action.type) {
    case categoryConstants.EDIT_CATEGORY_REQUEST:
      return state;
      case categoryConstants.EDIT_CATEGORY_SUCCESS:
      return{
        ...state,
        fetchEditCategoryObject:action.user
      };
  default:
    return state
}
}

export function FetchPlaceCategories(state={getAllPlaceCategoriesObject:false},action:any)
{

    switch (action.type) {
        case categoryConstants.GET_ALL_PLACE_CATEGORIES:
          return state;
          case categoryConstants.GET_ALL_PLACE_CATEGORIES_SUCCESS:
          return{
            ...state,
            getAllPlaceCategoriesObject:action.user
          };
      default:
        return state
    }
}

export function FetchEditPlaceCategory(state={fetchEditPlaceCategoryObject:false},action:any)
{
  switch (action.type) {
    case categoryConstants.EDIT_PLACE_CATEGORY_REQUEST:
      return state;
      case categoryConstants.EDIT_PLACE_CATEGORY_SUCCESS:
      return{
        ...state,
        fetchEditPlaceCategoryObject:action.user
      };
  default:
    return state
}
}

export function FetchPakMapCategories(state={getAllPakMapCategoriesObject:false},action:any)
{

    switch (action.type) {
        case categoryConstants.GET_ALL_PAK_MAP_CAT:
          return state;
          case categoryConstants.GET_ALL_PAK_MAP_CAT_SUCCESS:
          return{
            ...state,
            getAllPakMapCategoriesObject:action.user
          };
      default:
        return state
    }
}

export function FetchWeather(state={fetchWeatherObject:false},action:any)
{

    switch (action.type) {
        case categoryConstants.GET_WEATHER_REQUEST:
          return state;
          case categoryConstants.GET_WEATHER_REQUEST_SUCCESS:

          return{
            ...state,
            fetchWeatherObject:action.weather
          };
      default:
        return state
    }
}

export function FetchCurrentWeather(state={fetchCurrentWeatherObject:false},action:any)
{

    switch (action.type) {
        case categoryConstants.GET_CURRENT_WEATHER_REQUEST:
          return state;
          case categoryConstants.GET_CURRENT_WEATHER_REQUEST_SUCCESS:

          return{
            ...state,
            fetchCurrentWeatherObject:action.weather
          };
      default:
        return state
    }
}

