import { interestConstants } from "../constants";
import { interestService } from "../services";
import { history } from "../Helpers";
import { alertActions } from "./";

export const interestActions = {
  FetchAllInterest,
  AddInterest,
  FetchEditInterest,
  UpdateInterest,
  DeleteInterest,
};

function FetchAllInterest() {
  return (dispatch: any) => {
    dispatch(request());
    interestService.FetchAllInterest().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: interestConstants.GET_ALL_INTEREST_REQUEST };
  }
  function success(user: any) {
    return { type: interestConstants.GET_ALL_INTEREST_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: interestConstants.GET_ALL_INTEREST_FAILURE, error };
  }
}

function AddInterest(data: any) {
  return (dispatch: any) => {
    dispatch(request(data));
    interestService.AddInterest(data)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "201") {
          dispatch(success(data));
          dispatch(
            alertActions.success("You have successfully added an interest ")
          );
          history.push("/pr-admin-cp/interests");
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: interestConstants.ADD_INTEREST_REQUEST, data };
    }
    function success(user: any) {
      return { type: interestConstants.ADD_INTEREST_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: interestConstants.ADD_INTEREST_FAILURE, error };
    }
  };
}

function FetchEditInterest(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    interestService.GetEditInterest(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: interestConstants.EDIT_INTEREST_REQUEST, id };
  }
  function success(user: any) {
    return { type: interestConstants.EDIT_INTEREST_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: interestConstants.EDIT_INTEREST_FAILURE, error };
  }
}

function UpdateInterest(data: any, id: any) {
  return (dispatch: any) => {
    dispatch(request(data, id));
    interestService
      .UpdateInterest(data, id)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "200") {
          dispatch(success(data));
          dispatch(
            alertActions.success("You have successfully update an interest ")
          );
          history.push("/pr-admin-cp/interests");
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any, id: any) {
      return { type: interestConstants.UPDATE_INTEREST_REQUEST, data, id };
    }
    function success(user: any) {
      return { type: interestConstants.UPDATE_INTEREST_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: interestConstants.UPDATE_INTEREST_FAILURE, error };
    }
  };
}

function DeleteInterest(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    interestService
      .DeleteInterest(id)
      .then((response) => response)
      .then((res: any) => {
        if (res.status == "200") {
          dispatch(success(res));
          dispatch(alertActions.success(res));
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });

    function request(id: any) {
      return { type: interestConstants.DELETE_INTEREST_REQUEST, id };
    }
    function success(user: any) {
      return { type: interestConstants.DELETE_INTEREST_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: interestConstants.DELETE_INTEREST_FAILURE, error };
    }
  };
}
