
import { supportConstants } from "../constants";
import { supportServices } from "../services";
import { alertActions } from ".";
import {history} from '../Helpers'

const  AddHelpRequest=(data: any)=> {
  return (dispatch: any) => {
    dispatch(request(data));
    supportServices
      .AddHelpRequest(data)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "201") {
          dispatch(success(data));
          dispatch(
            alertActions.success(data.data.message)
          );
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: supportConstants.ADD_HELP_REQUEST, data };
    }
    function success(user: any) {
      return { type: supportConstants.ADD_HELP_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: supportConstants.ADD_HELP_FAILURE, error };
    }
  };
}

const  AddReportRequest=(data: any)=> {
  return (dispatch: any) => {
    dispatch(request(data));
    supportServices
      .AddReportRequest(data)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "201") {
          dispatch(success(data));
          dispatch(
            alertActions.success(data.data.message)
          );
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: supportConstants.ADD_REPORT_REQUEST, data };
    }
    function success(user: any) {
      return { type: supportConstants.ADD_REPORT_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: supportConstants.ADD_REPORT_FAILURE, error };
    }
  };
}

const  AddContactRequest=(data: any)=> {
  return (dispatch: any) => {
    dispatch(request(data));
    supportServices
      .AddContactRequest(data)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "201") {
          dispatch(success(data));
          dispatch(
            alertActions.success(data.data.message)
          );
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: supportConstants.ADD_CONTACT_REQUEST, data };
    }
    function success(user: any) {
      return { type: supportConstants.ADD_CONTACT_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: supportConstants.ADD_CONTACT_FAILURE, error };
    }
  };
}
const FetchEditPrivacy=(id: any)=>{
  return (dispatch: any) => {
    dispatch(request(id));
    supportServices.GetEditPrivacies(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: supportConstants.GET_EDIT_PRIVACY_REQUEST, id };
  }
  function success(user: any) {
    return { type: supportConstants.GET_EDIT_PRIVACY_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: supportConstants.GET_EDIT_PRIVACY_FAILURE, error };
  }
}


const  AddPrivacyRequest=(data: any)=> {
  return (dispatch: any) => {
    dispatch(request(data));
    supportServices
      .AddPrivacyRequest(data)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "201") {
          dispatch(success(data));
          dispatch(
            alertActions.success(data.data.message)
          );
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: supportConstants.ADD_PRIVACY_REQUEST, data };
    }
    function success(user: any) {
      return { type: supportConstants.ADD_REPORT_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: supportConstants.ADD_REPORT_FAILURE, error };
    }
  };
}

const FetchAllHelpForms =() => {
  return (dispatch: any) => {
    dispatch(request());
    supportServices.GetHelpForms().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: supportConstants.GET_HELP_REQUEST };
  }
  function success(user: any) {
    return { type: supportConstants.GET_HELP_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: supportConstants.GET_REPORT_FAILURE, error };
  }
}

const FetchAllContactForms =() => {
  return (dispatch: any) => {
    dispatch(request());
    supportServices.GetContactForms().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: supportConstants.GET_CONTACT_REQUEST };
  }
  function success(user: any) {
    return { type: supportConstants.GET_CONTACT_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: supportConstants.GET_CONTACT_FAILURE, error };
  }
}

const FetchAllPrivaciesAdmin =() => {
  return (dispatch: any) => {
    dispatch(request());
    supportServices.GetPrivacyFormsAdmin().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: supportConstants.GET_PRIVACY_ADMIN_REQUEST };
  }
  function success(user: any) {
    return { type: supportConstants.GET_PRIVACY_ADMIN_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: supportConstants.GET_PRIVACY_ADMIN_FAILURE, error };
  }
}
function FetchPrivacies(type: any) {
  return (dispatch: any) => {
    dispatch(request(type));
    supportServices.GetPrivacies(type).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: supportConstants.GET_PRIVACY_REQUEST, id };
  }
  function success(user: any) {
    return { type: supportConstants.GET_PRIVACY_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: supportConstants.GET_PRIVACY_FAILURE, error };
  }
}

const FetchAllReportForms =() => {
  return (dispatch: any) => {
    dispatch(request());
    supportServices.GetReportForms().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: supportConstants.GET_REPORT_REQUEST };
  }
  function success(user: any) {
    return { type: supportConstants.GET_REPORT_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: supportConstants.GET_REPORT_FAILURE, error };
  }
}

function UpdatePrivacy(data: any, id: any) {
  return (dispatch: any) => {
    dispatch(request(data, id));
    supportServices
      .UpdatePrivacy(data, id)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "200") {
          dispatch(success(data));
          dispatch(
            alertActions.success(data.data.message)
          );
          history.push("/pr-admin-cp/privacy-and-terms");
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any, id: any) {
      return { type: supportConstants.UPDATE_PRIVACY_REQUEST, data, id };
    }
    function success(user: any) {
      return { type: supportConstants.UPDATE_PRIVACY_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: supportConstants.UPDATE_PRIVACY_FAILURE, error };
    }
  };
}


export const SupportActions = {
  AddHelpRequest,
  AddReportRequest,
  FetchAllHelpForms,
  FetchAllReportForms,
  AddPrivacyRequest,
  FetchAllPrivaciesAdmin,
  FetchEditPrivacy,
  UpdatePrivacy,
  FetchPrivacies,
  AddContactRequest,
  FetchAllContactForms

};
