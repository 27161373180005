import { facilitiesConstants } from "../constants";
import { facilitiesService } from "../services";
import { alertActions } from ".";
import { history } from "../Helpers";

export const facilitiesActions = {
  FetchAllFacilities,
  AddActivities,
  FetchEditActivities,
  DeleteActivity,
  UpdateActivity,
};

function FetchAllFacilities() {
  return (dispatch: any) => {
    dispatch(request());
    facilitiesService.FetchAllFacilities().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: facilitiesConstants.GET_ALL_FACILITIES_REQUEST };
  }
  function success(user: any) {
    return { type: facilitiesConstants.GET_ALL_FACILITIES_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: facilitiesConstants.GET_ALL_FACILITIES_FAILURE, error };
  }
}

function AddActivities(data: any) {
  return (dispatch: any) => {
    dispatch(request(data));
    facilitiesService
      .AddActivity(data)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "201") {
          dispatch(success(data));
          dispatch(
            alertActions.success("You have successfully add an activity ")
          );
          history.push("/pr-admin-cp/activities");
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: facilitiesConstants.ADD_ACTIVITIES_REQUEST, data };
    }
    function success(user: any) {
      return { type: facilitiesConstants.ADD_ACTIVITIES_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: facilitiesConstants.ADD_ACTIVITIES_FAILURE, error };
    }
  };
}

function FetchEditActivities(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    facilitiesService.FetchEditActivity(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: facilitiesConstants.EDIT_ACTIVITY_REQUEST, id };
  }
  function success(user: any) {
    return { type: facilitiesConstants.EDIT_ACTIVITY_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: facilitiesConstants.EDIT_ACTIVITY_FAILURE, error };
  }
}

function DeleteActivity(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    facilitiesService
      .DeleteActivity(id)
      .then((res: any) => {
        dispatch(success(res.message));
        dispatch(alertActions.success(res.message));
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });

    function request(id: any) {
      return { type: facilitiesConstants.DELETE_ACTIVITY_REQUEST, id };
    }
    function success(user: any) {
      return { type: facilitiesConstants.DELETE_ACTIVITY_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: facilitiesConstants.DELETE_ACTIVITY_FAILURE, error };
    }
  };
}

function UpdateActivity(data: any, id: any) {
  return (dispatch: any) => {
    dispatch(request(data, id));
    facilitiesService
      .UpdateActivity(data, id)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "200") {
          dispatch(success(data));
          dispatch(
            alertActions.success("You have successfully update an activity ")
          );
          history.push("/pr-admin-cp/activities");
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any, id: any) {
      return { type: facilitiesConstants.UPDATE_ACTIVITY_REQUEST, data, id };
    }
    function success(user: any) {
      return { type: facilitiesConstants.UPDATE_ACTIVITY_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: facilitiesConstants.UPDATE_ACTIVITY_FAILURE, error };
    }
  };
}
