import React from 'react';
import { List, ListItem} from "@chakra-ui/core";
import {Link} from 'react-router-dom'
import './footer.css'

export function UpperFooterSection() {
  return (
    <div className="container-upper-footer ">
    <div className="upper-footer-container">
        <div className="inner-footer-container">
         <div className="footer-sec-li-container">
             <h3 className="header-text-footer">
             EXPLORE
             </h3>
             <List className="footer-list" styleType="none">
             <ListItem>
               <Link to="/pak-map">
                Pak Map
               </Link>
               </ListItem>
             <ListItem>
             <Link to="/travel-stories">
             Travel Stories
               </Link>
             </ListItem>
             <ListItem>
             <Link to="/travel-albums">
             Travel Albums
               </Link>
             </ListItem>
             <ListItem>
             <Link to="/tourists">
             Pakistan Tourists
               </Link>
             </ListItem>
            </List>
         </div>
         <div className="footer-sec-li-container">
         <h3 className="header-text-footer">
         GET OUR APP
             </h3>
             <List className="footer-list" styleType="none">
             <ListItem>
             <a href="https://play.google.com/store/apps/details?id=com.pakrism" target="_blank">
             Google Playstore
               </a>
               </ListItem>
             <ListItem>
             <a href="https://apps.apple.com/us/app/pakrism/id1496630730" target="_blank">
             Apple Store
               </a>
             </ListItem>
            </List>
         </div>
         <div className="footer-sec-li-container">
         <h3 className="header-text-footer">
             Support
             </h3>
             <List className="footer-list" styleType="none">
             <ListItem>
             <Link to="/help">
             Help
               </Link>
             </ListItem>
             <ListItem>
             <Link to="/report-a-problem">
             Report a problem
               </Link>
             </ListItem>
             <ListItem>
             <Link to="/contact-us">
             Contact us
               </Link>
             </ListItem>
             <ListItem>
             </ListItem>
            </List>
         </div>
         <div className="footer-sec-li-container">
         <h3 className="header-text-footer">
         Information
             </h3>
             <List className="footer-list" styleType="none">
             <ListItem>
             <Link to="/privacy-policy">
             Privacy policy
               </Link>
             </ListItem>
             <ListItem>
             <Link to="/terms-of-use">
             Terms of use
               </Link>
             </ListItem>
             <ListItem>
             <Link to="/cookies-statement">
             Cookies Statement
               </Link>
             </ListItem>

            </List>
         </div>
        </div>
    </div>
    </div>
  )
}


