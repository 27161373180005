import { alertActions } from "../actions";
import { config } from "../config";
import axios from "axios";

export const deviceInfoService = {
  FetchDeviceInfoById,
  AddDeviceInfo,
  FetchDeviceInfo,
  DeleteDeviceInfo,
  UpdateDeviceInfo
};

function FetchDeviceInfoById(id:any) {
  return fetch(`${config.apiurl}/admin//get-device-info/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

function FetchDeviceInfo() {
  return fetch(`${config.apiurl}/admin/get-device-info/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

function DeleteDeviceInfo(id: any) {
  return axios
    .delete(`${config.apiurl}/admin/delete-device-info/${id}`, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}


function AddDeviceInfo(values: any) {
  return axios
    .post(`${config.apiurl}/admin/add-device-info/`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}


function UpdateDeviceInfo(values: any,id:any) {
  return axios
    .patch(`${config.apiurl}/admin/update-device-info/${id}`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}
