import React, { useEffect, useState } from 'react';
import heartLine from '../../Assets/star-smile-line.svg';
import heartFill from '../../Assets/star-smile-fill.svg';
import  Share  from "../../Assets/icon_share_inactive_dark.svg";
import {storyActions} from '../../actions'
import {history} from '../../Helpers'
import {Image } from "@chakra-ui/core";
import { Modal } from 'antd';
import {FacebookShareButton, FacebookIcon} from "react-share";


export function LikeFooter(props:any) {

  let user = JSON.parse(localStorage.getItem('user')!)

  const {data,dispatch} = props;
  const [placeTitle,setPlaceTitle] = useState('');
  const [visible,setVisible] = useState(false);
  const [likes,setLikes]= useState(data.stories.likes.length)
  const [isliked,setLiked] =useState(false);
  useEffect(()=>{

    setPlaceTitle(data.stories.title.replace(/[ ,?]/g,'-'))
   if(user)
  {
    data.stories.likes.forEach((element:any) => {
      if(element.toString()===user.id.toString())
      {
       setLiked(true)
      }
     });
  }


  },[])
  const onUnlike=()=>{
    let obj={
      userId:user.id
    }
    setLikes(likes-1)
    setLiked(false)
    dispatch(storyActions.RemoveStoryLike(obj,data.stories._id));
  }
  const onLike=()=>{
    let obj={
      userId:user.id
    }
    setLikes(likes+1)
    setLiked(true)
    dispatch(storyActions.AddStoryLike(obj,data.stories._id));
  }

  const showModal = () => {
    setVisible(true)
  };
  const handleOk = (e:any) => {
    setVisible(false)
  };

  const handleCancel = (e:any)  => {
    setVisible(false)
  };
  return (
    <div id="footer-main" className="sotry-like-footer">
      {
        user?
        isliked?
        <div className="story-like-footer-reaction-container" onClick={onUnlike}>

      <div
       className="reaction-icon"
      >
        <Image
          src={heartFill}
          alt="inspired Fist"
          height="18px"
          width="18px"
        />
      </div>
      <div  className="icon-txt">
        <small className="small-txt-ftr" style={{color:'#37b44e'}}>Inspired({likes})</small>
      </div>
    </div>:
    <div className="story-like-footer-reaction-container" onClick={onLike}>
    <div
     className="reaction-icon"
    >
      <Image
        src={heartLine}
        alt="inspired Fist"
        height="18px"
        width="18px"
      />
    </div>
    <div  className="icon-txt">
      <small className="small-txt-ftr">Inspire({likes})</small>
    </div>
  </div>
  :
  <div className="story-like-footer-reaction-container" onClick={()=>history.push('/login')}>
    <div
     className="reaction-icon"
    >
      <Image
        src={heartLine}
        alt="inspired Fist"
        height="18px"
        width="18px"
      />
    </div>
    <div  className="icon-txt">
      <small className="small-txt-ftr">Inspire({likes})</small>
    </div>
  </div>
      }

    <div className="story-like-footer-reaction-container" onClick={showModal} >
    <div
       className="reaction-icon" >
        <Image
          src={Share}
          alt="inspired Fist"
          height="18px"
          width="18px"
        />
      </div>
      <div className="icon-txt"  >
        <small className="small-txt-ftr">Share</small>
      </div>
    </div>
    <Modal
          title="Share social"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <FacebookShareButton
                url={`https://pr-app.pakrism.com/place/${placeTitle}-${data.stories._id}`}
                quote={"Pakrism- World is yours to explore"}
                hashtag="#PakrismStory"
                className="" >
                 <FacebookIcon size={36} round={true} />
              </FacebookShareButton>

        </Modal>
  </div>
  )
}
