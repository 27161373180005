import React, { useEffect } from 'react';
import { Button, Form, Input, Select, Upload, DatePicker, message,Switch } from 'antd';
import { CloudUploadOutlined,CloseOutlined, CheckOutlined } from "@ant-design/icons";
import {Avatar} from '@chakra-ui/core'
import { history,store} from '../../../Helpers';
import { config } from "../../../config";
import { connect } from 'react-redux';
import { agenciesActions } from '../../../actions';
import moment from 'moment';

const { Option } = Select;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};

type EditTravelAgency = {
  dispatch: any;
  match: any;
  data: any;
}

const { Dragger } = Upload;

const prop: any = {
  // name: 'images',
  // action: '',
  // headers: {
  //   authorization: 'authorization-text',
  // },
};

function beforeUpload(file: any) {

  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const dateFormat = 'YYYY/MM/DD';

function EditTravelAgency(props: EditTravelAgency) {
  store.getState().FetchEditTravelAgencies.fetchEditTravelAgenciesObject = "";
  const { dispatch, match, data } = props;
  let image: any = '';


  const onFinish = (values: any) => {
    const formData = new FormData()

    if(values.dob)
    {
      formData.append("DOB", moment(values.dob).toISOString())
    }
    else
    {
      formData.append("DOB", DOB)
    }

    formData.append("firstName", values.firstName)
    formData.append("lastName", values.lastName)
    formData.append("prefix", values.prefix)
    formData.append("agancyName", values.agancyName)
    formData.append("agancyEmail", values.agancyEmail)
    formData.append("city", values.city)
    formData.append("state", values.state)
    formData.append("country", values.country)
    formData.append("zipCode", values.zipCode)
    formData.append("roles", values.roles)
    formData.append("lang", values.lang)
    formData.append("gender", values.gender)
    formData.append("email", values.email)
    if (values.logo) {
      formData.append("images", values.logo.file.originFileObj)
    }

    formData.append("logo", data.logo)
    dispatch(agenciesActions.UpdateAgencyAdmin(formData, match.params.id));

  };
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {
      dispatch(agenciesActions.FetchEditTravelAgency(match.params.id));
    }

  }, [dispatch, match.params.id]);

  const onFinishFailed = (errorInfo: any) => {
  };



  if (!data) {
    return (<div></div>)
  }
  let DOB:any="";
  image = config.s3BucketUrl + data.logo;
  let prefix: any = '';
  if(data.DOB)
  {

   DOB=moment(data.DOB).format(dateFormat);
  }
  const prefixSelector = (
    <Form.Item initialValue={prefix} name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="+92">+92</Option>
        <Option value="+91">+91</Option>
      </Select>
    </Form.Item>
  );
  return (
    <div>
      <Form
        {...layout}
        className="admin-login-form"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item className="lbl-name" label="Agent First Name"></Form.Item>
        <Form.Item
          name="firstName"
          initialValue={data.agent.firstName}
          hasFeedback
          rules={[{ required: true, message: 'Please enter your first name.' },
          {
            max: 24,
            message: "First name must be maximum 24 characters."
          },
          {
            pattern: new RegExp(/^[A-Z]*$/i),
            message: "First name can only have alphabets."
          },
          {
            min: 3,
            message: "First name must be minimum 3 characters."
          }
          ]}
        >
          <Input name="firstName" size="large" placeholder="First Name" />
        </Form.Item>

        <Form.Item className="lbl-name" label="Agent Last Name"></Form.Item>
        <Form.Item
          name="lastName"
          initialValue={data.agent.lastName}
          hasFeedback
          rules={[{ required: true, message: 'Please enter your last name.' },
          {
            max: 24,
            message: "Last name must be maximum 24 characters."
          },
          {
            pattern: new RegExp(/^[A-Z]*$/i),
            message: "Last name can only have alphabets."
          },
          {
            min: 3,
            message: "Last name must be minimum 3 characters."
          }
          ]}
        >
          <Input name="lastName" placeholder="Last Name" size="large"
          />
        </Form.Item>
        <Form.Item className="lbl-name" label="Agancy Name"></Form.Item>
        <Form.Item
          name="agancyName"
          initialValue={data.agancyName}
          hasFeedback
        >
          <Input placeholder="Agency Name" size="large" />
        </Form.Item>
        <Form.Item className="lbl-name" label="Agancy Email"></Form.Item>
        <Form.Item
          name="agancyEmail"
          initialValue={data.agancyEmail}
          hasFeedback
        >
          <Input name="agancyEmail" placeholder="Agency Email" size="large"
          />
        </Form.Item>


        <Form.Item className="lbl-name" label="Agent Email address"></Form.Item>
        <Form.Item
          name="email"
          initialValue={data.agent.email}
          hasFeedback
          rules={[
            {
              type: 'email',
              message: "Please enter a valid email address.",
            },
            { required: true, message: 'Please enter your email.' }]}
        >
          <Input disabled placeholder="Email address" size="large" />
        </Form.Item>
        <Form.Item className="lbl-name" label="Phone"></Form.Item>
        <Form.Item
          initialValue={data.phone}
          hasFeedback
          name="phone"
          >
          <Input addonBefore={prefixSelector} size="large" />
        </Form.Item>
        <Form.Item className="lbl-name" label="Photo"></Form.Item>
        <Form.Item
          name="logo"
          className="file-upload">
          <Dragger
             beforeUpload={beforeUpload}
             listType="picture-card"
             {...prop}


          >
            <p className="ant-upload-drag-icon">
              <CloudUploadOutlined style={{ color: "#000000" }} />
              <span className="upld-desc" > Upload or drop photo here</span>
            </p>
          </Dragger>
        </Form.Item>
        <Form.Item name="logo" style={{ width: "50%" }}>
        {
          data.logo? <img src={image} width="30%"  alt="image" style={{ textAlign: 'center' }} />
          :<Avatar name={data.agent.firstName+" "+data.agent.lastName}/>
        }
        </Form.Item>
        {/* <Form.Item className="lbl-name" label="Street"></Form.Item>
        <Form.Item
          name="street"
          initialValue={data[0].address.street}
          hasFeedback
          rules={[{ required: true, message: 'Please enter Street.' },]}
        >
          <Input name="street" placeholder="Street" size="large"
          />
        </Form.Item> */}
        {/* <Form.Item className="lbl-name" label="Apartment"></Form.Item>
        <Form.Item
          name="apartment"
          initialValue={data[0].address.apartment}
          hasFeedback
        >
          <Input name="apartment" placeholder="Apartment" size="large"
          />
        </Form.Item> */}
        <Form.Item className="lbl-name" label="City"></Form.Item>
        <Form.Item
          name="city"
          initialValue={data.address.city}
          hasFeedback
          rules={[{ required: true, message: 'Please enter City.' },]}
        >
          <Input name="city" placeholder="City" size="large"
          />
        </Form.Item>
        <Form.Item className="lbl-name" label="State"></Form.Item>
        <Form.Item
          initialValue={data.address.state}
          name="state"
          hasFeedback
          rules={[{ required: true, message: 'Please enter state.' },]}
        >
          <Input name="state" placeholder="State" size="large"
          />
        </Form.Item>

        <Form.Item className="lbl-name" label="Country"></Form.Item>
        <Form.Item
          initialValue={data.address.country}
          name="country"
          hasFeedback
          rules={[{ required: true, message: 'Please enter country.' },]}
        >
          <Input name="country" placeholder="Country" size="large"
          />
        </Form.Item>

        <Form.Item className="lbl-name" label="Postal Code"></Form.Item>
        <Form.Item
          initialValue={data.address.zipCode}
          name="zipCode"
          hasFeedback
        >
          <Input name="postalCode" placeholder="Postal Code" size="large"
          />
        </Form.Item>

        {/* <Form.Item className="lbl-name" label="Profession"></Form.Item>
        <Form.Item
          name="profession"
          initialValue={data[0].Profession}
          hasFeedback
          rules={[{ required: true, message: 'Please enter profession.' },]}
        >
          <Input name="profession" placeholder="Profession" size="large"
          />
        </Form.Item> */}

        <Form.Item className="lbl-name" label="Gender"></Form.Item>
        <Form.Item
          name="gender"
          initialValue={data.agent.gender}
          hasFeedback
          rules={[{ required: true, message: 'Please select your Gender!' }]}
        >
          <Select size="large" placeholder="Please select a Gender">
            <Option value="Male">Male</Option>
            <Option value="Female">Female</Option>
            <Option value="Other">Other</Option>
          </Select>
        </Form.Item>
        <Form.Item className="lbl-name" label="DOB"></Form.Item>
                              {
                                DOB?
                                <Form.Item
                                initialValue={DOB}
                                name="dob"
                                hasFeedback
                              >
                                <DatePicker  name="dob" size="large"  defaultValue={moment(data.DOB, dateFormat)}/>
                              </Form.Item>
                              :
                              <Form.Item
                              name="dob"
                              initialValue={DOB}
                              hasFeedback
                            >
                              <DatePicker  name="dob" size="large" defaultValue={moment('2020/10/13', dateFormat)}  />
                            </Form.Item>
                       }
        {/* <Form.Item className="lbl-name" label="Status"></Form.Item>
        <Form.Item
          name="status"
          initialValue={data.isActive}
          hasFeedback
        >
         <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
        defaultChecked={data.isActive}
    />
        </Form.Item> */}
        {/* <Form.Item className="lbl-name" label="Featured"></Form.Item>
        <Form.Item
          name="featured"
          initialValue={data[0].isFeatured}
          hasFeedback
        >
         <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
        defaultChecked={data[0].isFeatured}
    />
        </Form.Item> */}

        <Form.Item className="lbl-name" label="Roles"></Form.Item>
        <Form.Item
          hasFeedback
          initialValue={data.roles.toString().split(',')}
          name="roles"
          rules={[{ required: true, message: 'Please select Roles!', type: 'array' }]}
        >
          <Select mode="multiple" size="large" placeholder="Please select Roles">
            <Option value="Tourist">Tourist</Option>
            <Option value="TourGuide">Tour Guide</Option>
            <Option value="Club">Club</Option>
          </Select>
        </Form.Item>

        <Form.Item className="lbl-name" label="Languages"></Form.Item>
        <Form.Item
          hasFeedback
          initialValue={
            data.lang?
            data.lang.toString().split(',')
            :null
          }
          name="lang"
          rules={[{ required: true, message: 'Please select Languages!', type: 'array' }]}
        >
          <Select mode="multiple" size="large" placeholder="Please select Language">
            <Option value="English">English</Option>
            <Option value="Urdu">Urdu</Option>
            <Option value="Punjabi">Punjabi</Option>
            <Option value="Pashto">Pashto</Option>
            <Option value="Sindhi">Sindhi</Option>
            <Option value="Saraiki">Saraiki</Option>
            <Option value="Blochi">Blochi</Option>
            <Option value="Hindko">Hindko</Option>
            <Option value="Brahui">Brahui</Option>
            <Option value="Kashmiri">Kashmiri</Option>
            <Option value="Burushaski">Burushaski</Option>
            <Option value="Kalash">Kalash</Option>
            <Option value="Khowar">Khowar</Option>
            <Option value="Shina">Shina</Option>
            <Option value="Balti">Balti</Option>
          </Select>
        </Form.Item>

        <Form.Item >
          <Button className="btn-admin-login" size="large" type="primary" htmlType="submit">
            Update
                    </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
function mapStateToProps(state: any) {
  return {
    data: state.FetchEditTravelAgencies.fetchEditTravelAgenciesObject,
  };
}
export const connectedEditTravelAgency = connect(mapStateToProps)(EditTravelAgency);


