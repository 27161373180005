
import { alertActions } from "../actions";
import { config } from "../config";
import axios from "axios";

const GetHelpForms=()=>{
  return fetch(`${config.apiurl}/get-help-form`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}


const GetReportForms=()=>{
  return fetch(`${config.apiurl}/get-report-form`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

const AddHelpRequest =(values:any)=>{
  return axios
    .post(`${config.apiurl}/add-help-form`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

const GetContactForms=()=>{
  return fetch(`${config.apiurl}/get-contact-form`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

const AddContactRequest =(values:any)=>{
  return axios
    .post(`${config.apiurl}/add-contact-form`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

const GetPrivacyFormsAdmin=()=>{
  return fetch(`${config.apiurl}/get-privacy-admin`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

const GetPrivacies=(type:any)=>{
  return fetch(`${config.apiurl}/get-privacy-terms/${type}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}
const GetEditPrivacies=(id:any)=>{
  return fetch(`${config.apiurl}/get-edit-privacy-terms/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

const AddPrivacyRequest =(values:any)=>{
  return axios
    .post(`${config.apiurl}/add-privacy-terms`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

const AddReportRequest =(values:any)=>{
  return axios
    .post(`${config.apiurl}/add-report-form`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}
function UpdatePrivacy(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/update-privacy-admin/${id}`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}


export const supportServices={
  AddHelpRequest,
  AddReportRequest,
  GetHelpForms,
  GetReportForms,
  AddPrivacyRequest,
  GetPrivacyFormsAdmin,
  GetPrivacies,
  GetEditPrivacies,
  UpdatePrivacy,
  GetContactForms,
  AddContactRequest
}
