import Axios from 'axios';
import React,{ useEffect, useState } from 'react';
import { config } from '../../config';



export function AppleAssociation() {
  const [data,setData] = useState<any>();

  useEffect(()=>{
       fetch(`${config.apiurl}/apple-app-site-association`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((ree) => {
          setData(JSON.stringify(ree));
          return ree;
        });

  },[])


  return (
    <div>
            <div dangerouslySetInnerHTML= {{__html:data}}></div>
    </div>
  )
}
