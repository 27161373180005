import {supportConstants} from '../constants';

export function FetchHelpForm(state={getAllHelpObject:false},action:any)
{

    switch (action.type) {
        case supportConstants.GET_HELP_REQUEST:
          return state;
          case supportConstants.GET_HELP_SUCCESS:
          return{
            ...state,
            getAllHelpObject:action.user
          };
      default:
        return state
    }
}

export function FetchReportForm(state={fetchReportObject:""},action:any)
{
  switch (action.type) {
    case supportConstants.GET_REPORT_REQUEST:
      return state;
      case supportConstants.GET_REPORT_SUCCESS:
      return{
        ...state,
        fetchReportObject:action.user
      };
  default:
    return state
}
}

export function FetchPrivacyAdmin(state={FetchPrivacyAdminObject:""},action:any)
{
  switch (action.type) {
    case supportConstants.GET_PRIVACY_ADMIN_REQUEST:
      return state;
      case supportConstants.GET_PRIVACY_ADMIN_SUCCESS:
      return{
        ...state,
        FetchPrivacyAdminObject:action.user
      };
  default:
    return state
}
}

export function FetchPrivacy(state={FetchPrivacyObject:""},action:any)
{
  switch (action.type) {
    case supportConstants.GET_PRIVACY_REQUEST:
      return state;
      case supportConstants.GET_PRIVACY_SUCCESS:
      return{
        ...state,
        FetchPrivacyObject:action.user
      };
  default:
    return state
}
}

export function FetchEditPrivacy(state={FetchEditPrivacyObject:""},action:any)
{
  switch (action.type) {
    case supportConstants.GET_EDIT_PRIVACY_REQUEST:
      return state;
      case supportConstants.GET_EDIT_PRIVACY_SUCCESS:
      return{
        ...state,
        FetchEditPrivacyObject:action.user
      };
  default:
    return state
}
}


export function FetchContactPrivacy(state={FetchContactPrivacyObject:""},action:any)
{
  switch (action.type) {
    case supportConstants.GET_CONTACT_REQUEST:
      return state;
      case supportConstants.GET_CONTACT_SUCCESS:
      return{
        ...state,
        FetchContactPrivacyObject:action.user
      };
  default:
    return state
}
}

