import React, { useState } from "react";
import { Modal} from 'antd';
import {Avatar } from "@chakra-ui/core";
import {FacebookShareButton, FacebookIcon} from "react-share";
import {history} from '../../Helpers'
import "./storycard.css";
import {config} from '../../config';
const TimeStamp =require("react-timestamp");

type Cards={
data:any;
}
export function Cards(props:Cards) {
  const [visible,setVisible] = useState(false);
  const showModal = () => {
    setVisible(true)
  };
  const handleOk = (e:any) => {
    setVisible(false)
  };

  const handleCancel = (e:any)  => {
    setVisible(false)
  };
   const {data} = props;
   let imageUrl:any= config.s3BucketUrl+data.stories.image
  let story=data.stories.shortDescription.replace(/(<([^>]+)>)/gi, "");
      const onCardClick =(id:any,title:any,place:any,catId:any)=>{
        let places={
          placeId:place,
          categoryId:catId
       }
       localStorage.setItem('placeName', JSON.stringify(places));
        history.push('/travel-stories/'+title+"-"+id)
      }

      if(story.length > 10)
      {
       story=story.substring(0,100);
      }

const onPlaceClick=(id:any,placeName:any)=>{
  let placeTitle='';
  placeTitle=placeName.replace(/[ ,?]/g,'-')
  history.push(`/travel-places/${placeTitle}-${id}`)
}
  return (
    <div className="story-card-container" onClick={()=>onCardClick(data.stories._id,data.stories.urlTitle,data.places._id,data.categories._id)}>
        <div className="story-card-inner-left-container">
            <div className="story-card-user-container">
              <div style={{ float: "left", marginBottom: "16px" }} >
                <Avatar
                  name={data.users.firstName+" "+data.users.lastName}
                  size="lg"
                  className="question-avatar"
                  src={data.users.image}
                ></Avatar>
              </div>
              <div className="story-card-desc-container">
                <p className="story-card-decs-txt-container">
                  <small className="story-card-desc-txt">{data.users.firstName+" "+data.users.lastName}</small>
                  &nbsp;<small className="story-card-tourist-txt">published in</small>
                  <span className="story-card-timespan-txt story-card-place-name" onClick={()=>{onPlaceClick(data.places._id,data.places.placeName)}}> {data.places.placeName}</span>
                 <small className="story-card-timespan-txt"></small>
                </p>
                <p className="story-card-album-badge">
                  <small className="story-card-category-name story-card-place-name">
                  {data.categories.categoryName} .
                    </small>
                    &nbsp;
                    <small >
                    <TimeStamp className="story-card-timespan-txt" relative date={data.stories.createdDate} autoUpdate />
                    </small>
                </p>
              </div>
            </div>
            <div style={{display:"flow-root"}}>
            <div className="story-card-story-container" >
            <p className="story-card-story-title">
            {data.stories.title}
            </p>
            <div className="story-card-story-desc-container">
            <div className="story-card-story-desc" dangerouslySetInnerHTML= {{__html:story+'...'}}>
                </div>
            </div>
            </div>
          </div>
          </div>
          <div className="story-card-inner-right-container">
             <div className="story-card-story-image-container">
                 <div
                 style={{backgroundImage: 'url("'+imageUrl+'")',
                 backgroundPosition:'50% 52%',
                 backgroundRepeat:'no-repeat',
                 backgroundSize:'cover',
                 maxHeight:'100%',
                 borderRadius:'0 10px 10px 0',
                 height:'100%',

                }}
                 onClick={()=>onCardClick(data.stories._id,data.stories.urlTitle,data.places._id,data.categories._id)}>
            </div>
           </div>
          </div>
        <Modal
          title="Share social"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <FacebookShareButton
                url={"http://192.168.100.37:3000/stories"}
                quote={"Pakrism- World is yours to explore"}
                hashtag="#PakrismStories"
                className="" >
                 <FacebookIcon size={36} round={true} />
              </FacebookShareButton>

        </Modal>
    </div>
  );
}


