import { announcementConstants } from "../constants";
import { announcementService } from "../services";
import { alertActions } from ".";
import { history } from "../Helpers";

export const announcementActions = {
  FetchAllAnnouncement,
  AddAnnouncement,
  FetchEditAnnouncement,
  DeleteAnnouncement,
  UpdateAnnouncement,
  ActivatedAnnouncement
};

function FetchAllAnnouncement() {
  return (dispatch: any) => {
    dispatch(request());
    announcementService.FetchAllAnnouncement().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: announcementConstants.GET_ALL_ANNOUNCEMENT_REQUEST };
  }
  function success(user: any) {
    return { type: announcementConstants.GET_ALL_ANNOUNCEMENT_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: announcementConstants.GET_ALL_ANNOUNCEMENT_FAILURE, error };
  }
}

function AddAnnouncement(data: any) {
  return (dispatch: any) => {
    dispatch(request(data));
    announcementService
      .AddAnnouncement(data)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "201") {
          dispatch(success(data));
          dispatch(
            alertActions.success(data.data.message)
          );
          history.go(0);
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: announcementConstants.ADD_ANNOUNCEMENT_REQUEST, data };
    }
    function success(user: any) {
      return { type: announcementConstants.ADD_ANNOUNCEMENT_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: announcementConstants.ADD_ANNOUNCEMENT_FAILURE, error };
    }
  };
}

function FetchEditAnnouncement(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    announcementService.FetchEditAnnouncement(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: announcementConstants.EDIT_ANNOUNCEMENT_REQUEST, id };
  }
  function success(user: any) {
    return { type: announcementConstants.EDIT_ANNOUNCEMENT_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: announcementConstants.EDIT_ANNOUNCEMENT_FAILURE, error };
  }
}

function DeleteAnnouncement(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    announcementService
      .DeleteAnnouncement(id)
      .then((res: any) => {
        dispatch(success(res.message));
        dispatch(alertActions.success(res.message));
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });

    function request(id: any) {
      return { type: announcementConstants.DELETE_ANNOUNCEMENT_REQUEST, id };
    }
    function success(user: any) {
      return { type: announcementConstants.DELETE_ANNOUNCEMENT_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: announcementConstants.DELETE_ANNOUNCEMENT_FAILURE, error };
    }
  };
}

function UpdateAnnouncement(data: any, id: any) {
  return (dispatch: any) => {
    dispatch(request(data, id));
    announcementService
      .UpdateAnnouncement(data, id)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "200") {
          dispatch(success(data));
          dispatch(
            alertActions.success("You have successfully update an Announcement")
          );
          history.go(0);
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any, id: any) {
      return { type: announcementConstants.UPDATE_ANNOUNCEMENT_REQUEST, data, id };
    }
    function success(user: any) {
      return { type: announcementConstants.UPDATE_ANNOUNCEMENT_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: announcementConstants.UPDATE_ANNOUNCEMENT_FAILURE, error };
    }
  };
}

function ActivatedAnnouncement(body:any,id:any) {
  return (dispatch:any) => {
      dispatch(request({ body,id }));
      announcementService.ActivateAnnouncement(body,id)
          .then((data:any)=>{
              dispatch(success(data))
      if(data.status=="200")
      {
        dispatch(success(data));
        dispatch(alertActions.success(data.data.message))
        history.go(0)
      }

          }).catch((err:any)=>{
            dispatch(failure(err))
          });
          };

          function request(user:any) { return { type: announcementConstants.ACTIVATE_ANNOUNCEMENT_REQUEST, user } }
          function success(user:any) { return { type: announcementConstants.ACTIVATE_ANNOUNCEMENT_SUCCESS, user } }
          function failure(error:any) { return { type: announcementConstants.ACTIVATE_ANNOUNCEMENT_FAILURE, error } }
}

