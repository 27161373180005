import React, { useEffect } from 'react';
import {history} from '../../Helpers'

export function RightFooter() {
  useEffect(()=>{
  })

  return (
    <div className="footer-links-container">
    <ul className="footer-links-list">
      <li className="footer-links-list-item">About</li>
      <li className="footer-links-list-item">Help Center</li>
      <li className="footer-links-list-item">Privacy & Terms </li>
      <br/>
      <li className="footer-links-list-item">Download  Android</li>
      <li className="footer-links-list-item">Download  iOS</li>
      <br/>
      <li className="footer-links-list-item">&copy; 2020 <span className="logo-txt-footer" onClick={()=>{history.push('/')}}>PAKRISM</span></li>
    </ul>

    </div>
  )
}
