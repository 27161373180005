import {albumConstants} from '../constants';

export function FetchAlbumsAdmin(state={getAllAlbumsObject:false},action:any)
{

    switch (action.type) {
        case albumConstants.GET_ALL_ALBUMS_REQUEST_ADMIN:
          return state;
          case albumConstants.GET_ALL_ALBUMS_SUCCESS_ADMIN:
          return{
            ...state,
            getAllAlbumsObject:action.user
          };
      default:
        return state
    }
}

export function FetchEditAlbum(state={fetchEditAlbumObject:""},action:any)
{
  switch (action.type) {
    case albumConstants.EDIT_ALBUMS_REQUEST_ADMIN:
      return state;
      case albumConstants.EDIT_ALBUMS_ADMIN_SUCCESS:
      return{
        ...state,
        fetchEditAlbumObject:action.user
      };
  default:
    return state
}
}

export function FetchAlbumsUser(state={getAllAlbumsUserObject:false},action:any)
{

    switch (action.type) {
        case albumConstants.GET_ALL_ALBUMS_REQUEST_USER:
          return state;
          case albumConstants.GET_ALL_ALBUMS_SUCCESS_USER:
          return{
            ...state,
            getAllAlbumsUserObject:action.user
          };
      default:
        return state
    }
}


export function FetchMyAlbums(state={getMyAlbumsObject:false},action:any)
{

    switch (action.type) {
        case albumConstants.GET_MY_ALBUMS_REQUEST:
          return state;
          case albumConstants.GET_MY_ALBUMS_SUCCESS:
          return{
            ...state,
            getMyAlbumsObject:action.user
          };
      default:
        return state
    }
}

export function FetchLovedAlbums(state={getLovedAlbumsObject:false},action:any)
{

    switch (action.type) {
        case albumConstants.GET_LOVED_ALBUMS_REQUEST:
          return state;
          case albumConstants.GET_LOVED_ALBUMS_SUCCESS:
          return{
            ...state,
            getLovedAlbumsObject:action.user
          };
      default:
        return state
    }
}

export function FetchAlbumsCount(state={getAlbumsCountObject:false},action:any)
{

    switch (action.type) {
        case albumConstants.GET_ALBUMS_COUNT_REQUEST_ADMIN:
          return state;
          case albumConstants.GET_ALBUMS_COUNT_SUCCESS_ADMIN:
          return{
            ...state,
            getAlbumsCountObject:action.user
          };
      default:
        return state
    }
}

