import { alertActions } from "../actions";
import { config } from "../config";
import axios from "axios";
let admin = JSON.parse(localStorage.getItem('admin')!);
export const storyService = {
  FetchAllStories,
  AddAlbumCategory,
  GetEditStories,
  UpdateStory,
  DeleteStory,
  FetchStoryUser,
  FetchTopStoryTeller,
  GetStory,
  GetRelatedStories,
  GetReleventStory,
  GetMyStory,
  GetDraftStory,
  AddStories,
  GetPlaceStories,
  AddStoryComment,
  AddStoryLike,
  RemoveStoryLike,
  FeaturedAlbum,
  FetchStoriesCount
};

function AddStories(values: any) {
  return axios
    .post(`${config.apiurl}/user/add-stories/`, values, {
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function FetchAllStories() {
  return fetch(`${config.apiurl}/user/get-stories/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

function AddAlbumCategory(values: any) {
  return axios
    .post(`${config.apiurl}/admin/add-album-categories/`, values, {
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function GetEditStories(id: any) {
  return fetch(`${config.apiurl}/user/get-stories/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function UpdateStory(values: any, id: any, isUnPublish: boolean) {
  let apiUrl: any = "";
  if (isUnPublish) {
    apiUrl = `${config.apiurl}/user/unpublish-stories/${id}`;
  }
  else
  {
    apiUrl=`${config.apiurl}/user/update-stories/${id}`
  }
  return axios
    .patch(apiUrl, values, {
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function DeleteStory(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/user/delete-story/${id}`,values, {
      validateStatus: () => true,
    })
    .then((ree) => {
      return ree;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function FetchStoryUser() {
  return fetch(`${config.apiurl}/user/get-all-stories/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

function FetchTopStoryTeller() {
  return fetch(`${config.apiurl}/user/get-top-story-teller/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}


function GetStory(id: any) {
  return fetch(`${config.apiurl}/user/get-story/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function GetRelatedStories(placeId:any,id: any) {
  return fetch(`${config.apiurl}/user/get-related-stories/${placeId}/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function GetPlaceStories(id: any) {
  return fetch(`${config.apiurl}/user/get-place-story/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function GetReleventStory(id: any,placeId:any) {
  return fetch(`${config.apiurl}/user/get-category-story/${id}/${placeId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function GetMyStory(id: any) {
  return fetch(`${config.apiurl}/user/get-my-story/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function GetDraftStory(id: any) {
  return fetch(`${config.apiurl}/user/get-drafted-story/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function AddStoryComment(values: any,id:any) {
  return axios
    .patch(`${config.apiurl}/user/add-story-comments/${id}`, values, {
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function AddStoryLike(values: any,id:any) {
  return axios
    .patch(`${config.apiurl}/user/add-story-like/${id}`, values, {
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}


function RemoveStoryLike(values: any,id:any) {
  return axios
    .patch(`${config.apiurl}/user/remove-story-like/${id}`, values, {
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function FeaturedAlbum(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/user/featured-story/${id}`, values, {
      validateStatus: () => true,
      // receive two parameter endpoint url ,form data
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err.toString());
      }
    });
}

function FetchStoriesCount() {
  return fetch(`${config.apiurl}/user/stories-count/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + admin.token,
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}
