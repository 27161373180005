import React from 'react';
import {config} from '../../config';
import {history} from '../../Helpers'


type LovedAlbums={
  data:any;
}

export function LovedAlbums(props:LovedAlbums) {
  const {data} = props;
  let imageUrl = config.s3BucketUrl+data.albums.image[0].imageName;
  imageUrl= imageUrl.replace(/\\/g, '/');
  const onAlbumTitleClick=(id:any,title:string)=>{
    history.push(`/travel-albums/${title.replace(/ /g,"-")}-${id}`);
  }
  return (
    <div className="loved-albums-inner-container" onClick={()=>onAlbumTitleClick(data.albums._id,data.albums.title)}>
       <div className="loved-album-banner-container">
         <div className="loved-album-image" style={{backgroundImage: 'url("'+imageUrl+'")'}}>

         </div>

       </div>
       <div className="loved-album-data-container">
          <div>
           <p className="loved-album-title">{data.albums.title}</p>
           <p className="loved-album-count"><span className="loved-count">Love ({data.albums.likes.length})  </span> <span>{data.albums.image.length} photos </span></p>
           <p className="loved-album-count"><span>Created by </span> <span>{data.users.firstName +" "+ data.users.lastName} </span></p>
          </div>
       </div>
    </div>
  )
}
