import { alertActions } from "../actions";
import { config } from "../config";
import { history,adminAuthHeader } from "../Helpers";
import axios from "axios";

let admin = JSON.parse(localStorage.getItem('admin')!);
export const userService = {
  register,
  login,
  loginAdmin,
  getCurrentUser,
  fetchAllUsers,
  fetchEditUser,
  UdateUserAdmin,
  DeActiveUser,
  ForgetPassword,
  ResetPassword,
  VerifyUser,
  getUserProfile,
  UpdateUser,
  AddFavPlace,
  RemoveFavPlace,
  AddVistedPlace,
  RemoveVistedPlace,
  getFavPlaces,
  getVisetdPlaces,
  FetchTopUsers,
  SocialLogin,
  GoogleLogin,
  ActivateUser,
  FeaturedUser,
  getCheckedUser,
  ResentEmail,
  fetchAllUsersAdmin,
  fetchUsersCount,
  fetchUsersCountMonth
};

let token =JSON.parse(localStorage.getItem('admin')!)

function register(
  firstName: any,
  lastName: any,
  email: any,
  password: any,
  socialClick: any,
  sendAdmin: any,
  isFromMobile:any,
  playerId:any,
) {
  let isFromAndroid:boolean=false

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      firstName,
      lastName,
      email,
      password,
      socialClick,
      sendAdmin,
      isFromMobile,
      playerId,
      isFromAndroid,
    }),
  };
  return fetch(`${config.apiurl}/user/signup`, requestOptions,)
    .then((user: any) => {
      return user;

    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}
function login(email: any, password: any, socialClick: any,playerId:any) {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password, socialClick,playerId}),
  };
  return fetch(`${config.apiurl}/user/login/`, requestOptions)
    .then((response) => response.json())
    .then((data: any) => {
      if (data.loggedIn) {
        localStorage.setItem("user", JSON.stringify(data));
        history.push("/pak-map");
        return data;
      }
      if(data.status===409)
      {
       history.push('/resent-verification-email')
      }

      else {
        return alertActions.error(data.message);
      }
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err.toString());
      }
    });
}

function loginAdmin(email: any, password: any, socialClick: any) {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password, socialClick }),
  };
  return fetch(`${config.apiurl}/admin/login/`, requestOptions)
    .then((response) => response.json())
    .then((data: any) => {
      if (data.loggedIn) {
        localStorage.setItem("admin", JSON.stringify(data));
        return data;
      } else {
        return alertActions.error(data.message);
      }
    })
    .catch((err: any) => {
      if (err == "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err.toString());
      }
    });
}

function fetchEditUser(id: any) {
  return fetch(`${config.apiurl}/user/get-user/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function fetchAllUsers() {
  return fetch(`${config.apiurl}/user/get-all-users/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function fetchAllUsersAdmin(page:any,search:any,pageLimit: any) {
  return fetch(`${config.apiurl}/user/get-all-users-admin/${page}/${search}/${pageLimit}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function getCurrentUser(id: any) {
  return fetch(`${config.apiurl}/user/get-user/${id}`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}
function getUserProfile(id: any) {
  return fetch(`${config.apiurl}/user/get-user/${id}`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function UdateUserAdmin(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/admin/update-user-admin/${id}`, values, {
      validateStatus: () => true,
      // receive two parameter endpoint url ,form data
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err.toString());
      }
    });
}

function DeActiveUser(id: any) {
  return axios
    .patch(`${config.apiurl}/user/delete-user/${id}`, {
      validateStatus: () => true,
    })
    .then((res) => {
      // then print response status
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function ForgetPassword(email: any,isFromMobile:any) {
  let isFromAndroid:boolean=false
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email,isFromMobile,isFromAndroid}),
  };
  return fetch(`${config.apiurl}/user/forget-password/`, requestOptions)
    .then((response) => response.json())
    .then((data: any) => {
       return data;
    })
    .catch((err: any) => {
      if (err == "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err.toString());
      }
    });
}

function ResetPassword(password: any,token:any,userId:any) {
  const requestOptions = {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ password,token,userId}),
  };
  return fetch(`${config.apiurl}/user/reset-password/`, requestOptions)
    .then((response) => response.json())
    .then((data: any) => {
       return data;
    })
    .catch((err: any) => {
      if (err == "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err.toString());
      }
    });
}

function VerifyUser(id: any) {
  const requestOptions = {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({id}),
  };
  return fetch(`${config.apiurl}/user/verify-user/`, requestOptions)
  .then((response) => response.json())
  .then((data: any) => {
     return data;
  })
  .catch((err: any) => {
    if (err == "TypeError: Failed to fetch") {
      alertActions.error("Server is not responding");
    } else {
      alertActions.error(err.toString());
    }
  });
}

function UpdateUser(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/user/update-user/${id}`, values, {
      validateStatus: () => true,
      // receive two parameter endpoint url ,form data
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err.toString());
      }
    });
}

function AddFavPlace(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/user/add-favourite-place/${id}`, values, {
      validateStatus: () => true,
      // receive two parameter endpoint url ,form data
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err.toString());
      }
    });
}


function RemoveFavPlace(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/user/remove-favourite-place/${id}`, values, {
      validateStatus: () => true,
      // receive two parameter endpoint url ,form data
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err.toString());
      }
    });
}

function AddVistedPlace(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/user/add-visted-place/${id}`, values, {
      validateStatus: () => true,
      // receive two parameter endpoint url ,form data
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err.toString());
      }
    });
}

function ActivateUser(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/user/activate-user/${id}`, values, {
      validateStatus: () => true,
      // receive two parameter endpoint url ,form data
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err.toString());
      }
    });
}

function FeaturedUser(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/user/featured-user/${id}`, values, {
      validateStatus: () => true,
      // receive two parameter endpoint url ,form data
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err.toString());
      }
    });
}


function RemoveVistedPlace(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/user/remove-visted-place/${id}`, values, {
      validateStatus: () => true,
      // receive two parameter endpoint url ,form data
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err.toString());
      }
    });
}


function getVisetdPlaces(id: any) {
  return fetch(`${config.apiurl}/user/get-visted-places/${id}`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function getCheckedUser(id: any,friendId:any) {
  return fetch(`${config.apiurl}/user/check-friends/${id}/${friendId}`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function getFavPlaces(id: any) {
  return fetch(`${config.apiurl}/user/get-fav-places/${id}`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function FetchTopUsers() {
  return fetch(`${config.apiurl}/user/get-top-users/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function SocialLogin(values: any) {
  return axios
    .post(`${config.apiurl}/user/signup-social`, values, {
      validateStatus: () => true,
      // receive two parameter endpoint url ,form data
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err.toString());
      }
    });
}

function GoogleLogin(values: any) {
  return axios
    .post(`${config.apiurl}/user/signup-google`, values, {
      validateStatus: () => true,
      // receive two parameter endpoint url ,form data
    })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err.toString());
      }
    });
}

function ResentEmail(
  email: any,
  isFromMobile:any,
) {
  let isFromAndroid:boolean=false

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email,
      isFromMobile,
      isFromAndroid
    }),
  };
  return fetch(`${config.apiurl}/user/resent-email-verification`, requestOptions,)
    .then((user: any) => {
      return user;

    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function fetchUsersCount() {

  return fetch(`${config.apiurl}/user/users-count/`, {
    method: "GET",
    headers:{'Authorization': 'Bearer ' + admin.token,Accept: "application/json","Content-Type": "application/json"}
    ,
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function fetchUsersCountMonth() {
  return fetch(`${config.apiurl}/user/users-by-month/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + admin.token,
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

