import {userConstants} from '../constants/user.constants';


export function DataReducer(state={retrievedObject:false},action:any)
{

    switch (action.type) {
        case userConstants.CURRENT_USER_REQUEST:
          return state;
        case userConstants.CURRENT_USER_SUCCESS:
          return {
            ...state,
            retrievedObject:action.user
          };
        default:
          return state
      }
}
export function FetchUserProfile(state={UserProfile:false},action:any)
{

    switch (action.type) {
        case userConstants.GET_USER_PROFILE:
          return state;
        case userConstants.GET_USER_PROFILE_SUCCESS:
          return {
            ...state,
            UserProfile:action.user
          };
        default:
          return state
      }
}

export function FetchUsers(state={getAllUserObject:false},action:any)
{

    switch (action.type) {
        case userConstants.GET_ALL_USER_REQUEST:
          return state;
          case userConstants.GET_ALL_USER_SUCCESS:
          return{
            ...state,
            getAllUserObject:action.user
          };
      default:
        return state
    }
}

export function FetchAllUsersAdmin(state={getAllUserAdminObject:false},action:any)
{

    switch (action.type) {
        case userConstants.FETCH_ALL_USER_ADMIN_REQUEST:
          return state;
          case userConstants.FETCH_ALL_USER_ADMIN_SUCCESS:
          return{
            ...state,
            getAllUserAdminObject:action.user
          };
      default:
        return state
    }
}

export function FetchEditUser(state={fetchEditUserObject:""},action:any)
{
  switch (action.type) {
    case userConstants.EDIT_CURRENT_USER_REQUEST:
      return state;
      case userConstants.EDIT_CURRENT_USER_SUCCESS:
      return{
        ...state,
        fetchEditUserObject:action.user
      };
  default:
    return state
}
}

export function UpdateUser(state = {}, action:any) {
  switch (action.type) {
    case userConstants.UPDATE_CURRENT_USER_REQUEST:
      return { update:true};
    case userConstants.SIGNUP_REQUEST_SUCCESS:
      return {};
    case userConstants.SIGNUP_REQUEST_FAILURE:
      return {};
    default:
      return state
  }
}

export function DeActiveUser(state = {}, action:any) {
  switch (action.type) {
    case userConstants.DELETE_USER_REQUEST:
      return {} ;
    case userConstants.DELETE_USER_SUCCESS:
      return {delete:true};
    case userConstants.EDIT_CURRENT_USER_FAILURE:
      return {};
    default:
      return state
  }
}


export function FetchVistedPlaces(state={fetchVistedPlacesObject:''},action:any)
{
  switch (action.type) {
    case userConstants.GET_VISTED_PLACE_REQUEST:
      return state;
      case userConstants.GET_VISTED_PLACE_REQUEST_SUCCESS:
      return{
        ...state,
        fetchVistedPlacesObject:action.user
      };
  default:
    return state
}
}

export function FetchFavPlaces(state={fetchFavPlacesObject:''},action:any)
{
  switch (action.type) {
    case userConstants.GET_FAV_PLACE_REQUEST:
      return state;
      case userConstants.GET_FAV_PLACE_REQUEST_SUCCESS:
      return{
        ...state,
        fetchFavPlacesObject:action.user
      };
  default:
    return state
}
}

export function FetchTopUsers(state={fetchTopUsersObject:''},action:any)
{
  switch (action.type) {
    case userConstants.GET_TOP_USER_REQUEST:
      return state;
      case userConstants.GET_TOP_USER_SUCCESS:
      return{
        ...state,
        fetchTopUsersObject:action.user
      };
  default:
    return state
}
}


export function FetchCheckedUser(state={fetchCheckedUsersObject:''},action:any)
{
  switch (action.type) {
    case userConstants.GET_CHECKED_USER_REQUEST:
      return state;
      case userConstants.GET_CHECKED_USER_REQUEST_SUCCESS:
      return{
        ...state,
        fetchCheckedUsersObject:action.user
      };
  default:
    return state
}
}

export function FetchAllCountAdmin(state={getAllUserCountAdminObject:false},action:any)
{

    switch (action.type) {
        case userConstants.FETCH_USERS_COUNT_ADMIN_REQUEST:
          return state;
          case userConstants.FETCH_USERS_COUNT_ADMIN_SUCCESS:
          return{
            ...state,
            getAllUserCountAdminObject:action.user
          };
      default:
        return state
    }
}

export function FetchAllCountMonth(state={getAllUserCountMonthObject:false},action:any)
{

    switch (action.type) {
        case userConstants.FETCH_USERS_COUNT_MONTH_WISE_ADMIN_REQUEST:
          return state;
          case userConstants.FETCH_USERS_COUNT_MONTH_WISE_ADMIN_SUCCESS:
          return{
            ...state,
            getAllUserCountMonthObject:action.user
          };
      default:
        return state
    }
}


