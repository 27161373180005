import {friendsConstants} from '../constants';

export function FetchSentFriendRequests(state={getSentFriendRequestsObject:{}},action:any)
{
    switch (action.type) {
        case friendsConstants.GET_SENT_REQUEST:
          return state;
          case friendsConstants.GET_SENT_REQUEST_SUCCESS:
          return{
            ...state,
            getSentFriendRequestsObject:action.user
          };
      default:
        return state
    }
}

export function FetchReceivedFriendRequests(state={getReceivedFriendRequestsObject:{}},action:any)
{
    switch (action.type) {
        case friendsConstants.GET_RECEIVED_REQUEST:
          return state;
          case friendsConstants.GET_RECEIVED_REQUEST_SUCCESS:
          return{
            ...state,
            getReceivedFriendRequestsObject:action.user
          };
      default:
        return state
    }
}

export function FetchFriends(state={getFriendsObject:{}},action:any)
{
    switch (action.type) {
        case friendsConstants.GET_MY_FRIENDS:
          return state;
          case friendsConstants.GET_MY_FRIEND_SUCCESS:
          return{
            ...state,
            getFriendsObject:action.user
          };
      default:
        return state
    }
}
