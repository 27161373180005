import React, { useEffect, useState } from 'react';
import { Button, Form,Select} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { history, store } from '../../../Helpers';
import { connect } from 'react-redux';
import { SupportActions } from '../../../actions';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};

type EditPrivacy = {
  dispatch: any;
  match: any;
  data: any;
}
const { Option } = Select;



function EditPrivacy(props: EditPrivacy) {
  store.getState().FetchEditPrivacy.FetchEditPrivacyObject = "";
  let formRef:any = React.createRef<FormInstance>();
  let { dispatch, match, data } = props;
  const [quilValue, setquilValue] = useState('');

  const [finalData, setFintalData] = useState(data);

  const onFinish = (values: any) => {
    let formData={
      type:values.type,
      data:quilValue
    }
    dispatch(SupportActions.UpdatePrivacy(formData, match.params.id));
  };
  useEffect(() => {

    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {

      dispatch(SupportActions.FetchEditPrivacy(match.params.id));
    }

  }, [dispatch,match]);
  useEffect(()=>{
   return ()=> setFintalData(data)
  },[])

  const onFinishFailed = (errorInfo: any) => {
  };
  if (!data) {

    return (<div></div>)
  }
  const onQuilValueChange = (value: any,editor:any) => {
    setquilValue(editor.getData());

  }

  return (
    <div>
      <Form
        {...layout}
        ref={formRef}
        className="admin-login-form"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
         <Form.Item className="lbl-name" label="Type"></Form.Item>
            <Form.Item
        hasFeedback
        initialValue={data[0].type}
          name="type"
          rules={[{ required: true }]}
        >
         <Select size="large" placeholder="Select a Type">
            <Option value="privacy">Privacy Policies</Option>
            <Option value="terms">Terms of use</Option>
           </Select>
        </Form.Item>
        <Form.Item
          initialValue={data[0].data}
          name="quil"
        >
            <CKEditor
                 onChange={onQuilValueChange}
                    editor={ ClassicEditor }
                    data={data[0].data}
                />
          {/* <ReactQuill style={{ marginBottom: '20px' }} theme="snow" value={data[0].quilValue} onChange={onQuilValueChange} /> */}
        </Form.Item>
        <Form.Item >
          <Button className="btn-admin-login" style={{ marginTop: '20px' }} size="large" type="primary" htmlType="submit">
            Update Activity
                    </Button>
        </Form.Item>
      </Form>
    </div>
  )

}

function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    data: state.FetchEditPrivacy.FetchEditPrivacyObject,
  };
}

export const connectedEditPrivacy = connect(mapStateToProps)(EditPrivacy);
