export const deviceInfoConstants = {
  GET_ALL_REQUEST: "GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "GET_ALL_FAILURE",

  ADD_REQUEST: "DD_REQUEST",
  ADD_SUCCESS: "ADD_SUCCESS",
  ADD_FAILURE: "ADD_FAILURE",

  EDIT_REQUEST: "EDIT_REQUEST",
  EDIT_SUCCESS: "EDIT_SUCCESS",
  EDIT_FAILURE: "EDIT_FAILURE",

  UPDATE_REQUEST: "UPDATE_REQUEST",
  UPDATE_SUCCESS: "UPDATE_SUCCESS",
  UPDATE_FAILURE: "UPDATE_FAILURE",

  DELETE_REQUEST: "DELETE_REQUEST",
  DELETE_SUCCESS: "DELETE_SUCCESS",
  DELETE_FAILURE: "DELETE_FAILURE",
};
