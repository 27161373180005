import React from 'react';
import { Box, Heading, Text, Image} from "@chakra-ui/core";
import travelStories from '../../Assets/story_illustration-01.svg';
import {history} from '../../Helpers';
import {RightCircleOutlined } from '@ant-design/icons';

import './travel-stroies.css';


export function TravelStoriesSection() {
  return (
    <Box className="travel-stories-container">
    <Box className="inner-container-banner">
        <Box className="travel-places-left-sec">
        <Heading className="travel-album-headings">
        Travel Stories
        </Heading>
        <Text className="tour-desc">
        Travelers shared their mysterious travel stories,
        <br/>
        with love, experience and full of adventures.
        </Text>
        <div className="join-them">
        <button className="hero-join-btn travel-album-btn"  onClick={()=>history.push('/user/write-story')}>
        Write Your Story
         </button>
         <button className="explore-btn-common"  onClick={()=>history.push('/travel-stories')}>
         See All Stories
            <RightCircleOutlined />
            </button>
        </div>
       </Box>
       <Box className="travel-places-right-sec">
          <Image src={travelStories}/>
       </Box>
     </Box>
    </Box>
  )
}


