import { alertActions } from "../actions";
import { config } from "../config";
import axios from "axios";

export const facilitiesService = {
  FetchAllFacilities,
  AddActivity,
  FetchEditActivity,
  DeleteActivity,
  UpdateActivity,
};
function FetchAllFacilities() {
  return fetch(`${config.apiurl}/admin/get-facilities/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

function AddActivity(values: any) {
  return axios
    .post(`${config.apiurl}/admin/add-facilities/`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function FetchEditActivity(id: any) {
  return fetch(`${config.apiurl}/admin/get-facilities/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function DeleteActivity(id: any) {
  return fetch(`${config.apiurl}/admin/delete-facilities/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function UpdateActivity(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/admin/update-facilities/${id}`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}
