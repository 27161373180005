import React, { useEffect } from 'react';
import './pakmap.css';
import { Tooltip } from 'antd';
import { config } from "../../config";
import {history} from '../../Helpers';
import NoImage from '../../Assets/place-holder-image.svg'
const Marker = (props: any) => {
  let tooltipContainer: any = '';
  const { img, placeImage, placeName, desc,pin,placeId} = props;
  useEffect(() => {

  }, []);
   const onClick=(id:any,placeName:string)=>{
     let placeTitle='';
     placeTitle=placeName.replace(/[ ,?]/g,'-')
     history.push(`/travel-places/${placeTitle}-${id}`)
   }
   if(placeImage[0]!="")
   {
    tooltipContainer = (
      <div className="tooltip-container">
        <div className="image-container">
         <img className="tooltip-img"  src={config.s3BucketUrl + placeImage[0]} alt={placeImage} />
        </div>
        <div className="data-container">
          <p className="tooltip-place-name">{placeName}</p>
          <p className="tooltip-category-name">{img}</p>
          <p className="decs">{desc}</p>
        </div>
      </div>)
   }
   else
   {
    tooltipContainer = (
      <div className="tooltip-container">
        <div className="image-container">
         <img className="tooltip-img"  src={NoImage} alt={placeImage} />
        </div>
        <div className="data-container">
          <p className="tooltip-place-name">{placeName}</p>
          <p className="tooltip-category-name">{img}</p>
          <p className="decs">{desc}</p>
        </div>
      </div>)
   }
  return (
    <div className="marker-main" onClick={()=>onClick(placeId,placeName)}>
       <Tooltip placement="topLeft" className="tooltip-map" title={tooltipContainer}>
         <div onClick={()=>onClick(placeId,placeName)}>
        <img src={pin}  width="30" height="24" alt={pin} />
      </div>
      </Tooltip>
      <div>
      </div>

    </div>
  );
};

export default Marker;
