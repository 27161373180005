import React,{useEffect} from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../actions/users.action';
import ReactGA from 'react-ga';

type EmailVerificationPage = {
  dispatch: any
  match:any

}

 function EmailVerificationPage(props:EmailVerificationPage) {
  const {dispatch,match} = props
  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    dispatch(userActions.VerifyUser(match.params.id));
  },[dispatch, match.params.id])
  return (
    <div>
    </div>
  )
}


function mapStateToProps(state: any) {
  const { registering } = state.registration;
  const { type, message } = state.alert;

  return {
    type,
    message,
    registering
  };
}
export const connectedEmailVerificationPage = connect(mapStateToProps)(EmailVerificationPage);


