import React,{useState,useEffect} from 'react';
import Head from 'next/head'
import './HomePage.css';
import {config} from '../../config'
import NavBar from '../Navbar/Navbar'
import {Hero} from '../HeroSection'
import Tourist from '../Tourist/Tourist';
import {TravelPlanSection} from '../TravelPlacesSection';
import {TravelAlbumsSection} from '../TravelAlbumsSection';
import {TravelStoriesSection} from '../TravelStoriesSection';
import {VistedPlacesSection} from '../VistedPlaces';
import {QusetionAnswerSection} from '../QuestionAnswerSection';
import {MetaDecorator} from '../../utils'
import {MobileSection} from '../MobileSection';
import {UpperFooterSection,Footer} from '../FooterSection';
import ReactGA from 'react-ga';

export function HomePage(props:any) {
  const {match} = props
  const fromParent:any='home'
  let meta={
    title:"Home",
    description:"Pakistan’s first travel platform. Explore hundreds of travel places and travel experiences. Read and share exciting travel stories and travel albums. Helps to promote tourism in Pakistan.",
    imageAlt:"home page image",
    imageUrl:"social-share-image.png"
  }
  const [showScroll, setShowScroll] = useState(false)
useEffect(() => {
  ReactGA.initialize('UA-168266330-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
})
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400){
      setShowScroll(false)
    }
  };

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  window.addEventListener('scroll', checkScrollTop)
  return (
    <div>
      <Head>
            <meta charSet="UTF-8"/>
            <title>{meta.title}</title>
            <meta name="description" content={meta.description} />
            <meta name="title" content="Pakrism"></meta>

       {/* Open Graph / Facebook */}
            <meta property="og:type" content="website"/>
            <meta property="og:url" content={config.webUrl+match.url}/>
          <meta property="og:title" content={meta.title}/>
          <meta property="og:description" content={meta.description}/>
          <meta property="og:image" content={config.s3BucketUrl + meta.imageUrl}/>
          <meta name="viewport" content="width=device-width, initial-scale=1"/>

   {/* Twitter  */}
        <meta property="twitter:card" content={config.s3BucketUrl +meta.imageUrl}/>
        <meta property="twitter:url" content={config.webUrl+match.url}/>
        <meta property="twitter:title" content={meta.title}/>
        <meta property="twitter:description" content={meta.description}/>
        <meta property="twitter:image" content={config.s3BucketUrl +meta.imageUrl}/>
      </Head>
      <MetaDecorator title={meta.title} description={meta.description} imageAlt={meta.imageAlt} imageUrl={meta.imageUrl} match={match}/>
      <NavBar />
      <Hero fromParent={fromParent}/>
      <Tourist />
      <TravelPlanSection/>
      <TravelAlbumsSection/>
      <TravelStoriesSection/>
      <VistedPlacesSection/>
      <QusetionAnswerSection/>
      <MobileSection/>
      <UpperFooterSection/>
      <Footer/>
      <i className="scrollTop ri-arrow-up-circle-fill" onClick={scrollTop} style={{ display: showScroll ? 'flex' : 'none'}}></i>
    </div>
  )
}
