import React, { useEffect } from 'react';
import {PlaceQuestions} from '../Places';
import NavBar from '../Navbar/Navbar'
import {Button,Form,Input} from 'antd';
import {connect} from 'react-redux'
import ReactGA from 'react-ga';
import {Avatar} from '@chakra-ui/core';
import {MetaDecorator} from '../../utils';
import {history} from '../../Helpers'
import {answersActions,questionActions} from '../../actions';
import {PlaceAnswers} from '.'
type SingleQuestion={
  location: any;
  data:any;
  dispatch:any;
  relatedQuestions:any;
  match:any;
  singleQuestion:any;
}

 const SingleQuestion=(props:SingleQuestion)=>{
   const {location,data,dispatch,relatedQuestions,match,singleQuestion} = props;
   let user = JSON.parse(localStorage.getItem('user')!)
   let id = match.params.id;
   useEffect(()=>{
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
           let ids={
            placeId:match.params.placeId,
            questionId:id
      }
     dispatch(questionActions.FetchQuestionById(id));
     dispatch(questionActions.FetchRelatedUestion(ids));
    dispatch(answersActions.FetchAnswersByQuestion(id));

   },[location])

  if(!data)
  {
    return(
      <div></div>
    );
  }
  if(!singleQuestion)
  {
    return(
      <div></div>
    );
  }
  const onFinish = (values: any) => {
    console.log(singleQuestion[0])
    let data={
      userId:user.id,
      questionId:id,
      answer:values.answer,
      firstName:user.firstName,
      lastName:user.lastName,
      placeId:match.params.placeId,
      question:singleQuestion[0].question,
      playerId:singleQuestion[0].user.playerId
    }
    dispatch(answersActions.AddAnswer(data));

   }

   const onFinishFailed = (errorInfo: any) => {
  };

  const onRelatedQuestionClick=(questionId:any,question:any,place:any)=>{
    let questionTitle=question.replace(/[ ?]/g,'-');
    history.push(`/question/${questionTitle}/${questionId}/${place}`)
    window.scrollTo(0, 0)
  }
  let finalData={
    title:singleQuestion[0].question+" - Question - Pakrism",
    description:singleQuestion[0].question,
    imageAlt:"",
    imageUrl:""

  }
  return (
    <div className="single-question-main-container">
       <MetaDecorator title={finalData.title} description={finalData.description} imageAlt={finalData.imageAlt} imageUrl={finalData.imageUrl} match={match}/>

      <NavBar/>
      <div className="single-question-inner-container">
        <div className="single-question-left-container">
       <PlaceQuestions data={singleQuestion[0]}/>
    <div className="post-answer-main-container">
      <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
             >
               <div style={{width:'9%',float:'left'}}>
               <Avatar
               name={user.firstName+" "+ user.lastName}
                 src={user.image}
               />
                 </div>
                 <div style={{paddingTop:'5px'}}>
                 <Form.Item
               name="answer"
               rules={[{ required: true, message: 'Please enter an answer.' },]}
               >
                 <Input size="large" name="answer" placeholder="write your answer"/>
               </Form.Item>
                 </div>

               <Form.Item style={{textAlign:'right'}}>
               <Button style={{width: '30%'}} className="btn-admin-login" size="large" type="primary" htmlType="submit" >
                Post answer
                </Button>
               </Form.Item>
             </Form>
    </div>
    <div className="single-question-all-answers-container-main">
    <p className="place-card-timings-heading">
           {/* All answers ({singleQuestion[0].answerCount}) */}
    </p>
    <div className="answe-card-container">
      {
        data.map((item:any)=>
        <PlaceAnswers data={item} key={item._id}/>
        )
      }

    </div>
    </div>
    <div className="related-questions-container">
    <p className="place-card-timings-heading">
            Related questions
            </p>
            <div className="related-questins-inner-container">
                {
                  relatedQuestions.length>0?
                  relatedQuestions.map((item:any)=>
                <p className="related-question-txt" onClick={()=>onRelatedQuestionClick(item._id,item.question,item.place)}>{item.question}</p>
                  ):null
                }
            </div>
    </div>
    </div>
    </div>
    </div>
  )
}

function mapStateToProps(state: any) {
  return {
    data: state.FetchAnswers.getAnswersObject,
    relatedQuestions:state.FetchRelatedQuestions.getRelatedQuestionsObject,
    singleQuestion:state.FetchSingleQuestions.getSingleQuestionsObject,

  };
}
export const connectedSingleQuestion = connect(mapStateToProps)(SingleQuestion);
