export const blogCategoryConstants = {
  GET_ALL_REQUEST: 'GET_ALL_REQUEST',
  GET_ALL_SUCCESS: ' GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'GET_ALL_FAILURE',

  ADD_BLOG_CATEGORY_REQUEST: ' ADD_BLOG_CATEGORY_REQUEST',
  ADD_BLOG_CATEGORY_SUCCESS: 'ADD_BLOG_CATEGORY_SUCCESS',
  ADD_BLOG_CATEGORY_FAILURE: 'ADD_BLOG_CATEGORY_FAILURE',


  EDIT_BLOG_CATEGORY_REQUEST: ' EDIT_BLOG_CATEGORY_REQUEST',
  EDIT_BLOG_CATEGORY_SUCCESS: 'EDIT_BLOG_CATEGORY_SUCCESS',
  EDIT_BLOG_CATEGORY_FAILURE: 'EDIT_BLOG_CATEGORY_FAILURE',

  UPDATE_BLOG_CATEGORY_REQUEST: ' UPDATE_BLOG_CATEGORY_REQUEST',
  UPDATE_BLOG_CATEGORY_SUCCESS: 'UPDATE_BLOG_CATEGORY_SUCCESS',
  UPDATE_BLOG_CATEGORY_FAILURE: 'UPDATE_BLOG_CATEGORY_FAILURE',

  DELETE_BLOG_CATEGORY_REQUEST: ' DELETE_BLOG_CATEGORY_REQUEST',
  DELETE_BLOG_CATEGORY_SUCCESS: 'DELETE_BLOG_CATEGORY_SUCCESS',
  DELETE_BLOG_CATEGORY_FAILURE: 'DELETE_BLOG_CATEGORY_FAILURE',
};
