import { alertActions } from "../actions";
import { config } from "../config";
import axios from "axios";

 const AddBlogCategory=(values: any)=> {
  return axios
    .post(`${config.apiurl}/admin/add-blog-category/`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

const GetAllBlogCategories=()=>{
  return fetch(`${config.apiurl}/admin/get-blog-categories/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}


export const blogCategoryservice={
  AddBlogCategory,
  GetAllBlogCategories,
}
