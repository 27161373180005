import React from "react";
import {Avatar,Image } from "@chakra-ui/core";
import {FacebookShareButton, FacebookIcon} from "react-share";
import {config} from '../../config';
import './places.css'
const TimeStamp =require("react-timestamp");

export const PlaceAnswers= (props: any) => {
  const { data } = props;
  return (
    <div className="place-question-card-container answer-card-container" >
    <div >
        <div className="story-card-user-container">
          <div style={{ float: "left", marginBottom: "16px" }}>
            <Avatar
              name={data.user.firstName+" "+data.user.lastName}
              size="lg"
              className="question-avatar"
              src={data.user.image}
            ></Avatar>
          </div>
          <div className="story-card-desc-container">
            <p className="story-card-decs-txt-container">
              <small className="story-card-desc-txt">{data.user.firstName+" "+data.user.lastName}</small>
              &nbsp;<small className="story-card-tourist-txt">Answered</small>
              &nbsp; <small >
                <TimeStamp className="story-card-timespan-txt" relative date={data.createdDate} autoUpdate />
                </small>
            </p>
            <p className="story-card-tourist-txt answer-card-user-role">
              {data.user.roles}
              </p>
          </div>
        </div>
        <div>
          <p className="answer-txt">
            {data.answer}
          </p>
        </div>
      </div>

    {/* <Modal
      title="Share social"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <FacebookShareButton
            url={"http://192.168.100.37:3000/stories"}
            quote={"Pakrism- World is yours to explore"}
            hashtag="#PakrismStories"
            className="" >
             <FacebookIcon size={36} round={true} />
          </FacebookShareButton>

    </Modal> */}
</div>
  );
}
