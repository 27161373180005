
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import {albumActions} from '../../actions'
import {NoData} from '../Places'
import ReactGA from 'react-ga';
import {UserAlbumsCards} from '.'


const cookies = new Cookies();
export function UserAlbums(props:any) {
  let userFav=cookies.get('userData');
  const {dispatch,data,match}=props;

  useEffect(()=>{
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
      dispatch(albumActions.FetchMyAlbum(match.params.userName))
},[dispatch])

if(!data)
{
  return(<div></div>)
}

  return (
    <div>
        <div>
        {
          data.length>0?
          data.map((item: any,i:any) =>
          <UserAlbumsCards data={item} key={i}/>
          ):
          <NoData isFrom="albm"
            noData="No album created."
             noDataMessage="Need to create an album."
             />
        }
      </div>
    </div>
  )
}
function mapStateToProps(state: any) {
  return {
    data:state.FetchMyAlbums.getMyAlbumsObject,
  };
}
export const connectedUserAlbums = connect(mapStateToProps)(UserAlbums);
