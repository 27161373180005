import { blogCategoryConstants } from "../constants";
import { blogCategoryservice } from "../services";
import { alertActions } from ".";

function AddBlogCategory(data: any) {
  return (dispatch: any) => {
    dispatch(request(data));
    blogCategoryservice.AddBlogCategory(data)
      .then((response) => response)
      .then((data: any) => {
          dispatch(success(data));
          if(data.status===500)
          {
            dispatch(failure(data.error));
            dispatch(alertActions.error(data.data.error.message));
          }
          else
          {
            dispatch(alertActions.success(data.data.message));
          }



      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: blogCategoryConstants.ADD_BLOG_CATEGORY_REQUEST, data };
    }
    function success(user: any) {
      return { type: blogCategoryConstants.ADD_BLOG_CATEGORY_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: blogCategoryConstants.ADD_BLOG_CATEGORY_FAILURE, error };
    }
  };
}

function FetchAllBlogCategories() {
  return (dispatch: any) => {
    dispatch(request());
    blogCategoryservice.GetAllBlogCategories().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: blogCategoryConstants.GET_ALL_REQUEST };
  }
  function success(user: any) {
    return { type: blogCategoryConstants.GET_ALL_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: blogCategoryConstants.GET_ALL_FAILURE, error };
  }
}

export const blogCategoriesAction={
  AddBlogCategory,
  FetchAllBlogCategories
}
