import React from "react";
import {Stack, Image } from "@chakra-ui/core";
import  warningIcon from "../../Assets/warning-icon-01.svg";
import "./cards.css";

type VerificationCard={

}

export function VerificationCard(props:VerificationCard) {
    const{} = props;
  return (
    <div className="main-cont-user">
    <div className="verification-container">
            <div className="verification-inr-container">
              <div className="heading-container verification-card-caontainer">
                <p className="card-heading">Verifications</p>
              </div>
              <div>
                <div className="user-data-icon-wrapper">
                <i className="ri-mail-fill user-data-icons"></i>
               </div>
               <p className="verified-email-txt">Email verified.</p>
              </div>
            </div>
          </div>
          </div>
  );
}

