import React,{useEffect} from 'react'
import { Button, Form, Input, Upload, message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import {Avatar} from '@chakra-ui/core'
import { CloudUploadOutlined } from "@ant-design/icons";
import { history, store } from '../../../Helpers';
import { connect } from 'react-redux';
import { experiencesAction } from '../../../actions';
import { config } from "../../../config";
type EditExperience = {
  dispatch: any;
  match: any;
  data: any;
}

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};


const EditExperience=(props:EditExperience)=> {
  store.getState().FetchExperiencesAdmin.getExperiencesAdminObject = "";
  let formRef:any = React.createRef<FormInstance>();
  let { dispatch, match, data } = props;

  useEffect(() => {
  let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')
    }
    else {
      dispatch(experiencesAction.FetchExperienceAdmin(match.params.id));
    }

  }, []);

  const onFinish = (values: any) => {
    let imageFlag = false;
    let iconFlag = false;
    let formData = new FormData();
    formData.append("title", values.title)
    if (values.image) {
      imageFlag = true;
      formData.append("facilityImage", values.image.file.originFileObj)
    }
    else if (values.icon) {
      iconFlag = true;
      formData.append("facilityImage", values.icon.file.originFileObj)
    }
    formData.append("imageFlag", imageFlag.toString());
    formData.append("iconFlag", iconFlag.toString());

    // dispatch(facilitiesActions.UpdateActivity(formData, match.params.id));
  };  const onFinishFailed = (errorInfo: any) => {
  };

  if(!data) {
    return(<div></div>)
  }
  let icon:any='';
  let exp:any='';
  console.log('dddaaaaaaataaaaaaa',{data})
  switch (data[0].experience) {
    case 5:
      exp = "Out standing"
        break;

      case 4:
        exp = "Good"
        break;
        case 3:
          exp = "Average"
          break;
          case 2:
            exp = "Bad"
            break;
            case 1:
              exp = "Very Bad"
              break;
      default:
        break;
  }
  return (
    <div>
    <Form
      {...layout}
      ref={formRef}
      className="admin-login-form"
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item className="lbl-name" label="Title"></Form.Item>
      <Form.Item
        initialValue={data[0].title}
        name="title"
        rules={[{ required: true, message: 'Please enter a title.' },]}
      >
        <Input name="title" size="large" placeholder="Title" />
      </Form.Item>
      <Form.Item className="lbl-name" label="Tour Type"></Form.Item>
      <Form.Item
        initialValue={data[0].tourType}
        name="tourType"
        rules={[{ required: true, message: 'Please enter tour type.' },]}
      >
        <Input name="tourType" size="large" placeholder="tourType" />
      </Form.Item>
      <Form.Item className="lbl-name" label="Experience"></Form.Item>
      <Form.Item
        initialValue={exp}
        name="experience"
        rules={[{ required: true, message: 'Please enter tour type.' },]}
      >
        <Input name="experience" size="large" placeholder="tourType" />
      </Form.Item>
      <Form.Item className="lbl-name" label="Images"></Form.Item>
      <Form.Item
        name="icon"
       >
         <div>
           {
             data[0].images?
             data[0].images.map((item: any) =>
             <div style={{width:'30%',float:'left',padding:'10px'}}>
         <img src={config.s3BucketUrl+item}  alt={icon} style={{height:'300px',width:'100%',objectFit: "cover"}} />
         </div>
             )
             :null

           }
         </div>
      </Form.Item>
      <Form.Item >
        <Button className="btn-admin-login" style={{ marginTop: '20px' }} size="large" type="primary" htmlType="submit">
          Update Activity
                  </Button>
      </Form.Item>
    </Form>
  </div>
  )
}

function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    data: state.FetchExperiencesAdmin.getExperiencesAdminObject,
  };
}

export const connectedEditExperience = connect(mapStateToProps)(EditExperience);
