export const agenciesConstants = {


  GET_ADMIN_TRAVEL_AGENCIES_REQUEST: "GET_ADMIN_TRAVEL_AGENCIES_REQUEST",
  GET_ADMIN_TRAVEL_AGENCIES_SUCCESS: "GET_ADMIN_TRAVEL_AGENCIES_SUCCESS",
  GET_ADMIN_TRAVEL_AGENCIES_FAILURE: "GET_ADMIN_TRAVEL_AGENCIES_FAILURE",


  GET_EDIT_ADMIN_TRAVEL_AGENCIES_REQUEST: "GET_EDIT_ADMIN_TRAVEL_AGENCIES_REQUEST",
  GET_EDIT_ADMIN_TRAVEL_AGENCIES_SUCCESS: "GET_EDIT_ADMIN_TRAVEL_AGENCIES_SUCCESS",
  GET_EDIT_ADMIN_TRAVEL_AGENCIES_FAILURE: "GET_EDIT_ADMIN_TRAVEL_AGENCIES_FAILURE",


  UPDATE_ADMIN_TRAVEL_AGENCIES_REQUEST: "UPDATE_ADMIN_TRAVEL_AGENCIES_REQUEST",
  UPDATE_ADMIN_TRAVEL_AGENCIES_SUCCESS: "UPDATE_ADMIN_TRAVEL_AGENCIES_SUCCESS",
  UPDATE_ADMIN_TRAVEL_AGENCIES_FAILURE: "UPDATE_ADMIN_TRAVEL_AGENCIES_FAILURE",


  DELETE_ADMIN_TRAVEL_AGENCIES_REQUEST: "DELETE_ADMIN_TRAVEL_AGENCIES_REQUEST",
  DELETE_ADMIN_TRAVEL_AGENCIES_SUCCESS: "DELETE_ADMIN_TRAVEL_AGENCIES_SUCCESS",
  DELETE_ADMIN_TRAVEL_AGENCIES_FAILURE: "DELETE_ADMIN_TRAVEL_AGENCIES_FAILURE",

  UPDATE_STATUS_ADMIN_TRAVEL_AGENCIES_REQUEST: "UPDATE_STATUS_ADMIN_TRAVEL_AGENCIES_REQUEST",
  UPDATE_STATUS_ADMIN_TRAVEL_AGENCIES_SUCCESS: "UPDATE_STATUS_ADMIN_TRAVEL_AGENCIES_SUCCESS",
  UPDATE_STATUS_ADMIN_TRAVEL_AGENCIES_FAILURE: "UPDATE_STATUS_ADMIN_TRAVEL_AGENCIES_FAILURE",

  ADD_ADMIN_TRAVEL_AGENCIES_REQUEST: "ADD_ADMIN_TRAVEL_AGENCIES_REQUEST",
  ADD_ADMIN_TRAVEL_AGENCIES_SUCCESS: "ADD_ADMIN_TRAVEL_AGENCIES_SUCCESS",
  ADD_ADMIN_TRAVEL_AGENCIES_FAILURE: "ADD_ADMIN_TRAVEL_AGENCIES_FAILURE",


};
