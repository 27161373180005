
import { placesConstants } from "../constants";
import { placeServices } from "../services";
import { alertActions } from ".";
import { history } from "../Helpers";


export const placesActions={
  GetRelatedPlaces,
  AddUserPlaceImages,
  GetUserPlaceImageLimit,
  GetUserAllPlaceImage,
  GetUserAllPlaceImageUserProfile,
  GetUserAllPlaceImageAdmin,
  DeletePhotoAdmin,
  GetPlacesCount,
  fetchAllUserPlacesAdmin,
  fetchEditUserPlace
}

function GetRelatedPlaces(placeId:any,id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    placeServices.GetRelatedPlaces(placeId,id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: placesConstants.GET_RELATED_PLACES_REQUEST, id };
  }
  function success(user: any) {
    return { type: placesConstants.GET_RELATED_PLACES_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: placesConstants.GET_RELATED_PLACES_FAILURE, error };
  }
}

function AddUserPlaceImages(data: any) {
  return (dispatch: any) => {
    dispatch(request(data));
    placeServices
      .AddUserPlaceImage(data)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "201") {
          dispatch(success(data));
          dispatch(
            alertActions.success("You have successfully added images")
          );
          history.go(0);
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: placesConstants.ADD_USER_PLACE_IMAGES_REQUEST, data };
    }
    function success(user: any) {
      return { type: placesConstants.ADD_USER_PLACE_IMAGES_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: placesConstants.ADD_USER_PLACE_IMAGES_FAILURE, error };
    }
  };
}


function GetUserPlaceImageLimit(placeId:any) {
  return (dispatch: any) => {
    dispatch(request(placeId));
    placeServices.GetUserPlaceImageLimit(placeId).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: placesConstants.GET_USER_PLACE_IMAGES_LIMIT_REQUEST, id };
  }
  function success(user: any) {
    return { type: placesConstants.GET_USER_PLACE_IMAGES_LIMIT_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: placesConstants.GET_USER_PLACE_IMAGES_PROFILE_FAILURE, error };
  }
}


function GetUserAllPlaceImage(placeId:any) {
  return (dispatch: any) => {
    dispatch(request(placeId));
    placeServices.GetUserAllPlaceImage(placeId).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: placesConstants.GET_USER_PLACE_IMAGES_REQUEST, id };
  }
  function success(user: any) {
    return { type: placesConstants.GET_USER_PLACE_IMAGES_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: placesConstants.GET_USER_PLACE_IMAGES_FAILURE, error };
  }
}

function GetUserAllPlaceImageAdmin(page:any) {
  return (dispatch: any) => {
    dispatch(request());
    placeServices.GetUserAllPlaceImageAdmin(page).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: placesConstants.GET_USER_IMAGES_ADMIN_REQUEST};
  }
  function success(user: any) {
    return { type: placesConstants.GET_USER_IMAGES_ADMIN_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: placesConstants.GET_USER_IMAGES_ADMIN_FAILURE, error };
  }
}

function GetUserAllPlaceImageUserProfile(userId:any) {
  return (dispatch: any) => {
    dispatch(request(userId));
    placeServices.GetUserAllPlaceImageUserProfile(userId).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: placesConstants.GET_USER_PLACE_IMAGES_REQUEST, id };
  }
  function success(user: any) {
    return { type: placesConstants.GET_USER_PLACE_IMAGES_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: placesConstants.GET_USER_PLACE_IMAGES_FAILURE, error };
  }
}

function DeletePhotoAdmin(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    placeServices.DeleteUserPhotoAdmin(id)
      .then((res: any) => {
        dispatch(success(res.message));
        dispatch(alertActions.success(res.message));
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });

    function request(id: any) {
      return { type: placesConstants.DELETE_USER_IMAGES_ADMIN_REQUEST, id };
    }
    function success(user: any) {
      return { type: placesConstants.DELETE_USER_IMAGES_ADMIN_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: placesConstants.DELETE_USER_IMAGES_ADMIN_FAILURE, error };
    }
  };
}

function GetPlacesCount() {
  return (dispatch: any) => {
    dispatch(request());
    placeServices.GetPlacesCount().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: placesConstants.GET_USER_PLACE_COUNT_ADMIN_REQUEST,};
  }
  function success(user: any) {
    return { type: placesConstants.GET_USER_PLACE_COUNT_ADMIN_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: placesConstants.GET_USER_PLACE_COUNT_ADMIN_FAILURE, error };
  }
}

function fetchAllUserPlacesAdmin(page:any,search:any,pageLimit:any){
  return (dispatch:any) => {
    dispatch(request(page,search,pageLimit));
    placeServices.fetchAllUserPlacesAdmin(page,search,pageLimit)
    .then(
      (res) => {
          dispatch(success(res));
      },
      (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.toString()));
      });
        };

        function request(page:any,search:any,pageLimit:any) { return { type: placesConstants.GET_USER_ALL_PLACES_ADMIN_REQUEST,page,search,pageLimit} }
        function success(user:any) { return { type: placesConstants.GET_USER_ALL_PLACES_ADMIN_SUCCESS, user } }
        function failure(error:any) { return { type: placesConstants.GET_USER_ALL_PLACES_ADMIN_FAILURE, error } }
}

function fetchEditUserPlace(id:any){
  return (dispatch:any) => {
    dispatch(request(id));
    placeServices.GetUserPlaceAdmin(id)
    .then(
      (res) => {
          dispatch(success(res));
      },
      (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.toString()));
      });
        };

        function request(id:any) { return { type: placesConstants.GET_USER_SINGLE_PLACE_ADMIN_REQUEST, id } }
        function success(user:any) { return { type: placesConstants.GET_USER_SINGLE_PLACE_ADMIN_SUCCESS, user } }
        function failure(error:any) { return { type: placesConstants.GET_USER_SINGLE_PLACE_ADMIN_FAILURE, error } }
}
