import { alertActions } from "../actions";
import { config } from "../config";
import axios from "axios";
let admin = JSON.parse(localStorage.getItem('admin')!);
export const categoryService = {
  FetchAllCategories,
  AddCategory,
  GetEditCategory,
  UpdateCategory,
  DeleteCategory,
  FetchAllPlaceCategories,
  AddPlaceCategory,
  GetEditPlaceCategory,
  UpdatePlaceCategory,
  DeletePlace,
  FetchAllPakMapCategories,
  FetchWeather,
  FetchCurrentWeather,
  AddExperience,
  FeaturedPlace,
  AddUserPlaceAdmin
};

function FetchAllCategories() {
  return fetch(`${config.apiurl}/admin/get-categories/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + admin.token,
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

function AddCategory(values: any) {
  return axios
    .post(`${config.apiurl}/admin/add-categories/`, values, {
      headers:{"Authorization" : `Bearer ${admin.token}`},
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function GetEditCategory(id: any) {
  return fetch(`${config.apiurl}/admin/get-categories/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + admin.token,
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function UpdateCategory(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/admin/update-categories/${id}`, values, {
      headers:{"Authorization" : `Bearer ${admin.token}`},
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function DeleteCategory(id: any) {
  return fetch(`${config.apiurl}/admin/delete-categories/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + admin.token,
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function FetchAllPlaceCategories() {
  return fetch(`${config.apiurl}/admin/get-place-categories/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + admin.token,
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

function AddPlaceCategory(values: any) {
  return axios
    .post(`${config.apiurl}/admin/add-place-categories/`, values, {
      headers:{"Authorization" : `Bearer ${admin.token}`},
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function AddUserPlaceAdmin(values: any) {
  return axios
    .post(`${config.apiurl}/user/add-place-admin/`, values, {
      headers:{"Authorization" : `Bearer ${admin.token}`},
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function GetEditPlaceCategory(id: any) {
  return fetch(`${config.apiurl}/admin/get-place-categories/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      localStorage.setItem('places',JSON.stringify(ree));
      return ree;
    });
}

function UpdatePlaceCategory(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/admin/update-place-categories/${id}`, values, {
      headers:{"Authorization" : `Bearer ${admin.token}`},
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function DeletePlace(id: any) {
  return fetch(`${config.apiurl}/admin/delete-place-categories/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + admin.token,
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function FetchAllPakMapCategories() {
  return  fetch(`${config.apiurl}/user/get-categories-pak-map/`, {
    method: "GET",
  })
  .then((response) => response.json())
  .then((res) => {
    return res;
  })
    .catch((err) => {
      console.log(console.log("Can’t access " + config.apiurl + " response. Blocked by browser?"))
      return err;
    });
}


function FetchWeather(values:any) {
  return axios
  .get(`https://api.openweathermap.org/data/2.5/forecast?lat=${values.lat}&lon=${values.lng}&appid=6ed77dc835d6c730fa8c34fb5c0a3510`, {
    // receive two parameter endpoint url ,form data
    validateStatus: () => true,
  })
  .then((res) => {
    return res;
  })
  .catch((err: any) => {
    if (err === "TypeError: Failed to fetch") {
      alertActions.error("Server is not responding");
    }
    // else {
    //   alertActions.error(err);
    // }
  });

}
function FetchCurrentWeather(values:any) {
  return axios
  .get(`https://api.openweathermap.org/data/2.5/weather?lat=${values.lat}&lon=${values.lng}&appid=6ed77dc835d6c730fa8c34fb5c0a3510`, {
    // receive two parameter endpoint url ,form data
    validateStatus: () => true,
  })
  .then((res) => {
    return res;
  })
  .catch((err: any) => {
    if (err === "TypeError: Failed to fetch") {
      alertActions.error("Server is not responding");
    }
    //  else {
    //   alertActions.error(err);
    // }
  });

}

function FeaturedPlace(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/admin/featured-place/${id}`, values, {
      validateStatus: () => true,
      // receive two parameter endpoint url ,form data
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err.toString());
      }
    });
}


function AddExperience(values: any) {
  return axios
    .post(`${config.apiurl}/user/add-experience/`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
  }
