import { deviceInfoConstants } from "../constants";
import { deviceInfoService } from "../services";
import { alertActions } from ".";
import { history } from "../Helpers";

export const deviceInfoActions = {
  FetchDeviceInfoById,
  AddDeviceInfo,
  FetchDeviceInfo,
  DeleteDeviceInfo,
  UpdateDeviceInfo
};

function FetchDeviceInfoById(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    deviceInfoService.FetchDeviceInfoById(id).then(
      (res) => {
        // history.push('/place')
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: deviceInfoConstants.EDIT_REQUEST, id };
  }
  function success(user: any) {
    return { type: deviceInfoConstants.EDIT_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: deviceInfoConstants.EDIT_FAILURE, error };
  }
}

function FetchDeviceInfo() {
  return (dispatch: any) => {
    dispatch(request());
    deviceInfoService.FetchDeviceInfo().then(
      (res) => {
        // history.push('/place')
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: deviceInfoConstants.GET_ALL_REQUEST };
  }
  function success(user: any) {
    return { type: deviceInfoConstants.GET_ALL_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: deviceInfoConstants.GET_ALL_FAILURE, error };
  }
}

function AddDeviceInfo(data: any) {
  return (dispatch: any) => {
    dispatch(request(data));
    deviceInfoService
      .AddDeviceInfo(data)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "201") {
          dispatch(success(data));
          dispatch(
            alertActions.success("You have successfully added an activity")
          );
          history.go(0);
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: deviceInfoConstants.ADD_REQUEST, data };
    }
    function success(user: any) {
      return { type: deviceInfoConstants.ADD_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: deviceInfoConstants.ADD_FAILURE, error };
    }
  };
}

function DeleteDeviceInfo(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    deviceInfoService
      .DeleteDeviceInfo(id)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "200") {
          dispatch(success(data));
          dispatch(alertActions.success(" deleted successfully")
          );
          history.go(0);
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request( id: any) {
      return {type: deviceInfoConstants.DELETE_REQUEST,id };
    }
    function success(user: any) {
      return { type: deviceInfoConstants.DELETE_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: deviceInfoConstants.DELETE_FAILURE, error };
    }
  };
}

function UpdateDeviceInfo(data: any, id: any) {
  return (dispatch: any) => {
    dispatch(request(data, id));
    deviceInfoService
      .UpdateDeviceInfo(data, id)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "200") {
          dispatch(success(data));
          dispatch(alertActions.success("Updated successfully")
          );
          history.go(0);
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any, id: any) {
      return { type: deviceInfoConstants.UPDATE_REQUEST, data, id };
    }
    function success(user: any) {
      return { type: deviceInfoConstants.UPDATE_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: deviceInfoConstants.UPDATE_FAILURE, error };
    }
  };
}

