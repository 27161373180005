
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {NoData} from '../Places';
import {placesActions} from '../../actions';
import ReactGA from 'react-ga';
import {UserPhotoCard} from '.'

function Photos(props:any) {
const {dispatch,data,match} = props;
  useEffect(()=>{
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    dispatch(placesActions.GetUserAllPlaceImageUserProfile(match.params.userName));
  },[dispatch])
  if(!data)
  {
    return(<div></div>)
  }
  return (
    <div>
      {
        data.length>0?
        data.map((item:any,i:any)=>
        <UserPhotoCard data={item} key={i}/>
        ):
        <div>
                   <NoData isFrom="photo"
                noData="No photo uploaded."
                noDataMessage="Need to upload a photo on a place."
             />
                </div>

      }
    </div>

  )
}

function mapStateToProps(state: any) {
  return {
    data:state.GetUserAllPlaceImage.getUserAllPlaceImageObject,
  };
}
export const connectedPhotos = connect(mapStateToProps)(Photos);
