export const albumConstants = {

  ADD_ALBUMS_REQUEST: "ADD_ALBUMS_REQUEST",
  ADD_ALBUMS_SUCCESS: " ADD_ALBUMS_SUCCESS",
  ADD_ALBUMS_FAILUR: "ADD_ALBUMS_FAILUR",

  GET_ALL_ALBUMS_REQUEST_ADMIN: "GET_ALL_ALBUMS_REQUEST_ADMIN",
  GET_ALL_ALBUMS_SUCCESS_ADMIN: " GET_ALL_ALBUMS_SUCCESS_ADMIN",
  GET_ALL_ALBUMS_FAILURE_ADMIN: "GET_ALL_ALBUMS_FAILURE_ADMIN",

  EDIT_ALBUMS_REQUEST_ADMIN: " EDIT_ALBUMS_REQUEST_ADMIN",
  EDIT_ALBUMS_ADMIN_SUCCESS: "EDIT_ALBUMS_ADMIN_SUCCESS",
  EDIT_ALBUMS_ADMIN_FAILURE: "EDIT_ALBUMS_ADMIN_FAILURE",

  UPDATE_ALBUMS_REQUEST_ADMIN: " UPDATE_ALBUMS_REQUEST_ADMIN",
  UPDATE_ALBUMS_REQUEST_ADMIN_SUCCESS: "UPDATE_ALBUMS_REQUEST_ADMIN_SUCCESS",
  UPDATE_ALBUMS_REQUEST_ADMIN_FAILURE: "UPDATE_ALBUMS_REQUEST_ADMIN_FAILURE",

  DELETE_ALBUMS_REQUEST_ADMIN_REQUEST: " DELETE_ALBUMS_REQUEST_ADMIN_REQUEST",
  DELETE_ALBUMS_REQUEST_ADMIN_SUCCESS: "DELETE_ALBUMS_REQUEST_ADMIN_SUCCESS",
  DELETE_ALBUMS_REQUEST_ADMIN_FAILURE: "DELETE_ALBUMS_REQUEST_ADMIN_FAILURE",

  GET_ALL_ALBUMS_REQUEST_USER: "GET_ALL_ALBUMS_REQUEST_USER",
  GET_ALL_ALBUMS_SUCCESS_USER: " GET_ALL_ALBUMS_SUCCESS_USER",
  GET_ALL_ALBUMS_FAILURE_USER: "GET_ALL_ALBUMS_FAILURE_USER",


  GET_MY_ALBUMS_REQUEST: "GET_MY_ALBUMS_REQUEST",
  GET_MY_ALBUMS_SUCCESS: " GET_MY_ALBUMS_SUCCESS",
  GET_MY_ALBUMS_FAILURE: "GET_MY_ALBUMS_FAILURE",


  GET_LOVED_ALBUMS_REQUEST: "GET_LOVED_ALBUMS_REQUEST",
  GET_LOVED_ALBUMS_SUCCESS: " GET_LOVED_ALBUMS_SUCCESS",
  GET_LOVED_ALBUMS_FAILURE: "GET_LOVED_ALBUMS_FAILURE",

  DELETE_ALBUMS_REQUEST: "DELETE_ALBUMS_REQUEST",
  DELETE_ALBUMS_SUCCESS: " DELETE_ALBUMS_SUCCESS",
  DELETE_ALBUMS_FAILUR: "DELETE_ALBUMS_FAILUR",

  ADD_ALBUM_COMMENT_REQUEST: 'ADD_ALBUM_COMMENT_REQUEST',
  ADD_ALBUM_COMMENT_SUCCESS: 'ADD_ALBUM_COMMENT_SUCCESS',
  ADD_ALBUM_COMMENT_FAILURE: 'ADD_ALBUM_COMMENT_FAILURE',

  ADD_ALBUM_LIKE_REQUEST: 'ADD_ALBUM_LIKE_REQUEST',
  ADD_ALBUM_LIKE_SUCCESS: 'ADD_ALBUM_LIKE_SUCCESS',
  ADD_ALBUM_LIKE_FAILURE: 'ADD_ALBUM_LIKE_FAILURE',

  REMOVE_ALBUM_LIKE_REQUEST: 'REMOVE_ALBUM_LIKE_REQUEST',
  REMOVE_ALBUM_LIKE_SUCCESS: 'REMOVE_ALBUM_LIKE_SUCCESS',
  REMOVE_ALBUM_LIKE_FAILURE: 'REMOVE_ALBUM_LIKE_FAILURE',

  FEATURED_ALBUM_REQUEST: 'FEATURED_ALBUM_REQUEST',
  FEATURED_ALBUM_SUCCESS: 'FEATURED_ALBUM_SUCCESS',
  FEATURED_ALBUM_FAILURE: 'FEATURED_ALBUM_FAILURE',

  GET_ALBUMS_COUNT_REQUEST_ADMIN: "GET_ALBUMS_COUNT_REQUEST_ADMIN",
  GET_ALBUMS_COUNT_SUCCESS_ADMIN: " GET_ALBUMS_COUNT_SUCCESS_ADMIN",
  GET_ALBUMS_COUNT_FAILURE_ADMIN: "GET_ALBUMS_COUNT_FAILURE_ADMIN",
};
