import React from "react";
import {config} from '../../config';
import { MoreOutlined } from '@ant-design/icons';
import {Dropdown, Menu} from 'antd';
import {history} from '../../Helpers';

type PublishedStoryCards={
data:any;
dispatch:any;
}

export function PublishedStoryCards(props:PublishedStoryCards) {
   const {data} = props;
   let story=data.stories.shortDescription;
   if(story.length > 10)
  {
   story=story.substring(0,135);
  }
  const handleAction= (e:any,id:any) =>{
    // if(e.key == "Del")
    // {

    //   Modal.warning({
    //     title: 'Opppssss',

    //     content: (
    //       <div className="warning-modal">
    //         <p>Are you sure you want to delete a story?</p>
    //       </div>
    //     ),
    //     width: 560,
    //     okType: 'danger',
    //     okText: 'ok',
    //     okCancel: true,
    //     centered: true,
    //     cancelText: 'cancel',
    //     onOk: () => {
    //      let reason="delete by user"
    //       dispatch(storyActions.DeleteStory(reason, id));

    //     },
    //     onCancel() {
    //     },
    //   });
    // }
    if(e.key==="Ed")
    {
      history.push('/user/edit-stories/'+id)
    }
  }
  return (

        <div className="published-card-container">
          <div className="publish-card-image-banner">
          <img className="published-img-banner" src ={config.s3BucketUrl+data.stories.image} alt="abc" />
          </div>
          <div className="published-card-data-container" >
            <div className="published-card-title-container" onClick={()=>history.push(`/travel-stories/${data.stories.urlTitle}-${data.stories._id}`)}>
            <p className="published-card-story-title">
            {data.stories.title}
            </p>
            <div className="story-card-story-desc-container">
            <div className="story-card-story-desc" dangerouslySetInnerHTML= {{__html:story+"..."}}>
           </div>
            </div>
            </div>
            <div className="published-card-menu-container">
            <Dropdown overlay={(
                    <Menu  onClick={(e:any)=>handleAction(e,data.stories._id)}>
                      <Menu.Item key="Ed" >Edit</Menu.Item>
                      {/* <Menu.Item key="Del" >Delete</Menu.Item> */}
                    </Menu>)} trigger={['click']} >
                    <MoreOutlined  style={{float:'right'}}/>
              </Dropdown>
            </div>
          </div>
          </div>
  );
}


