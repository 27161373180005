import { alertActions } from "../actions";
import { config } from "../config";
import axios from "axios";
let admin = JSON.parse(localStorage.getItem('admin')!);
export const albumCategoryService = {
  FetchAllAlbumCategories,
  AddAlbumCategory,
  GetEditAlbumCategory,
  UpdateAlbumCategory,
  DeleteCategory,
};

function FetchAllAlbumCategories() {
  return fetch(`${config.apiurl}/admin/get-album-categories/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // 'Authorization': 'Bearer ' + admin.token,
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

function AddAlbumCategory(values: any) {
  return axios
    .post(`${config.apiurl}/admin/add-album-categories/`, values, {
       headers:{"Authorization" : `Bearer ${admin.token}`},
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function GetEditAlbumCategory(id: any) {
    return fetch(`${config.apiurl}/admin/get-album-categories/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + admin.token,
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function UpdateAlbumCategory(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/admin/update-album-categories/${id}`, values, {
      headers:{"Authorization" : `Bearer ${admin.token}`},
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function DeleteCategory(id: any) {
  return fetch(`${config.apiurl}/admin/delete-album-categories/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + admin.token,
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}
