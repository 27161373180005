export const storiesConstants = {
  GET_ALL_STORIES_REQUEST: 'GET_ALL_STORIES_REQUEST',
  GET_ALL_STORIES_REQUEST_SUCCESS: 'GET_ALL_STORIES_REQUEST_SUCCESS',
  GET_ALL_STORIES_REQUEST_FAILURE: 'GET_ALL_STORIES_REQUEST_FAILURE',

  ADD_STORY_REQUEST: ' ADD_STORY_REQUEST',
  ADD_STORY_SUCCESS: 'ADD_STORY_SUCCESS',
  ADD_STORY_FAILURE: 'ADD_STORY_FAILURE',

  GET_PLACE_STORY_REQUEST: ' GET_PLACE_STORY_REQUEST',
  GET_PLACE_STORY_SUCCESS: 'GET_PLACE_STORY_SUCCESS',
  GET_PLACE_STORY_FAILURE: 'GET_PLACE_STORY_FAILURE',


  EDIT_STORY_REQUEST: 'EDIT_STORY_REQUEST',
  EDIT_STORY_SUCCESS: 'EDIT_STORY_SUCCESS',
  EDIT_STORY_FAILURE: 'EDIT_STORY_FAILURE',

  UPDATE_STORY_REQUEST: 'UPDATE_STORY_REQUEST',
  UPDATE_STORY_SUCCESS: 'UPDATE_STORY_SUCCESS',
  UPDATE_STORY_FAILURE: 'UPDATE_STORY_FAILURE',

  DELETE_STORY_REQUEST: 'DELETE_STORY_REQUEST',
  DELETE_STORY_SUCCESS: 'DELETE_STORY_SUCCESS',
  DELETE_STORY_FAILURE: 'DELETE_STORY_FAILURE',

  GET_ALL_STORY_USER_REQUEST: 'GET_ALL_STORY_USER_REQUEST',
  GET_ALL_STORY_USER_SUCCESS: 'GET_ALL_STORY_USER_SUCCESS',
  GET_ALL_STORY_USER_FAILURE: 'GET_ALL_STORY_USER_FAILURE',

  GET_EDIT_STORY_USER_REQUEST: 'GET_EDIT_STORY_USER_REQUEST',
  GET_EDIT_STORY_USER_SUCCESS: 'GET_EDIT_STORY_USER_SUCCESS',
  GET_EDIT_STORY_USER_FAILURE: 'GET_EDIT_STORY_USER_FAILURE',

  GET_TOP_STORY_TELLER_REQUEST: 'GET_TOP_STORY_TELLER_REQUEST',
  GET_TOP_STORY_TELLER_SUCCESS: 'GET_TOP_STORY_TELLER_SUCCESS',
  GET_TOP_STORY_TELLER_FAILURE: 'GET_TOP_STORY_TELLER_FAILURE',

  GET_STORY_REQUEST: 'GET_STORY_REQUEST',
  GET_STORY_SUCCESS: 'GET_STORY_SUCCESS',
  GET_STORY_FAILURE: 'GET_STORY_FAILURE',

  GET_RELETED_STORY_REQUEST: 'GET_RELETED_STORY_REQUEST',
  GET_RELETED_STORY_SUCCESS: 'GET_RELETED_STORY_SUCCESS',
  GET_RELETED_STORY_FAILURE: 'GET_RELETED_STORY_FAILURE',

  GET_CATEGORY_STORY_REQUEST: 'GET_CATEGORY_STORY_REQUEST',
  GET_CATEGORY_STORY_SUCCESS: 'GET_CATEGORY_STORY_SUCCESS',
  GET_CATEGORY_STORY_FAILURE: 'GET_CATEGORY_STORY_FAILURE',

  GET_MY_STORY_REQUEST: 'GET_MY_STORY_REQUEST',
  GET_MY_STORY_SUCCESS: 'GET_MY_STORY_SUCCESS',
  GET_MY_STORY_FAILURE: 'GET_MY_STORY_FAILURE',

  GET_DRAFT_STORY_REQUEST: 'GET_DRAFT_STORY_REQUEST',
  GET_DRAFT_STORY_SUCCESS: 'GET_DRAFT_STORY_SUCCESS',
  GET_DRAFT_STORY_FAILURE: 'GET_DRAFT_STORY_FAILURE',

  ADD_STORY_COMMENT_REQUEST: 'ADD_STORY_COMMENT_REQUEST',
  ADD_STORY_COMMENT_SUCCESS: 'ADD_STORY_COMMENT_SUCCESS',
  ADD_STORY_COMMENT_FAILURE: 'ADD_STORY_COMMENT_FAILURE',

  ADD_STORY_LIKE_REQUEST: 'ADD_STORY_LIKE_REQUEST',
  ADD_STORY_LIKE_SUCCESS: 'ADD_STORY_LIKE_SUCCESS',
  ADD_STORY_LIKE_FAILURE: 'ADD_STORY_LIKE_FAILURE',

  REMOVE_STORY_LIKE_REQUEST: 'REMOVE_STORY_LIKE_REQUEST',
  REMOVE_STORY_LIKE_SUCCESS: 'REMOVE_STORY_LIKE_SUCCESS',
  REMOVE_STORY_LIKE_FAILURE: 'REMOVE_STORY_LIKE_FAILURE',

  FEATURED_STORY_REQUEST: 'FEATURED_STORY_REQUEST',
  FEATURED_STORY_SUCCESS: 'FEATURED_STORY_SUCCESS',
  FEATURED_STORY_FAILURE: 'FEATURED_STORY_FAILURE',

  GET_STORIES_COUNT_REQUEST: 'GET_STORIES_COUNT_REQUEST',
  GET_STORIES_COUNT_SUCCESS: 'GET_STORIES_COUNT_SUCCESS',
  GET_STORIES_COUNT_FAILURE: 'GET_STORIES_COUNT_FAILURE',

};
