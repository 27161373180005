import React from 'react';
import { Box,Text} from "@chakra-ui/core";
import {RightCircleOutlined } from '@ant-design/icons';
import question from '../../Assets/question_illustration.svg';
import Experience from '../../Assets/experience_illustration.svg';
import {history} from '../../Helpers';

import './qa-section.css'

export function QusetionAnswerSection() {
  return (
    <Box className="visted-place-container qa-container">
    <Box className="inner-container-banner qa-inner-banner">
        <Box className="qa-left-sec lft-margin">
        <div className="qa-inner-left">
          <img src={question} alt={question} className="question-img"/>
        </div>
        <div className="qa-inner-right">
         <Text className="qa-heading">
         Could not find information you’re looking for?
         </Text>
         <Text className="qa-desc">
         Experienced tourists and explores are here to help you to make your trip the best.
         </Text>
         <button className="explore-btn-common hlp-them-btn" onClick={()=>history.push('/pak-map')}>
            Ask Your Question
            <RightCircleOutlined />
            </button>
        </div>
       </Box>

       <Box className="qa-right-sec">
        <div className="qa-inner-left">
          <img src={Experience} alt={Experience} className="experience-img"/>
        </div>
        <div className="qa-inner-right">
         <Text className="qa-heading">
         Travel Experiences, opinions and recommendations.
         </Text>
         <Text className="qa-desc">
         Browse hundreds of tourist experiences and reviews about travel places.
         </Text>
         <button className="explore-btn-common hlp-them-btn" onClick={()=>history.push('/pak-map')}>
         Share Your Experience
            <RightCircleOutlined />
            </button>
        </div>
       </Box>
     </Box>
    </Box>
  )
}


