import React from 'react';
import { Box, Heading, Text} from "@chakra-ui/core";
import mapImage from '../../Assets/Pakrism-Mobile-view.png';
import appStore from '../../Assets/Apple Store@2x.png'
import googleStore from '../../Assets/Google Play@2x.png'
import './mobile.css';


export function MobileSection() {
   return (
    <Box className="mobile-container">
    <Box className="inner-container-banner mobile-inner">
        <Box className="travel-places-left-sec mobile-images">
          <div className="mobile-img-container">
          <img src={mapImage} className="mobile-img-left" alt={mapImage}/>
          </div>
          <div className="mobile-img-container-2nd">
          <img src={mapImage} className="mobile-img-right" alt={mapImage} />
          </div>
       </Box>
       <Box className="travel-places-right-sec travel-album-right qa-album-right">
       <Heading className="mobile-headings">
         DOWNLOAD THE APP
        </Heading>
        <Text className="mobile-desc">
        Easy access to Pakistan Tourism anytime, anywhere.
        </Text>
        <Text className="mobile-desc-one">
        Select your device platform and get start download.
        </Text>
        <div className="apple-btn-container">
          <img src={appStore} alt={appStore} className='apple-store-btn' style={{cursor:"pointer"}} onClick={()=>window.open("https://apps.apple.com/app/id1496630730",'_blank')}>
          </img>
          <img src={googleStore} alt={googleStore} className='google-store-btn' style={{cursor:"pointer"}} onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.pakrism")}/>
          </div>
          <div className="apple-btn-container google-btn-container">

          </div>


       </Box>
     </Box>
    </Box>
  )
}


