
import { config } from "../config";
import { alertActions } from "../actions";
import axios from "axios";
let admin = JSON.parse(localStorage.getItem('admin')!);

export const placeServices={
  GetRelatedPlaces,
  AddUserPlaceImage,
  GetUserPlaceImageLimit,
  GetUserAllPlaceImage,
  GetUserAllPlaceImageUserProfile,
  GetUserAllPlaceImageAdmin,
  DeleteUserPhotoAdmin,
  GetPlacesCount,
  fetchAllUserPlacesAdmin,
  GetUserPlaceAdmin
}
function GetRelatedPlaces(placeId:any,id: any) {
  return fetch(`${config.apiurl}/admin/get-related-places/${placeId}/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

 function AddUserPlaceImage(values:any)
{
  return axios
  .post(`${config.apiurl}/user/add-place-images/`, values, {
    // receive two parameter endpoint url ,form data
    validateStatus: () => true,
  })
  .then((res) => {
    return res;
  })
  .catch((err: any) => {
    if (err === "TypeError: Failed to fetch") {
      alertActions.error("Server is not responding");
    } else {
      alertActions.error(err);
    }
  });
}

function GetUserPlaceImageLimit(placeId:any) {
  return fetch(`${config.apiurl}/user/get-place-images-by-place-id/${placeId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function GetUserAllPlaceImage(placeId:any) {
  return fetch(`${config.apiurl}/user/get-all-place-images-by-place-id/${placeId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function fetchAllUserPlacesAdmin(page:any,search:any,pageLimit: any) {
  return fetch(`${config.apiurl}/user/get-user-places-admin/${page}/${search}/${pageLimit}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function GetUserPlaceAdmin(id:any) {
  return fetch(`${config.apiurl}/user/get-user-place-admin/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}


function GetUserAllPlaceImageAdmin(page:any) {
  return fetch(`${config.apiurl}/user/get-admin-place-images/${page}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}


function GetUserAllPlaceImageUserProfile(userId:any) {
  return fetch(`${config.apiurl}/user/get-all-place-images-by-user-Id/${userId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function DeleteUserPhotoAdmin(id: any){
  return fetch(`${config.apiurl}/user/delete-user-place-image/${id}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}



function GetPlacesCount() {
  return fetch(`${config.apiurl}/user/place-count`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + admin.token,
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}
