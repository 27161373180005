export const announcementConstants = {
  GET_ALL_ANNOUNCEMENT_REQUEST: "GET_ALL_FACILITIES_REQUEST",
  GET_ALL_ANNOUNCEMENT_SUCCESS: " GET_ALL_FACILITIES_SUCCESS",
  GET_ALL_ANNOUNCEMENT_FAILURE: "GET_ALL_FACILITIES_FAILURE",

  ADD_ANNOUNCEMENT_REQUEST: "ADD_ACTIVITIES_REQUEST",
  ADD_ANNOUNCEMENT_SUCCESS: " ADD_ACTIVITIES_SUCCESS",
  ADD_ANNOUNCEMENT_FAILURE: "ADD_ACTIVITIES_FAILURE",

  EDIT_ANNOUNCEMENT_REQUEST: " EDIT_PLACE_CATEGORY_REQUEST",
  EDIT_ANNOUNCEMENT_SUCCESS: "EDIT_PLACE_CATEGORY_SUCCESS",
  EDIT_ANNOUNCEMENT_FAILURE: "EDIT_PLACE_CATEGORY_FAILURE",

  UPDATE_ANNOUNCEMENT_REQUEST: " UPDATE_ACTIVITY_REQUEST",
  UPDATE_ANNOUNCEMENT_SUCCESS: "UPDATE_ACTIVITY_SUCCESS",
  UPDATE_ANNOUNCEMENT_FAILURE: "UPDATE_ACTIVITY_FAILURE",

  DELETE_ANNOUNCEMENT_REQUEST: " DELETE_ACTIVITY_REQUEST",
  DELETE_ANNOUNCEMENT_SUCCESS: "UPDATE_ACTIVITY_SUCCESS",
  DELETE_ANNOUNCEMENT_FAILURE: "DELETE_ACTIVITY_FAILURE",

  ACTIVATE_ANNOUNCEMENT_REQUEST: 'ACTIVATE_ANNOUNCEMENT_REQUEST',
  ACTIVATE_ANNOUNCEMENT_SUCCESS: 'ACTIVATE_ANNOUNCEMENT_SUCCESS',
  ACTIVATE_ANNOUNCEMENT_FAILURE: 'ACTIVATE_ANNOUNCEMENT_FAILURE',
};
