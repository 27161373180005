import {announcementConstants} from '../constants';

export function FetchAnnouncement(state={getAllAnnouncementObject:false},action:any)
{

    switch (action.type) {
        case announcementConstants.GET_ALL_ANNOUNCEMENT_REQUEST:
          return state;
          case announcementConstants.GET_ALL_ANNOUNCEMENT_SUCCESS:
          return{
            ...state,
            getAllAnnouncementObject:action.user
          };
      default:
        return state
    }
}

export function FetchEditAnnouncement(state={fetchEditAnnouncementObject:""},action:any)
{
  switch (action.type) {
    case announcementConstants.EDIT_ANNOUNCEMENT_REQUEST:
      return state;
      case announcementConstants.EDIT_ANNOUNCEMENT_SUCCESS:
      return{
        ...state,
        fetchEditAnnouncementObject:action.user
      };
  default:
    return state
}
}
