export const facilitiesConstants = {
  GET_ALL_FACILITIES_REQUEST: "GET_ALL_FACILITIES_REQUEST",
  GET_ALL_FACILITIES_SUCCESS: " GET_ALL_FACILITIES_SUCCESS",
  GET_ALL_FACILITIES_FAILURE: "GET_ALL_FACILITIES_FAILURE",

  ADD_ACTIVITIES_REQUEST: "ADD_ACTIVITIES_REQUEST",
  ADD_ACTIVITIES_SUCCESS: " ADD_ACTIVITIES_SUCCESS",
  ADD_ACTIVITIES_FAILURE: "ADD_ACTIVITIES_FAILURE",

  EDIT_ACTIVITY_REQUEST: " EDIT_PLACE_CATEGORY_REQUEST",
  EDIT_ACTIVITY_SUCCESS: "EDIT_PLACE_CATEGORY_SUCCESS",
  EDIT_ACTIVITY_FAILURE: "EDIT_PLACE_CATEGORY_FAILURE",

  UPDATE_ACTIVITY_REQUEST: " UPDATE_ACTIVITY_REQUEST",
  UPDATE_ACTIVITY_SUCCESS: "UPDATE_ACTIVITY_SUCCESS",
  UPDATE_ACTIVITY_FAILURE: "UPDATE_ACTIVITY_FAILURE",

  DELETE_ACTIVITY_REQUEST: " DELETE_ACTIVITY_REQUEST",
  DELETE_ACTIVITY_SUCCESS: "UPDATE_ACTIVITY_SUCCESS",
  DELETE_ACTIVITY_FAILURE: "DELETE_ACTIVITY_FAILURE",
};
