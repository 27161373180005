import React, { useEffect } from 'react';
import { Button, Form, Input, Select,Upload,message} from 'antd';
import { CloseOutlined } from "@ant-design/icons";
import { history} from '../../Helpers';
import { CloudUploadOutlined } from "@ant-design/icons";
import { connect } from 'react-redux';
import { albumActions, albumCategoryActions} from '../../actions';
import NavBar from '../Navbar/Navbar'
import { config } from "../../config";
import ReactGA from 'react-ga';


const { Dragger } = Upload;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};

const { Option } = Select;
const { TextArea } = Input;
type EditAlbum = {
  dispatch: any;
  match: any;
  albums: any;
  albumCategories: any,
  places: any,
}

const prop = {
  name: 'uploadImages',
  action: '',
};

function beforeUpload(file:any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}


let image: any = [];
let resultArray: any = '';
function EditAlbum(props: EditAlbum) {
  // store.getState().FetchAlbumCategories.getAllAlbumCategoriesObject="";
  const { dispatch, match, albums, albumCategories} = props;
  const [showResults, setShowResults] = React.useState(true)
  const [filterResult, setfilterResult] = React.useState([])

  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    let user = JSON.parse(localStorage.getItem('user')!)
    if (!user) {
      history.push('/login')
    }
    else {
      image = [];
      setfilterResult([])

      dispatch(albumCategoryActions.FetchAllAlbumCategories());
      // dispatch(categoryActions.FetchAllPlaceCategories());
      dispatch(albumActions.FetchEditAlbumAdmin(match.params.id));

    }

  }, [dispatch, match.params.id]);
  const onFinishFailed = (errorInfo: any) => {
  };

  if (!albumCategories) {
    return (<div></div>)
  }
  // if (!places) {
  //   return (<div></div>)
  // }

  if (!albums) {
    return (<div></div>)
  }
  image = [];
    albums[0].albums.image.forEach((element: any) => {
    image.push({
      id: element._id,
      image: config.s3BucketUrl+element.imageName,
      imageDesc: element.imageDesc,
      location: element.location
    })
  });
  const onCloseClick = (id: any) => {

    if (resultArray.length == "0") {

      resultArray = image.filter((item: any) => item.id !== id);
      setfilterResult(resultArray)
      setShowResults(false)
    }
    else {
      resultArray = resultArray.filter((item: any) => item.id !== id)
      setfilterResult(resultArray)
    }
  }

  const onFinish = (values: any) => {

    let imagesArray: any = [];
    if(filterResult.length>0)
    {
      for (let i = 0; i < filterResult.length; i++) {
        imagesArray.push({imageName:image[i].image.substring(48)})
      }
    }
    else
    {
      for (let i = 0; i < image.length; i++) {
        imagesArray.push({imageName:image[i].image.substring(48)})
      }
    }
    const data = new FormData();
    data.append("title",values.title);
    data.append("description",values.description);
    data.append("categories",values.albumcats);
    data.append("oldImages",JSON.stringify(imagesArray));
    if(values.uploadImages)
    {
     values.uploadImages.fileList.forEach((element:any) => {
       data.append("albumImages", element.originFileObj)
      });
    }
    dispatch(albumActions.UpdateAlbumAdmin(data, match.params.id));
  };
  const onChange = () => {

  };

  const onPreview = async (file:any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow:any = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  return (
    <div>
      <NavBar/>
      <div  className="add-album-container">
      <Form
        {...layout}
        className="admin-login-form"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item className="lbl-name" label="Title"></Form.Item>
        <Form.Item
          initialValue={albums[0].albums.title}
          name="title"
          rules={[{ required: true, message: 'Please enter a title.' },]}
        >
          <Input name="title" size="large" placeholder="title" />
        </Form.Item>
        <Form.Item className="lbl-name" label="Category Name"></Form.Item>
        <Form.Item
          hasFeedback
          name="albumcats"
          initialValue={albums[0].albumcategories._id}
          rules={[{ required: true, message: 'Please enter a place.' },]}
        >
          <Select defaultValue={albumCategories[0]._id} placeholder="select a place" size="large" >

            {
              albumCategories.length > 0 ?
                albumCategories.map((item: any) => <Option selected="selected" value={item._id} >
                  {item.categoryName}
                </Option >)
                :
                <Option value="1">data</Option>
            }
          </Select>
        </Form.Item>
        <Form.Item className="lbl-name" label="Description"></Form.Item>
        <Form.Item
          initialValue={albums[0].albums.description}
          name="description"
        >
          <TextArea name="description" placeholder="Description" rows={4}
          />
        </Form.Item>
        <Form.Item className="lbl-name" label="Photo"></Form.Item>
        <Form.Item
         hasFeedback
        name="uploadImages"
        >
        <Dragger
        beforeUpload={beforeUpload}
                onPreview={onPreview}
                onChange={onChange}
                multiple={true}
                {...prop}
                listType="picture-card">

                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined style={{ color: "#000000" }} />
                  <span className="upld-desc" > Upload or drop photo here</span>
                </p>
              </Dragger>

        </Form.Item>

        {
          showResults ?
            image.length > 0 ?
              image.map((item: any, i: any) =>
                <div style={{ display: ' flow-root', boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.16)", marginBottom: '20px',width:"30%"}}>
                  <div style={{ float: 'right', padding: "10px 10px 0px 0px", cursor: 'pointer' }} onClick={() => onCloseClick(item.id)}>
                    <CloseOutlined />
                  </div>
                   <div style={{ width: "90%", float: "left", padding: "40px 10px" }}>
                    <img src={item.image} width="100%" alt={item.image} style={{ textAlign: 'center', marginBottom: "20px" }} />
                  </div>
                 {/* <div style={{ width: "70%", float: 'right' }}>
                  <Form.Item className="lbl-name" label="Image Description"></Form.Item>
                    <Form.Item
                      initialValue={item.imageDesc}
                      name={"desc" + i}
                      rules={[{ required: true, message: 'Please enter a description.' },]}
                    >
                      <Input name={"desc" + i} value={item.imageDesc} size="large" placeholder="image Desc" />
                    </Form.Item>
                    <Form.Item className="lbl-name" label="Image Place"></Form.Item>
                    <Form.Item
                      hasFeedback
                      name={"location" + i}
                      initialValue={item.location}
                      rules={[{ required: true, message: 'Please enter a place.' },]}
                    >
                      <Select defaultValue={item.location} placeholder="select a place" size="large" >

                        {
                          places.length > 0 ?
                            places.map((dat: any) => <Option selected="selected" value={dat._id} >
                              {dat.placeName}
                            </Option >)
                            :
                            <Option value="1">data</Option>
                        }
                      </Select>
                    </Form.Item>
                  </div>*/}
                </div>
              )
              : null
            : filterResult.map((item: any, x: any) =>
              <div style={{ display: ' flow-root', boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.16)", marginBottom: '20px',width:"30%" }}>
                <div style={{ float: 'right', padding: "10px 10px 0px 0px", cursor: 'pointer' }} onClick={() => onCloseClick(item.id)}>
                  <CloseOutlined />
                </div>

                <div style={{ width: "90%", float: "left", padding: "20px" }}>
                  <img src={item.image} width="100%" alt={item.image} style={{ textAlign: 'center', marginBottom: "20px" }} />
                </div>
                {/* <div style={{ width: "70%", float: 'right', padding: '60px' }}>
                  <Form.Item
                    initialValue={item.imageDesc}
                    name={"desc" + x}
                    rules={[{ required: true, message: 'Please enter a title.' },]}
                  >
                    <Input name={"desc" + x} size="large" placeholder="image Desc" />
                  </Form.Item>
                  <Form.Item
                    hasFeedback
                    name={"location" + x}
                    initialValue={item.location}
                    rules={[{ required: true, message: 'Please enter a place.' },]}
                  >
                    <Select defaultValue={item.location} placeholder="select a place" size="large" >

                      {
                        places.length > 0 ?
                          places.map((dat: any) => <Option name={"location" + x} selected="selected" value={dat._id} >
                            {dat.placeName}
                          </Option >)
                          :
                          <Option value="1">data</Option>
                      }
                    </Select>
                  </Form.Item>
                </div> */}
              </div>
            )
        }
        <Form.Item >
          <Button className="btn-admin-login" size="large" type="primary" htmlType="submit">
            Update Album
          </Button>
        </Form.Item>
      </Form>
      </div>
    </div>
  )
}
function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    albums: state.FetchEditAlbum.fetchEditAlbumObject,
    albumCategories: state.FetchAlbumCategories.getAllAlbumCategoriesObject,
    // places: state.FetchPlaceCategories.getAllPlaceCategoriesObject,

  };
}

export const connectedEditAlbumUser = connect(mapStateToProps)(EditAlbum);
