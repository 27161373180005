import {tripConstants} from '../constants';

export function FetchAccessoriesCategories(state={getAllAccessoriesCategoriesObject:false},action:any)
{

    switch (action.type) {
        case tripConstants.GET_ADMIN_ACCESSORIES_CATEGORIES_REQUEST:
          return state;
          case tripConstants.GET_ADMIN_ACCESSORIES_CATEGORIES_SUCCESS:
          return{
            ...state,
            getAllAccessoriesCategoriesObject:action.user
          };
      default:
        return state
    }
}

export function FetchEditAccessoriesCategories(state={fetchEditAccessoriesCategoriesObject:""},action:any)
{
  switch (action.type) {
    case tripConstants.GET_EDIT_ADMIN_ACCESSORIES_CATEGORIES_REQUEST:
      return state;
      case tripConstants.GET_EDIT_ADMIN_ACCESSORIES_CATEGORIES_SUCCESS:
      return{
        ...state,
        fetchEditAccessoriesCategoriesObject:action.user
      };
  default:
    return state
}
}
