import React,{useEffect,useState} from 'react'
import {config} from '../../config'
import NavBar from '../Navbar/Navbar'
import {RightFooter} from '../FooterSection';
import {AdvertiseCard} from '../Cards';
import {placesActions} from '../../actions/places.action'
import {MetaDecorator} from '../../utils';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import {Modal} from 'antd';
import moment from 'moment'


 const PlaceAllPhotos = (props: any) => {
  const {dispatch,match,data} = props;
  const [visible, setVisible] = useState(false);
  const [dataImage, setDataImage] = useState("");
  useEffect(()=>{
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    let id = match.params.id.substr(match.params.id.length-24)
    dispatch(placesActions.GetUserAllPlaceImage(id))
  },[match])

  const handleCancel = (e: any) => {
    setVisible(false)
  };
  const showModal = (image:any) => {
    setDataImage(image)
    setVisible(true);
  }
  if(!data)
  {
   return(<div></div>)
  }
  let finalData={
    title:"Place Photo - Pakrism - "+match.params.id.slice(0, match.params.id.lastIndexOf('-')),
    description:match.params.id.slice(0, match.params.id.lastIndexOf('-')),
    imageAlt:"",
    imageUrl:""

  }
  return (
    <div className="single-question-main-container">
       <MetaDecorator title={finalData.title} description={finalData.description} imageAlt={finalData.imageAlt} imageUrl={finalData.imageUrl} match={match}/>

    <NavBar/>
    <div className="single-question-inner-container">
      <div className="single-question-left-container">
  <div className="photo-container-main">
  <div className="photo-card-container">
  {
    data?
    data.map((item:any,i:any)=>
    <div className="place-user-photo-container">
        <img className="place-img" src={config.s3BucketUrl+item.images} onClick={()=>{showModal(config.s3BucketUrl+item.images)}} />
        <p className="place-photo-card-user-desc">
          <span className="story-card-desc-txt">
            {item.user.firstName+" "+item.user.lastName+" " }
          </span>
          <span className="story-card-tourist-txt">
             {"uploaded on"+" "}
          </span>
          <span className="story-card-timespan-txt">
            {moment(item.createdDate).fromNow() }
          </span>
        </p>
    </div>
    ):null
   }

  </div>
  </div>
  </div>
  <div className="all-question-right-container">
    <div>
    <AdvertiseCard/>
    </div>
    <div className="copyright-footer-container">
    <RightFooter/>
    </div>
  </div>
  </div>
      <Modal
        title="Place Photos"

        className="place-photo-modal"
        closable={true}
        visible={visible}
        onCancel={handleCancel}
        footer={false}
      >
        <div>
        <img className="place-single-img"  src={dataImage}/>
        </div>
      </Modal>
  </div>
  );
};

function mapStateToProps(state: any) {
  return {
    data: state.GetUserAllPlaceImage.getUserAllPlaceImageObject,
  };
}
export const connectedPlaceAllPhotos = connect(mapStateToProps)(PlaceAllPhotos);
