import { alertActions } from "../actions";
import { config } from "../config";
import axios from "axios";
export const interestService = {
  FetchAllInterest,
  AddInterest,
  GetEditInterest,
  UpdateInterest,
  DeleteInterest,

};

function FetchAllInterest() {
  return fetch(`${config.apiurl}/admin/get-interest/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

function AddInterest(values: any) {
  return axios
    .post(`${config.apiurl}/admin/add-interest/`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function GetEditInterest(id: any) {
  return fetch(`${config.apiurl}/admin/get-interest/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function UpdateInterest(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/admin/update-interest/${id}`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function DeleteInterest(id: any) {
  return fetch(`${config.apiurl}/admin/delete-interest/${id}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}
