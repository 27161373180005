import { combineReducers } from 'redux';
import { registration } from './registration.reducer';
import { authentication } from './authentication.reducer';
import {DataReducer,
  FetchUsers,
  FetchEditUser,
  UpdateUser,
  DeActiveUser,
  FetchUserProfile,
  FetchFavPlaces,
  FetchVistedPlaces,
  FetchTopUsers,
  FetchCheckedUser,
  FetchAllUsersAdmin,
  FetchAllCountAdmin,
  FetchAllCountMonth
} from './user.reducer'
import {FetchCategories,FetchEditCategory,
  FetchPlaceCategories,
  FetchEditPlaceCategory,
  FetchPakMapCategories,
  FetchWeather,FetchCurrentWeather
} from './categories.reducer'
import {FetchFacilities,FetchEditActivity} from './activities.reducer';
import {FetchAlbumCategories,FetchEditAlbumCategory} from './albumcategories.reducer';
import {FetchAllStories,FetchEditStories,
  FetchStoryUser,
  FetchTopSotryTeller,
  GetStory,
  GetRelatedStory,
  GetCategoryStory,
  GetMyStory,
  GetDraftStory,
  GetPlaceStory,
  FetchStoryCount
} from './story.reducer';
import {FetchAlbumsAdmin,FetchEditAlbum,FetchAlbumsUser,FetchMyAlbums,FetchLovedAlbums,FetchAlbumsCount} from './album.reducer';
import {FetchEditInterest,FetchInterest} from './interests.reducer';
import {FetchPlaceQuestions,FetchRelatedQuestions,FetchSingleQuestions,FetchUserQuestions,FetchAllQuestions,FetchAllQuestionsAdmin,FetchEditQuestionsAdmin} from './question.reducer';
import {FetchAnswers,FetchUserAnswers,FetchUserAnswersByQuestionAdmin} from './answers.reducer';
import {FetchPlaceExperiences,FetchAllPlaceExperiences,FetchAllPlaceExperiencesAdmin,FetchExperiencesAdmin} from './experiences.reducer';
import {FetchRelatedPlaces,GetUserPlaceImageLimit,
  GetUserAllPlaceImage,GetUserAllPlaceImageAdmin,
  GetUserAllPlaceCountAdmin,
  GetUserAllPlaces,
  GetUserPlace
} from './places.reducer';
import {FetchBlogCategories} from './blog-categories.reducer'
import {FetchBlog} from './blog.reducer';
import {FetchDeviceInfo,FetchDeviceInfoById} from './device-info.reducer'

import {FetchSentFriendRequests,FetchFriends,FetchReceivedFriendRequests} from './friends.reducer';
import {FetchAnnouncement, FetchEditAnnouncement} from './announcement.reducer';
import {FetchHelpForm,FetchReportForm,FetchEditPrivacy,FetchPrivacy,FetchPrivacyAdmin,FetchContactPrivacy} from './support.reducer';
import {FetchAccessoriesCategories, FetchEditAccessoriesCategories} from './trip.reducer';
import {FetchEditTravelAgencies, FetchTravelAgencies} from './travel-agencies.reducer';





import { alert } from './alert.reducer';

const rootReducer = combineReducers({
    alert,
    registration,
    authentication,
    DataReducer,
    FetchUsers,
    FetchEditUser,
    UpdateUser,
    DeActiveUser,
    FetchCategories,
    FetchEditCategory,
    FetchPlaceCategories,
    FetchFacilities,
    FetchEditPlaceCategory,
    FetchEditActivity,
    FetchPakMapCategories,
    FetchAlbumCategories,
    FetchEditAlbumCategory,
    FetchAllStories,
    FetchEditStories,
    FetchAlbumsAdmin,
    FetchEditAlbum,
    FetchAlbumsUser,
    FetchUserProfile,
    FetchEditInterest,
    FetchInterest,
    FetchStoryUser,
    FetchTopSotryTeller,
    GetStory,
    GetRelatedStory,
    GetCategoryStory,
    GetMyStory,
    GetDraftStory,
    FetchMyAlbums,
    FetchLovedAlbums,
    FetchWeather,
    FetchCurrentWeather,
    FetchPlaceQuestions,
    FetchAnswers,
    FetchRelatedQuestions,
    FetchSingleQuestions,
    FetchPlaceExperiences,
    GetPlaceStory,
    FetchRelatedPlaces,
    FetchUserQuestions,
    FetchUserAnswers,
    FetchFavPlaces,
    FetchVistedPlaces,
    FetchBlogCategories,
    FetchSentFriendRequests,
    GetUserPlaceImageLimit,
    GetUserAllPlaceImage,
    FetchFriends,
    FetchAllPlaceExperiences,
    FetchAllQuestions,
    FetchTopUsers,
    FetchHelpForm,
    FetchReportForm,
    FetchEditPrivacy,
    FetchPrivacy,
    FetchPrivacyAdmin,
    FetchContactPrivacy,
    FetchReceivedFriendRequests,
    FetchCheckedUser,
    FetchBlog,
    FetchAllUsersAdmin,
    GetUserAllPlaceImageAdmin,
    FetchAllQuestionsAdmin,
    FetchEditQuestionsAdmin,
    FetchUserAnswersByQuestionAdmin,
    GetUserAllPlaceCountAdmin,
    FetchAllCountAdmin,
    FetchStoryCount,
    FetchAllCountMonth,
    FetchAlbumsCount,
    FetchAllPlaceExperiencesAdmin,
    FetchExperiencesAdmin,
    FetchDeviceInfo,
    FetchDeviceInfoById,
    FetchAnnouncement,
    FetchEditAnnouncement,
    FetchAccessoriesCategories,
     FetchEditAccessoriesCategories,
     GetUserAllPlaces,
     GetUserPlace,
     FetchEditTravelAgencies,
      FetchTravelAgencies
  });

  export default rootReducer;
