import React,{useEffect} from 'react';
import {config} from '../../config'
import moment from 'moment'
export const UserPhotoCard =(props:any)=>{
  const {data} = props;
  useEffect(()=>{

  },[])
  return(
    <div>
      <div className="place-img-container">
        <img className="place-img" src={config.s3BucketUrl+data.images}  alt={data.images}/>
        <div className="user-place-photo-container">
        <p className="user-place-photo-desc">
          <p className="story-card-desc-txt" style={{color:"#fff"}}>
            {data.place.placeName+" " }
          </p>
          <span className="story-card-tourist-txt" style={{color:"#fff"}}>
             {"uploaded on"+" "}
          </span>
          <span className="story-card-timespan-txt" style={{color:"#fff"}}>
            {moment(data.createdDate).fromNow() }
          </span>
        </p>
        </div>
    </div>
    </div>
  )

}
