import React, { useEffect } from "react";
import NavBar from "../Navbar/Navbar";
import { Avatar} from "@chakra-ui/core";
import {Hero} from '../HeroSection'
import {RightFooter} from '../FooterSection';
import ContentLoader from 'react-content-loader'
import ReactGA from 'react-ga';
import {history} from '../../Helpers'
import {userActions,friendsActions} from '../../actions'
import {MemberUserCard} from '.'
import {AdvertiseCard} from '../Cards'
import { connect } from 'react-redux';
import {MetaDecorator} from '../../utils'
import "./membes.css";


let meta={
  title:"Tourists",
  description:"Connect with the tourists like you and Tripmate them to plan your next trip.",
  imageAlt:"tourists banner",
  imageUrl:"social-share-image.png"
}

type MembersPage ={
    dispatch:any;
    data:any;
    allUsers:any;
    friends:any;
    sentRequests:any;
    receivedRequests:any;
    match:any;
}


function MembersPage(props:MembersPage) {
    const {data,dispatch,allUsers,friends,sentRequests,match,receivedRequests} = props;
  let user = JSON.parse(localStorage.getItem("user")!);
  useEffect(()=>{
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
      if(user)
      {
        dispatch(friendsActions.GetFriends(user.user.userName))
        dispatch(friendsActions.GetSentRequests(user.id));
        dispatch(friendsActions.GetReceivedRequests(user.id));
        dispatch(userActions.FetchTopUsers());
        dispatch(userActions.fetchAllUsers());
      }
      else
      {
        history.push('/login')
      }

  },[])

  const MyLoader = () => (
    <ContentLoader
    viewBox="0 0 380 100">
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="350" />
    </ContentLoader>
  );
  const LeftLoader = () => (
    <ContentLoader
    viewBox="0 0 500 550">
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="550" />
    </ContentLoader>
  )
  const RighLoader=()=>(
    <ContentLoader
    viewBox="0 0 500 650">
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="650" />
  </ContentLoader>
  )

  if(!data)
  return(
    <div >
    <div style={{width:'100%'}}>
    <MyLoader/>
    </div>
    <div style={{maxWidth:'1366px',margin:'0px auto'}}>
      <div style={{marginTop:'30px',width:'75%',float:'left'}}>
      <div style={{width:'30%', padding:'5px',float:'left'}}>
    <LeftLoader/>
    </div>
    <div  style={{width:'30%',padding:'5px',float:'left'}}>
    <LeftLoader/>
    </div>
    <div  style={{width:'30%',padding:'5px',float:'left'}}>
    <LeftLoader/>
    </div>
      </div>
      <div style={{marginTop:'30px',width:'25%',float:'left',padding:'16px'}}>
        <RighLoader/>
      </div>
    </div>
  </div>
  )
  if(!allUsers)
  {
    return(<div></div>)
  }
  if(!friends)
  {
    return(<div></div>)
  }
  return (
    <div className="members-container">
      <MetaDecorator title={meta.title} description={meta.description} imageAlt={meta.imageAlt} imageUrl={meta.imageUrl} match={match}/>

      <NavBar />
      <Hero fromParent="members" allUsers={allUsers}/>
      <div className="members-inner-container">

        <div className="members-left-container">
          {
            allUsers && friends && sentRequests && receivedRequests?
            <MemberUserCard allUsers={allUsers} friends={friends} sentRequests={sentRequests} receivedRequests={receivedRequests}/>
            :null
          }

        </div>
        <div className="members-ad-container">
         <div className="stories-advertise-container">
             <AdvertiseCard/>
         </div>
       <div className="top-story-taller-container">
         <p className="top-story-taller-heading">
        Top  Traveller
         </p>
         <div>
           <ul className="top-story-teller-list">
           {
             data?
            data.map((item: any) =>
           <li className="top-story-teller-list-item" >
           <div className="top-travler-inner-container" onClick={() => {history.push(`/tourist/${item.userName}/about`)}}>
              <div className="top-user-avatar-container">
                <Avatar
                  name={item.firstName+" "+item.lastName}
                  size="xl"
                  className="question-avatar"
                  src={item.image}
                ></Avatar>
              </div>
              <div className="top-user-txt-container">
                 <p className="top-users-headings">{item.firstName+" "+item.lastName}</p>
              </div>
            </div>
           </li>
           )
           :null
          }
           </ul>
         </div>
       </div>
       <div className="copyright-footer-container">
       <RightFooter/>
       </div>
        </div>


      </div>
    </div>
  );
}

function mapStateToProps(state: any) {
    return {
      data: state.FetchTopUsers.fetchTopUsersObject,
      allUsers: state.FetchUsers.getAllUserObject,
      friends:state.FetchFriends.getFriendsObject,
      sentRequests:state.FetchSentFriendRequests.getSentFriendRequestsObject,
      receivedRequests:state.FetchReceivedFriendRequests.getReceivedFriendRequestsObject,

    };
  }
  export const connectedMembersPage = connect(mapStateToProps)(MembersPage);
