import React, { useEffect, useState } from 'react';
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import './cards.css'
export function Tab(props: any) {
  const { location, match } = props;
  useEffect(() => {
  }, [])
  return (

    <div className="tab-container">
      <Menu theme="light" mode="horizontal" defaultSelectedKeys={[location.pathname]} selectedKeys={[location.pathname]} >
        {/* <Menu.Item key={`/${match.params.userName}/news-feed`}>
          <Link to={`/${match.params.userName}/news-feed`}>
            Activity feed
          </Link>
        </Menu.Item> */}
        <Menu.Item key={`/tourist/${match.params.userName}/about`}>
          <Link to={`/tourist/${match.params.userName}/about`}>
            About
          </Link>
        </Menu.Item>
        <Menu.Item key={`/tourist/${match.params.userName}/friends`} >
          <Link to={`/tourist/${match.params.userName}/friends`}>
          Friends
          </Link>
        </Menu.Item>
        <Menu.Item key={`/tourist/${match.params.userName}/places`} >
          <Link to={`/tourist/${match.params.userName}/places`}>
          Places
          </Link>
        </Menu.Item>
        <Menu.Item key={`/tourist/${match.params.userName}/photos`} >
          <Link to={`/tourist/${match.params.userName}/photos`}>
          Photos
          </Link>
        </Menu.Item>
        <Menu.Item key={`/tourist/${match.params.userName}/stories`} >
          <Link to={`/tourist/${match.params.userName}/stories`}>
          Stories
          </Link>
        </Menu.Item>
        <Menu.Item key={`/tourist/${match.params.userName}/albums`} >
          <Link to={`/tourist/${match.params.userName}/albums`}>
          Albums
          </Link>
        </Menu.Item>
        <Menu.Item key={`/tourist/${match.params.userName}/questions`} >
          <Link to={`/tourist/${match.params.userName}/questions`}>
          Questions
          </Link>
        </Menu.Item>
        <Menu.Item key={`/tourist/${match.params.userName}/answers`} >
          <Link to={`/tourist/${match.params.userName}/answers`}>
          Answers
          </Link>
        </Menu.Item>
      </Menu>
      {/* <div className="inner-container-tab">



               <span className="tab-menu" >
                <Link  to="/user/">

               </Link>
               </span>
               <span className="tab-menu" >
                <Link  to="/user/">

               </Link>
               </span>
               <span className="tab-menu" >
                <Link  to="/user/">

               </Link>
               </span>
               <span className="tab-menu" >
                ...
               </span>
          </div> */}
    </div>
  )
}
