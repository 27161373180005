export const questionConstants = {

  ADD_QUESTION_REQUEST: ' ADD_QUESTION_REQUEST',
  ADD_QUESTION_SUCCESS: 'ADD_QUESTION_SUCCESS',
  ADD_QUESTION_FAILURE: 'ADD_QUESTION_FAILURE',

  GET_ALL_QUESTION_REQUEST: ' GET_ALL_QUESTION_REQUEST',
  GET_ALL_QUESTION_SUCCESS: 'GET_ALL_QUESTION_SUCCESS',
  GET_ALL_QUESTION_FAILURE: 'GET_ALL_QUESTION_FAILURE',

  GET_SINGLE_QUESTION_REQUEST: ' GET_SINGLE_QUESTION_REQUEST',
  GET_SINGLE_QUESTION_SUCCESS: 'GET_SINGLE_QUESTION_SUCCESS',
  GET_SINGLE_QUESTION_FAILURE: 'GET_SINGLE_QUESTION_FAILURE',

  GET_ALL_PLACE_QUESTION_REQUEST: ' GET_ALL_PLACE_QUESTION_REQUEST',
  GET_ALL_PLACE_QUESTION_SUCCESS: 'GET_ALL_PLACE_QUESTION_SUCCESS',
  GET_ALL_PLACE_QUESTION_FAILURE: 'GET_ALL_PLACE_QUESTION_FAILURE',

  GET_QUESTION_REQUEST: ' GET_QUESTION_REQUEST',
  GET_QUESTION_SUCCESS: 'GET_QUESTION_SUCCESS',
  GET_QUESTION_FAILURE: 'GET_QUESTION_FAILURE',

  GET_RELATED_QUESTION_REQUEST: ' GET_RELATED_QUESTION_REQUEST',
  GET_RELATED_QUESTION_SUCCESS: 'GET_RELATED_QUESTION_SUCCESS',
  GET_RELATED_QUESTION_FAILURE: 'GET_RELATED_QUESTION_FAILURE',

  GET_USER_QUESTION_REQUEST: ' GET_USER_QUESTION_REQUEST',
  GET_USER_QUESTION_SUCCESS: 'GET_USER_QUESTION_SUCCESS',
  GET_USER_QUESTION_FAILURE: 'GET_USER_QUESTION_FAILURE',


  GET_ALL_QUESTION_ADMIN_REQUEST: 'GET_ALL_QUESTION_ADMIN_REQUEST',
  GET_ALL_QUESTION_ADMIN_SUCCESS: 'GET_ALL_QUESTION_ADMIN_SUCCESS',
  GET_ALL_QUESTION_ADMIN_FAILURE: 'GET_ALL_QUESTION_ADMIN_FAILURE',

  GET_EDIT_QUESTION_ADMIN_REQUEST: 'GET_EDIT_QUESTION_ADMIN_REQUEST',
  GET_EDIT_QUESTION_ADMIN_SUCCESS: 'GET_EDIT_QUESTION_ADMIN_SUCCESS',
  GET_EDIT_QUESTION_ADMIN_FAILURE: 'GET_EDIT_QUESTION_ADMIN_FAILURE',

  UPDATE_QUESTION_ADMIN_REQUEST: 'UPDATE_QUESTION_ADMIN_REQUEST',
  UPDATE_EDIT_QUESTION_ADMIN_SUCCESS: 'UPDATE_EDIT_QUESTION_ADMIN_SUCCESS',
  UPDATE_EDIT_QUESTION_ADMIN_FAILURE: 'UPDATE_EDIT_QUESTION_ADMIN_FAILURE',

  DELETE_QUESTION_ADMIN_REQUEST: 'DELETE_QUESTION_ADMIN_REQUEST',
  DELETE_QUESTION_ADMIN_SUCCESS: 'DELETE_QUESTION_ADMIN_SUCCESS',
  DELETE_QUESTION_ADMIN_FAILURE: 'DELETE_QUESTION_ADMIN_FAILURE',

};
