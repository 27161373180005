import {interestConstants} from '../constants';


export function FetchInterest(state={getAllInterestObject:false},action:any)
{

    switch (action.type) {
        case interestConstants.GET_ALL_INTEREST_REQUEST:
          return state;
          case interestConstants.GET_ALL_INTEREST_SUCCESS:
          return{
            ...state,
            getAllInterestObject:action.user
          };
      default:
        return state
    }
}

export function FetchEditInterest(state={fetchEditInterestObject:false},action:any)
{
  switch (action.type) {
    case interestConstants.EDIT_INTEREST_REQUEST:
      return state;
      case interestConstants.EDIT_INTEREST_SUCCESS:
      return{
        ...state,
        fetchEditInterestObject:action.user
      };
  default:
    return state
}
}
