import { config } from "../config";
import { alertActions } from "../actions";
import axios from "axios";
let admin = JSON.parse(localStorage.getItem('admin')!);
export const AlbumService = {
  FetchAllAlbumsAdmin,
  GetEditAlbumAdmin,
  UpdateAlbumAdmin,
  DeleteAlbumAdmin,
  FetchAllAlbumsUser,
  GetMyAlbums,
  FetchLovedAlbums,
  AddAlbum,
  DeleteAlbum,
  AddAlbumComment,
  AddAlbumLike,
  RemoveAlbumLike,
  FeaturedAlbum,
  fetchAlbumsCount
};

function AddAlbum(values: any) {
  return axios
    .post(`${config.apiurl}/user/add-album/`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function FetchAllAlbumsAdmin() {
  return fetch(`${config.apiurl}/user/get-albums-admin/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

function GetEditAlbumAdmin(id: any) {
  return fetch(`${config.apiurl}/user/get-albums/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      console.log(ree);
      return ree;
    });
}

function UpdateAlbumAdmin(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/user/update-album/${id}`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function DeleteAlbumAdmin(id: any) {
  return fetch(`${config.apiurl}/user/delete-album-admin-1/${id}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function FetchAllAlbumsUser() {
  return fetch(`${config.apiurl}/user/get-albums/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}


function GetMyAlbums(id: any) {
  return fetch(`${config.apiurl}/user/get-albums-by-user-id/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function FetchLovedAlbums() {
  return fetch(`${config.apiurl}/user/get-popular-albums/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

function DeleteAlbum(values: any, id: any) {
  let data={
    reason:values,
  }

  return axios
    .patch(`${config.apiurl}/user/delete-album/${id}`,data, {
      validateStatus: () => true,
    })
    .then((ree) => {
      return ree;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function AddAlbumComment(values: any,id:any) {
  return axios
    .patch(`${config.apiurl}/user/add-album-comment/${id}`, values, {
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function AddAlbumLike(values: any,id:any) {
  return axios
    .patch(`${config.apiurl}/user/add-album-like/${id}`, values, {
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}


function RemoveAlbumLike(values: any,id:any) {
  return axios
    .patch(`${config.apiurl}/user/remove-album-like/${id}`, values, {
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function FeaturedAlbum(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/user/featured-album/${id}`, values, {
      validateStatus: () => true,
      // receive two parameter endpoint url ,form data
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err.toString());
      }
    });
}

function fetchAlbumsCount() {
  return fetch(`${config.apiurl}/user/album-count/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
       'Authorization': 'Bearer ' + admin.token,
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}
