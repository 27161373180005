import { alertActions } from "../actions";
import { config } from "../config";
import axios from "axios";

export const answersService = {
  FetchAnswersByQuestion,
  AddAnswers,
  FetchUserAnswers,
  FetchAnswersByQuestionAdmin,
  DeleteAnswerAdmin
};

function FetchAnswersByQuestion(id:any) {
  return fetch(`${config.apiurl}/user/get-answers-by-question/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}


function FetchAnswersByQuestionAdmin(id:any) {
  return fetch(`${config.apiurl}/user/get-answers-by-question-admin/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}


function FetchUserAnswers(id:any) {
  return fetch(`${config.apiurl}/user/get-answers-user/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    })
    .catch((err) => {
      return err;
    });
}

function DeleteAnswerAdmin(values: any, id: any) {
  return axios
    .patch(`${config.apiurl}/user/delete-answers-admin/${id}`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}


function AddAnswers(values: any) {
  return axios
    .post(`${config.apiurl}/user/add-answers/`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}
