import React, { useEffect, useState } from 'react';
import NavBar from '../Navbar/Navbar'
import {UpperFooterSection,Footer} from '../FooterSection';
import {Form,Input,Select,Button} from 'antd';
import { connect } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import ReactGA from 'react-ga';
import {SupportActions} from '../../actions'
import {Avatar} from '@chakra-ui/core';
import {config} from '../../config'
import noUser from '../../Assets/no-user.png'
 import './support.css'

 const { Option } = Select;
 const { TextArea } = Input;
 type ReportPage={
  dispatch:any;
}

 function ReportPage(props:ReportPage) {
  let formRef:any = React.createRef<FormInstance>();
  const {dispatch} = props;
  let user = JSON.parse(localStorage.getItem('user')!)
  const [img,setImg] = useState('');

  useEffect(()=>{
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    if(user)
    {
      setImg(config.s3BucketUrl+user.user.image)
    }
    else
    {
      setImg(noUser)
    }
  },[])

  const onFinish = (values:any) => {
    let data={
      firstName:values.firstName,
       lastName:values.lastName,
       email:values.email,
      phone:values.mobile,
       category:values.category,
      message:values.message
     };
     dispatch(SupportActions.AddReportRequest(data));
    formRef.current.resetFields()
  };
  return (
    <div>
      <NavBar />
      <div className="suppoert-main-container">
      <div className="support-form-container">
      <div className="support-form-header-container">
          <p className="support-form-heading">
          Did you encounter a problem?
          </p>
          <p className="support-form-desc">
          Please fill the form below to report it. We’ll do our best to resolve it and provide feedback.
          </p>
          <div className="support-form-avatar-container">
            {
              user?
               <Avatar className="support-form-avatar" size="2xl" src={img} name={user.firstName+" "+user.lastName}/>
              : <Avatar className="support-form-avatar" size="2xl" src={img} name="No Image"/>
            }

          </div>
        </div>
      <Form
       ref={formRef}
      className="support-form" name="complex-form" onFinish={onFinish} labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
      <Form.Item>
      {
          user?
          <Form.Item
          name="firstName"
          hasFeedback
          initialValue={user.firstName}
          rules={[{ required: true }]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <Input size="large" placeholder="First name" />
        </Form.Item>:
         <Form.Item
         name="firstName"
         hasFeedback
         rules={[{ required: true }]}
         style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
       >
         <Input size="large" placeholder="First name" />
       </Form.Item>
        }
          {
            user?
            <Form.Item
            hasFeedback
              name="lastName"
              initialValue={user.lastName}
              rules={[{ required: true }]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '15px' }}
            >
              <Input size="large" placeholder="Last name" />
            </Form.Item>:
             <Form.Item
             hasFeedback
               name="lastName"
               rules={[{ required: true }]}
               style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '15px' }}
             >
               <Input size="large" placeholder="Last name" />
             </Form.Item>
          }
        </Form.Item>
        <Form.Item>
          {
            user?
            <Form.Item
            hasFeedback
            initialValue={user.email}
              name="email"
              rules={[{ required: true }]}
            >
              <Input size="large" placeholder="email" />
            </Form.Item>
            :
            <Form.Item
            hasFeedback
              name="email"
              rules={[{ required: true }]}
            >
              <Input size="large" placeholder="email" />
            </Form.Item>

          }
          </Form.Item>

        <Form.Item>
          {
            user?
            <Form.Item
            initialValue={ user.user.phone}
            hasFeedback
              name="mobile"
              rules={[{ required: true }]}
            >
              <Input size="large" placeholder="Mobile" />
            </Form.Item>:
              <Form.Item
              hasFeedback
                name="mobile"
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="Mobile" />
              </Form.Item>
          }

        </Form.Item>
        <Form.Item>
        <Form.Item
        hasFeedback
          name="category"
          rules={[{ required: true }]}
        >
         <Select size="large" placeholder="I need help with">
            <Option value="User">A User</Option>
            <Option value="Pakistan Map">Pakistan Map</Option>
            <Option value="Travel Place">Travel Place</Option>
            <Option value="Travel Story">Travel Story</Option>
            <Option value="Travel Album">Travel Album</Option>
            <Option value="Question & Answer">Question & Answer</Option>
            <Option value="Experience">Experience</Option>
            <Option value="Photos">Photos</Option>
            <Option value="Comment">Comment</Option>
           </Select>
        </Form.Item>
        </Form.Item>
        <Form.Item
          name="message"
          hasFeedback
          rules={[{ required: true }]}
        >
          <TextArea name="message" placeholder="Write your message here..." rows={4}
          />
        </Form.Item>
        <Form.Item
        >
           <Button className="btn-support-form" type="primary"  size="large" htmlType="submit">Submit</Button>
        </Form.Item>
        </Form>
      </div>
      </div>
      <div className="user-dashboard-footer">
         <UpperFooterSection/>
         <Footer/>
       </div>
    </div>
  )
}

export const connectedReportPage = connect()(ReportPage);
