import React, { useState, useEffect } from 'react';
import { history } from '../../../Helpers';
import { Layout, Menu, Dropdown, Avatar } from 'antd';
import { Route, Switch } from 'react-router-dom';
import { config } from "../../../config";
import ReactGA from 'react-ga';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  AreaChartOutlined,
  ProjectOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { connectedUsers} from '../Users';
import { connectedAnalytics} from '../analytics';
import { connectedEditUser } from '../EditUser';
import { userActions } from '../../../actions';
import { connectedCategories, connectedEditCategories } from '../Categories';
import { connectedPlaceCategories, connectedEditPlaceCategories } from '../Places';
import { connectedActivities, connectedEditActivity } from '../Activities';
import { connectedAlbumCategories, connectedEditAlbumCategories } from '../AlbumCategories';
import { connectedStories, connectedEditStories } from '../Stories';
import { connectedAlbums, connectedEditAlbum } from '../Albums';
import {connectedInterests ,connectedEditInterest} from '../Interest';
import {connectedBlogCategories} from '../BlogCategories';
import {connectedBlog} from '../blog';

import {connectedHelp,connectedReport,connectedPrivacyTerms,connectedEditPrivacy,connectedContact} from '../Support';
import {connectedAdminUserPhotos} from '../user-photos';
import {connectedAdminUserQuestions,connectedEditQuestion} from '../Questions';
import {connectedEditExperience,connectedExperience} from '../Experiences';
import {connectedDevoiceInfo,connectedEditDeviceInfo} from '../Device-Info';
import {connectedAnnouncement,connectedEditAnnouncement} from '../Announcement';
import {connectedAccessoriesCategories,connectedEditAccessoriesCategories} from '../accessories-categories';
import {connectedUserPlaces,connectedEditUserPlace} from '../user-places';
import {connectedTravelAgencies,connectedEditTravelAgency} from '../travel-agencies';









import './dashboard.css';

type DashBoard = {
  dispatch: any;
  data: any;
  location: any

}

const { SubMenu } = Menu;

function DashBoard(props: DashBoard) {
  const { data, dispatch, location } = props;

  const [collapsed, setcollapsed] = useState(false);
  const toggle = () => {
    setcollapsed(!collapsed)
  }
  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    let user = JSON.parse(localStorage.getItem('admin')!)
    if (!user) {
      history.push('/pr-admin-cp/login')


    }
    else {
      if (user.role[0].split(',')[0] != "admin") {
        history.push('/login')
      }
      else {
        dispatch(userActions.getCurrentUser(user.userId));
      }

    }

  }, [dispatch]);
  const onLogOut = () => {
    localStorage.removeItem('admin');

    history.push('/pr-admin-cp/login');
  }

  const onNavigate = (e: any) => {
    history.push(e.key)
  }

  const userMenu = (
    <Menu onClick={onLogOut}>
      <Menu.Item key="Lg" className="user-menu-item">
        <span>
          Logout
        </span>
      </Menu.Item>
    </Menu>
  );
  if (!data) {
    return (<div></div>)
  }
  const image = config.apiurl + "/" + data[0].image;
  const { Header, Sider, Content } = Layout;
  return (
    <Switch>
      <React.Fragment>
        <Layout>
          <Sider trigger={null} collapsible collapsed={collapsed}>
            {/* <div className="logo" >
                <IconFont  type="icon-java"/>
               </div> */}
            <Menu theme="light" mode="inline" onClick={onNavigate} defaultSelectedKeys={[location.pathname]} selectedKeys={[location.pathname]}>
            <Menu.Item key='/pr-admin-cp/analytics' icon={<AreaChartOutlined />}>
                   Analytics
                 </Menu.Item>
              <Menu.Item key='/pr-admin-cp/users' icon={<UserOutlined />}>
                manage users
                 </Menu.Item>
              <Menu.Item key="/pr-admin-cp/categories" icon={<VideoCameraOutlined />}>
                Categories
                 </Menu.Item>
                 <SubMenu key="Places" icon={<VideoCameraOutlined />} title="Places">
              <Menu.Item key="/pr-admin-cp/places" icon={<UploadOutlined />}>
                Places
               </Menu.Item>
               <Menu.Item  disabled key="/pr-admin-cp/user-places" icon={<UserOutlined />}>
                User Places
               </Menu.Item>
                 </SubMenu>
                 <Menu.Item disabled  key='/pr-admin-cp/travel-agencies' icon={<UserOutlined />}>
                   Travel Agencies
                 </Menu.Item>
                 <Menu.Item key="/pr-admin-cp/announcement" icon={<VideoCameraOutlined />}>
                   Announcement
                 </Menu.Item>
              <Menu.Item key="/pr-admin-cp/activities" icon={<UploadOutlined />}>
                Activities
                 </Menu.Item>
              <Menu.Item key="/pr-admin-cp/album-categories" icon={<VideoCameraOutlined />}>
                Album Categories
                 </Menu.Item>
              <Menu.Item key='/pr-admin-cp/stories' icon={<UserOutlined />}>
                Stories
                 </Menu.Item>
              <Menu.Item key='/pr-admin-cp/albums' icon={<UserOutlined />}>
                Albums
                 </Menu.Item>
                 <Menu.Item key='/pr-admin-cp/interests' icon={<UserOutlined />}>
                   Interests
                 </Menu.Item>
                 <Menu.Item key="/pr-admin-cp/user-photos" icon={<VideoCameraOutlined />}>
                  User Photos
                 </Menu.Item>
                 <Menu.Item key="/pr-admin-cp/user-questions" icon={<UploadOutlined />}>
                Questions
                 </Menu.Item>
                 <Menu.Item key="/pr-admin-cp/experience" icon={<ProjectOutlined />}>
                Experience
                 </Menu.Item>
                 <Menu.Item key="/pr-admin-cp/device-info" icon={<UploadOutlined />}>
                Device Information
                 </Menu.Item>
                 {/* <Menu.Item key="/pr-admin-cp/blog-categories" icon={<VideoCameraOutlined />}>
                    Blog Categories
                 </Menu.Item>
                 <Menu.Item key="/pr-admin-cp/blog" icon={<VideoCameraOutlined />}>
                    Blog
                 </Menu.Item> */}
                  <Menu.Item disabled key="/pr-admin-cp/accessories-categories" icon={<VideoCameraOutlined />}>
                  Accessories Categories
                 </Menu.Item>
              <SubMenu key="Support" icon={<VideoCameraOutlined />} title="Support">
              <Menu.Item key="/pr-admin-cp/help">Help</Menu.Item>
              <Menu.Item key="/pr-admin-cp/report">Report</Menu.Item>
              <Menu.Item key="/pr-admin-cp/privacy-and-terms">Privacy and terms</Menu.Item>
              <Menu.Item key="/pr-admin-cp/contact-us">ContactUs</Menu.Item>
            </SubMenu>

            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Header className="site-layout-background" style={{ padding: 0 }}>
              {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: toggle,
              })}
              <Dropdown className="admin-acc" overlay={userMenu} trigger={['click']} >
                <span className="acc-drop-down"><Avatar className="avatar-user-header" src={image} /><span className="user-name">{data[0].firstName} {data[0].lastName}</span></span>
              </Dropdown>
            </Header>
            <Content
              className="site-layout-background"
              style={{
                margin: '24px 16px',
                padding: 24,
                minHeight: 280,//
              }}
            >
              <div >
                <Switch>
                <Route path="/pr-admin-cp/edit-travel-agency/:id" component={connectedEditTravelAgency} />
                <Route path="/pr-admin-cp/travel-agencies" component={connectedTravelAgencies} />
                <Route path="/pr-admin-cp/edit-user-place/:id" component={connectedEditUserPlace} />
                <Route path="/pr-admin-cp/user-places" component={connectedUserPlaces} />
                <Route path="/pr-admin-cp/edit-accessories-categories/:id" component={connectedEditAccessoriesCategories} />
                <Route path="/pr-admin-cp/accessories-categories" component={connectedAccessoriesCategories} />
                <Route path="/pr-admin-cp/edit-announcement/:id" component={connectedEditAnnouncement} />
                <Route path="/pr-admin-cp/announcement" component={connectedAnnouncement} />
                <Route path="/pr-admin-cp/edit-device-info/:id" component={connectedEditDeviceInfo} />
                <Route path="/pr-admin-cp/device-info" component={connectedDevoiceInfo} />
                <Route path="/pr-admin-cp/edit-experience/:id" component={connectedEditExperience} />
                <Route path="/pr-admin-cp/experience" component={connectedExperience} />
                <Route path="/pr-admin-cp/analytics" component={connectedAnalytics} />
                <Route path="/pr-admin-cp/edit-questions/:id" component={connectedEditQuestion} />
                <Route path="/pr-admin-cp/user-questions" component={connectedAdminUserQuestions} />
                <Route path="/pr-admin-cp/user-photos" component={connectedAdminUserPhotos} />
                <Route path="/pr-admin-cp/blog" component={connectedBlog} />
                <Route path="/pr-admin-cp/contact-us" component={connectedContact} />
                <Route path="/pr-admin-cp/privacy-and-terms/:id" component={connectedEditPrivacy} />
                <Route path="/pr-admin-cp/privacy-and-terms" component={connectedPrivacyTerms} />
                <Route path="/pr-admin-cp/help" component={connectedHelp} />
                <Route path="/pr-admin-cp/report" component={connectedReport} />
                <Route path="/pr-admin-cp/blog-categories" component={connectedBlogCategories} />
                <Route path="/pr-admin-cp/edit-interest/:id" component={connectedEditInterest} />
                <Route path="/pr-admin-cp/interests" component={connectedInterests} />
                  <Route path="/pr-admin-cp/edit-albums/:id" component={connectedEditAlbum} />
                  <Route path="/pr-admin-cp/albums" component={connectedAlbums} />
                  <Route path="/pr-admin-cp/edit-story/:id" component={connectedEditStories} />
                  <Route path="/pr-admin-cp/stories" component={connectedStories} />
                  <Route path="/pr-admin-cp/edit-album-categories/:id" component={connectedEditAlbumCategories} />
                  <Route path="/pr-admin-cp/album-categories" component={connectedAlbumCategories} />
                  <Route path="/pr-admin-cp/users/" component={connectedUsers} />
                  <Route path="/pr-admin-cp/edit-user/:id" component={connectedEditUser} />
                  <Route path="/pr-admin-cp/categories" component={connectedCategories} />
                  <Route path="/pr-admin-cp/edit-categories/:id" component={connectedEditCategories} />
                  <Route path="/pr-admin-cp/places" component={connectedPlaceCategories} />
                  <Route path="/pr-admin-cp/edit-place/:id" component={connectedEditPlaceCategories} />
                  <Route path="/pr-admin-cp/activities" component={connectedActivities} />
                  <Route path="/pr-admin-cp/edit-activities/:id" component={connectedEditActivity} />
                </Switch>
              </div>
            </Content>
          </Layout>
        </Layout>
      </React.Fragment>
    </Switch>

  )
}

function mapStateToProps(state: any) {
  const { loggingIn } = state.authentication;
  const { type, message } = state.alert;
  return {
    type,
    message,
    loggingIn,
    data: state.DataReducer.retrievedObject
  };
}
export const connectedDashBoard = connect(mapStateToProps)(DashBoard);

