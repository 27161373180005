import React  from 'react';
import {Box,Avatar} from '@chakra-ui/core'
import './storycard.css';
import {config} from '../../config';
import {HeartTwoTone,HeartOutlined } from '@ant-design/icons';

const TimeStamp =require("react-timestamp");


type StoryCard={
  data:any;
};

export function StoryCard(props:StoryCard)
{
  let heart:any= (<HeartOutlined />)
  const {data} =props;
   let user = JSON.parse(localStorage.getItem('user')!)
      let story=data.stories.story;
      if(data.storylikes.user===user.id)
      {
        heart =<HeartTwoTone twoToneColor="#eb2f96" />
      }

  return (
    <Box>
     <p className="travel-story-heading">Travel stories</p>
     <Box className="story-card-stories">
       <Box className="inner-story-card-container">
      <Box className="story-card-user-avatar">
        <Avatar size="xl" name={data.users.firstName+" "+data.users.lastName} src={config.s3BucketUrl+data.users.image}/>

      </Box>
      <Box className="story-card-posted-desc-container">

  <p className="story-card-upper-text"><span className="story-card-user-name">{data.users.firstName+" "+data.users.lastName}</span> <span className="story-card-static-text">published in</span>
  <span className="story-card-place-name"> {data.places.placeName}</span>
       </p>
       <p>
  <span className="story-card-place-name">{data.categories.categoryName}</span> <span className="story-card-static-text"><TimeStamp relative date={data.stories.createdDate} autoUpdate /></span>
       </p>
      </Box>
    </Box>
    <Box className="story-card-story-title-container">
       <p className="story-card-story-title">
        {data.stories.title}
       </p>
      </Box>
      <Box className="story-card-story-desc-container">
      <div className="story-card-story-desc single-story-desc" dangerouslySetInnerHTML= {{__html:story}}>
             </div>
      </Box>
      <Box className="story-card-story-footer-container">
       <ul className="story-card-story-footer-list">
         <li className="story-card-story-footer-list-item">
           <div className="heart-container-story-card">
           {heart}
           </div>
            <div className="story-card-like-count">1</div>
         </li>
         <li className="story-card-story-footer-list-item">
         <div className="heart-container-story-card">
           {heart}
           </div>
            <div className="story-card-like-count">0</div>
         </li>
         <li className="story-card-story-footer-list-item">
           share
         </li>
      </ul>
      </Box>
     </Box>
    </Box>
  );
}
