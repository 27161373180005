import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import {Select } from "antd";
import {PlaceCards} from '../Cards'
import {MetaDecorator} from '../../utils'
import ContentLoader from 'react-content-loader'
import NavBar from '../Navbar/Navbar';
import Marker from './marker';
import { Avatar } from 'antd';
import { categoryActions } from '../../actions';
import { config } from "../../config";
import Carousel from "react-multi-carousel";
import ReactGA from 'react-ga';
import {Footer,UpperFooterSection} from '../FooterSection'
import "react-multi-carousel/lib/styles.css";
import './pakmap.css';
import {history} from '../../Helpers'
import { connect } from 'react-redux';
import flag from '../../Assets/all_pakistan.png'

type PakMap = {
  dispatch: any;
  categories: any;
  match:any;
}
const style = {
  height: "55vh",
  width: "100%",
}

const { Option } = Select;
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 9,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 9,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function PakMap(props: PakMap) {

  const { dispatch,categories,match } = props;
  let coordinates: any = [];
  let categoryArray: any = [];
  let places:any=[];
  //navigator.geolocation.getCurrentPosition((position:any)=>{
  //  lat=position.coords.latitude;
  //  lng=position.coords.longitude;
  //   setCenter({lat:lat,lng:lng})
 // })
  const [center] = useState({ lat: 30.3753, lng: 69.3451 });

  const [zoom] = useState(5);
  const [alldata, setalldata] = useState(true);
  const [filter, setfilter] = useState('');
  const [placeFilter, setplaceFilter] = useState('');
  const [province, setprovince] = useState('');
  const [city, setcity] = useState('');
  const [cat,setcat] =useState('');

  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
      dispatch(categoryActions.FetchPakMapCategories());


  }, [dispatch]);
  const MyLoader = () => (
    <ContentLoader
    height={140}
    speed={1}
    viewBox="0 0 380 100"
  >
    <rect x="5" y="5" rx="50" ry="50" width="70" height="70" />
  </ContentLoader>
  );
  const LeftLoader = () => (
    <ContentLoader

    viewBox="0 0 300 100">
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="550" />
    </ContentLoader>
  )
  const TopLoader = () => (
    <ContentLoader
    viewBox="0 0 2680 700">
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="800px" />
    </ContentLoader>
  )
  const RighLoader=()=>(
    <ContentLoader
    viewBox="0 0 300 250">
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="550" />
  </ContentLoader>
  )


  if (!categories) {
    return (
      <div>
      <div>
        <div style={{width:'75%',float:'left'}}>
          <div style={{width:'250px',padding:'16px',marginTop:'100px',marginLeft:'8px',float:'left'}}>
          <TopLoader />
          </div>
          <div style={{width:'250px',padding:'16px',marginTop:'100px',marginLeft:'8px',float:'left'}}>
          <TopLoader />
          </div>
          <div style={{width:'250px',padding:'16px',marginTop:'100px',marginLeft:'8px',float:'left'}}>
          <TopLoader />
          </div>
        <div style={{width:'100%', padding:'16px'}}>
       <LeftLoader/>
       <div style={{marginTop:'20px',width:'150px',float:'left'}}>
         <MyLoader/>
       </div>
       <div style={{marginTop:'20px',width:'150px',float:'left'}}>
         <MyLoader/>
       </div>
       <div style={{marginTop:'20px',width:'150px',float:'left'}}>
         <MyLoader/>
       </div>
       <div style={{marginTop:'20px',width:'150px',float:'left'}}>
         <MyLoader/>
       </div>
       <div style={{marginTop:'20px',width:'150px',float:'left'}}>
         <MyLoader/>
       </div>
       <div style={{marginTop:'20px',width:'150px',float:'left'}}>
         <MyLoader/>
       </div>
       <div style={{marginTop:'20px',width:'150px',float:'left'}}>
         <MyLoader/>
       </div>
       <div style={{marginTop:'20px',width:'150px',float:'left'}}>
         <MyLoader/>
       </div>
      </div>
        </div>
        <div style={{marginTop:'150px',width:'25%',float:'left',padding:'16px'}}>
           <RighLoader/>
        </div>
        <div style={{width:'25%',float:'left',padding:'16px'}}>
           <RighLoader/>
        </div>
      </div>
      </div>
    )
  }
     let icons:any ='';
     let placeimage:any=''
     let title:any="";
     if(categories.length>0)
     {
      categories.forEach((element: any,index:any) => {

        if(element.places._id)
        {
          placeimage=element.places.image[0]
        }
        if(element.places.facilities)
        {
          icons=config.s3BucketUrl+element.places.facilities;
          title =element.places.facilities.title
        }
        else
        {
          icons =null;
          title=null;
        }

        places.push({
          id:element.places._id,
         categoryName:element.categories.categoryName,
         createdDate:element.places.createdDate,
         activities:element.places.facilities,
         placeName:element.places.placeName,
          placeImage:placeimage,
          catId: element.categories._id,
          provience:element.places.province,
          city:element.places.city,
          experienceCount:element.places.experienceCount,
          questionsCount:element.places.questionsCount,
          storiesCount:element.places.storiesCount,
           activityIcon:icons,
           activityTitle:title,
        })
        categoryArray.push({
          id: element.categories._id,
          sortId:element.categories.sortId,
          categoryName: element.categories.categoryName,
          categoryImage: config.s3BucketUrl+ element.categories.categoryImage,
          pin:config.s3BucketUrl+ element.categories.pin,
          placeName:element.places.placeName,
          placeImage:placeimage,
          provience:element.places.province,
          city:element.places.city,
          experienceCount:element.places.experienceCount,
          questionsCount:element.places.questionsCount,
          storiesCount:element.places.storiesCount,
          activityIcon:icons,
          activityTitle:title,
        })
        if (Object.keys(element.places).length) {
          coordinates.push({
            lat: element.places.latitude,
            lng: element.places.longititude,
            placeId:element.places._id,
            category: element.categories.categoryName,
            catId: element.categories._id,
            placeImage: element.places.image,
            placeDescription: element.places.description,
            placeName: element.places.placeName,
            pin:config.s3BucketUrl+ element.categories.pin,
            provience:element.places.province,
            city:element.places.city,
            experienceCount:element.places.experienceCount,
            questionsCount:element.places.questionsCount,
            storiesCount:element.places.storiesCount,
            activityIcon:icons,
            activityTitle:title,
          })
        }
      });

     }
     let meta={
      title:"Pak Map",
      description:"Drop your footprints on the map, and let the others know where you have traveled and will be.",
      imageAlt:"banner image",
      imageUrl:"social-share-image.png"

    }

  let allData = (
    <div style={style}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyAuJYLmzmglhCpBYTn0BjbJhjWYg0fPEEA' }}
        defaultCenter={center}
        zoom={zoom}
        center={center}
        defaultZoom={zoom}
      >
        {
          coordinates.map((item: any) => (
            <Marker
            key={item.placeId}
              lat={item.lat}
              categoryId={item.catId}
              lng={item.lng}
              placeId={item.placeId}
              img={item.category}
              placeImage={item.placeImage}
              placeName={item.placeName}
              desc={item.placeDescription}
              pin={item.pin}
            />
          ))
        }

      </GoogleMapReact>
    </div>
  );
  let filterData=(
    <div>
    {
      places.map((item: any) => (
        <PlaceCards data={item}/>
      ))
    }
   </div>
  );
  let uniqueCategoryArray = categoryArray.reduce((unique: any, o: any) => {
    if (!unique.some((obj: any) => obj.id === o.id)) {
      unique.push(o);
    }
    return unique;
  }, []);
  uniqueCategoryArray.sort((a:any,b:any)=>{
    return a.sortId-b.sortId
  })
  let uniqueCityArray = categoryArray.reduce((unique: any, o: any) => {
    if (!unique.some((obj: any) => obj.city === o.city)) {
      unique.push(o);
    }
    return unique;
  }, []);
  let uniqueProvinceArray = categoryArray.reduce((unique: any, o: any) => {
    if (!unique.some((obj: any) => obj.provience === o.provience)) {
      unique.push(o);
    }
    return unique;
  }, []);
const onProvinenceChange=(e:any)=>{
   setprovince(e);
   provinceFilter(e)
}
const onCategoryChange=(e:any)=>{
  console.log(e)
  setcat(e);
  onCategoryFilter(e);
}
const onCityChange=(e:any)=>{
 setcity(e);
 cityFilter(e)

}
  const onCategoryFilter = (id: any) => {
    let data:any=[];
    let placedFilter:any=[];
    setcat(id);
    if(id==="" && !city && !province)
    {
      data = coordinates;
      placedFilter=places
    }
    else if(id==="" && city && !province)
    {
      data = coordinates.filter((vendor: any) => vendor.city ===city);
      placedFilter=places.filter((vendor: any) =>vendor.city ===city);
    }
    else if(id==="" && !city && province)
    {
      data = coordinates.filter((vendor: any) =>vendor.provience ===province);
      placedFilter=places.filter((vendor: any) =>vendor.provience ===province);
    }
   else if(city)
    {
      data = coordinates.filter((vendor: any) => vendor.catId === id && vendor.city ===city);
      placedFilter=places.filter((vendor: any) => vendor.catId === id && vendor.city ===city);
    }
    else if(province)
    {
      data = coordinates.filter((vendor: any) => vendor.catId === id && vendor.provience ===province);
      placedFilter=places.filter((vendor: any) => vendor.catId === id && vendor.provience ===province);
    }
    else if(city && province )
    {
      data = coordinates.filter((vendor: any) => vendor.catId === id && vendor.provience ===province && vendor.city ===city);
      placedFilter=places.filter((vendor: any) => vendor.catId === id && vendor.provience ===province && vendor.city ===city);
    }
    else
    {
    data = coordinates.filter((vendor: any) => vendor.catId === id);
    placedFilter=places.filter((vendor: any) => vendor.catId === id);
    }

    setalldata(false)
    let filter: any = (
      <div style={style}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAuJYLmzmglhCpBYTn0BjbJhjWYg0fPEEA' }}
          defaultCenter={{ lat: 34.9526, lng: 72.3311 }}
          defaultZoom={5}
        >
          {
            data.map((item: any) => (
              <Marker
              categoryId={item.catId}
              placeId={item.placeId}
                lat={item.lat}
                lng={item.lng}
                img={item.category}
                placeImage={item.placeImage}
                placeName={item.placeName}
                desc={item.placeDescription}
                pin={item.pin}
              />
            ))
          }

        </GoogleMapReact>
      </div>
    )

    let setplacedFilter:any =(
      <div>
      {
        placedFilter.map((item: any) => (
          <PlaceCards data={item}/>
        ))
      }
     </div>
    )
    setplaceFilter(setplacedFilter)
    setfilter(filter)
  }

 const cityFilter = (city:any) => {
  let data:any='';
  let placedFilter:any='';
  if(province)
  {
   if(city)
   {
    data = coordinates.filter((vendor: any) =>  vendor.city === city && vendor.provience ===province);
    placedFilter=places.filter((vendor: any) => vendor.city === city && vendor.provience ===province);
   }
   else
   {
    data = coordinates;
      placedFilter=places
   }

  }
  else if(cat)
  {

    data = coordinates.filter((vendor: any) =>  vendor.city === city && vendor.catId === cat);
    placedFilter=places.filter((vendor: any) => vendor.city === city && vendor.catId === cat);
  }
  else if(province && cat )
  {

    data = coordinates.filter((vendor: any) => vendor.catId === cat && vendor.provience ===province && vendor.city ===city);
    placedFilter=places.filter((vendor: any) => vendor.catId === cat && vendor.provience ===province && vendor.city ===city);
  }
  else if(city==="")
    {
      data = coordinates;
      placedFilter=places
    }
  else
  {

  data = coordinates.filter((vendor: any) => vendor.city === city);
  placedFilter=places.filter((vendor: any) => vendor.city === city);
  }

  setalldata(false);
  let filter: any = (
    <div style={style}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyAuJYLmzmglhCpBYTn0BjbJhjWYg0fPEEA' }}
        defaultCenter={{ lat: 34.9526, lng: 72.3311 }}
        defaultZoom={5}
      >
        {
          data.map((item: any) => (
            <Marker
            categoryId={item.catId}
            placeId={item.placeId}
              lat={item.lat}
              lng={item.lng}
              img={item.category}
              placeImage={item.placeImage}
              placeName={item.placeName}
              desc={item.placeDescription}
              pin={item.pin}
            />
          ))
        }

      </GoogleMapReact>
    </div>
  )
  let setplacedFilter:any =(
    <div>
    {
      placedFilter.map((item: any) => (
        <PlaceCards data={item}/>
      ))
    }
   </div>
  )
  setplaceFilter(setplacedFilter)
  setfilter(filter)
}

  const provinceFilter = (province:any) => {
    let data:any='';
    let placedFilter:any='';
     if(province===""  && !city && !cat)
    {
      data = coordinates;
      placedFilter=places
    }
    else if(province==="" && city && !cat)
    {
      data = coordinates.filter((vendor: any) => vendor.city ===city);
      placedFilter=places.filter((vendor: any) =>vendor.city ===city);
    }
    else if(province==="" && !city && cat)
    {
      data = coordinates.filter((vendor: any) =>vendor.catId ===cat);
      placedFilter=places.filter((vendor: any) =>vendor.catId ===cat);
    }
    else if(city)
    {
      data = coordinates.filter((vendor: any) =>  vendor.provience === province && vendor.city ===city);
      placedFilter=places.filter((vendor: any) => vendor.provience === province && vendor.city ===city);
    }
    else if(cat)
    {

      data = coordinates.filter((vendor: any) =>  vendor.provience === province && vendor.catId === cat);
      placedFilter=places.filter((vendor: any) => vendor.provience === province && vendor.catId === cat);
    }
    else if(city && cat )
    {
      data = coordinates.filter((vendor: any) => vendor.catId === cat && vendor.provience ===province && vendor.city ===city);
      placedFilter=places.filter((vendor: any) => vendor.catId === cat && vendor.provience ===province && vendor.city ===city);
    }
    else
    {
   data = coordinates.filter((vendor: any) => vendor.provience === province);
    placedFilter=places.filter((vendor: any) => vendor.provience === province);
    }

    setalldata(false);
    let filter: any = (
      <div style={style}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAuJYLmzmglhCpBYTn0BjbJhjWYg0fPEEA' }}
          defaultCenter={{ lat: 34.9526, lng: 72.3311 }}
          defaultZoom={5}
        >
          {
            data.map((item: any) => (
              <Marker
              categoryId={item.catId}
              placeId={item.placeId}
                lat={item.lat}
                lng={item.lng}
                img={item.category}
                placeImage={item.placeImage}
                placeName={item.placeName}
                desc={item.placeDescription}
                pin={item.pin}
              />
            ))
          }

        </GoogleMapReact>
      </div>
    )
    let setplacedFilter:any =(
      <div>
      {
        placedFilter.map((item: any) => (
          <PlaceCards data={item}/>
        ))
      }
     </div>
    )
    setplaceFilter(setplacedFilter)
    setfilter(filter)
  }
  const onPlaceChange =(e:any)=>{
    let obj = places.find((o:any) => o.id === e);
    let placeTitle='';
    placeTitle=obj.placeName.replace(/[ ,?]/g,'-')
    history.push(`/travel-places/${placeTitle}-${obj.id}`)
  }
  return (
    <div>
      <NavBar />
      <MetaDecorator title={meta.title} description={meta.description} imageAlt={meta.imageAlt} imageUrl={meta.imageUrl} match={match}/>
       <div className="pak-map-inner-container">
      <div className="filter-container">
        <div className="filters">
          <ul className="fliter-list">
          <li className="filter-item">Filters:</li>
            <li className="filter-item">
            <Select size="large"  placeholder="All Province" onChange={onProvinenceChange}>
            {
              uniqueProvinceArray.map((item: any) => (
              <option value={item.provience}>
                {item.provience}
              </option>
              ))
            }
            </Select>
            </li>
            <li className="filter-item">
            <Select size="large"  placeholder="All Cities"  onChange={onCityChange}>
            {
              uniqueCityArray.map((item: any) => (
              <option value={item.city}>
                {item.city}
              </option>
              ))
            }
            </Select>
            </li>
            <li className="filter-item">
            <Select size="large" className="filter-item-last"  value={cat}  placeholder="All categories" onChange={onCategoryChange}>
              {
                uniqueCategoryArray.map((item: any) => (
              <option   value={item.id}>
                  {item.categoryName}
              </option>
            ))
             }
            </Select>
            </li>
            {/* <li className="filter-item">
            <Select  focusBorderColor="none" placeholder="Mark As">
            <option value="Favourite">Favourite</option>
            <option value="Visted">Visted</option>
            </Select>
              </li> */}
          </ul>

         </div>
        <div className="map-container">
          {
            alldata ? allData : filter
          }

        </div>

        <div className="category-container-pak-map">
        <Carousel responsive={responsive}>
          <div onClick={() => onCategoryFilter("")}>
          <Avatar className="category-avatar"  size={75} src={flag} style={{backgroundColor:'green'}} />
          <p className="category-text">
                  All
                </p>
          </div>
          {
            uniqueCategoryArray.map((item: any) => (
              <div style={{ float: 'left' }} onClick={() => onCategoryFilter(item.id)}>
                <Avatar className="category-avatar" size={75} src={item.categoryImage} />
                <p className="category-text">
                  {item.categoryName}
                </p>
              </div>
            ))
          }
          </Carousel>
        </div>
      </div>
      <div className="side-bar-container">

      <Select
              showSearch
               className="select-places-pak-map"
                  placeholder="Search travel places here"
                  filterOption={(input, option:any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                   onChange={onPlaceChange}
                   size="large" >
                {
                    places.map((item: any) =>
                     <Option value={item.id} >
                      {item.placeName}
                    </Option >
                    )
                }
              </Select>
      <div className="filter-place-container">
      {alldata ? filterData : placeFilter}
      </div>
      </div>
      </div>
      <div className="footer-container-1">
      <UpperFooterSection/>
      <Footer/>
      </div>
    </div>

  )
}
function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    categories: state.FetchPakMapCategories.getAllPakMapCategoriesObject,


  };
}

export const connectedPakMap = connect(mapStateToProps)(PakMap);



