import React,{useEffect} from 'react';
import { connect } from 'react-redux';
import { storyActions } from '../../actions';
import NavBar from '../Navbar/Navbar';
import {Box,Image,Avatar} from '@chakra-ui/core';
import ContentLoader from 'react-content-loader'
import {MetaDecorator} from '../../utils'
import {history} from '../../Helpers'
import {Hero} from '../HeroSection'
import {Cards} from '../Cards';
import ReactGA from 'react-ga';
import {RightFooter} from '../FooterSection'
import advertise from '../../Assets/bridge.jpg';
import './stories.css';

type Stories = {
  dispatch: any;
  data: any;
  storyTeller:any;
  match:any;
}
const MyLoader = () => (
  <ContentLoader
  viewBox="0 0 380 100">
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="350" />
	</ContentLoader>
);
const LeftLoader = () => (
  <ContentLoader
  viewBox="0 0 500 100">
  	<rect x="0" y="0" rx="5" ry="5" width="100%" height="350" />
	</ContentLoader>
)
const RighLoader=()=>(
  <ContentLoader
  viewBox="0 0 500 500">
  <rect x="0" y="0" rx="5" ry="5" width="100%" height="450" />
</ContentLoader>
)

function AllStories(props:Stories) {

  const { dispatch, data,storyTeller,match } = props;
  useEffect(() => {
    ReactGA.initialize("UA-168266330-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    dispatch(storyActions.FethTopSotryTeller());
      dispatch(storyActions.FethStoryUser());
  }, [dispatch]);
  if (!data) {
    return (<div >

      <div style={{width:'100%'}}>
      <MyLoader/>
      </div>
      <div style={{maxWidth:'1366px',margin:'0px auto'}}>
        <div style={{marginTop:'30px',width:'75%',float:'left'}}>
        <div style={{width:'100%', padding:'16px'}}>
       <LeftLoader/>
      </div>
      <div  style={{width:'100%',padding:'16px'}}>
      <LeftLoader/>
      </div>
        </div>
        <div style={{marginTop:'30px',width:'25%',float:'left',padding:'16px'}}>
           <RighLoader/>
        </div>
      </div>

    </div>)
  }
  const fromParent:any='travelStory'
  let meta={
    title:"Travel Stories",
    description:"Travelers shared their mysterious travel stories, with love, experience and full of adventures.",
    imageAlt:"travel stories banner image",
    imageUrl:"social-share-image.png"

  }
   return (
    <Box>
      <NavBar/>
      <MetaDecorator title={meta.title} description={meta.description} imageAlt={meta.imageAlt} imageUrl={data.imageUrl} match={match}/>
      <Hero fromParent={fromParent}/>
      <Box className="stories-main-container">
        <Box className="inner-container-banner-stories">
     <Box className="stories-container-inner-left">
      <div className="stories-container">
       {
           data.map((item: any) =>
           <Cards data={item} key={item._id}  />
           )
       }
        </div>
     </Box>
     <div className="stories-container-inner-right">
       <div className="stories-advertise-container">
       <Image size="350px" src={advertise} alt="advertise" />
       </div>
       <div className="top-story-taller-container">
         <p className="top-story-taller-heading">
          Top story teller
         </p>
         <div>
           <ul className="top-story-teller-list">
           {
             storyTeller?
            storyTeller.map((item: any) =>
           <li className="top-story-teller-list-item" >
           <div style={{ display: "flow-root",cursor:'pointer'}} onClick={()=>{history.push(`/tourist/${item.users.userName}/about`)}} >
              <div style={{ float: "left", marginBottom: "16px" }}>
                <Avatar
                  name={item.users.firstName+" "+item.users.lastName}
                  size="lg"
                  className="question-avatar"
                  src={item.users.image}
                ></Avatar>
              </div>
              <div className="story-card-desc-container">
                <p className="story-card-decs-txt-container">
                  <small className="story-card-desc-txt">{item.users.firstName+" "+item.users.lastName}</small>
                </p>
                <p className="story-card-album-badge">
                    <small className="story-card-timespan-txt">
                     posted {item.storyCount} stories
                    </small>
                </p>
              </div>
            </div>
           </li>
           )
           :null
          }
           </ul>
         </div>
       </div>
       <div className="copyright-footer-container">
       <RightFooter/>
       </div>
     </div>
      </Box>
      </Box>
    </Box>
  )
}

function mapStateToProps(state: any) {
  const { type, message } = state.alert;
  return {
    type,
    message,
    data: state.FetchStoryUser.getAllUserStoriesObject,
    storyTeller:state.FetchTopSotryTeller.getTopSotryTellerObject
  };
}

export const connectedAllStories = connect(mapStateToProps)(AllStories);
