import React from 'react';
import { Box, Heading, Text,Avatar } from "@chakra-ui/core";
import './Tourist.css';
import {RightCircleOutlined } from '@ant-design/icons';
import {history} from '../../Helpers';
import user1 from '../../Assets/sir-mukarram.jpg';
import user2 from '../../Assets/abdul-mannan.jpg';
import user3 from '../../Assets/sana-aga-khan.jpg';
import user4 from '../../Assets/mehwish-malik.jpg';
import user5 from '../../Assets/smirza.jpg';
import user6 from '../../Assets/muzammal.jpg';




function Tourist() {
  return (
    <Box className="tourist-container">
      <Box className="inner-container-banner">
      <Box className="left-sec-tour">
        <Heading className="tourist-heading">
        Top Tourists
        </Heading>
        <Text className="tour-desc">
        Proud tourists at Pakrism,<br />
        travelling across Pakistan.
        </Text>
        <button className="explore-btn-common" onClick={()=>history.push('/signup')}>
        Join Them
            <RightCircleOutlined />
            </button>
      </Box>
      <Box className="right-sec-tour">
        <Box className="avatar-container-tourist">
      <Avatar className="banner-avatar" size="2xl" name="Mukarm Tareen" src={user1} />
      <Avatar className="banner-avatar" size="2xl" name="Abdul Manan" src={user2} />
      <Avatar className="banner-avatar" size="2xl" name="Sana Agha" src={user3} />
      <Avatar className="banner-avatar" size="2xl" name="Mehwish Malik" src={user4} />
      <Avatar className="banner-avatar" size="2xl" name="Smirza" src={user5} />
      <Avatar className="banner-avatar" size="2xl" name="Muzammal" src={user6} />
      </Box>
      </Box>
      </Box>
    </Box>
  )
}

export default Tourist;
