
import {alertActions} from '.';
import {experienceServices} from '../services';
import {experiencesConstants} from '../constants';
import {history} from '../Helpers';


const FetchPlaceExperiences=(id: any)=>{
  return (dispatch: any) => {
    dispatch(request(id));
    experienceServices.FetchPlaceExperiences(id).then(
      (res) => {
        // history.push('/place')
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: experiencesConstants.GET_EXPERIENCE_BY_PLACE_REQUEST, id };
  }
  function success(user: any) {
    return { type: experiencesConstants.GET_EXPERIENCE_BY_PLACE_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: experiencesConstants.GET_EXPERIENCE_BY_PLACE_FAILURE, error };
  }
}

const FetchPlaceExperiencesAdmin=()=>{
  return (dispatch: any) => {
    dispatch(request());
    experienceServices.FetchAllPlaceExperiencesAdmin().then(
      (res) => {
        // history.push('/place')
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: experiencesConstants.GET_ALL_EXPERIENCE_ADMIN_REQUEST};
  }
  function success(user: any) {
    return { type: experiencesConstants.GET_ALL_EXPERIENCE_ADMIN_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: experiencesConstants.GET_ALL_EXPERIENCE_ADMIN_FAILURE, error };
  }
}

const FetchAllPlaceExperiences=(id: any)=>{
  return (dispatch: any) => {
    dispatch(request(id));
    experienceServices.FetchAllPlaceExperiences(id).then(
      (res) => {
        // history.push('/place')
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: experiencesConstants.GET_ALL_EXPERIENCE_BY_PLACE_REQUEST, id };
  }
  function success(user: any) {
    return { type: experiencesConstants.GET_ALL_EXPERIENCE_BY_PLACE_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: experiencesConstants.GET_EXPERIENCE_BY_PLACE_FAILURE, error };
  }
}

const FetchExperienceAdmin=(id: any)=>{
  return (dispatch: any) => {
    dispatch(request(id));
    experienceServices.FetchExperienceAdmin(id).then(
      (res) => {
        // history.push('/place')
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: experiencesConstants.GET_EXPERIENCE_ADMIN_REQUEST, id };
  }
  function success(user: any) {
    return { type: experiencesConstants.GET_EXPERIENCE_ADMIN_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: experiencesConstants.GET_EXPERIENCE_ADMIN_FAILURE, error };
  }
}

const UpdateExperienceAdmin =(data:any,requestId:any)=>{
  return (dispatch: any) => {
    dispatch(request(requestId));
    experienceServices.UpdateExperienceAdmin(data,requestId)
      .then((response) => response)
      .then((data: any) => {
        history.go(0)
        if (data.status == "200") {

          dispatch(success(data));
          dispatch(
            alertActions.success(data.data.message)
          );
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: experiencesConstants.UPDATE_EXPERIENCE_ADMIN_REQUEST, data };
    }
    function success(user: any) {
      return { type: experiencesConstants.UPDATE_EXPERIENCE_ADMIN_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: experiencesConstants.UPDATE_EXPERIENCE_ADMIN_FAILURE, error };
    }
  };
}

const DeleteExperienceAdmin =(data:any,requestId:any)=>{
  return (dispatch: any) => {
    dispatch(request(requestId));
    experienceServices.DeleteExperienceAdmin(data,requestId)
      .then((response) => response)
      .then((data: any) => {
        history.go(0)
        if (data.status == "200") {

          dispatch(success(data));
          dispatch(
            alertActions.success(data.data.message)
          );
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: experiencesConstants.DELETE_EXPERIENCE_ADMIN_REQUEST, data };
    }
    function success(user: any) {
      return { type: experiencesConstants.DELETE_EXPERIENCE_ADMIN_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: experiencesConstants.DELETE_EXPERIENCE_ADMIN_FAILURE, error };
    }
  };
}


export const experiencesAction={
  FetchPlaceExperiences,
  FetchAllPlaceExperiences,
  FetchPlaceExperiencesAdmin,
  FetchExperienceAdmin,
  UpdateExperienceAdmin,
  DeleteExperienceAdmin
}
