import React,{useState} from 'react';
import {Avatar} from '@chakra-ui/core'
import {config} from '../../config';
import ImageGallery from 'react-image-gallery';
import moment from 'moment';
import './storycard.css';
import {Modal} from 'antd';
import {history} from '../../Helpers'
import "react-image-gallery/styles/css/image-gallery.css";


type SingleAlbumCard={
  data:any;
  dispatch:any;
  albumCat:any;
}


 export function SingleAlbumCard(props:any) {

  const {data,dispatch,match} = props;
  let id = match.params.id.slice((match.params.id.length - 24))
  const [visible, setvisible] = useState(false);
  const [arr, setArr] = useState<any[]>([])
  let res:any=[];
 const showModal = (item:any)=>{

  setArr(res)
    setvisible(true);

  };
  const handleOk = (e: any) => {
    setvisible(false);
  };

  const handleCancel = (e: any) => {
    setvisible(false);
  };
  let image:any=[]
   for(const img of data.albums.image)
   {
     image.push({
      original:config.s3BucketUrl+img.imageName,
      thumbnail:config.s3BucketUrl+img.imageName
     })
   }
  return (
    <div className="single-albums-inner-container">
       <Modal
          title=""
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
         footer={null}
         className="modal-carosel"
         >
          <div>
          <div className="carousel-container">
          {
            <div className="place-component-carosal-container">
              <ImageGallery items={image} showPlayButton={false} />
            </div>
                 }
            </div>
           </div>
        </Modal>

            <div className="story-card-user-container" onClick={()=>{history.push(`/tourist/${data.users.userName}/about`)}}>
              <div style={{ float: "left", marginBottom: "16px"}} >
                <Avatar
                  name={data.users.firstName+" "+data.users.lastName}
                  size="lg"
                  className="question-avatar"
                  src={data.users.image}
                ></Avatar>
              </div>
              <div className="story-card-desc-container">
                <p className="story-card-decs-txt-container">
                  <small className="story-card-desc-txt">{data.users.firstName+" "+data.users.lastName}</small>
                  &nbsp;<small className="story-card-tourist-txt">created album </small>
                 <small className="story-card-timespan-txt">{moment(data.createdDate).format("MMM DD,YYYY")}</small>
                </p>
                <p className="story-card-album-badge">
                  <small className="story-card-category-name story-card-place-name">
                    {data.albums.image.length} photos

                    </small>
                    &nbsp;
                    <small className="story-card-timespan-txt" >
                      {moment(data.albums.createdDate).fromNow()}
                    </small>
                </p>
              </div>
            </div>
            <div style={{marginBottom:'30px',background:'red'}}>
            <div className="story-card-story-container" >
            <p className="single-story-card-story-title">
            {data.title}
            </p>
            <div className="story-card-story-desc-container">
              <p className="story-card-story-desc single-story-desc">
              {data.description}
              </p>
            </div>
            <div>
              {
            data.albums.image.map((item: any,i:any) =>
             <div className="single-album-image-banner">
             <img className="single-album-image" alt="Lake" src={config.s3BucketUrl+item.imageName}  onClick={()=>showModal(item._id)} />
           </div>
            )}
            </div>
            </div>
          </div>
    </div>
  )
}

// function mapStateToProps(state: any) {
//   const { type, message } = state.alert;
//   return {
//     type,
//     message,
//     // albumCat: state.FetchEditPlaceCategory.fetchEditPlaceCategoryObject,
//   };
// }

// export const connectedSingleAlbumCard = connect(mapStateToProps)(SingleAlbumCard);
