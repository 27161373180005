import { alertActions } from "../actions";
import { config } from "../config";
import axios from "axios";

const SendFriendRequest =(values:any)=>{
  return axios
    .post(`${config.apiurl}/user/sent-friend-request/`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

const FetchSentRequests=(id:any)=>{
  return fetch(`${config.apiurl}/user/get-sent-requests/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
        // let bytes  = CryptoJS.AES.decrypt(ree, '1234567890123456789');
        // let originalText = bytes.toString(CryptoJS.enc.Utf8);
      return ree;
    });
}
const FetchReceivedRequests=(id:any)=>{
  return fetch(`${config.apiurl}/user/get-received-requests/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
        // let bytes  = CryptoJS.AES.decrypt(ree, '1234567890123456789');
        // let originalText = bytes.toString(CryptoJS.enc.Utf8);
      return ree;
    });
}

const AcceptFriendRequest =(values:any,requestId:any)=>{
  return axios
    .patch(`${config.apiurl}/user/accept-friend-request/${requestId}`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

const FetchFriends=(id:any)=>{
  return fetch(`${config.apiurl}/user/get-friends/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((ree) => {
      return ree;
    });
}

function UnfriendRequest(id: any) {
  return axios
    .delete(`${config.apiurl}/user/unfriend/${id}`,{
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}

function CancelFriendRequest(values: any) {
  return axios
    .patch(`${config.apiurl}/user/cancel-friend-request`, values, {
      // receive two parameter endpoint url ,form data
      validateStatus: () => true,
    })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      if (err === "TypeError: Failed to fetch") {
        alertActions.error("Server is not responding");
      } else {
        alertActions.error(err);
      }
    });
}
export const friendsServices={
  SendFriendRequest,
  FetchSentRequests,
  AcceptFriendRequest,
  FetchFriends,
  UnfriendRequest,
  CancelFriendRequest,
  FetchReceivedRequests
}
