import {storiesConstants} from '../constants';

export function FetchAllStories(state={getAllStoriesObject:false},action:any)
{

    switch (action.type) {
        case storiesConstants.GET_ALL_STORIES_REQUEST:
          return state;
          case storiesConstants.GET_ALL_STORIES_REQUEST_SUCCESS:
          return{
            ...state,
            getAllStoriesObject:action.user
          };
      default:
        return state
    }
}

export function FetchStoryUser(state={getAllUserStoriesObject:false},action:any)
{

    switch (action.type) {
        case storiesConstants.GET_ALL_STORY_USER_REQUEST:
          return state;
          case storiesConstants.GET_ALL_STORY_USER_SUCCESS:
          return{
            ...state,
            getAllUserStoriesObject:action.user
          };
      default:
        return state
    }
}


export function FetchEditStories(state={fetchEditStoriesObject:''},action:any)
{
  switch (action.type) {
    case storiesConstants.EDIT_STORY_REQUEST:
      return state;
      case storiesConstants.EDIT_STORY_SUCCESS:
      return{
        ...state,
        fetchEditStoriesObject:action.user
      };
  default:
    return state
}
}

export function FetchTopSotryTeller(state={getTopSotryTellerObject:false},action:any)
{

    switch (action.type) {
        case storiesConstants.GET_TOP_STORY_TELLER_REQUEST:
          return state;
          case storiesConstants.GET_TOP_STORY_TELLER_SUCCESS:
          return{
            ...state,
            getTopSotryTellerObject:action.user
          };
      default:
        return state
    }
}

export function GetStory(state={getStoryObject:false},action:any)
{
  switch (action.type) {
    case storiesConstants.GET_STORY_REQUEST:
      return state;
      case storiesConstants.GET_STORY_SUCCESS:
      return{
        ...state,
        getStoryObject:action.user
      };
  default:
    return state
}
}

export function GetRelatedStory(state={getRelatedStoryObject:false},action:any)
{
  switch (action.type) {
    case storiesConstants.GET_RELETED_STORY_REQUEST:
      return state;
      case storiesConstants.GET_RELETED_STORY_SUCCESS:
      return{
        ...state,
        getRelatedStoryObject:action.user
      };
  default:
    return state
}
}

export function GetPlaceStory(state={getPlaceStoryObject:false},action:any)
{
  switch (action.type) {
    case storiesConstants.GET_PLACE_STORY_REQUEST:
      return state;
      case storiesConstants.GET_PLACE_STORY_SUCCESS:
      return{
        ...state,
        getPlaceStoryObject:action.user
      };
  default:
    return state
}
}

export function GetCategoryStory(state={getCategoryStoryObject:false},action:any)
{
  switch (action.type) {
    case storiesConstants.GET_CATEGORY_STORY_REQUEST:
      return state;
      case storiesConstants.GET_CATEGORY_STORY_SUCCESS:
      return{
        ...state,
        getCategoryStoryObject:action.user
      };
  default:
    return state
}
}

export function GetMyStory(state={getMyStoryStoryObject:false},action:any)
{
  switch (action.type) {
    case storiesConstants.GET_MY_STORY_REQUEST:
      return state;
      case storiesConstants.GET_MY_STORY_SUCCESS:
      return{
        ...state,
        getMyStoryStoryObject:action.user
      };
  default:
    return state
}
}

export function GetDraftStory(state={getDraftStoryObject:false},action:any)
{
  switch (action.type) {
    case storiesConstants.GET_DRAFT_STORY_REQUEST:
      return state;
      case storiesConstants.GET_DRAFT_STORY_SUCCESS:
      return{
        ...state,
        getDraftStoryObject:action.user
      };
  default:
    return state
}
}


export function FetchStoryCount(state={getStoriesCountObject:false},action:any)
{

    switch (action.type) {
        case storiesConstants.GET_STORIES_COUNT_REQUEST:
          return state;
          case storiesConstants.GET_STORIES_COUNT_SUCCESS:
          return{
            ...state,
            getStoriesCountObject:action.user
          };
      default:
        return state
    }
}

