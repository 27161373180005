import { answerConstants } from "../constants";
import { answersService } from "../services";
import { alertActions } from ".";
import { history } from "../Helpers";

export const answersActions = {
  FetchAnswersByQuestion,
  AddAnswer,
  FetchUserAnswers,
  FetchAnswersByQuestionAdmin,
  DeleteAnswerAdmin
};

function FetchAnswersByQuestion(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    answersService.FetchAnswersByQuestion(id).then(
      (res) => {
        // history.push('/place')
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: answerConstants.GET_ANSWER_REQUEST, id };
  }
  function success(user: any) {
    return { type: answerConstants.GET_ANSWER_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: answerConstants.GET_ANSWER_FAILURE, error };
  }
}

function FetchAnswersByQuestionAdmin(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    answersService.FetchAnswersByQuestionAdmin(id).then(
      (res) => {
        // history.push('/place')
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: answerConstants.GET_ANSWER_BY_QUESTION_ADMIN_REQUEST, id };
  }
  function success(user: any) {
    return { type: answerConstants.GET_ANSWER_BY_QUESTION_ADMIN_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: answerConstants.GET_ANSWER_BY_QUESTION_ADMIN_FAILURE, error };
  }
}

function FetchUserAnswers(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));
    answersService.FetchUserAnswers(id).then(
      (res) => {
        // history.push('/place')
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id: any) {
    return { type: answerConstants.GET_USER_ANSWER_REQUEST, id };
  }
  function success(user: any) {
    return { type: answerConstants.GET_USER_ANSWER_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: answerConstants.GET_USER_ANSWER_FAILURE, error };
  }
}

function AddAnswer(data: any) {
  let url = window.location.pathname;
  let result= url.split('/');
  return (dispatch: any) => {
    dispatch(request(data));
    answersService
      .AddAnswers(data)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "201") {
          dispatch(success(data));
          dispatch(
            alertActions.success("You have successfully added an answer")
          );
          history.push(`/question/${result[2]}/${result[3]}/${result[4]}`);
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any) {
      return { type: answerConstants.ADD_ANSWER_REQUEST, data };
    }
    function success(user: any) {
      return { type: answerConstants.ADD_ANSWER_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: answerConstants.ADD_ANSWER_FAILURE, error };
    }
  };
}

function DeleteAnswerAdmin(data: any, id: any) {
  return (dispatch: any) => {
    dispatch(request(data, id));
    answersService
      .DeleteAnswerAdmin(data, id)
      .then((response) => response)
      .then((data: any) => {
        if (data.status == "200") {
          dispatch(success(data));
          dispatch(alertActions.success("You have successfully delete an answer ")
          );
          history.go(0);
        }
      })
      .catch((err: any) => {
        dispatch(failure(err));
        dispatch(alertActions.error(err.toString()));
      });
    function request(data: any, id: any) {
      return { type: answerConstants.DELETE_ANSWER_ADMIN_REQUEST, data, id };
    }
    function success(user: any) {
      return { type: answerConstants.DELETE_ANSWER_ADMIN_SUCCESS, user };
    }
    function failure(error: any) {
      return { type: answerConstants.DELETE_ANSWER_ADMIN_FAILURE, error };
    }
  };
}

