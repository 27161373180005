export const friendsConstants ={
SEND_FRIEND_REQUEST:"SEND_FRIEND_REQUEST",
SEND_FRIEND_SUCCESS:"SEND_FRIEND_SUCCESS",
SEND_FRIEND_FAILURE:"SEND_FRIEND_FAILURE",

GET_SENT_REQUEST:"GET_SENT_REQUEST",
GET_SENT_REQUEST_SUCCESS:"GET_SENT_REQUEST_SUCCESS",
GET_SENT_REQUEST_FAILURE:"GET_SENT_REQUEST_FAILURE",

GET_RECEIVED_REQUEST:"GET_RECEIVED_REQUEST",
GET_RECEIVED_REQUEST_SUCCESS:"GET_RECEIVED_REQUEST_SUCCESS",
GET_RECEIVED_REQUEST_FAILURE:"GET_RECEIVED_REQUEST_FAILURE",


ACCEPT_FRIEND_REQUEST:"ACCEPT_FRIEND_REQUEST",
ACCEPT_FRIEND_SUCCESS:"ACCEPT_FRIEND_SUCCESS",
ACCEPT_FRIEND_FAILURE:"ACCEPT_FRIEND_FAILURE",

GET_MY_FRIENDS:"GET_MY_FRIENDS",
GET_MY_FRIEND_SUCCESS:"GET_MY_FRIEND_SUCCESS",
GET_MY_FRIEND_FAILURE:"GET_MY_FRIEND_FAILURE",

UNFRIEND_FRIEND_REQUEST:"UNFRIEND_FRIEND_REQUEST",
UNFRIEND_FRIEND_SUCCESS:"UNFRIEND_FRIEND_SUCCESS",
UNFRIEND_FRIEND_FAILURE:"UNFRIEND_FRIEND_FAILURE",

CANCEL_FRIEND_REQUEST:"CANCEL_FRIEND_REQUEST",
CANCEL_FRIEND_SUCCESS:"CANCEL_FRIEND_SUCCESS",
CANCEL_FRIEND_FAILURE:"CANCEL_FRIEND_FAILURE",

};
