import React from 'react';
import { Box, Heading, Text, Image} from "@chakra-ui/core";
import travelPlaces from '../../Assets/place illustration-01.svg';
import {RightCircleOutlined } from '@ant-design/icons';
import {history} from '../../Helpers';
import './travelplaces.css'


export function TravelPlanSection() {
  return (
    <Box className="travel-plan-container">
     <Box className="inner-container-banner container-travel-places-inner">
       <Box className="travel-places-left-sec">
       <Heading className="tourist-heading travel-place-heading">
       Travel Places
        </Heading>
        <Text className="tour-desc">
        There were hidden gems we discovered for you to explore.<br />
        New rewarding way to travel, explore & share.
        </Text>
        <button className="explore-btn-common" onClick={()=>history.push('/pak-map')}>
        See All Places
            <RightCircleOutlined />
            </button>
       </Box>
       <Box className="travel-places-right-sec">
          <Image src={travelPlaces}/>
       </Box>
     </Box>
    </Box>
  )
}


