import React, { useEffect, useState } from "react";
import {userActions} from '../../actions';
import {FacebookShareButton, FacebookIcon} from "react-share";
import {Tag,Modal,message} from 'antd';
import {history} from '../../Helpers'
import {Tooltip} from '@chakra-ui/core'
import flagLine from '../../Assets/icon_place_mark_visited_inactive_white.svg';
import flagFill from '../../Assets/icon_place_mark_visited_active_white.svg';
import heartLine from '../../Assets/icon_place_mark_favorite_inactive_white.svg';
import heartFill from '../../Assets/icon_place_mark_favorite_active_white.svg';
import share from '../../Assets/icon_share_place_white.svg';




import './places.css';

import 'remixicon/fonts/remixicon.css'

export const PlaceDataCard = (props: any) => {
  let user = JSON.parse(localStorage.getItem('user')!)
  const { data,placeId,match,dispatch } = props;
  const [visible,setVisible] = useState(false);
  const [favourite,setFavourite] = useState(false)
  const [visted,setVisted] = useState(false)
  useEffect(()=>{
    if(user)
    {
      let fav=user.user.favPlaces.includes(placeId);
      setVisted(user.user.vistedPlaces.includes(placeId))
      if(fav)
      {
        setFavourite(true);
      }
    }


  },[data])

  const showModal = () => {
    setVisible(true)
  };
  const handleOk = (e:any) => {
    setVisible(false)
  };

  const handleCancel = (e:any)  => {
    setVisible(false)
  };

  const onFav=()=>{
    setFavourite(true)
    let place:any={placeId};
    dispatch(userActions.AddFavPlace(place,user.id));
    message.success('you hav successfully added a place as favourite')
  }


const onUnFav=()=>{
  setFavourite(false)
  let place:any={placeId};
  dispatch(userActions.RemoveFavPlace(place,user.id));
  message.success('you hav successfully removed a place as favourite')
}


const onVisted=()=>{
  setVisted(true)
  let place:any={placeId};
  dispatch(userActions.AddVistedPlace(place,user.id));
  message.success('you hav successfully added a place as visted')
}


const onUnVisted=()=>{
  setVisted(false)
  let place:any={placeId};
  dispatch(userActions.RemoveVistedPlace(place,user.id));
  message.success('you hav successfully removed a place as visted')
}
  return (
    <div className="Place-data-container">
       <div className="place-card-reaction-container">
         <ul className="place-card-reaction-list">
           <li className="reaction-item">
           {
          user?
          favourite?
          <Tooltip label="Marked as favorite"  aria-label="Mark as favorite" bg="#37b44e" placement="top" hasArrow>
          <p style={{cursor:'pointer'}}><img src={heartFill} width="32px" onClick={onUnFav} /></p>
          </Tooltip>
          :
          <Tooltip label="Mark as favorite"  aria-label="Marked as favorite" bg="#37b44e" placement="top" hasArrow>
          <p style={{cursor:'pointer'}}><img onClick={onFav} src={heartLine} width="32px"/></p>
          </Tooltip>
          :
          <Tooltip label="Mark as favorite"  aria-label="Marked as favorite" bg="#37b44e" placement="top" hasArrow>
          <p style={{cursor:'pointer'}}><img onClick={()=>{history.push('/login')}} src={heartLine} width="32px"/></p>
          </Tooltip>
        }
           </li>
           <li className="reaction-item">
           {
             user?
          visted?
          <Tooltip label="Marked as visted" className="tooltip-marked"  aria-label="Marked as visted" bg="#37b44e" placement="top" hasArrow>
          <p style={{marginTop:'5px',cursor:'pointer'}}><img src={flagFill} width="32px" onClick={onUnVisted} /></p>
          </Tooltip>
          :
          <Tooltip label="Mark as visted"  aria-label="Mark as visted" bg="#37b44e" placement="top" hasArrow>
          <p style={{marginTop:'5px',cursor:'pointer'}}><img onClick={onVisted} src={flagLine} width="32px"/></p>
          </Tooltip>
          :
          <Tooltip label="Mark as visted"  aria-label="Mark as visted" bg="#37b44e" placement="top" hasArrow>
          <p style={{marginTop:'5px',cursor:'pointer'}}><img onClick={()=>{history.push('/login')}} src={flagLine} width="32px"/></p>
          </Tooltip>
        }
           </li>
           <li className="reaction-item">
           <Tooltip label="Share on social media"  aria-label="Share on social media" bg="#37b44e" placement="top" hasArrow>
        <p style={{marginTop:'5px',cursor:'pointer'}}><img src={share} width="32px" onClick={showModal} /></p>
        </Tooltip>
           </li>
         </ul>
        <Modal
          title="Share social"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <FacebookShareButton
                url={`https://pr-app.pakrism.com/place/${match.params.id}`}
                quote={"Pakrism- World is yours to explore"}
                hashtag="#PakrismPlaces"
                className="" >
                 <FacebookIcon size={36} round={true} />
              </FacebookShareButton>

        </Modal>
        </div>
      <div className="place-card-data-container">
        <div className="place-data-heading-container">
          <p className="place-card-place-name-heading">
            {data.placeName}
          </p>
          <p className="story-card-category-name story-card-place-name">
            {data.categories.categoryName}
          </p>
          <p className="place-card-place-name-desc">
            {data.description}
          </p>
        </div>
        <div className="place-card-location-container">
          <p className="place-card-location-heading">
            Location
          </p>
          <div className="place-card-location-attribute">
            <div className="place-card-icon-container">
            <div className="user-data-icon-wrapper">
            <i className="ri-map-pin-fill user-data-icons"></i>
            </div>
        </div>
            <div className="place-card-address-container">
              {data.address}
            </div>
          </div>
          <div>
            <div className="place-card-icon-container">
            <div className="user-data-icon-wrapper">
            <i className="ri-phone-fill user-data-icons"></i>
            </div>
        </div>
            <div className="place-card-phone-container">
              {
              data.phone?
              <p>{data.phone}</p>:
              <p>Information will be updated soon</p>
              }
            </div>
          </div>
        </div>
        <div className="place-card-available-main-container">
        <div className="place-card-headings-container">
            <p className="place-card-timings-heading">
             Available for
             </p>
          </div>
          <div className="tag-container">
          {
          data.availableFor.map((item: any) => <Tag>{item.split(',')}</Tag>)
          }
          </div>
        </div>
        <div className="place-card-available-main-container">
        <div className="place-card-headings-container">
            <p className="place-card-timings-heading">
             Accessible via
             </p>
          </div>
          <div className="tag-container">
          {
            data.accessibleVia.length>0?
            data.accessibleVia.map((item: any) => <Tag>{item}</Tag>
          ):
          <p>Information will be updated soon</p>
          }
          </div>
        </div>
        <div className="place-card-available-main-container">
        <div className="place-card-headings-container">
            <p className="place-card-timings-heading">
             Foods available
             </p>
          </div>
          <div >
          {
            data.foodsAvailable?
          <p>{data.foodsAvailable}</p>
           :
          <p>Information will be updated soon</p>
          }
          </div>
        </div>
        <div className="place-card-available-main-container">
        <div className="place-card-headings-container">
            <p className="place-card-timings-heading">
             Road condition
             </p>
          </div>
          <div>
          {
            data.roadCondition?
          <p>{data.roadCondition}</p>
           :
          <p>Information will be updated soon</p>
          }
          </div>
        </div>
      </div>
    </div>
  );
}
