import React, { useState } from 'react';
import {Heading, Text} from "@chakra-ui/core";
import {history} from '../../Helpers';
import './travel-albums.css';
import {RightCircleOutlined } from '@ant-design/icons';
import river from '../../Assets/river.jpg';
import bridge from '../../Assets/bridge.jpg';
import lake from '../../Assets/lake_road.jpg';
import road from '../../Assets/road.jpg';




export function TravelAlbumsSection() {
  let user = JSON.parse(localStorage.getItem('user')!)
  let maxData:any='';
  let minData:any='';
  const [width] =useState(window.innerWidth)

  if(width >= 1100)
  {
  maxData=
  <div className="inner-content">
  <div className="travel-places-left-sec">
    <div className="max">
    <div className="first-img-travel-album">
    </div>
    <div className="second-img-travel-album">
    </div>
    <div className="third-img-travel-album">
    </div>
    <div className="forth-img-travel-album">
    </div>
    </div>
 </div>
   <div className="travel-places-right-sec travel-album-right">
   <Heading className="travel-album-headings">
   Travel Albums
    </Heading>
    <Text className="tour-desc">
    Let the world see Pakistan with your eyes.<br />
    Breath taking scenes, full of majesty places,<br />
    Travel memories, captured beauty & random clicks.
    </Text>
    <div className="join-them album-btn">
            <button className="hero-join-btn travel-album-btn" onClick={()=>history.push('/travel-albums/create-album')} >
            Create Your Album
                </button>

        <button className="explore-btn-common" onClick={()=>history.push('/travel-albums')}>
        See All Albums
        <RightCircleOutlined />
        </button>
    </div>
   </div>
   </div>
  }
  else{
   minData=
   <div className="min-container">
      <div className="travel-content">
   <Heading className="travel-album-headings">
   Travel Albums
    </Heading>
    <Text className="tour-desc">
    Let the world see Pakistan with your eyes.<br />
    Breath taking scenes, full of majesty places,<br />
    Travel memories, captured beauty & random clicks.
    </Text>
    <div className="join-them album-btn">
    <button className="hero-join-btn travel-album-btn" onClick={()=>history.push('/login')} >
    Create Your Album
        </button>
        <button className="explore-btn-common" onClick={()=>history.push('/login')}>
        See All Albums
        <RightCircleOutlined />
        </button>
    </div>
   </div>
   <div className="min-data">
   <ul className="img-list">
  <li className="img-item">
   <img src={river} alt={river} />
   </li>
   <li className="img-item">
   <img src={bridge} alt={bridge} />
   </li>
   <li className="img-item">
   <img src={lake} alt={lake} />
   </li>
   <li className="img-item">
   <img src={road} alt={road} />
   </li>
   </ul>
   </div>
   </div>

  }
  return (
    <div className="travel-album-container">
       <div className="container-travelplan">
    <div className="inner-container-banner ">
          {maxData}
       {minData}
     </div>
     </div>
    </div>
  )
}


